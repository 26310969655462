export function setDotBg(status: string) {
  switch (status) {
    case 'Scheduled':
      return '#fc6566'
    case 'Completed':
      return '#22c55e'
    case 'Cancelled':
      return '#dc2626'
  }
}
