import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export function InfoBox({
  setCompanyLocationId,
  setCompanyId
}: {
  setCompanyLocationId: React.Dispatch<React.SetStateAction<string>>
  setCompanyId: React.Dispatch<React.SetStateAction<string>>
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { employee, company } = location.state
  const [info, setInfo] = useState({
    employee: {
      name: '',
      gender: '',
      age: '',
      position: '',
      department: ''
    },
    company: {
      id: '',
      name: '',
      location: {
        id: '',
        name: ''
      },
      address: ''
    }
  })
  useEffect(() => {
    if (location.state) {
      setInfo({
        employee: {
          name: employee.firstName + ' ' + employee.lastName,
          gender: employee.gender,
          age: employee.age,
          position: employee.position,
          department: employee.department
        },
        company: {
          id: company.id,
          name: company.name,
          location: {
            id: company.location.id,
            name: company.location.name
          },
          address: company.address
        }
      })
      setCompanyId(company.id)
      setCompanyLocationId(company.location.id)
    }
  }, [location])
  return (
    <>
      <div className='flex items-start justify-between'>
        <div className='flex flex-col'>
          <h2 className='customHead2'>
            {info.employee.name}, {info.employee.age}, {info.employee.gender}
          </h2>
          <h4 className='customHead4 my-1'>
            {info.company.name} {info.company.location.name}, {info.company.address}
          </h4>
          <h4 className='customHead4'>
            {info.employee.department}, {info.employee.position}
          </h4>
        </div>
        <IconButton
          onClick={() => navigate(`/scheduled-appointment-overview/${location.state.appReqId}`)}
        >
          <ChevronLeftIcon className='w-6' />
          <label className='text-[13px]'>{t('EXAMINATIONS.BACK_TO_APPOINTMENT')}</label>
        </IconButton>
      </div>
    </>
  )
}
