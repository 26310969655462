import { EyeIcon } from '@heroicons/react/20/solid'
import { LockClosedIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { QueryObject, UserListItem, UserSearch } from 'src/interfaces/users'
import { getAllInternalUsers, remove } from 'src/redux/actions/userActions'
import { USER_EDIT_PASSWORD_RESET } from 'src/redux/constants/authConstants'
import {
  USER_CREATE_RESET,
  USER_DELETE_RESET,
  USER_EDIT_RESET
} from 'src/redux/constants/userConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { columns } from './helpers/TableColumnsHelper'

export const UsersList = () => {
  const { t } = useTranslation()
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('DE')
  const savedLng = i18next.language
  const [firstLoad, setFirstLoad] = useState(true)
  const [sameUserError, setSameUserError] = useState(false)
  const deleteButtonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('USER_INFO.FIRST_NAME')}</p>
  }
  columns[1].renderHeader = () => {
    return <p className='font-bold'>{t('USER_INFO.LAST_NAME')}</p>
  }
  columns[2].renderHeader = () => {
    return <p className='font-bold'>{t('USER.ROLE')}</p>
  }
  columns[3].renderHeader = () => {
    return <p className='font-bold'>{t('PRACTICES.PRACTICE')}</p>
  }
  columns[4].renderCell = (params: GridRenderCellParams) => {
    return (
      <div>
        <IconButton
          onClick={() => navigate(`/users/edit-password/${params.id}`)}
          title={t('GENERIC.EDIT_PASSWORD')}
        >
          <LockClosedIcon className='w-5' />
        </IconButton>
        <IconButton onClick={() => onOpenModal(params.id)} title={t('GENERIC.DELETE')}>
          <TrashIcon className='w-5' />
        </IconButton>
        <IconButton onClick={() => navigate(`/users/edit/${params.id}`)} title={t('GENERIC.EDIT')}>
          <PencilSquareIcon className='w-5' />
        </IconButton>
      </div>
    )
  }
  const [limit, setLimit] = useState(config.limit)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userGetAllInternal, userDelete, userCreate, userEditPassword, userEdit, userSignin } =
    useAppSelector((state) => state)
  const { loading: getAllLoading, users, count } = userGetAllInternal
  const { loading: userDeleteLoading, success: successDelete, error: deleteError } = userDelete
  const { success: userCreateSuccess } = userCreate
  const { success: userEditSuccess } = userEdit
  const { success: editPasswordSuccess } = userEditPassword
  const { user } = userSignin

  const [page, setPage] = useState<string | number>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [tableRows, setTableRows] = useState<UserListItem[]>([])
  const [userId, setUserId] = useState<GridRowId>('')
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  useEffect(() => {
    const practiceNameFilter =
      queryObject.field === 'practice' && queryObject.value ? queryObject.value : ''
    const practiceNameFilterType =
      queryObject.field === 'practice' && queryObject.operator ? queryObject.operator : 'contains'
    const firstNameFilter =
      queryObject.field === 'firstName' && queryObject.value ? queryObject.value : ''
    const firstNameFilterType =
      queryObject.field === 'firstName' && queryObject.operator ? queryObject.operator : 'contains'
    const lastNameFilter =
      queryObject.field === 'lastName' && queryObject.value ? queryObject.value : ''
    const lastNameFilterType =
      queryObject.field === 'lastName' && queryObject.operator ? queryObject.operator : 'contains'
    const quickFilter =
      queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
    dispatch(
      getAllInternalUsers({
        limit,
        page,
        firstNameFilter,
        firstNameFilterType,
        lastNameFilter,
        lastNameFilterType,
        practiceNameFilter,
        practiceNameFilterType,
        quickFilter
      } as UserSearch)
    )
  }, [page, queryObject, limit])

  useEffect(() => {
    dispatch({ type: USER_DELETE_RESET })
    return () => {
      dispatch({ type: USER_EDIT_PASSWORD_RESET })
      dispatch({ type: USER_EDIT_RESET })
      dispatch({ type: USER_CREATE_RESET })
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (users) {
        const userArray = []
        for (let i = 0; i < users.length; i++) {
          const user: UserListItem = {
            id: users[i].id,
            firstName: users[i].firstName,
            lastName: users[i].lastName,
            role: users[i].roles[0].role,
            practice: users[i].BASPractice
          }
          userArray.push(user)
        }
        setTableRows(userArray)
        setFinishedLoading(true)
      }
    })()
  }, [users])

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const onDelete = (userId: GridRowId) => {
    if (userId === user?.id) {
      setSameUserError(true)
      setIsDeleteModal(false)
      setTimeout(() => {
        setSameUserError(false)
      }, 3000)
      return
    }
    dispatch(remove(userId))
    setIsDeleteModal(false)
  }
  const onOpenModal = (id: GridRowId) => {
    setIsDeleteModal(true)
    setUserId(id)
  }

  useEffect(() => {
    if (successDelete) {
      typeof page === 'string' ? setPage(0) : setPage('0')
    }
  }, [successDelete])

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {sameUserError && <ErrorAlert hide message={`${t('ALERTS.SAME_USER_ERROR')}`} />}
              {userCreateSuccess && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_CREATE')} ${t('USER.USER').toLowerCase()}`}
                />
              )}
              {(editPasswordSuccess || userEditSuccess) && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_EDIT')} ${t('USER.USER').toLowerCase()}`}
                />
              )}
              {successDelete && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_DELETE')} ${t('USER.USER').toLowerCase()}`}
                />
              )}
              {deleteError && <ErrorAlert message={deleteError} hide />}
            </div>
            <Button className='main-btn' variant='contained' href='/users/create'>
              {t('USER.CREATE_USER')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            {isDeleteModal && (
              <Modal
                onCancel={() => setIsDeleteModal(false)}
                onConfirm={() => onDelete(userId)}
                message={`${t('MODAL.DELETE')} ${t('USER.USER').toLowerCase()}?`}
              />
            )}
            {!finishedLoading ? (
              <LoadingOverlay />
            ) : (
              <div className='' style={{ width: '100%' }}>
                {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
                  <ThemeProvider theme={setTheme(language)}>
                    <DataGrid
                      {...tableRows}
                      className='MuiDataGrid-cellContent-alignTop'
                      columns={columns}
                      rows={tableRows}
                      rowCount={count > 0 ? count : 0}
                      pagination
                      paginationMode='server'
                      sortingMode='server'
                      filterMode='server'
                      onFilterModelChange={onFilterChange}
                      loading={getAllLoading || userDeleteLoading}
                      paginationModel={{
                        page: Number(page),
                        pageSize: limit
                      }}
                      pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                      onPaginationModelChange={(params) => {
                        setPage(params.page)
                        setLimit(params.pageSize)
                      }}
                      slots={{ toolbar: Toolbar }}
                      localeText={{
                        toolbarFilters: t('GENERIC.FILTER')
                      }}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                        },
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: {
                            placeholder: t('GENERIC.SEARCH'),
                            inputProps: {
                              'aria-label': t('GENERIC.SEARCH')
                            },
                            debounceMs: 500
                          }
                        }
                      }}
                      sx={GridStyles}
                      columnVisibilityModel={{ leftCompanyDate: false }}
                      getRowHeight={() => 'auto'}
                      autoHeight={true}
                      rowSelection={false}
                      checkboxSelection={false}
                      disableColumnSelector={true}
                      disableRowSelectionOnClick={true}
                    />
                  </ThemeProvider>
                ) : (
                  <EmptyState
                    titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                      type: t('EMPTY_STATE.USERS')
                    })}
                    additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
                    buttonText={t('USER.USER').toLowerCase()}
                    url='/users/create'
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
