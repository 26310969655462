import { AnyAction } from 'redux'
import {
  DOCTOR_AVAILABILLITY_CREATE_FAIL,
  DOCTOR_AVAILABILLITY_CREATE_REQUEST,
  DOCTOR_AVAILABILLITY_CREATE_RESET,
  DOCTOR_AVAILABILLITY_CREATE_SUCCESS,
  DOCTOR_AVAILABILLITY_GET_ALL_FAIL,
  DOCTOR_AVAILABILLITY_GET_ALL_REQUEST,
  DOCTOR_AVAILABILLITY_GET_ALL_RESET,
  DOCTOR_AVAILABILLITY_GET_ALL_SUCCESS
} from 'src/redux/constants/doctorAvailabillityContants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const createDoctorAvailabillityReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case DOCTOR_AVAILABILLITY_CREATE_REQUEST:
      return { loading: true }
    case DOCTOR_AVAILABILLITY_CREATE_SUCCESS:
      return { loading: false, success: true }
    case DOCTOR_AVAILABILLITY_CREATE_FAIL:
      error = errorMessage
      return { loading: false, error }
    case DOCTOR_AVAILABILLITY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const getDoctorAvailabillityForUserReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case DOCTOR_AVAILABILLITY_GET_ALL_REQUEST:
      return { loading: true }
    case DOCTOR_AVAILABILLITY_GET_ALL_SUCCESS:
      return { loading: false, success: true, doctorAvailabillities: action.payload }
    case DOCTOR_AVAILABILLITY_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    case DOCTOR_AVAILABILLITY_GET_ALL_RESET:
      return {}
    default:
      return state
  }
}

export const doctorAvailabillityReducers = {
  createDoctorAvailabillity: createDoctorAvailabillityReducer,
  getAvailabillityForUser: getDoctorAvailabillityForUserReducer
}
