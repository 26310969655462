import { getGridStringOperators, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { RiskAreaEntity } from 'src/entities/interfaces'
import { getAll } from 'src/redux/actions/examinationTypeActions'
import { useAppDispatch } from 'src/redux/hook'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value)
)

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('EXAMINATIONS.EXAMINATION_TITLE'),
    flex: 1,
    editable: false,
    sortable: false,
    filterOperators,
    renderCell: (params: GridRenderCellParams) => {
      return <Link to={`/examinations-types/edit/${params.id}`}>{params.formattedValue}</Link>
    }
  },
  {
    field: 'riskAreas',
    headerName: i18n.t('EXAMINATION_TYPES.ASSOCIATED_RISK_AREAS'),
    editable: false,
    sortable: false,
    filterable: true,
    filterOperators,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const dispatch = useAppDispatch()
      return (
        <div className='flex flex-wrap gap-1 my-1'>
          {params.formattedValue &&
            params.formattedValue.length > 0 &&
            params.formattedValue.map((area: RiskAreaEntity, index: number) => (
              <div
                title={area?.name}
                className={'customTableChip hover:cursor-pointer'}
                key={index}
                onClick={() => {
                  dispatch(getAll({ limit: 50, page: 0, riskAreaId: area.id }))
                }}
              >
                {area?.name?.length > 40 ? area?.name.slice(0, 40) + '...' : area?.name}
              </div>
            ))}
        </div>
      )
    },
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'actions',
    filterable: false,
    headerName: '',
    editable: false,
    sortable: false,
    flex: 0.5,
    disableColumnMenu: true,
    align: 'right',
    getApplyQuickFilterFn: undefined
  }
]
