import i18n, { changeLanguage, use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { de, en } from 'src/services/Translations'

const savedLanguage = localStorage.getItem('SELECTED_LNG')
use(initReactI18next).init({
  resources: {
    EN: {
      translation: en
    },
    DE: {
      translation: de
    }
  },
  returnNull: false,
  fallbackLng: 'EN',
  interpolation: {
    escapeValue: false
  }
})

if (savedLanguage) {
  changeLanguage(savedLanguage)
} else {
  const envLng = process.env.REACT_APP_DEFAULT_LANGUAGE
  if (envLng) {
    changeLanguage(envLng)
  } else {
    changeLanguage('DE')
  }
}

export default i18n
