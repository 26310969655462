import { Draggable } from '@fullcalendar/interaction'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getProvidedSlotsTime } from '../helpers/internalFunctions'

export const GroupItem = ({
  group,
  index,
  selectedGroup,
  selectGroup,
  setDuration,
  saveIdanaLink,
  myEvents
}: {
  group: any
  index: number
  selectedGroup: any
  selectGroup: any
  setDuration: any
  saveIdanaLink: any
  myEvents: any
}) => {
  const elRef = useRef(null)
  const providedMinutes = getProvidedSlotsTime(myEvents, group.id)

  useEffect(() => {
    if (elRef.current) {
      const draggable = new Draggable(elRef.current, {
        eventData: function () {
          return { ...event, create: true }
        }
      })
      return () => draggable.destroy()
    }
  }, [])

  const { t } = useTranslation()

  return (
    <div
      onClick={() => selectGroup(index, group)}
      onMouseDown={() => selectGroup(index, group)}
      className={`examination-div relative ${
        selectedGroup?.group?.id === group.id ? 'selected' : ''
      }`}
    >
      <div className='justify-end items-start px-2 pt-3 relative hidden'>
        {/*<div ref={elRef} draggable className='w-auto cursor-move'>
          <CalendarIcon className='w-9 h-9' />
          <CursorArrowRaysIcon className='w-4 h-4 absolute right-[18px] top-[27px]' />
        </div>*/}
      </div>
      <div key={index} className='p-9'>
        <h3 className='customHead3 mb-3'>
          {group.numberOfUsers}{' '}
          {`${
            group.numberOfUsers > 1
              ? t('EXAMINATION_REQUEST.EMPLOYEES')
              : t('EXAMINATION_REQUEST.EMPLOYEE')
          }`}{' '}
          {t('EXAMINATION_REQUEST.WILL_TAKE')}
        </h3>
        {group.examinationTypes &&
          group.examinationTypes.map((ex: any, index: number) => {
            return (
              <ul className='customParagraph' key={index}>
                <li className='' key={index}>
                  {ex.examinationName}
                </li>
              </ul>
            )
          })}
        <div className='flex flex-col mt-3'>
          <div style={{ marginTop: 10 }}>
            <h3 className='customHead3 mb-3'>{t('EXAMINATION_REQUEST.ESTIMATED_TIME')}:</h3>
            <div className='inline-flex items-center'>
              <p className='customParagraph'>{group.minDoctorPerPatient}</p>
              <div className='flex flex-col gap-[2px] mx-2'>
                <button
                  className='change-duration-button arrow-up'
                  onClick={() =>
                    setDuration(
                      group.minDoctorPerPatient,
                      'increment',
                      group.id,
                      'minDoctorPerPatient'
                    )
                  }
                />
                <button
                  className='change-duration-button arrow-down'
                  onClick={() =>
                    setDuration(
                      group.minDoctorPerPatient,
                      'decrement',
                      group.id,
                      'minDoctorPerPatient'
                    )
                  }
                />
              </div>
            </div>
            <span className='customParagraph'>{t('EXAMINATION_REQUEST.MIN_DOCTOR')} + </span>
            <div className='inline-flex flex-col'>
              <div className='inline-flex items-center'>
                <p className='customParagraph'>{group.minMfaPerPatient}</p>
                <div className='flex flex-col gap-[2px] mx-2'>
                  <button
                    className='change-duration-button arrow-up'
                    onClick={() =>
                      setDuration(group.minMfaPerPatient, 'increment', group.id, 'minMfaPerPatient')
                    }
                  ></button>
                  <button
                    className='change-duration-button arrow-down'
                    onClick={() =>
                      setDuration(group.minMfaPerPatient, 'decrement', group.id, 'minMfaPerPatient')
                    }
                  ></button>
                </div>
              </div>
            </div>
            <span className='customParagraph'>
              {t('EXAMINATION_REQUEST.MIN_MFA')} {t('EXAMINATION_REQUEST.PER_PATIENT')}
            </span>
          </div>
          <div style={{ marginTop: 10 }}>
            <div className='inline-flex items-center'>
              <p className='customParagraph'>
                {group.minDoctorPerPatient * group.numberOfUsers}
                {'\u00a0'}
                {t('EXAMINATION_REQUEST.MIN_DOCTOR')}
                {'\u00a0'}
              </p>
              <p className='customParagraph'>
                {' + '}
                {group.minMfaPerPatient * group.numberOfUsers} {t('EXAMINATION_REQUEST.MIN_MFA')}
                {'\u00a0'}
                {'total'}
              </p>
            </div>
          </div>
          <div className='customParagraph mt-3'>
            <span>{t('EXAMINATION_REQUEST.PROVIDED')} </span>
            <span
              className={`${
                group.numberOfUsers * group.minDoctorPerPatient > providedMinutes && 'text-red-500'
              }`}
            >
              {providedMinutes} min{' '}
            </span>
            <span>{t('EXAMINATION_REQUEST.OF')} </span>
            <span>{group.numberOfUsers * group.minDoctorPerPatient} min</span>
          </div>
        </div>
        <div className='mt-1 mb-1'>
          <label className='customLabel'>Idana link</label>
          <input
            style={{ paddingBottom: 10 }}
            name='idanaLink'
            value={group.idanaLink || ''}
            onChange={(e) => saveIdanaLink(e.target.value, group.id)}
            className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:outline-none focus:ring-gray-800 sm:text-sm'
          />
        </div>
      </div>
    </div>
  )
}
