import i18n from '../language.service'
const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
export const isRequired = (value: string, message = required) => {
  if (value.length === 0) {
    return message
  }
}

export const tooManyCharacters = (
  value: string,
  message = i18n.t('ERROR_MESSAGES.TOO_MANY_CHARACTERS')
) => {
  if (value.length > 100) {
    return message
  }
}

export const isNumber = (value: string, message = i18n.t('ERROR_MESSAGES.IS_NOT_NUMBER')) => {
  if (isNaN(Number(value))) {
    return message
  }
}

export const emailValidation = (value: string) => {
  // eslint-disable-next-line
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line
  if (!regex.test(value)) {
    return i18n.t('ERROR_MESSAGES.INVALID_EMAIL')
  }
}

export const urlValidation = (value: string) => {
  // eslint-disable-next-line
  const expression = /^(https?:\/\/)?[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}(\/.*)?$/gi

  const regex = new RegExp(expression)
  if (!regex.test(value)) {
    return i18n.t('ERROR_MESSAGES.INVALID_URL')
  }
}

export const passwordValidation = (value: string) => {
  const message = i18n.t('ERROR_MESSAGES.PASSWORD_VALIDATION')
  if (value.length < 8) {
    return message
  }
  if (value.search(/[a-z]/) === -1) {
    return message
  }
  if (value.search(/[A-Z]/) === -1) {
    return message
  }

  //eslint-disable-next-line
  const noSpecialCharacter = value.search(/[-!$%^&*()_+|~=`'"\\{}\[\]:\/;<>?,.@#]/) === -1
  //eslint-disable-next-line
  const noNumber = value.search(/[0-9]/) === -1
  if (noNumber === false && noSpecialCharacter === false) {
    return
  } else return message
}
