import allLocales from '@fullcalendar/core/locales-all'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import i18next from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { getAppointmentAgenda } from 'src/redux/actions/appointmentActions'
import { APPOINTMENT_AGENDA_GET_RESET } from 'src/redux/constants/appointmentConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { EventContent } from './components/EventContent'

export const Agenda = () => {
  const [language, setLanguage] = useState<string>('DE')
  const savedLng = i18next.language
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [events, setEvents] = useState<any>([])
  const calendarRef = useRef<FullCalendar | null>(null)
  const [dates, setDates] = useState<{ start?: Date; end?: Date }>({})
  const { appointmentAgendaGet } = useAppSelector((state) => state)
  const {
    appointments,
    error: getAppointmentsError,
    loading: getAppointmentsLoading
  } = appointmentAgendaGet

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])

  useEffect(() => {
    dispatch({ type: APPOINTMENT_AGENDA_GET_RESET })
    sessionStorage.clear()
  }, [])

  useEffect(() => {
    if (id && dates.start && dates.end) {
      dispatch(getAppointmentAgenda(id, dates))
    }
  }, [dates])

  useEffect(() => {
    if (appointments) {
      const eventsHelper: any = []
      for (const appointment of appointments) {
        const employeeName = `${appointment.employee.firstName} ${appointment.employee.lastName}`
        const event = {
          start: appointment.dateTime,
          status: appointment.status,
          employeeName: employeeName,
          companyName: appointment?.employee?.company?.name,
          companyLocationName: appointment?.employee?.companyLocation[0].name,
          url:
            appointment.status === 'Cancelled'
              ? ''
              : `/examinations-record/${appointment.id}/${appointment.employee.id}`
        }
        if (appointment?.status !== 'Cancelled') {
          eventsHelper.push(event)
        }
      }
      setEvents(eventsHelper)
    }
  }, [appointments])

  return (
    <div>
      {getAppointmentsLoading && <LoadingOverlay />}
      {getAppointmentsError && <ErrorAlert message={t('ERROR_MESSAGES.DEFAULT')} hide />}
      <div>
        <FullCalendar
          datesSet={(dateInfo) => {
            setDates({
              start: dateInfo.start,
              end: dateInfo.end
            })
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          eventBackgroundColor={'#214260'}
          eventBorderColor={'#fff'}
          dayMaxEvents={4}
          initialView='dayGridMonth'
          weekends={false}
          locales={allLocales}
          locale={language.toLowerCase()}
          events={events}
          slotDuration={'00:05:00'}
          slotLabelInterval={1}
          eventOverlap={true}
          allDaySlot={false}
          slotMinTime={'00:00'}
          slotMaxTime={'24:00'}
          scrollTime={'08:00'}
          showNonCurrentDates={false}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false
          }}
          eventContent={(e) => <EventContent eventInfo={e} />}
          ref={calendarRef}
        />
      </div>
    </div>
  )
}
