import axios from 'axios'
import { GLOBAL_RESET } from 'src/redux/constants/globalConstants'
import { getAccessToken, memoizedRefreshToken, remove } from 'src/services/token.service'

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})
export const interceptor = (store: any) => {
  api.interceptors.request.use(
    async (config: any) => {
      const accessToken = getAccessToken()

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error.config
      if (error.response.status === 401 && !config.sent) {
        config.sent = true

        const newToken = await memoizedRefreshToken()
        if (newToken.error) {
          remove()
          localStorage.removeItem('USER_INFO')
          store.dispatch({ type: GLOBAL_RESET })
        }
        if (newToken.accessToken) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${newToken.accessToken}`
          }
        }

        return api(config)
      }
      return Promise.reject(error)
    }
  )
}
