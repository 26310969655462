import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CookieModal } from 'src/components/CookieModal'
import Modal from 'src/components/Modal'
import NotFound from 'src/components/NotFound'
import { signout } from 'src/redux/actions/authActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { sendFeedback } from 'src/redux/actions/widgetActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import FeedbackModal from '../../components/FeedbackModal'
import DashboardHeader from './components/DashboardHeader'
import { Footer } from './components/Footer'
import Navbar from './components/Navbar'

export function DashboardLayout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [errors, setErrors] = useState({
    sender: '',
    subject: '',
    description: '',
    files: ''
  })
  const [isModal, setIsModal] = useState<boolean>(false)
  const [cookiesPresent, setCookiesPresent] = useState<boolean>(true)
  const [isFeedbackModal, setIsFeedbackModal] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const { userSignin, notFoundElementDisplay, userCheckCookies, widgetSendFeedback } =
    useAppSelector((state) => state)
  const { isSignin, user } = userSignin
  const { cookies } = userCheckCookies
  const { displayed } = notFoundElementDisplay
  const { success } = widgetSendFeedback
  const [feedbackValues, setFeedbackValues] = useState({
    sender: user?.email,
    subject: '',
    description: ''
  })

  useEffect(() => {
    dispatch({ type: 'WIDGET_SEND_FEEDBACK_RESET' })
  }, [dispatch])

  useEffect(() => {
    if (success) {
      setFeedbackValues({ sender: user?.email, subject: '', description: '' })
      setFiles([])
      setTimeout(() => {
        setIsFeedbackModal(false)
        dispatch({ type: 'WIDGET_SEND_FEEDBACK_RESET' })
      }, 2000)
    }
  }, [success, user?.email, dispatch])

  useEffect(() => {
    const appElement = document.querySelector('body')
    if (appElement && process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL) {
      const snippet = document.createElement('script')
      snippet.type = 'text/javascript'
      snippet.src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL
      appElement.appendChild(snippet)
    }
  }, [])

  useEffect(() => {
    if (cookies && cookies['cookie_acceptance'] !== 'true') {
      setCookiesPresent(false)
    }
  }, [cookies])

  useEffect(() => {
    dispatch(displayNotFoundElement(false))
  }, [dispatch, location])

  useEffect(() => {
    if (!isSignin) {
      navigate('/signin', { replace: true })
    }
  }, [isSignin, navigate])

  const handleFeedbackChange = (value: any, name: string) => {
    if (name === 'files') {
      const fileHolder: File[] = []
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          fileHolder.push(value[i])
        }
        setFiles(fileHolder)
      }
      return
    }
    setFeedbackValues({ ...feedbackValues, [name]: value })
  }

  const onSubmit = () => {
    let canSubmit = true
    let totalFileSize = 0
    const newErrors = { ...errors }
    if (feedbackValues.subject === '') {
      newErrors.subject = t('GENERIC.REQUIRED_FIELD')
      canSubmit = false
    } else {
      newErrors.subject = ''
    }
    if (feedbackValues.description === '') {
      newErrors.description = t('GENERIC.REQUIRED_FIELD')
      canSubmit = false
    } else {
      newErrors.description = ''
    }

    if (files.length > 0) {
      files.forEach((file) => {
        totalFileSize += file.size
      })
      if (totalFileSize > 20971520) {
        newErrors.files = t('FEEDBACK_MODAL.MAX_FILE_SIZE')
        canSubmit = false
      }
    }

    setErrors(newErrors)

    if (canSubmit) {
      dispatch(sendFeedback(feedbackValues, files))
    }
  }

  return (
    <div className='dashboard-layout bg-white'>
      <div className='min-h-screen flex flex-col'>
        <div className='fixed -right-7 top-1/2 transform -translate-y-1/2 z-50 -rotate-90'>
          <div
            className='bg-brand-blue-700 rounded p-2 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] hover:cursor-pointer'
            onClick={() => setIsFeedbackModal(true)}
          >
            <p className='customParagraph white'>{t('FEEDBACK_MODAL.TITLE')}</p>
          </div>
        </div>
        {isFeedbackModal && (
          <FeedbackModal
            onCancel={() => {
              setIsFeedbackModal(false)
              setErrors({ sender: '', subject: '', description: '', files: '' })
            }}
            onConfirm={onSubmit}
            userEmail={user?.email}
            handleFeedbackChange={handleFeedbackChange}
            errors={errors}
          />
        )}
        {isModal && (
          <Modal
            onCancel={() => setIsModal(false)}
            onConfirm={() => dispatch(signout())}
            message={t('AUTH.CONFIRM_LOGOUT')}
          />
        )}
        <Navbar onSignout={() => setIsModal(true)} user={user} />
        {!displayed && <DashboardHeader />}
        {!cookiesPresent && <CookieModal setCookiesPresent={setCookiesPresent} />}
        <main>
          <div className='mt-6 mx-auto max-w-7xl pb-12 px-4 sm:px-6 lg:px-8'>
            {!displayed ? <Outlet /> : <NotFound />}
          </div>
        </main>
        <footer className='mt-auto'>
          <Footer />
        </footer>
      </div>
    </div>
  )
}
