import {
  MassCreateCompanyLocationSelectedExaminationTypeAdminDto,
  ShareWithClientCompanyLocationSelectedExaminationTypeAdminDto
} from 'src/entities/interfaces'
import { SelectedExaminationsESSearch } from 'src/interfaces/companyLocationSelectedExaminationType'
import {
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS
} from 'src/redux/constants/companyLocationSelectedExaminationTypeConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const massCreate =
  (data: MassCreateCompanyLocationSelectedExaminationTypeAdminDto) => (dispatch: AppDispatch) => {
    dispatch({ type: CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST, payload: {} })
    api
      .post('/api/admin/company-location-selected-examination-type/mass-create', data)
      .then((res) => {
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS,
          payload: res.data.success
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const shareWithClient =
  (data: ShareWithClientCompanyLocationSelectedExaminationTypeAdminDto) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST, payload: {} })
    api
      .post('/api/admin/company-location-selected-examination-type/share-with-client', data)
      .then((res) => {
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS,
          payload: res.data.success
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAll =
  ({ page = 0, limit = 10, query = '', companyLocationId = '' }: SelectedExaminationsESSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/company-location-selected-examination-type?page=${page}&limit=${limit}&query=${query}&companyLocationId=${companyLocationId}`
      )
      .then((res) => {
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS,
          payload: {
            companyLocationSelectedExaminationTypes: res.data,
            count: Number(res.headers['x-count'])
          }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
