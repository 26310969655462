import { EyeIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button, IconButton } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { QueryObject, RiskAreaListItem, RiskAreaSearch } from 'src/interfaces/riskAreas'
import { getAll, remove } from 'src/redux/actions/riskExposureAction'
import {
  RISK_EXPOSURE_CREATE_RESET,
  RISK_EXPOSURE_DELETE_RESET,
  RISK_EXPOSURE_EDIT_RESET
} from 'src/redux/constants/riskExposureConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { columns } from './helper/TableColumnsHelper'

export const RiskExposuresList = () => {
  const { t } = useTranslation()
  const [language, setLanguage] = useState('DE')
  const savedLng = i18next.language
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const deleteButtonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('RISK_EXPOSURE.RISK_EXPOSURE')}</p>
  }
  columns[1].renderHeader = () => {
    return <span className='font-bold'>{t('RISK_EXPOSURE.RISK_EXPOSURE_GROUPS')}</span>
  }
  columns[2].renderCell = (params: GridRenderCellParams) => {
    return (
      <div className=''>
        <IconButton onClick={() => onOpenModal(params.id)} title={t('GENERIC.DELETE')}>
          <TrashIcon className='w-5' />
        </IconButton>
        <IconButton
          onClick={() => navigate(`/risk-exposures/edit/${params.id}`)}
          title={t('GENERIC.EDIT')}
        >
          <PencilSquareIcon className='w-5' />
        </IconButton>
      </div>
    )
  }
  const { riskExposureGetAll, riskExposureDelete, riskExposureCreate, riskExposureEdit } =
    useAppSelector((state) => state)
  const { riskArea, getAllLoading, count } = riskExposureGetAll
  const { success: editSuccess } = riskExposureEdit
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [riskAreaId, setRiskAreaId] = useState<GridRowId>('')
  const [page, setPage] = useState<number | string>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [tableRows, setTableRows] = useState<RiskAreaListItem[]>([])
  const {
    loading: riskAreaDeleteLoading,
    success: successDelete,
    error: deleteError
  } = riskExposureDelete
  const { success: successCreate } = riskExposureCreate
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [limit, setLimit] = useState(config.limit)

  useEffect(() => {
    dispatch({ type: RISK_EXPOSURE_DELETE_RESET })
    return () => {
      dispatch({ type: RISK_EXPOSURE_CREATE_RESET })
      dispatch({ type: RISK_EXPOSURE_EDIT_RESET })
    }
  }, [])

  useEffect(() => {
    const riskAreaHelper = []
    if (riskArea) {
      for (let i = 0; i < riskArea.length; i++) {
        const riskAreaObject: RiskAreaListItem = {
          id: riskArea[i].id,
          name: riskArea[i].name,
          riskExposureGroups: riskArea[i].riskExposureGroup
        }
        riskAreaHelper.push(riskAreaObject)
      }
      setTableRows(riskAreaHelper)
      setFinishedLoading(true)
    }
  }, [riskArea])

  useEffect(() => {
    const riskAreaNameFilter =
      queryObject.field === 'name' && queryObject.value ? queryObject.value : ''
    const riskAreaNameFilterType =
      queryObject.field === 'name' && queryObject.operator ? queryObject.operator : 'contains'
    const riskAreaExposureGroupFilter =
      queryObject.field === 'riskExposureGroups' && queryObject.value ? queryObject.value : ''
    const riskAreaExposureGroupFilterType =
      queryObject.field === 'riskExposureGroups' && queryObject.operator
        ? queryObject.operator
        : 'contains'
    const quickFilter =
      queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
    dispatch(
      getAll({
        limit,
        page,
        queryObject,
        riskAreaExposureGroupFilter,
        riskAreaExposureGroupFilterType,
        riskAreaNameFilter,
        riskAreaNameFilterType,
        quickFilter
      } as RiskAreaSearch)
    )
  }, [page, queryObject, limit])

  const onOpenModal = (id: GridRowId) => {
    setIsDeleteModal(true)
    setRiskAreaId(id)
  }

  const onDelete = (riskAreaId: GridRowId) => {
    dispatch(remove(riskAreaId))
    setIsDeleteModal(false)
  }

  useEffect(() => {
    if (successDelete) {
      typeof page === 'string' ? setPage(0) : setPage('0')
    }
  }, [successDelete])

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {successDelete && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_DELETE')} ${t(
                    'RISK_EXPOSURE.RISK_EXPOSURE'
                  ).toLowerCase()}`}
                />
              )}
              {editSuccess && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_EDIT')} ${t(
                    'RISK_EXPOSURE.RISK_EXPOSURE'
                  ).toLowerCase()}`}
                />
              )}
              {successCreate && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_CREATE')} ${t(
                    'RISK_EXPOSURE.RISK_EXPOSURE'
                  ).toLowerCase()}`}
                />
              )}
              {deleteError && <ErrorAlert message={deleteError} hide />}
            </div>
            <Button className='main-btn' variant='contained' href='/risk-exposures/create'>
              {t('RISK_EXPOSURE.CREATE_RISK_EXPOSURE')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            {isDeleteModal && (
              <Modal
                onCancel={() => setIsDeleteModal(false)}
                onConfirm={() => onDelete(riskAreaId)}
                message={`${t('MODAL.DELETE')} ${t('RISK_EXPOSURE.RISK_EXPOSURE').toLowerCase()}?`}
              />
            )}
            {!finishedLoading ? (
              <LoadingOverlay />
            ) : (
              <div className='' style={{ width: '100%' }}>
                {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
                  <ThemeProvider theme={setTheme(language)}>
                    <DataGrid
                      {...tableRows}
                      className='MuiDataGrid-cellContent-alignTop'
                      columns={columns}
                      rows={tableRows}
                      rowCount={count > 0 ? count : 0}
                      pagination
                      paginationMode='server'
                      sortingMode='server'
                      filterMode='server'
                      onFilterModelChange={onFilterChange}
                      loading={getAllLoading || riskAreaDeleteLoading}
                      paginationModel={{
                        page: Number(page),
                        pageSize: limit
                      }}
                      pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                      onPaginationModelChange={(params) => {
                        setPage(params.page)
                        setLimit(params.pageSize)
                      }}
                      slots={{ toolbar: Toolbar }}
                      localeText={{
                        toolbarFilters: t('GENERIC.FILTER')
                      }}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                        },
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: {
                            placeholder: t('GENERIC.SEARCH'),
                            inputProps: {
                              'aria-label': t('GENERIC.SEARCH')
                            },
                            debounceMs: 500
                          }
                        }
                      }}
                      sx={GridStyles}
                      columnVisibilityModel={{ leftCompanyDate: false }}
                      getRowHeight={() => 'auto'}
                      autoHeight={true}
                      rowSelection={false}
                      checkboxSelection={false}
                      disableColumnSelector={true}
                      disableRowSelectionOnClick={true}
                    />
                  </ThemeProvider>
                ) : (
                  <EmptyState
                    titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                      type: t('EMPTY_STATE.RISK_EXPOSURE')
                    })}
                    additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
                    buttonText={t('NAVBAR.RISK_EXPOSURE').toLowerCase()}
                    url='/risk-exposures/create'
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
