import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='flex flex-col items-center justify-center'>
        <div className='md:text-[250px] text-gray-200 font-bold mt-12 text-[150px] mb-[-40px]'>
          404
        </div>
        <h1 className='md:text-[30px] text-[20px] font-bold text-gray-800'>
          {t('NOT_FOUND.TEXT_1')}
        </h1>
      </div>
    </div>
  )
}

export default NotFound
