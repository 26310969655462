export const EXAMINATION_TYPE_CREATE_REQUEST = 'EXAMINATION_TYPE_CREATE_REQUEST'
export const EXAMINATION_TYPE_CREATE_SUCCESS = 'EXAMINATION_TYPE_CREATE_SUCCESS'
export const EXAMINATION_TYPE_CREATE_FAIL = 'EXAMINATION_TYPE_CREATE_FAIL'
export const EXAMINATION_TYPE_CREATE_RESET = 'EXAMINATION_TYPE_CREATE_RESET'

export const EXAMINATION_TYPE_EDIT_REQUEST = 'EXAMINATION_TYPE_EDIT_REQUEST'
export const EXAMINATION_TYPE_EDIT_SUCCESS = 'EXAMINATION_TYPE_EDIT_SUCCESS'
export const EXAMINATION_TYPE_EDIT_FAIL = 'EXAMINATION_TYPE_EDIT_FAIL'
export const EXAMINATION_TYPE_EDIT_RESET = 'EXAMINATION_TYPE_EDIT_RESET'

export const EXAMINATION_TYPE_GET_ONE_REQUEST = 'EXAMINATION_TYPE_GET_ONE_REQUEST'
export const EXAMINATION_TYPE_GET_ONE_SUCCESS = 'EXAMINATION_TYPE_GET_ONE_SUCCESS'
export const EXAMINATION_TYPE_GET_ONE_FAIL = 'EXAMINATION_TYPE_GET_ONE_FAIL'
export const EXAMINATION_TYPE_GET_ONE_RESET = 'EXAMINATION_TYPE_GET_ONE_RESET'

export const EXAMINATION_TYPE_GET_ALL_REQUEST = 'EXAMINATION_TYPE_GET_ALL_REQUEST'
export const EXAMINATION_TYPE_GET_ALL_SUCCESS = 'EXAMINATION_TYPE_GET_ALL_SUCCESS'
export const EXAMINATION_TYPE_GET_ALL_FAIL = 'EXAMINATION_TYPE_GET_ALL_FAIL'

export const EXAMINATION_TYPE_DELETE_REQUEST = 'EXAMINATION_TYPE_DELETE_REQUEST'
export const EXAMINATION_TYPE_DELETE_SUCCESS = 'EXAMINATION_TYPE_DELETE_SUCCESS'
export const EXAMINATION_TYPE_DELETE_FAIL = 'EXAMINATION_TYPE_DELETE_FAIL'
export const EXAMINATION_TYPE_DELETE_RESET = 'EXAMINATION_TYPE_DELETE_RESET'

export const EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_REQUEST =
  'EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_REQUEST'
export const EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_SUCCESS =
  'EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_SUCCESS'
export const EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_FAIL =
  'EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_FAIL'
export const EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_RESET =
  'EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_RESET'
