import { GridRowId } from '@mui/x-data-grid'
import { CreateBasPracticeAdminDto, UpdateBasPracticeAdminDto } from 'src/entities/interfaces'
import { PracticeInputs, PracticeSearch } from 'src/interfaces/practices'
import {
  BAS_PRACTICE_FIND_RELATION_FAIL,
  BAS_PRACTICE_FIND_RELATION_REQUEST,
  BAS_PRACTICE_FIND_RELATION_SUCCESS,
  PRACTICE_CREATE_FAIL,
  PRACTICE_CREATE_REQUEST,
  PRACTICE_CREATE_SUCCESS,
  PRACTICE_DELETE_FAIL,
  PRACTICE_DELETE_REQUEST,
  PRACTICE_DELETE_SUCCESS,
  PRACTICE_EDIT_FAIL,
  PRACTICE_EDIT_REQUEST,
  PRACTICE_EDIT_SUCCESS,
  PRACTICE_GET_ALL_FAIL,
  PRACTICE_GET_ALL_REQUEST,
  PRACTICE_GET_ALL_SUCCESS,
  PRACTICE_GET_ONE_FAIL,
  PRACTICE_GET_ONE_REQUEST,
  PRACTICE_GET_ONE_SUCCESS,
  PRACTICE_WORKING_HOURS_GET_FAIL,
  PRACTICE_WORKING_HOURS_GET_REQUEST,
  PRACTICE_WORKING_HOURS_GET_SUCCESS
} from 'src/redux/constants/practiceConstants'
import type { AppDispatch } from 'src/redux/store'
import services from 'src/services'
import { api } from 'src/services/api.service'

export const create = (inputs: PracticeInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: PRACTICE_CREATE_REQUEST, payload: {} })
  services.practice
    .create({ ...inputs } as CreateBasPracticeAdminDto)
    .then((res) => {
      dispatch({ type: PRACTICE_CREATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: PRACTICE_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const edit = (inputs: PracticeInputs, id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: PRACTICE_EDIT_REQUEST, payload: {} })
  api
    .patch(`/api/admin/baspractice/${id}`, { ...inputs } as UpdateBasPracticeAdminDto)
    .then((res) => {
      dispatch({ type: PRACTICE_EDIT_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: PRACTICE_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const remove = (id: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: PRACTICE_DELETE_REQUEST, payload: {} })
  api
    .delete(`/api/admin/baspractice/${id}`)
    .then((res) => {
      dispatch({ type: PRACTICE_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: PRACTICE_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getOne = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: PRACTICE_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/baspractice/${id}`)
    .then((res) => {
      dispatch({ type: PRACTICE_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: PRACTICE_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAll =
  ({
    page = 0,
    limit = 10,
    query = '',
    practiceNameFilter = '',
    practiceNameFilterType = '',
    quickFilter = ''
  }: PracticeSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: PRACTICE_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/baspractice?page=${page}&limit=${limit}&query=${query}&practiceNameFilter=${practiceNameFilter}&practiceNameFilterType=${practiceNameFilterType}&quickFilter=${quickFilter}`
      )
      .then((res) => {
        dispatch({
          type: PRACTICE_GET_ALL_SUCCESS,
          payload: { practices: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: PRACTICE_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const findByRelation = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: BAS_PRACTICE_FIND_RELATION_REQUEST, payload: {} })
  api
    .get(`/api/admin/baspractice/user/${id}`)
    .then((res) => {
      dispatch({
        type: BAS_PRACTICE_FIND_RELATION_SUCCESS,
        payload: { practices: res.data, count: Number(res.headers['x-count']) }
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: BAS_PRACTICE_FIND_RELATION_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getWorkingHours = (practiceId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: PRACTICE_WORKING_HOURS_GET_REQUEST, payload: {} })
  api
    .get(`/api/admin/baspractice/working-hours/${practiceId}`)
    .then((res) => {
      dispatch({
        type: PRACTICE_WORKING_HOURS_GET_SUCCESS,
        payload: res.data
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: PRACTICE_WORKING_HOURS_GET_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
