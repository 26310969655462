import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'src/redux/hook'

export function InfoBox() {
  const [info, setInfo] = useState({
    employee: {
      name: '',
      gender: '',
      age: '',
      position: '',
      department: ''
    },
    company: {
      name: '',
      locationName: '',
      address: ''
    }
  })
  const { t } = useTranslation()
  const { appointmentExaminationRecordGet } = useAppSelector((state) => state)
  const { appointment } = appointmentExaminationRecordGet
  const navigate = useNavigate()
  useEffect(() => {
    if (appointment) {
      const {
        employee,
        AppointmentRequest: { companyLocation }
      } = appointment
      setInfo({
        employee: {
          name: `${employee.firstName} ${employee.lastName}`,
          gender: employee.gender,
          age:
            employee.dateOfBirth &&
            Math.abs(moment().diff(moment(employee.dateOfBirth, 'YYYYMMDD'), 'years')),
          position: employee.currentPosition || '',
          department: employee.currentDepartment || ''
        },
        company: {
          name: companyLocation.company.name,
          locationName: companyLocation.name,
          address: companyLocation.address
        }
      })
    }
  }, [appointment])
  return (
    <>
      <div className='flex items-start justify-between'>
        <div className='flex flex-col'>
          <h2 className='customHead2'>
            {info.employee.name}, {info.employee.age}, {info.employee.gender}
          </h2>
          <h4 className='customHead4 my-1'>
            {info.company.name} {info.company.locationName}, {info.company.address}
          </h4>
          <h4 className='customHead4'>
            {info.employee.department}, {info.employee.position}
          </h4>
        </div>
        <IconButton
          onClick={() =>
            navigate(`/scheduled-appointment-overview/${appointment.AppointmentRequest.id}`)
          }
        >
          <ChevronLeftIcon className='w-6' />
          <label className='text-[13px]'>{t('EXAMINATIONS.BACK_TO_APPOINTMENT')}</label>
        </IconButton>
      </div>
    </>
  )
}
