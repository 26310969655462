import { Pagination } from '@mui/material'
import { Buffer } from 'buffer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { ExaminationHistoryInterface } from 'src/interfaces/examination'
import { getExaminationHistory } from 'src/redux/actions/examinationActions'
import { getOneUser } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationHistoryElementEdit } from './ExaminationHistoryElementEdit'

export function ExaminationHistory() {
  const limit = 10
  const [page, setPage] = useState(1)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const { examinationHistoryGet, examinationDownloadCertificates } = useAppSelector(
    (state) => state
  )
  const [groupedExaminations, setGroupedExaminations] = useState<{
    [performedDate: string]: ExaminationHistoryInterface[]
  }>({})
  const { examinations, count, loading } = examinationHistoryGet
  const { certificatesBuffer } = examinationDownloadCertificates
  const { userId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (userId) {
      dispatch(getOneUser(userId))
      dispatch(getExaminationHistory({ userId, limit, page: page - 1 }))
    }
  }, [dispatch, page, userId])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (certificatesBuffer) {
      certificatesBuffer.forEach((buffer: any) => {
        const blob = new Blob([Buffer.from(buffer.buffer.data)])
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none;')
        a.href = url
        a.download = `${buffer.fileName}`
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
      })
      setDisplaySuccessButton(true)
      dispatch({ type: 'DOWNLOAD_CERTIFICATES_RESET' })
    }
  }, [certificatesBuffer, dispatch])

  useEffect(() => {
    if (examinations) {
      setGroupedExaminations(examinations)
    }
  }, [examinations])

  return (
    <>
      {loading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <h2 className='customHead2 mt-5'>{t('EXAMINATION_HISTORY.HISTORY')}</h2>
          {Object.entries(groupedExaminations).map(([performedDate, examinations], index) => (
            <div key={index}>
              <div className='mt-5 border-b border-gray-200 pb-2 flex justify-between mb-3'>
                <h3 className='customHead3'>{performedDate}</h3>
                <h3 className='customHead3'>{` ${examinations[0]?.companyLocation?.BASPractice?.name} `}</h3>
              </div>
              {examinations.map((examination, index) => (
                <ExaminationHistoryElementEdit examination={examination} key={index} />
              ))}
            </div>
          ))}
          {count && Math.ceil(count / limit) > 1 ? (
            <div className='mt-10 flex w-full justify-center items-center'>
              <Pagination
                count={Math.ceil(count / limit)}
                shape='rounded'
                page={page}
                onChange={(e, v) => {
                  setPage(v)
                }}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}
