import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { GlobalContext } from 'src/App'
import { getCompanyLocationByAppointmentETGroupId } from 'src/pages/Doctors/AppointmentRequests/helpers/externalFunctions'
import { getCompanyByLocation } from 'src/pages/Doctors/CustomerOverview/helpers/externalFunctions'
import { getCompanyLocationById } from 'src/pages/Doctors/SingleCustomer/ExaminationsSelection/helpers/externalFunctions'

const DashboardHeader = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const context: any = useContext(GlobalContext)
  const { header } = context.context
  const { id } = useParams()
  const [clientName, setClientName] = useState<string>('')

  useEffect(() => {
    ;(async () => {
      if (id) {
        let companyLocation
        let locationName
        let companyName
        let fullName
        let company
        switch (location.pathname) {
          case `/examination-request/${id}`:
            companyLocation = await getCompanyLocationByAppointmentETGroupId(id)
            if (companyLocation) {
              company = await getCompanyByLocation(companyLocation.id)
              if (company) {
                companyName = company[0].name
                locationName = companyLocation.name
                fullName = `${companyName} - ${locationName}`
                setClientName(fullName)
              }
            }
            break
          case `/client/${id}`:
          case `/client/${id}/add-contact`:
            companyLocation = await getCompanyLocationById(id)
            locationName = companyLocation.name
            if (companyLocation) {
              company = await getCompanyByLocation(companyLocation.id)
              if (company) {
                companyName = company[0].name
                fullName = `${header}: ${companyName} - ${locationName}`
                setClientName(fullName)
              }
            }
            break
          default:
            break
        }
      }
    })()
  }, [id, header])

  const headerText = (pathname: string) => {
    if (pathname.includes('edit')) {
      const preffix = pathname.split('/')[1]
      const suffix = pathname.split('/')[2]
      pathname = '/' + preffix + '/' + suffix
    } else if (
      (pathname.includes('client') && pathname != '/clients/create') ||
      pathname.includes('scheduled-appointment-overview') ||
      pathname.includes('examinations-record') ||
      pathname.includes('examinations-history') ||
      pathname.includes('examination-request') ||
      pathname.includes('agenda')
    ) {
      const preffix = pathname.split('/')[1]
      pathname = '/' + preffix
    }

    switch (pathname) {
      case '/':
        return t('NAVBAR.DASHBOARD')
      case '/practices':
        return t('NAVBAR.PRACTICES')
      case '/risk-exposures':
        return t('NAVBAR.RISK_EXPOSURE')
      case '/risk-exposures/create':
        return t('NAVBAR.CREATE_RISK_EXPOSURE')
      case '/practices/create':
        return t('PRACTICES.CREATE_PRACTICE')
      case '/practices/edit':
        return t('PRACTICES.EDIT_PRACTICE')
      case '/risk-exposures/edit':
        return t('RISK_EXPOSURE.EDIT_RISK_EXPOSURE')
      case '/companies':
        return t('NAVBAR.COMPANIES')
      case '/companies/create':
        return t('COMPANY.CREATE')
      case '/companies/edit':
        return t('COMPANY.EDIT')
      case '/clients':
        return t('NAVBAR.CUSTOMER_OVERVIEW')
      case '/clients/create':
        return t('NAVBAR.CUSTOMERS')
      case '/appointment-requests':
        return t('NAVBAR.APPOINTMENT_REQUEST')
      case '/examinations-types':
        return t('NAVBAR.EXAMINATION_TYPES')
      case '/examinations-types/create':
        return t('NAVBAR.CREATE_EXAMINATION_TYPES')
      case '/examinations-types/edit':
        return t('NAVBAR.EDIT_EXAMINATION_TYPES')
      case '/users':
        return t('NAVBAR.USERS')
      case '/users/create':
        return t('USER.CREATE_USER')
      case '/users/edit':
        return t('USER.EDIT_USER')
      case '/client':
        return clientName ? clientName : header
      case '/examinations-history':
        return header
      case '/examination-request':
        return clientName
          ? `${t('EXAMINATION_SELECTION.CREATE_TIME_SLOT')} ${clientName}`
          : `${t('EXAMINATION_SELECTION.CREATE_TIME_SLOT')} ${header}`
      case '/users/edit-password':
        return header ? `${t('AUTH.CHANGE_PASSWORD')} for ${header}` : t('AUTH.CHANGE_PASSWORD')
      case '/scheduled-appointment-overview':
        return t('NAVBAR.SCHEDULED_APPOINTMENTS_OVERVIEW')
      case '/examinations-record':
        return t('NAVBAR.EXAMINATIONS_RECORD')
      case '/agenda':
        return t('NAVBAR.AGENDA')
      case '/customers':
        return t('NAVBAR.CUSTOMER_LOCATIONS')
      case '/examinations-record/edit':
        return t('NAVBAR.EDIT_EXAMINATION_RECORD')
    }
  }

  return (
    <div className='bg-brand-blue-700 shadow'>
      <div className='mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8'>
        <h1 className='customHead1'>{headerText(location.pathname)}</h1>
      </div>
    </div>
  )
}

export default DashboardHeader
