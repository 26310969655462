import { api } from 'src/services/api.service'

export const getLocationByPractice = async (id: string, excludeRole = '') => {
  return api
    .get(`/api/admin/company-location/practice/${id}`, { params: { excludeRole } })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching location by practice:', error)
    })
}

export const getCompanyByLocation = async (companyLocationId: string) => {
  return api
    .get(`/api/admin/company/location/${companyLocationId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getContactPersonForLocation = async ({
  page = 0,
  limit = 10,
  sort = '',
  order = '',
  quickFilter = '',
  locationId = '',
  firstNameFilter = '',
  firstNameFilterType = '',
  lastNameFilter = '',
  lastNameFilterType = '',
  emailFilter = '',
  emailFilterType = ''
}: {
  page?: number | string
  limit?: number
  sort?: string
  order?: string
  quickFilter?: string
  locationId?: string
  firstNameFilter?: string
  firstNameFilterType?: string
  lastNameFilter?: string
  lastNameFilterType?: string
  emailFilter?: string
  emailFilterType?: string
}) => {
  return api
    .get(`/api/admin/user/contact-person/${locationId}`, {
      params: {
        page,
        limit,
        sort,
        order,
        quickFilter,
        firstNameFilter,
        firstNameFilterType,
        lastNameFilter,
        lastNameFilterType,
        emailFilter,
        emailFilterType
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllContactsForCompanyByCompanyLocation = async (locationId: string) => {
  return api
    .get(`/api/admin/user/company-contacts/${locationId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}
