import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'src/redux/hook'
import { groupingExaminations } from '../helper/internalFunctions'
import { Actions } from './Actions'
import { ExaminationItem } from './ExaminationItem'

export function ExaminationsList({
  companyLocationId,
  companyId
}: {
  companyLocationId: string
  companyId: string
}) {
  const [examinations, setExaminations] = useState([])
  const { t } = useTranslation()
  const { userId } = useParams<{
    userId: string
  }>()

  const { examinationsOptedOutGet } = useAppSelector((state) => state)
  const { optedExaminations } = examinationsOptedOutGet

  useEffect(() => {
    if (optedExaminations && userId) {
      if (optedExaminations?.examinations?.length > 0) {
        setExaminations(
          groupingExaminations(
            optedExaminations.examinations,
            userId,
            companyId,
            companyLocationId,
            'examination'
          )
        )
      } else if (optedExaminations?.preventiveLogExaminationTypes?.length > 0) {
        setExaminations(
          groupingExaminations(
            optedExaminations.preventiveLogExaminationTypes,
            userId,
            companyId,
            companyLocationId,
            'preventive'
          )
        )
      }
    }
  }, [optedExaminations, userId, companyId, companyLocationId])

  const setVal = (id: string, value: any, name: string) => {
    const newExaminations: any = examinations.map((examination: any) => {
      if (examination.id === id) {
        const performedDate = examination.performedDate ? examination.performedDate : moment()
        switch (name) {
          case 'status':
            if (value) {
              examination.status = 'Completed'
            } else {
              examination.status = 'Not performed'
              examination.performedDate = null
              examination.examinationCheckup = null
              examination.examinationCheckupOption = { label: '', value: '' }
              examination.nextExaminationDate = null
              examination.examinationNote = null
            }
            break
          case 'performedDate':
            examination.performedDate = value
            break
          case 'examinationCheckup':
            examination.examinationCheckup = value.value
            examination.examinationCheckupOption = value
            if (value.value === 'First examination') {
              examination.nextExaminationDate = moment(performedDate).add(12, 'M')
            } else if (value.value === 'Second examination') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            } else if (value.value === 'Follow up') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            }
            break
          case 'nextExaminationDate':
            examination.nextExaminationDate = value
            break
          case 'examinationNote':
            examination.examinationNote = value
            break
        }
      }
      return examination
    })
    setExaminations(newExaminations)
  }
  return (
    <div className='mt-6'>
      <div className='mt-6'>
        <h3 className='customHead3'>{t('EXAMINATIONS_RECORD.OPTED_OUT')}</h3>
        {examinations.map((examination) => {
          const {
            id,
            status,
            performedDate,
            examinationCheckupOption,
            nextExaminationDate,
            examinationNote,
            employerCertificate,
            examinationName,
            examinationRequired,
            employeeOptedIn
          } = examination
          if (examinationRequired === 1) {
            return (
              <ExaminationItem
                key={id}
                id={id}
                status={status}
                employeeOptedOut={employeeOptedIn ? '' : ` (${t('EXAMINATIONS.OPT_OUT')})`}
                performedDate={performedDate}
                examinationCheckupOption={examinationCheckupOption}
                nextExaminationDate={nextExaminationDate}
                examinationNote={examinationNote}
                employerCertificate={employerCertificate}
                examinationName={examinationName}
                setVal={setVal}
              />
            )
          }
        })}
      </div>
      <Actions examinations={examinations} companyLocationId={companyLocationId} />
    </div>
  )
}
