export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'

export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_FAIL = 'USER_SIGNOUT_FAIL'

export const USER_EDIT_PASSWORD_REQUEST = 'USER_EDIT_PASSWORD__REQUEST'
export const USER_EDIT_PASSWORD_SUCCESS = 'USER_EDIT_PASSWORD__SUCCESS'
export const USER_EDIT_PASSWORD_FAIL = 'USER_EDIT_PASSWORD__FAIL'
export const USER_EDIT_PASSWORD_RESET = 'USER_EDIT_PASSWORD__RESET'
