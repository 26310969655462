import { omit } from 'lodash'
import { RiskAreaErrors, RiskAreaInputs } from 'src/interfaces/riskAreas'
import i18n from '../language.service'
import { isRequired } from './index'

export const validation = (inputs: RiskAreaInputs) => {
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  let errors: RiskAreaErrors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'name':
        errors[key] = isRequired(value, `${i18n.t('RISK_EXPOSURE.NAME')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'riskExposureGroupId':
        if (value.length === 0) {
          errors[key] = i18n.t('ERROR_MESSAGES.NO_GROUP')
        }
        if (!errors[key]) {
          errors = omit(errors, key)
        }
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: RiskAreaInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if (key === 'riskExposureGroupId' && ((input && value?.length === 0) || (input && !value))) {
      isDisabled += 1
    } else if ((input && !value) || (input && value && isRequired(value))) {
      isDisabled += 1
    }
  }
  return isDisabled
}
