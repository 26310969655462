import { StyledEngineProvider } from '@mui/material/styles'
import { createContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthLayout, DashboardLayout } from 'src/layouts'
import { RoleLayout } from 'src/layouts/RoleLayout'
import { CompaniesList, CompanyForm } from 'src/pages/Admins/Companies'
import { CustomersList } from 'src/pages/Admins/Customers'
import { ExaminationForm, ExaminationsList } from 'src/pages/Admins/ExaminationTypes'
import { PracticeForm, PracticesList } from 'src/pages/Admins/Practices'
import { RiskExposuresList } from 'src/pages/Admins/RiskExposure'
import { RiskExposureForm } from 'src/pages/Admins/RiskExposure/RiskExposureForm'
import { UserEditPassword, UserForm, UsersList } from 'src/pages/Admins/Users'
import { SignIn } from 'src/pages/Auth'
import { Agenda } from 'src/pages/Doctors/Agenda'
import { AppointmentRequestList } from 'src/pages/Doctors/AppointmentRequests/AppointmentRequestList'
import { CreatePreventiveLogCustomer } from 'src/pages/Doctors/CustomerOverview/CreatePreventiveLogCustomer'
import { CustomerOverview } from 'src/pages/Doctors/CustomerOverview/CustomerOverviewList'
import { Dashboard } from 'src/pages/Doctors/Dashboard'
import { ExaminationRequest } from 'src/pages/Doctors/ExaminationRequest/ExaminationRequest'
import { ExaminationsHistory } from 'src/pages/Doctors/ExaminationsHistory/ExaminationsHistory'
import { ExaminationsRecord } from 'src/pages/Doctors/ExaminationsRecord/ExaminationsRecord'
import { ExaminationsRecordManual } from 'src/pages/Doctors/ExaminationsRecordManual/ExaminationsRecordManual'
import { ExaminationsRecordManualEdit } from 'src/pages/Doctors/ExaminationsRecordManualEdit/ExaminationsRecordManualEdit'
import { ExaminationsRecordOptedOut } from 'src/pages/Doctors/ExaminationsRecordOptedOut/ExaminationsRecordOptedOut'
import { ScheduledAppointmentOverview } from 'src/pages/Doctors/ScheduledAppointmentOverview/ScheduledAppointmentOverview'
import { SingleCustomer } from 'src/pages/Doctors/SingleCustomer'
import { ContactPersonForm } from 'src/pages/Doctors/SingleCustomer/ContactPerson/components/ContactPersonForm'
import { NotFound } from 'src/pages/NotFound'
import { getRoleByUserId } from 'src/redux/actions/roleActions'
import { checkUserCookies } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export const GlobalContext = createContext({})

function App() {
  const dispatch = useAppDispatch()
  const { userSignin } = useAppSelector((state) => state)
  const { user } = userSignin
  const [context, setContext] = useState({})

  useEffect(() => {
    if (user) {
      dispatch(checkUserCookies())
      dispatch(getRoleByUserId(user.id))
    }
  }, [user])

  return (
    <div className='App'>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <GlobalContext.Provider value={{ context, setContext }}>
            <Routes>
              <Route element={<DashboardLayout />}>
                <Route element={<RoleLayout authorizedRoles={['admin', 'superadmin']} />}>
                  <Route path='/practices' element={<PracticesList />} />
                  <Route path='/practices/create' element={<PracticeForm />} />
                  <Route path='/practices/edit/:id' element={<PracticeForm />} />
                  <Route path='/risk-exposures' element={<RiskExposuresList />} />
                  <Route path='/risk-exposures/create' element={<RiskExposureForm />} />
                  <Route path='/risk-exposures/edit/:id' element={<RiskExposureForm />} />
                  <Route path='/companies' element={<CompaniesList />} />
                  <Route path='/companies/create' element={<CompanyForm />} />
                  <Route path='/companies/edit/:id' element={<CompanyForm />} />
                  <Route path='/customers' element={<CustomersList />} />
                  <Route path='/examinations-types' element={<ExaminationsList />} />
                  <Route path='/examinations-types/create' element={<ExaminationForm />} />
                  <Route path='/examinations-types/edit/:id' element={<ExaminationForm />} />
                  <Route path='/users' element={<UsersList />} />
                  <Route path='/users/create' element={<UserForm />} />
                  <Route path='/users/edit/:id' element={<UserForm />} />
                  <Route path='/users/edit-password/:id' element={<UserEditPassword />} />
                </Route>
                <Route element={<RoleLayout authorizedRoles={['doctor', 'mfa']} />}>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/client/:id' element={<SingleCustomer />} />
                  <Route path='/client/:id/add-contact' element={<ContactPersonForm />} />
                  <Route path='/examinations-history/:userId' element={<ExaminationsHistory />} />
                  <Route path='/clients' element={<CustomerOverview />} />
                  <Route path='/clients/create' element={<CreatePreventiveLogCustomer />} />
                  <Route path='/examination-request/:id' element={<ExaminationRequest />} />
                  <Route path='/appointment-requests' element={<AppointmentRequestList />} />
                  <Route path='/agenda/:id' element={<Agenda />} />
                  <Route
                    path='/scheduled-appointment-overview/:id'
                    element={<ScheduledAppointmentOverview />}
                  />
                  <Route
                    path='/examinations-record/:appointmentId/:userId'
                    element={<ExaminationsRecord />}
                  />
                  <Route
                    path='/examinations-record/create/:userId'
                    element={<ExaminationsRecordManual />}
                  />
                  <Route
                    path='/examinations-record/edit/:userId'
                    element={<ExaminationsRecordManualEdit />}
                  />
                  <Route
                    path='/examinations-record/:userId'
                    element={<ExaminationsRecordOptedOut />}
                  />
                </Route>
              </Route>
              <Route element={<AuthLayout />}>
                <Route path='/signin' element={<SignIn />} />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </GlobalContext.Provider>
        </BrowserRouter>
      </StyledEngineProvider>
    </div>
  )
}

export default App
