export const GridStyles = () => ({
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none !important'
  },
  '& .PrivateSwitchBase-input.css-1m9pwf3': {
    width: '35px',
    height: '35px'
  },
  '& .MuiDataGrid-cell': {
    color: '#555',
    fontSize: '14px',
    fontWeight: '400',
    minHeight: '52px!important',
    overflow: 'visible!important',
    whiteSpace: 'normal!important'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#111',
    fontSize: '14px',
    fontWeight: '500'
  },
  '& .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none'
  },
  '& .MuiInputBase-root': {
    padding: '2px'
  },
  '& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root':
    {
      color: '#f87474'
    },
  '& button:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
    borderRadius: '5px !important'
  },
  '& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd':
    {
      '&:focus': {
        outline: 'none !important',
        boxShadow: 'none !important'
      }
    },
  '& .MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd ':
    {
      '&:after': {
        borderBottom: 'none !important'
      }
    },
  '& .MuiDataGrid-toolbarContainer': {
    padding: '5px 10px !important'
  }
})
