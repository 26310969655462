import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'src/redux/store'
import { interceptor } from 'src/services/api.service'
import 'src/services/language.service'
import App from './App'
import './index.scss'

interceptor(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
