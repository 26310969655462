export const APPOINTMENT_EXAMINATION_RECORD_GET_FAIL = 'APPOINTMENT_EXAMINATION_RECORD_GET_FAIL'
export const APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST =
  'APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST'
export const APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS =
  'APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS'
export const APPOINTMENT_EXAMINATION_RECORD_GET_RESET = 'APPOINTMENT_AGENDA_GET_RESET'

export const APPOINTMENT_AGENDA_GET_FAIL = 'APPOINTMENT_AGENDA_GET_FAIL'
export const APPOINTMENT_AGENDA_GET_REQUEST = 'APPOINTMENT_AGENDA_GET_REQUEST'
export const APPOINTMENT_AGENDA_GET_SUCCESS = 'APPOINTMENT_AGENDA_GET_SUCCESS'
export const APPOINTMENT_AGENDA_GET_RESET = 'APPOINTMENT_AGENDA_GET_RESET'
