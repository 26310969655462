import { AppointmentEntity, DoctorAvailabillityEntity } from 'src/entities/interfaces'

export const getSlots = (user: any) => {
  let timeslotsScheduled: DoctorAvailabillityEntity[] = []
  for (const AppointmentRequestEmployeeExaminationTypes of user.AppointmentRequestEmployeeExaminationTypes) {
    const { AppointmentRequest, AppointmentRequestETGroup } =
      AppointmentRequestEmployeeExaminationTypes
    let appointmentsScheduled = 0
    if (AppointmentRequest.status === 'booked') {
      const isAppointment = AppointmentRequest.Appointment.find(
        (Appointment: AppointmentEntity) =>
          Appointment.status === 'Scheduled' && user.id === Appointment.employeeId
      )
      if (isAppointment) {
        appointmentsScheduled += 1
      }
    }
    if (appointmentsScheduled > 0) {
      timeslotsScheduled = [...AppointmentRequestETGroup.DoctorAvailabillity]
    }
  }
  return {
    timeslotsScheduled,
    appointmentReqExGroup: user.AppointmentRequestEmployeeExaminationTypes
  }
}
