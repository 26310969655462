import allLocales from '@fullcalendar/core/locales-all'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import i18next from 'i18next'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAllOtherTimeslots } from 'src/redux/actions/doctorAvailabillityActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { calculateGroupDuration, generateUid } from '../helpers/internalFunctions'
import { EventContent } from './EventContent'

export const Calendar = ({
  updateEvents,
  assignAvailabillity,
  selectedGroup,
  hours,
  appointmentRequestEtGroups,
  myEvents,
  setMyEvents,
  savedEvents,
  setAddedAppointments,
  addedAppointments
}: {
  selectedGroup: any
  updateEvents: any
  assignAvailabillity: any
  hours: any
  appointmentRequestEtGroups: any
  myEvents: any
  setMyEvents: any
  savedEvents: any
  setAddedAppointments: any
  addedAppointments: any
}) => {
  const initialRef: any = null
  const [language, setLanguage] = useState<string>('DE')
  const [initialDate, setInitialDate] = useState<string | null>(null)
  const savedLng = i18next.language
  const calendarRef = useRef(initialRef)
  const dispatch = useAppDispatch()
  const { userSignin } = useAppSelector((state) => state)
  const { user } = userSignin
  const { id } = useParams()

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])

  // useEffect(() => {
  //   if (savedEvents) {
  //     for (const savedEvent of savedEvents) {
  //       // if (savedEvent.belongsToAppointment) {
  //       for (const scheduledAppointment of savedEvent.scheduledAppointments) {
  //         const startTime = moment(scheduledAppointment.dateTime).format('HH:mm')
  //         const endTime = moment(scheduledAppointment.dateTime)
  //           .add(scheduledAppointment.duration, 'minutes')
  //           .format('HH:mm')

  //         const timeslot = `${startTime} - ${endTime} - ${t('EXAMINATION_TYPES.OCCUPIED')}`
  //         if (savedEvent.occupied_timeslots) {
  //           savedEvent.occupied_timeslots.push(timeslot)
  //         }
  //       }
  //       // }
  //     }
  //   }
  // }, [savedEvents])

  const moveEvent = (info: any) => {
    const { start, end, extendedProps, id } = info.event
    if (!extendedProps.belongsToAppointment) {
      info.revert()
      return
    }
    const startTime = new Date(start).getTime()
    const endTime = new Date(end).getTime()
    if (startTime <= new Date().getTime() && endTime <= new Date().getTime()) {
      info.revert()
      return
    }

    const newArray = myEvents.map((event: any) => {
      if (event.id == id) {
        event = { ...event, start: start, end: end }
      }
      return event
    })
    setMyEvents(newArray)
    updateEvents(info.event, start, end)
  }

  const resizeEvent = (info: any) => {
    const { start, extendedProps, id, end } = info.event
    const { start: oldStart, end: oldEnd } = info.oldEvent
    const group = appointmentRequestEtGroups.find(
      (appointmentRequestEtGroup: any) => selectedGroup?.group?.id === appointmentRequestEtGroup.id
    )
    if (group) {
      const groupDuration = calculateGroupDuration(
        selectedGroup?.group?.id,
        appointmentRequestEtGroups
      )
      if (groupDuration) {
        const step = group?.minDoctorPerPatient
        const minutesDiff = moment.duration(moment(end).diff(moment(start))).asMinutes()
        if (typeof step == 'number' && minutesDiff < step) {
          info.revert()
          return
        }
        if (groupDuration <= 0 || typeof groupDuration != 'number') {
          info.revert()
          return
        }
      }
    }
    if (!extendedProps.belongsToAppointment) {
      info.revert()
      return
    }
    const startTime = new Date(start).getTime()
    const endTime = new Date(end).getTime()
    if (startTime <= new Date().getTime() && endTime <= new Date().getTime()) {
      info.revert()
      return
    }
    const minutesDiff = moment.duration(moment(end).diff(moment(start))).asMinutes()
    const doctorDuration = selectedGroup.group.minDoctorPerPatient
    let newStart = start
    let newEnd = end

    // REFACTORING THIS TO USE REUSABLE FUNCTION
    const startDiff = moment.duration(moment(start).diff(moment(oldStart))).asMinutes()
    const endDiff = moment.duration(moment(end).diff(moment(oldEnd))).asMinutes()
    if (startDiff !== 0) {
      if (minutesDiff < doctorDuration) {
        newStart = moment(start).subtract(doctorDuration - minutesDiff, 'minutes')
      } else {
        const rest = minutesDiff % doctorDuration
        const doctorDurationMid = doctorDuration / 2

        if (rest !== 0) {
          if (rest < doctorDurationMid) {
            newStart = moment(start).add(rest, 'minutes')
          } else {
            newStart = moment(start).subtract(doctorDuration - rest, 'minutes')
          }
        }
      }
    }
    if (endDiff !== 0) {
      if (minutesDiff < doctorDuration) {
        newEnd = moment(end).add(doctorDuration - minutesDiff, 'minutes')
      } else {
        const rest = minutesDiff % doctorDuration
        const doctorDurationMid = doctorDuration / 2

        if (rest !== 0) {
          if (rest < doctorDurationMid) {
            newEnd = moment(end).subtract(rest, 'minutes')
          } else {
            newEnd = moment(end).add(doctorDuration - rest, 'minutes')
          }
        }
      }
    }
    const newArray = myEvents.map((event: any) => {
      if (event.id == id) {
        event = { ...event, start: moment(newStart).toDate(), end: moment(newEnd).toDate() }
      }
      return event
    })
    setMyEvents(newArray)
    updateEvents(info.event, moment(newStart).toDate(), moment(newEnd).toDate())
  }

  const handleEventReceive = (info: any) => {
    const { start } = info.event
    const groupDuration = calculateGroupDuration(selectedGroup.group.id, appointmentRequestEtGroups)
    if ((groupDuration && groupDuration <= 0) || typeof groupDuration != 'number') {
      info.revert()
      return
    }
    const end = moment(start).add(groupDuration, 'minutes')
    const startTime = new Date(start).getTime()
    if (startTime <= new Date().getTime()) {
      info.revert()
      return
    } else {
      const newEvent = {
        id: myEvents.length,
        title: `Group ${selectedGroup.id + 1}`,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        eventUniqueId: generateUid(),
        groupDuration,
        idanaLink: '',
        examinationTypes: selectedGroup.group.examinationTypes,
        etGroupId: selectedGroup.group.id,
        belongsToAppointment: true
      } as any
      setMyEvents((state: any) => [...state, newEvent])
      assignAvailabillity(myEvents, newEvent)
    }
  }

  const onSelect = (info: any) => {
    const { start, end, view } = info
    if (view.type === 'dayGridMonth') {
      calendarRef.current?.getApi().changeView('timeGridDay', start)
      return
    }
    const minutesDiff = moment.duration(moment(end).diff(moment(start))).asMinutes()
    const doctorDuration = selectedGroup.group.minDoctorPerPatient
    let newEnd = end
    view.calendar.unselect()
    const groupDuration = calculateGroupDuration(selectedGroup.group.id, appointmentRequestEtGroups)
    if ((groupDuration && groupDuration <= 0) || typeof groupDuration != 'number') {
      return
    }
    if (minutesDiff < doctorDuration) {
      newEnd = moment(end).add(doctorDuration - minutesDiff, 'minutes')
    } else {
      const rest = minutesDiff % doctorDuration
      const doctorDurationMid = doctorDuration / 2

      if (rest !== 0) {
        if (rest < doctorDurationMid) {
          newEnd = moment(end).subtract(rest, 'minutes')
        } else {
          newEnd = moment(end).add(doctorDuration - rest, 'minutes')
        }
      }
    }
    const startTime = new Date(start).getTime()
    if (startTime <= new Date().getTime()) {
      return
    } else {
      const newEvent = {
        id: Date.now(),
        title: `Group ${selectedGroup.id + 1}`,
        start: moment(start).toDate(),
        end: moment(newEnd).toDate(),
        eventUniqueId: generateUid(),
        groupDuration,
        idanaLink: '',
        examinationTypes: selectedGroup.group.examinationTypes,
        etGroupId: selectedGroup.group.id,
        belongsToAppointment: true
      } as any
      setMyEvents((state: any) => [...state, newEvent])
      assignAvailabillity(myEvents, newEvent)
    }
  }

  const removeSlot = (id: string) => {
    const newEvents = myEvents.filter((event: any) => event.id != id)
    const newAddedAppointmends = addedAppointments.filter(
      (appointment: any) => id != appointment.id
    )
    setMyEvents(newEvents)
    setAddedAppointments(newAddedAppointmends)
  }

  useEffect(() => {
    let initialDate: any = null
    savedEvents.forEach((event: any) => {
      if (event.belongsToAppointment) {
        if (!initialDate) {
          initialDate = moment(event.start).toDate()
        } else {
          const minutesDiff = moment
            .duration(moment(initialDate).diff(moment(event.start)))
            .asDays()
          if (event)
            if (minutesDiff > 0) {
              initialDate = moment(event.start).toDate()
            }
        }
      }
    })
    if (!initialDate) {
      initialDate = moment().toDate()
    }
    setInitialDate(moment(initialDate).format('YYYY-MM-DD'))
  }, [savedEvents])

  return (
    <>
      {initialDate && (
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          height={600}
          initialDate={initialDate}
          scrollTime={hours?.min ? hours.min : '09:00'}
          eventBackgroundColor={'transparent'}
          eventBorderColor={'#fff'}
          dayMaxEvents={4}
          initialView='timeGridDay'
          weekends={true}
          events={myEvents}
          editable={true}
          locales={allLocales}
          locale={language.toLowerCase()}
          droppable={true}
          selectable={true}
          eventResizableFromStart={true}
          eventDrop={moveEvent}
          eventResize={resizeEvent}
          eventReceive={handleEventReceive}
          slotDuration={'00:05:00'}
          selectMirror={true}
          allDaySlot={false}
          eventDurationEditable={true}
          slotLabelInterval={1}
          slotMinTime={'05:00'}
          slotMaxTime={'22:00'}
          selectOverlap={true}
          select={onSelect}
          showNonCurrentDates={false}
          eventContent={(e) => (
            <EventContent eventInfo={e} savedEvents={savedEvents} removeSlot={removeSlot} />
          )}
          businessHours={[
            {
              daysOfWeek: [1, 2, 3, 4, 5],
              startTime: hours?.min ? hours.min : '09:00',
              endTime: hours?.max ? hours.max : '17:00'
            }
          ]}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false
          }}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          datesSet={(dateInfo: any) =>
            id &&
            dispatch(
              getAllOtherTimeslots({
                page: 0,
                limit: 10000,
                id: user.id,
                day: new Date(dateInfo.start).toISOString(),
                appointmentId: id
              })
            )
          }
        />
      )}
    </>
  )
}
