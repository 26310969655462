import { XMarkIcon } from '@heroicons/react/20/solid'
import { Button } from '@mui/material'
import PropTypes, { InferProps } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/redux/hook'
import { SuccessAlert } from './Alerts'
import { Input, TextArea } from './FormComponents'
import LoadingOverlay from './LoadingOverlay'

FeedbackModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  userEmail: PropTypes.string,
  handleFeedbackChange: PropTypes.func,
  errors: PropTypes.any
}

function FeedbackModal({
  onCancel,
  onConfirm,
  userEmail,
  handleFeedbackChange,
  errors
}: InferProps<typeof FeedbackModal.propTypes>) {
  const { t } = useTranslation()

  const { widgetSendFeedback } = useAppSelector((state) => state)
  const { success, loading } = widgetSendFeedback

  return (
    <div>
      <div
        className='fixed w-full h-full bg-brand-behind-modal-color z-[52] left-0 top-0'
        onClick={() => onCancel?.()}
      ></div>
      <div className='bg-white rounded-lg shadow fixed z-[53] max-w-[800px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full'>
        <button
          onClick={() => onCancel?.()}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='p-6 text-left flex flex-col'>
          {loading ? (
            <div className='p-6 flex justify-center items-center'>
              <LoadingOverlay />
            </div>
          ) : (
            <>
              {success && <SuccessAlert message={t('FEEDBACK_MODAL.SUCCESS_MESSAGE')} hide />}
              <h1 className='customHead2'>{t('FEEDBACK_MODAL.TITLE')}</h1>
              <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-1'>
                  <Input
                    label={t('FEEDBACK_MODAL.FROM')}
                    required
                    value={userEmail ?? ''}
                    disabled
                    error={errors?.sender}
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <Input
                    label={t('FEEDBACK_MODAL.SUBJECT')}
                    required
                    onChange={(value: any, name?: string) =>
                      handleFeedbackChange && handleFeedbackChange(value, name)
                    }
                    name='subject'
                    error={errors?.subject}
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <TextArea
                    label={t('FEEDBACK_MODAL.DESCRIPTION')}
                    required
                    onChange={(value: any, name?: string) =>
                      handleFeedbackChange && handleFeedbackChange(value, name)
                    }
                    name='description'
                    error={errors?.description}
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <p className='block customLabel'>{t('FEEDBACK_MODAL.IMAGE_ATTACHMENTS')}</p>
                  <input
                    type='file'
                    className='w-fit'
                    onChange={(e) =>
                      handleFeedbackChange && handleFeedbackChange(e.target.files, 'files')
                    }
                    name='files'
                    accept='image/*'
                    multiple={true}
                    placeholder={t('FEEDBACK_MODAL.UPLOAD_BUTTON')}
                  />
                  {errors?.files && <p className='text-red-500 text-sm'>{errors?.files}</p>}
                </div>
              </div>
              <div className='flex flex-col sm:flex-row gap-2 mt-5'>
                <Button onClick={() => onConfirm?.()} variant='contained' className='main-btn'>
                  {t('BUTTONS.SEND')}
                </Button>
                <Button variant='contained' onClick={() => onCancel?.()} className='secondary-btn'>
                  {t('BUTTONS.CANCEL')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
