import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppointmentRequestEntity } from 'src/entities/interfaces'
import { AppointmentRequestListItem } from 'src/interfaces/appointmentRequest'
import {
  getAllOverdueUsers,
  getAllScheduledAppointments
} from 'src/pages/Admins/Users/helpers/externalFunctions'
import { getOneUser } from 'src/redux/actions/userActions'
import {
  getNumberOfLocationsEmployees,
  getUpcomingAppointmentRequests
} from 'src/redux/actions/widgetActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { getAppointmentRequests } from '../AppointmentRequests/helpers/externalFunctions'
import { getCompanyByLocation } from '../CustomerOverview/helpers/externalFunctions'
import { getCompanyLocationById } from '../SingleCustomer/ExaminationsSelection/helpers/externalFunctions'
import { ReportCard, UpcomingAppointmentRequestsList } from './components'
import './index.scss'

export const Dashboard = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { userSignin, rolesGetByUser, widgetLocationsEmployeesNumberGet } = useAppSelector(
    (state) => state
  )
  const { user } = userSignin
  const { role } = rolesGetByUser
  const { numbers } = widgetLocationsEmployeesNumberGet
  const [examinationsOverdue, setExaminationsOverdue] = useState<number>()
  const [scheduled, setScheduled] = useState<number>(0)
  const [appointmentRequests, setAppointmentRequests] = useState([] as AppointmentRequestEntity[])
  const [examinationRequests, setExaminationRequests] = useState<AppointmentRequestListItem[]>([])
  const [overDueUsers, setOverDueUsers] = useState<string[]>()

  useEffect(() => {
    ;(async () => {
      if (user) {
        dispatch(getUpcomingAppointmentRequests(user.id))
        dispatch(getNumberOfLocationsEmployees(user.id))
        const users = await getAllOverdueUsers(user.id)
        const scheduledAppointments = await getAllScheduledAppointments(user.id)
        if (users) {
          setOverDueUsers(users)
        }
        if (scheduledAppointments) {
          setScheduled(
            scheduledAppointments
              .map((item: any) => item.value)
              .reduce((a: any, b: any) => a + b, 0)
          )
        }
      }
    })()
  }, [user])

  useEffect(() => {
    if (overDueUsers) {
      let overDueLengthHelper = 0
      overDueUsers.forEach((user: any) => {
        overDueLengthHelper += user.value
      })
      setExaminationsOverdue(overDueLengthHelper)
    }
  }, [overDueUsers])

  const checkRole = (role: string[]) => {
    let isDoctorOrMfa = false
    if (role && role.length > 0) {
      role.forEach((role) => {
        if (role === 'doctor' || role === 'mfa') {
          isDoctorOrMfa = true
        }
      })
    }
    return isDoctorOrMfa
  }

  useEffect(() => {
    ;(async () => {
      if (appointmentRequests && checkRole(role)) {
        const appointmentRequests = await getAppointmentRequests({
          userId: user?.id
        })
        setAppointmentRequests(appointmentRequests.rows)
      }
    })()
  }, [role])

  useEffect(() => {
    ;(async () => {
      const rows: AppointmentRequestListItem[] = []
      for (const appointmentRequest of appointmentRequests) {
        if (appointmentRequest.status === 'requested') {
          const { companyLocationId, contactId } = appointmentRequest
          const companyLocation = await getCompanyLocationById(companyLocationId)
          const contact = await getOneUser(contactId)(dispatch)
          const company = await getCompanyByLocation(companyLocationId)
          rows.push({
            ...appointmentRequest,
            createdAt: moment(appointmentRequest.createdAt).format('DD.MM.YYYY.'),
            companyLocation: companyLocation.name,
            contact: `${contact.firstName} ${contact.lastName}`,
            status: appointmentRequest.status,
            company: company[0].name
          })
        }
      }
      setExaminationRequests(rows)
    })()
  }, [appointmentRequests])

  return (
    <div className='dashboard-page'>
      {checkRole(role) ? (
        <div className='mx-auto max-w-7xl py-6 gap-4'>
          <div className='card-holder grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
            <ReportCard
              topText={t('DASHBOARD.CUSTOMERS')}
              bottomText={`${numbers?.numberOfEmployees || 0} ${t('DASHBOARD.EMPLOYEES')}`}
              number={numbers?.numberOfLocations || 0}
              link={'clients'}
            />
            <ReportCard
              topText={t('DASHBOARD.OVERDUE')}
              bottomText={`${t('DASHBOARD.EXAMINATIONS')}`}
              number={examinationsOverdue || 0}
              link={null}
            />
            <ReportCard
              topText={t('DASHBOARD.SCHEDULED')}
              bottomText={`${t('DASHBOARD.EXAMINATIONS')}`}
              number={scheduled || 0}
              link={null}
            />
            <ReportCard
              topText={t('DASHBOARD.REQUESTS')}
              bottomText={`${t('DASHBOARD.EXAMINATIONS')}`}
              number={examinationRequests?.length || 0}
              link={'appointment-requests'}
            />
          </div>
          <div className='mt-[25px] px-2'>
            <UpcomingAppointmentRequestsList />
          </div>
        </div>
      ) : null}
    </div>
  )
}
