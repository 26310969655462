import { ReactNode } from 'react'
import { useLocation, useParams } from 'react-router-dom'

interface LabelLeftHolderInterface {
  label?: string
  children?: ReactNode
  inputHolderStyle?: string
  required?: boolean
}

export function LabelLeftHolder({
  children,
  label = '',
  inputHolderStyle = '',
  required = false
}: LabelLeftHolderInterface) {
  const location = useLocation()
  const { id: companyId } = useParams()

  return (
    <div className='label-left-holder'>
      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:pb-[20px] items-center'>
        <label className='block customLabel'>
          {label}
          {required && <span className='text-red-700'> *</span>}
        </label>
        <div
          className={`${
            inputHolderStyle && inputHolderStyle
          } mt-1 sm:mt-0 col-span-2 md:col-span-1`}
        >
          {children}
        </div>
        {label === 'Email' && location.pathname === `/users/edit/${companyId}` && (
          <div className='col-span-2 md:col-span-1'>
            <span className='text-sm customLabel'>
              Changing this field will affect the login details for this user
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
