import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { getBasPracticeByCompanyLocationId } from 'src/pages/Admins/Companies/helper/externalFunctions'
import { getCompanyByLocation } from 'src/pages/Doctors/CustomerOverview/helpers/externalFunctions'
import { getCompanyLocationById } from 'src/pages/Doctors/SingleCustomer/ExaminationsSelection/helpers/externalFunctions'
import { edit } from 'src/redux/actions/appointmentRequestActions'
import { editAppointmentRequestEtGroup } from 'src/redux/actions/appointmentRequestEtGroupActions'
import { createOrUpdate, getAllForUser } from 'src/redux/actions/doctorAvailabillityActions'
import { getOne } from 'src/redux/actions/examinationRequest'
import { getWorkingHours } from 'src/redux/actions/practiceActions'
import { getOneUser } from 'src/redux/actions/userActions'
import {
  DOCTOR_AVAILABILLITY_CREATE_RESET,
  DOCTOR_AVAILABILLITY_GET_ALL_RESET
} from 'src/redux/constants/doctorAvailabillityContants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { Calendar, GroupList, InfoBox } from './components'
import {
  calculateGroupDuration,
  compareEvents,
  generateUid,
  getProvidedSlotsTime
} from './helpers/internalFunctions'
import './index.scss'

export const ExaminationRequest = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    createDoctorAvailabillity,
    userSignin,
    userGetOne,
    getAvailabillityForUser,
    appointmentRequestEtGroupGetAll,
    practiceWorkingHoursGet
  } = useAppSelector((state) => state)
  const { user: clientUser } = userGetOne
  const { doctorAvailabillities, loading } = getAvailabillityForUser
  const { user } = userSignin
  const { hours } = practiceWorkingHoursGet
  const { appointmentRequestEtGroups } = appointmentRequestEtGroupGetAll
  const { error: createError, success, loading: createLoading } = createDoctorAvailabillity
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({ id: 0, group: [] })
  const [addedAppointments, setAddedAppointments] = useState<any[]>([])
  const [company, setCompany] = useState({ name: '', id: '' })
  const [companyLocation, setCompanyLocation] = useState({ id: '', address: '' })
  const [clientContact, setClientContact] = useState({ name: '', phone: '', email: '' })
  const [error, setError] = useState<string>()
  const [submitted, setSubmitted] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [myEvents, setMyEvents] = useState<any[]>([])
  const [savedEvents, setSavedEvents] = useState<any[]>([])
  const [eventsAreSame, setEventsAreSame] = useState(true)
  const [groupsAreSame, setGroupsAreSame] = useState(true)
  const [examinations, setExaminations] = useState<any>([])
  const [savedExaminations, setSavedExaminations] = useState<any[]>([])
  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([])
  const [originalPerformAddress, setOriginalPerformAddress] = useState<string | undefined>('')
  const [performAddress, setPerformAddress] = useState<string | undefined>('')
  const [appointmentRequestEtGroupsState, setAppointmentRequestEtGroupsState] = useState<any>([])
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_RESET })
    dispatch({ type: DOCTOR_AVAILABILLITY_CREATE_RESET })
    dispatch(getOne(id))
    setIsFirstLoad(false)
  }, [])

  const checkIfEventBelongsToAppointment = (array: any, event: any) => {
    let title = ''
    array.forEach((arr: any, index: string) => {
      if (arr.id === event.appointmentRequestETGroupId) {
        index = index + 1
        title = `${t('GENERIC.GROUP')} ${index.toString()}`
      }
    })
    return title
  }

  const checkForUniqueId = (array: any, event: any) => {
    let id
    array.forEach((appointment: { doctorAvailabillityId: any; eventUniqueId: any }) => {
      if (appointment.doctorAvailabillityId === event.id) {
        id = appointment.eventUniqueId
      }
    })
    return id
  }

  useEffect(() => {
    ;(async () => {
      if (doctorAvailabillities && doctorAvailabillities.length && appointmentRequestEtGroups) {
        const belongsToAppointment = doctorAvailabillities.filter(
          (item1: { appointmentRequestETGroupId: string }) =>
            appointmentRequestEtGroups.some(
              (item2: { id: string }) => item1.appointmentRequestETGroupId === item2.id
            )
        )
        if (belongsToAppointment && belongsToAppointment.length > 0) {
          belongsToAppointment.forEach((appointment: any) => {
            appointment.doctorAvailabillityId = appointment.id
            appointment.etGroupId = appointment.appointmentRequestETGroupId
            appointment.eventUniqueId = generateUid()
            appointment.start = appointment.startDate
            appointment.end = appointment.endDate
          })
          setAddedAppointments(belongsToAppointment)
        }
        const appointmentsHelper: {
          id: any
          title: string
          start: Date
          end: Date
          belongsToAppointment: boolean
          idanaLink: any
          examinationTypes: any
          etGroupId: any
          groupDuration: number | undefined
          addedTimeframe: boolean
          doctorAvailabillityId: string
          eventUniqueId: string
          performAddress: string
        }[] = []
        for (let i = 0; i < doctorAvailabillities.length; i++) {
          const checkId = checkForUniqueId(belongsToAppointment, doctorAvailabillities[i])
          const groupTitle = checkIfEventBelongsToAppointment(
            appointmentRequestEtGroups,
            doctorAvailabillities[i]
          )
          const group = appointmentRequestEtGroups.find(
            (group: any) => group.id === doctorAvailabillities[i].appointmentRequestETGroupId
          )
          // const isOccupied = await getBookedAppointments(doctorAvailabillities[i].id)
          appointmentsHelper.push({
            id: doctorAvailabillities[i].id,
            title: groupTitle,
            start: new Date(doctorAvailabillities[i].startDate),
            end: new Date(doctorAvailabillities[i].endDate),
            belongsToAppointment: groupTitle ? true : false,
            idanaLink: doctorAvailabillities[i].idanaLink,
            examinationTypes: group?.examinationTypes || [],
            etGroupId: doctorAvailabillities[i].appointmentRequestETGroupId,
            groupDuration: calculateGroupDuration(
              doctorAvailabillities[i].appointmentRequestETGroupId,
              appointmentRequestEtGroups
            ),
            addedTimeframe: true,
            doctorAvailabillityId: '',
            eventUniqueId: checkId ? checkId : generateUid(),
            performAddress: doctorAvailabillities[i].performAddress
            // scheduledAppointments: isOccupied,
            // occupied_timeslots: []
          })

          if (doctorAvailabillities[i].performAddress && !isLoaded) {
            setPerformAddress(doctorAvailabillities[i].performAddress)
            setOriginalPerformAddress(doctorAvailabillities[i].performAddress)
          }
        }
        setSavedEvents((prev) => [...prev, ...appointmentsHelper])
        setMyEvents((prev) => [...prev, ...appointmentsHelper])
        setIsLoaded(true)
      } else if (doctorAvailabillities && doctorAvailabillities.length === 0 && !loading) {
        setIsLoaded(true)
      }
    })()
  }, [doctorAvailabillities, appointmentRequestEtGroups, loading])

  useEffect(() => {
    if (
      originalPerformAddress &&
      !locationOptions.find((location) => location.value === originalPerformAddress)
    ) {
      setLocationOptions((prev) => [
        ...prev,
        { label: originalPerformAddress, value: originalPerformAddress }
      ])
    }
  }, [originalPerformAddress, locationOptions])

  useEffect(() => {
    if (user && id) {
      dispatch(
        getAllForUser({
          page: 0,
          limit: 10000,
          id: user.id,
          appointmentId: id
        })
      )
    }
  }, [user, id])

  useEffect(() => {
    ;(async () => {
      if (state) {
        const fetchedCompany = await getCompanyByLocation(state.state.companyLocationId)
        const fetchedCompanyLocation = await getCompanyLocationById(state.state.companyLocationId)
        setCompany({ name: fetchedCompany[0].name, id: fetchedCompany[0].id })
        setCompanyLocation({
          id: state.state.companyLocationId,
          address: fetchedCompanyLocation.address
        })
        dispatch(getOneUser(state.state.contactId))
        dispatch(getWorkingHours(state.state.basPracticeId))
      }
    })()
  }, [state])

  useEffect(() => {
    if (clientUser && state) {
      setClientContact({
        name: state.state.contact,
        phone: clientUser.phoneNumber,
        email: clientUser.email
      })
    }
  }, [clientUser, state])

  const assignAvailabillity = (app: any, event: any) => {
    setAddedAppointments((prev) => [event, ...prev])
    const examinationHelper = []
    for (let examination of examinations) {
      if (examination.id === event.etGroupId) {
        examination = { ...examination, addedTimeframe: true }
      }
      examinationHelper.push(examination)
    }
    setExaminations(examinationHelper)
  }

  const updateEvents = (event: any, start: Date, end: Date) => {
    if (addedAppointments.length) {
      const appointmentHelper = []
      for (let appointment of addedAppointments) {
        if (appointment.eventUniqueId === event.extendedProps.eventUniqueId) {
          appointment = { ...appointment, start, end }
        }
        appointmentHelper.push(appointment)
      }
      setAddedAppointments(appointmentHelper)
    }
  }

  const saveSelectedTimeframe = () => {
    setSubmitted(true)
    const hasError = checkForErrors()
    if (hasError) {
      return
    } else {
      const data = addedAppointments.map((appointment) => {
        return {
          startDate: appointment.start.toString(),
          endDate: appointment.end.toString(),
          doctorId: user.id,
          companyId: company.id,
          companyLocationId: companyLocation.id,
          idanaLink: appointment.idanaLink,
          appointmentRequestETGroupId: appointment.etGroupId,
          groupDuration: calculateGroupDuration(appointment.etGroupId, appointmentRequestEtGroups),
          doctorAvailabillityId: appointment.doctorAvailabillityId
            ? appointment.doctorAvailabillityId
            : '',
          contactEmail: clientContact.email,
          contactName: clientContact.name,
          performAddress: performAddress
        }
      })
      dispatch(createOrUpdate(data))
      appointmentRequestEtGroupsState.forEach((group: any) => {
        dispatch(editAppointmentRequestEtGroup({ IDANALink: group.idanaLink }, group.id))
      })
      dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_RESET })
    }
  }

  useEffect(() => {
    if (!isFirstLoad) {
      setEventsAreSame(compareEvents(myEvents, savedEvents))
    }
  }, [myEvents])

  useEffect(() => {
    if (!isFirstLoad) {
      let groupsAreSame = true
      for (const examination of examinations) {
        const savedExamination = savedExaminations.find((e: any) => e.id === examination.id)
        const indanaLink = examination.idanaLink ? examination.idanaLink : null
        const performAddress = examination.performAddress ? examination.performAddress : null
        if (savedExamination.performAddress !== performAddress) {
          groupsAreSame = false
        }
        if (savedExamination.idanaLink !== indanaLink) {
          groupsAreSame = false
        }
        if (!groupsAreSame) {
          break
        }
      }
      setGroupsAreSame(groupsAreSame)
    }
  }, [examinations])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (success && id && !isFirstLoad) {
      setDisplaySuccessButton(true)
      dispatch(edit(id, { status: 'booked' }))
      navigate('/appointment-requests', { replace: true })
    }
  }, [success])

  useEffect(() => {
    if (!isFirstLoad) {
      checkForErrors()
    }
  }, [submitted, myEvents, performAddress])

  const checkForErrors = () => {
    let hasError = false
    hasError = appointmentRequestEtGroups.find(
      (group: any) =>
        getProvidedSlotsTime(myEvents, group.id) < group?.minDoctorPerPatient * group?.numberOfUsers
    )
    if (hasError) {
      setError(t('ERROR_MESSAGES.NO_ENOUGH_TIME'))
    }
    if (!hasError) {
      hasError = myEvents.find((group: any) => group.addedTimeframe === false) ? true : false
      if (hasError) {
        setError(t('ERROR_MESSAGES.NO_TIMEFRAMES'))
      } else {
        setError('')
      }
    }
    if (!hasError && !performAddress) {
      hasError = true
      setError(t('ERROR_MESSAGES.NO_CHOOSEN_LOCATION'))
    }
    return hasError
  }

  useEffect(() => {
    ;(async () => {
      if (companyLocation.id) {
        const helper: { label: string; value: string }[] = []
        helper.push({ label: companyLocation.address, value: companyLocation.address })
        const basPractice = await getBasPracticeByCompanyLocationId({ id: companyLocation.id })
        if (basPractice[0].address) {
          const { address } = basPractice[0]
          helper.push({ label: address, value: address })
        }
        setLocationOptions(helper)
      }
    })()
  }, [companyLocation])

  return (
    <div className='overflow-hidden bg-white'>
      {createLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          {createError && <ErrorAlert message={createError} hide />}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/appointment-requests')}
              message={t('MODAL.CANCEL')}
            />
          )}
          <div className='mx-auto max-w-7xl'>
            <div className='grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start h-full'>
              <div className='h-full'>
                <InfoBox
                  companyAddress={companyLocation.address}
                  clientContact={clientContact}
                  appointmentRequestEtGroups={appointmentRequestEtGroups}
                />
                <GroupList
                  setAppointmentRequestEtGroupsState={setAppointmentRequestEtGroupsState}
                  appointmentRequestEtGroupsState={appointmentRequestEtGroupsState}
                  myEvents={myEvents}
                  setMyEvents={setMyEvents}
                  setSelectedGroup={setSelectedGroup}
                  selectedGroup={selectedGroup}
                  examinations={examinations}
                  setExaminations={setExaminations}
                  setSavedExaminations={setSavedExaminations}
                  addedAppointments={addedAppointments}
                  setAddedAppointments={setAddedAppointments}
                  locationOptions={locationOptions}
                  setPerformAddress={setPerformAddress}
                  performAddress={performAddress}
                />
                {error && error.length && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
                <div className='flex justify-start gap-3 mt-3'>
                  <Button
                    className={`main-btn ${(createLoading || displaySuccessButton) && 'processing'}`}
                    variant='contained'
                    disabled={
                      createLoading ||
                      displaySuccessButton ||
                      (eventsAreSame && groupsAreSame) ||
                      error !== ''
                    }
                    onClick={() => saveSelectedTimeframe()}
                  >
                    {createLoading || displaySuccessButton ? (
                      <ButtonProcessing displaySuccessButton={displaySuccessButton} />
                    ) : (
                      `${t('CREATE_PREVENTIVE_LOG_CUSTOMER.SAVE_TIMEFRAME')}`
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      if (eventsAreSame && groupsAreSame) {
                        navigate('/appointment-requests')
                      } else {
                        setIsCancelModal(true)
                      }
                    }}
                    className='secondary-btn'
                    variant='contained'
                  >
                    {t('BUTTONS.CANCEL')}
                  </Button>
                </div>
              </div>
              <div className='sm:px-6 lg:px-0'>
                <div className='mx-auto max-w-2xl sm:mx-0 sm:max-w-none'>
                  {isLoaded && (
                    <Calendar
                      hours={hours}
                      myEvents={myEvents}
                      savedEvents={savedEvents}
                      setMyEvents={setMyEvents}
                      selectedGroup={selectedGroup}
                      updateEvents={updateEvents}
                      assignAvailabillity={assignAvailabillity}
                      setAddedAppointments={setAddedAppointments}
                      addedAppointments={addedAppointments}
                      appointmentRequestEtGroups={appointmentRequestEtGroups}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
