import { XMarkIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import PropTypes, { InferProps } from 'prop-types'

WarningModal.propTypes = {
  message: PropTypes.string,
  onCancel: PropTypes.func
}

function WarningModal({ message, onCancel }: InferProps<typeof WarningModal.propTypes>) {
  return (
    <div>
      <div
        className='fixed w-full h-full bg-brand-behind-modal-color z-[52] left-0 top-0'
        onClick={() => onCancel?.()}
      ></div>
      <div className='bg-white rounded-lg shadow fixed z-[53] max-w-[400px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <button
          onClick={() => onCancel?.()}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='p-6 text-center'>
          <ExclamationCircleIcon className='mx-auto mb-4 text-gray-500 w-11 h-11' />
          <p className='customParagraph medium-size'>{message}</p>
        </div>
      </div>
    </div>
  )
}

export default WarningModal
