import Button from '@mui/material/Button'
import { debounce, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { InputLabelLeft, MultiSelectLabelLeft, RadioGroup } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { BasPracticeEntity, RoleEntity } from 'src/entities/interfaces'
import { PracticeOption } from 'src/interfaces/practices'
import { RoleSearch } from 'src/interfaces/role'
import { FetchedRoles, UserErrors, UserInputs } from 'src/interfaces/users'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { getAll } from 'src/redux/actions/practiceActions'
import { getAllRoles } from 'src/redux/actions/roleActions'
import { create, editUser, getOneUser } from 'src/redux/actions/userActions'
import { USER_CREATE_RESET, USER_GET_ONE_RESET } from 'src/redux/constants/userConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { formsChangesListener } from 'src/services/formsChangesListener.service'
import { emailValidation } from 'src/services/validation'
import { checkRequiredFields, validation } from 'src/services/validation/userForm'
import {
  editConnection,
  findUserByPracticeConnection,
  findUserByRoleConnection,
  getAvailableUserName
} from './helpers/externalFunctions'

export const UserForm = () => {
  const { t } = useTranslation()
  const { id: userId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [oldPractice, setOldPractice] = useState<PracticeOption[]>([])
  const [chosenRole, setChosenRole] = useState<RoleEntity[] | string>('')
  const [practicesQuery, setPracticesQuery] = useState('')
  const [practicesOptions, setPracticesOptions] = useState<PracticeOption[]>([])
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [practiceId, setPracticeId] = useState<string[]>([])
  const [roleId, setRoleId] = useState('')
  const [fetchedRoles, setFetchedRoles] = useState<FetchedRoles[]>([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [relation, setRelation] = useState<string>('')
  const [submitted, setSubmitted] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [validationAction, setValidationAction] = useState('create')
  const [isCancelModalDisabled, setIsCancelModalDisabled] = useState(true)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [userNameHelper, setUserNameHelper] = useState('')
  const [inputs, setInputs] = useState<UserInputs>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    practice: [],
    userName: '',
    password: ''
  })
  const [oldInputs, setOldInputs] = useState<UserInputs>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    practice: [],
    userName: '',
    password: ''
  })
  let errors: UserErrors = {}

  const { practiceGetAll, userCreate, userEdit, rolesGetAll, userGetOne } = useAppSelector(
    (state) => state
  )
  const { user: oneUser, error: getOneError, loading: getOneLoading } = userGetOne
  const {
    error: userCreateError,
    user,
    loading: userCreateLoading,
    success: userCreateSuccess
  } = userCreate
  const { loading: editUserLoading, success: editUserSuccess, error: editUserError } = userEdit
  const { practices } = practiceGetAll
  const { roles } = rolesGetAll

  useEffect(() => {
    dispatch(getAll({ page: 0, limit: 20, query: practicesQuery }))
    dispatch(getAllRoles({ page: 0, limit: 20 } as RoleSearch))
  }, [])

  useEffect(() => {
    dispatch({ type: USER_CREATE_RESET })
    if (location.pathname.includes('edit')) {
      setValidationAction('edit')
      dispatch(getOneUser(userId))
      fetchByRelation()
    }
    if (relation) {
      setInputs({ ...inputs, role: relation })
    }
  }, [relation])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (userCreateSuccess && !initialLoad) {
      setDisplaySuccessButton(true)
      setTimeout(() => {
        navigate('/users')
      }, 100)
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [userCreateSuccess])

  useEffect(() => {
    if (editUserSuccess && !initialLoad) {
      setDisplaySuccessButton(true)
      setTimeout(() => {
        navigate('/users')
      }, 100)
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [editUserSuccess])

  useEffect(() => {
    ;(async () => {
      if (oneUser) {
        const role: RoleEntity[] = await findUserByRoleConnection(userId)
        setRoleId(role[0]?.id)
        const practiceHelper: PracticeOption[] = []
        if (role[0]?.role === 'doctor' || role[0]?.role === 'mfa') {
          setChosenRole(role)
        }
        const practices: BasPracticeEntity[] = await findUserByPracticeConnection(userId)
        practices.map((practice: BasPracticeEntity) => {
          practiceHelper.push({ value: practice.id, label: practice.name })
        })
        setInputs({
          firstName: oneUser.firstName,
          lastName: oneUser.lastName,
          email: oneUser.email,
          password: oneUser.password,
          userName: oneUser.userName,
          phoneNumber: oneUser.phoneNumber,
          role: role[0]?.role.charAt(0).toUpperCase() + role[0]?.role.slice(1),
          practice: practiceHelper
        })
        setOldInputs({
          firstName: oneUser.firstName,
          lastName: oneUser.lastName,
          email: oneUser.email,
          password: oneUser.password,
          userName: oneUser.userName,
          phoneNumber: oneUser.phoneNumber,
          role: role[0]?.role.charAt(0).toUpperCase() + role[0]?.role.slice(1),
          practice: practiceHelper
        })
        setOldPractice(practiceHelper)
        setUserNameHelper(oneUser.userName)
      }
    })()
    if (getOneError && !initialLoad) {
      dispatch({ type: USER_GET_ONE_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [oneUser, getOneError])

  useEffect(() => {
    const options = []
    if (practices) {
      for (let i = 0; i < practices.length; i++) {
        const option = {
          value: practices[i].id,
          label: practices[i].name
        }
        options.push(option)
      }
    }
    setPracticesOptions(options)
  }, [practices])

  useEffect(() => {
    if (user && submitted) {
      const relations = {
        roleId: [roleId],
        practiceId: practiceId
      }
      editConnection(user.id, relations)
      setSubmitted(false)
    }
  }, [user, submitted])

  useEffect(() => {
    if (oneUser && submitted) {
      const relations = {
        roleId: [roleId],
        practiceId: practiceId
      }
      editConnection(userId, relations)
    }
  }, [oneUser, submitted])

  useEffect(() => {
    if (roles && roles.roles && initialLoad) {
      roles.roles.forEach((role: RoleEntity) => {
        if (role.role === 'employee') {
          return
        } else {
          setFetchedRoles((prev) => [
            ...prev,
            { id: role.id, title: role.role.charAt(0).toUpperCase() + role.role.slice(1) }
          ])
        }
      })
      setInitialLoad(false)
    }
  }, [roles, initialLoad])

  useEffect(() => {
    if (!initialLoad) {
      const isDisabledRequired = checkRequiredFields(
        inputs,
        ['firstName', 'lastName', 'email', 'password', 'role', 'practice'],
        oneUser ? 'edit' : 'create'
      )
      let isDisabledChanges = false
      if (oneUser) {
        isDisabledChanges = formsChangesListener({
          inputs,
          oldInputs,
          multiSelectInputs: ['practice']
        })
      }
      if (!isDisabledChanges && oneUser) {
        setIsCancelModalDisabled(true)
      } else {
        setIsCancelModalDisabled(false)
      }
      if (isDisabledRequired > 0 || (!isDisabledChanges && oneUser)) {
        setIsSaveDisabled(true)
      } else {
        setIsSaveDisabled(false)
      }
    }
  }, [inputs, oneUser])

  async function fetchByRelation() {
    const role: RoleEntity[] = await findUserByRoleConnection(userId)
    setRelation(role[0]?.role)
  }

  const setUserName = debounce(async (email: string, firstName: string, lastName: string) => {
    let userName = ''
    const userId = oneUser?.id || null
    if (!emailValidation(email) && firstName !== '' && lastName !== '') {
      userName = await getAvailableUserName(email, firstName, lastName, userId)
    }
    setUserNameHelper(userName)
  }, 500)

  const setVal = (value: any, name: string) => {
    if (name === 'role') {
      roles?.roles?.forEach((role: RoleEntity) => {
        if (role.id === value) {
          if (role.role === 'doctor' || role.role === 'mfa') {
            setChosenRole(role.role)
          } else {
            setChosenRole('')
          }
          setRoleId(role.id)
          if (oldPractice.length > 0 && (role.role === 'doctor' || role.role === 'mfa')) {
            setInputs({
              ...inputs,
              role: role.role.charAt(0).toUpperCase() + role.role.slice(1),
              practice: [...oldPractice]
            })
          } else {
            setInputs({ ...inputs, role: role.role.charAt(0).toUpperCase() + role.role.slice(1) })
          }
        }
      })
    } else if (name === 'email') {
      setUserName(value, inputs['firstName'], inputs['lastName'])
      setInputs({ ...inputs, [name]: value })
    } else if (name === 'firstName') {
      setUserName(inputs['email'], value, inputs['lastName'])
      setInputs({ ...inputs, [name]: value })
    } else if (name === 'lastName') {
      setUserName(inputs['email'], inputs['firstName'], value)
      setInputs({ ...inputs, [name]: value })
    } else {
      setInputs({ ...inputs, [name]: value })
    }
  }

  useEffect(() => {
    if (inputs.role && inputs.role !== 'Doctor' && inputs.role !== 'Mfa') {
      setInputs({ ...inputs, practice: [] })
    }
  }, [inputs.role])

  const onSubmit = (action: string) => {
    setSubmitted(true)
    if (inputs.practice) {
      const practiceId = inputs.practice.map((practice: PracticeOption) => practice.value)
      setPracticeId(practiceId)
    }
    errors = validation(inputs, validationAction, inputs.role)
    if (!isEmpty(errors)) {
      return
    } else {
      inputs.userName = userNameHelper
      if (action === 'edit') {
        dispatch(editUser(inputs, userId))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else {
        if (inputs.role && inputs.practice) {
          Reflect.deleteProperty(inputs, 'role')
          Reflect.deleteProperty(inputs, 'practice')
        }
        dispatch(create(inputs))
        dispatch({ type: USER_CREATE_RESET })
      }
    }
  }

  if (submitted) {
    errors = validation(inputs, validationAction, inputs.role)
  }

  return (
    <>
      {getOneLoading || userCreateLoading || editUserLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(editUserError || userCreateError) && (
            <ErrorAlert message={userCreateError || editUserError} hide />
          )}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/users')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                const action = userId ? 'edit' : 'create'
                onSubmit(action)
                setIsSaveModal(false)
              }}
              message={`${t('GENERIC.CONFIRM_CHOICE')} ${
                userId ? t('GENERIC.UPDATE').toLowerCase() : t('GENERIC.CREATE').toLowerCase()
              } ${t('USER.USER').toLowerCase()}?`}
            />
          )}
          <div className='space-y-6'>
            <InputLabelLeft
              type='text'
              name='firstName'
              label={t('USER_INFO.FIRST_NAME')}
              onChange={setVal}
              error={errors['firstName']}
              value={inputs['firstName']}
              required
            />
            <InputLabelLeft
              type='text'
              name='lastName'
              label={t('USER_INFO.LAST_NAME')}
              onChange={setVal}
              error={errors['lastName']}
              value={inputs['lastName']}
              required
            />
            <InputLabelLeft
              type='text'
              name='email'
              label={t('USER_INFO.EMAIL')}
              onChange={setVal}
              error={errors['email']}
              value={inputs['email']}
              required
            />
            <InputLabelLeft
              type='text'
              name='userName'
              label={t('USER_INFO.USERNAME')}
              disabled={true}
              onChange={setVal}
              value={userNameHelper}
            />
            {location.pathname.includes('create') && (
              <InputLabelLeft
                type='text'
                name='password'
                label={t('AUTH.PASSWORD')}
                onChange={setVal}
                error={errors['password']}
                value={inputs['password']}
                required
              />
            )}
            {location.pathname.includes('edit') && inputs['role'].length > 0 && (
              <RadioGroup
                name='role'
                onChange={setVal}
                label={t('USER.ROLE')}
                values={fetchedRoles}
                error={errors['role']}
                defaultValue={inputs['role']}
                required
              />
            )}
            {!userId && (
              <RadioGroup
                name='role'
                onChange={setVal}
                label={t('USER.ROLE')}
                values={fetchedRoles}
                error={errors['role']}
                defaultValue=''
                required
              />
            )}
            {chosenRole && (
              <MultiSelectLabelLeft
                name='practice'
                options={practicesOptions}
                value={inputs['practice']}
                onChange={setVal}
                error={errors['practice']}
                setQuery={setPracticesQuery}
                label={t('PRACTICES.PRACTICE')}
                required
              />
            )}
            <InputLabelLeft
              type='text'
              name='phoneNumber'
              label={t('USER_INFO.PHONE_NUMBER')}
              value={inputs['phoneNumber']}
              onChange={setVal}
            />
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (userCreateLoading || editUserLoading || displaySuccessButton) && 'processing'
              }`}
              disabled={
                isSaveDisabled || userCreateLoading || editUserLoading || displaySuccessButton
              }
              variant='contained'
            >
              {userCreateLoading || editUserLoading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              onClick={() => {
                if (isCancelModalDisabled) {
                  navigate('/users')
                } else {
                  setIsCancelModal(true)
                }
              }}
              className='secondary-btn'
              variant='contained'
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
