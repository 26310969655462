import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { getAppointmentExaminationRecord } from 'src/redux/actions/appointmentActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { APPOINTMENT_EXAMINATION_RECORD_GET_RESET } from 'src/redux/constants/appointmentConstants'
import {
  EXAMINATION_HISTORY_GET_RESET,
  EXAMINATION_RECORD_MASS_MARK_RESET
} from 'src/redux/constants/examinationConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationHistory, ExaminationsList, InfoBox } from './components'

export const ExaminationsRecord = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { appointmentId, userId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { appointmentExaminationRecordGet, examinationRecordMassMark } = useAppSelector(
    (state) => state
  )
  const { loading: examinationsRecordLoading, error: examinationsRecordError } =
    appointmentExaminationRecordGet
  const {
    loading: massMarkLoading,
    error: massMarkError,
    success: massMarkSuccess
  } = examinationRecordMassMark

  useEffect(() => {
    setIsFirstLoad(false)
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_RESET })
    dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_RESET })
    dispatch({ type: EXAMINATION_HISTORY_GET_RESET })
    if (appointmentId && userId) {
      dispatch(getAppointmentExaminationRecord(appointmentId, userId))
    }
  }, [dispatch, appointmentId, userId])

  useEffect(() => {
    if (examinationsRecordError && !isFirstLoad) {
      dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [dispatch, examinationsRecordError])

  return (
    <>
      {massMarkLoading || examinationsRecordLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='relative'>
          {massMarkSuccess && <SuccessAlert message={t('ALERTS.SUCCESS_EDIT')} hide />}
          {massMarkError && <ErrorAlert message={massMarkError} hide />}
          <InfoBox />
          <ExaminationsList />
          <ExaminationHistory />
        </div>
      )}
    </>
  )
}
