import { AnyAction } from 'redux'
import {
  USER_EDIT_PASSWORD_FAIL,
  USER_EDIT_PASSWORD_REQUEST,
  USER_EDIT_PASSWORD_RESET,
  USER_EDIT_PASSWORD_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT_SUCCESS
} from 'src/redux/constants/authConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const userSigninReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true }
    case USER_SIGNIN_SUCCESS:
      return { loading: false, isSignin: true, user: action.payload }
    case USER_SIGNIN_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
        case 'WRONG_PASSWORD':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
      }
      return { loading: false, error }
    case USER_SIGNOUT_SUCCESS:
      return {}
    default:
      return state
  }
}

const userEditPasswordReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_EDIT_PASSWORD_REQUEST:
      return { loading: true }
    case USER_EDIT_PASSWORD_SUCCESS:
      return { loading: false, success: true }
    case USER_EDIT_PASSWORD_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case USER_EDIT_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

const userSignOutReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_SIGNOUT_SUCCESS:
      return { success: true }
    default:
      return state
  }
}

export const authReducers = {
  userSignin: userSigninReducer,
  userEditPassword: userEditPasswordReducer,
  userSignOut: userSignOutReducer
}
