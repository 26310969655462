import { ChevronDoubleRightIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { Button, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ExaminationTypeEntity } from 'src/entities/interfaces'
import { SelectedExaminationsInput } from 'src/interfaces/companyLocationSelectedExaminationType'
import { getAllExaminationTypeExaminationSelectionSearch } from 'src/redux/actions/examinationTypeActions'
import { CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET } from 'src/redux/constants/companyLocationSelectedExaminationTypeConstants'
import { EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_RESET } from 'src/redux/constants/examinationTypeConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export const ExaminationTypesList = ({
  setSelectedExaminationTypes,
  selectedExaminationTypes,
  query
}: ExaminationTypesList) => {
  const { t } = useTranslation()
  const { id: companyLocationId } = useParams()
  const dispatch = useAppDispatch()
  const [assignedByDoctor, setAssignedByDoctor] = useState<string>('')

  const { examinationTypeExaminationSelectionSearchGetAll, userSignin } = useAppSelector(
    (state) => state
  )
  const { riskExposureGroups } = examinationTypeExaminationSelectionSearchGetAll
  const { user } = userSignin

  useEffect(() => {
    if (user) {
      setAssignedByDoctor(user.id)
    }
  }, [user])

  useEffect(() => {
    dispatch({ type: EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_RESET })
    dispatch({ type: CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET })
  }, [])

  useEffect(() => {
    dispatch(getAllExaminationTypeExaminationSelectionSearch({ page: 0, limit: 1000, query }))
  }, [query])

  const onSelectExamination = (selectedExaminationType: ExaminationTypeEntity) => {
    const itemExist = selectedExaminationTypes.find(
      (examinationType) => examinationType.examinationTypeId === selectedExaminationType.id
    )
    if (!itemExist) {
      setSelectedExaminationTypes((prev: SelectedExaminationsInput[]) => [
        ...prev,
        {
          examinationTypeId: selectedExaminationType.id,
          label: selectedExaminationType.name,
          examinationNote: '',
          companyLocationId: companyLocationId,
          assignedBy: assignedByDoctor
        }
      ])
    }
  }

  const onSelectRiskArea = (riskArea: { examinationTypes: ExaminationTypeEntity[] }) => {
    const examinationTypesHelper = [...selectedExaminationTypes]
    for (const examinationType of riskArea.examinationTypes) {
      const selectedExaminationType = examinationType
      const itemExist = examinationTypesHelper.find(
        (examinationType) => examinationType.examinationTypeId === selectedExaminationType.id
      )
      if (!itemExist && companyLocationId) {
        examinationTypesHelper.push({
          examinationTypeId: selectedExaminationType.id,
          label: selectedExaminationType.name,
          examinationNote: '',
          companyLocationId: companyLocationId,
          assignedBy: assignedByDoctor
        })
      }
    }
    setSelectedExaminationTypes(examinationTypesHelper)
  }

  const onSelectRiskExposureGroup = (exposureGroup: any) => {
    const examinationTypesHelper = [...selectedExaminationTypes]
    for (const riskaArea of exposureGroup.riskArea) {
      const { examinationTypes } = riskaArea
      for (const examinationType of examinationTypes) {
        const selectedExaminationType = examinationType
        const itemExist = examinationTypesHelper.find(
          (examinationType) => examinationType.examinationTypeId === selectedExaminationType.id
        )
        if (!itemExist && companyLocationId) {
          examinationTypesHelper.push({
            examinationTypeId: selectedExaminationType.id,
            label: selectedExaminationType.name,
            examinationNote: '',
            companyLocationId: companyLocationId,
            assignedBy: assignedByDoctor
          })
        }
      }
    }
    setSelectedExaminationTypes(examinationTypesHelper)
  }

  return (
    <div className='h-full'>
      <div className='flex flex-col h-full'>
        <div className='flex flex-wrap gap-3 relative'>
          <div className='flex flex-col divide-y'>
            {riskExposureGroups?.length > 0 &&
              riskExposureGroups.map((riskExposureGroup: any, index: number) => {
                let isExaminationTypes = 0
                riskExposureGroup.riskArea.forEach((riskArea: any) => {
                  if (riskArea?.examinationTypes?.length > 0) {
                    isExaminationTypes += 1
                  }
                })
                if (riskExposureGroup.riskArea.length > 0 && isExaminationTypes > 0) {
                  return (
                    <div key={index} className='pt-12 first-of-type:pt-0'>
                      <div className='flex items-center gap-1'>
                        <h3 className='customHead2 mb-0 '>{riskExposureGroup.name}</h3>
                        <IconButton
                          className='py-0'
                          onClick={() => onSelectRiskExposureGroup(riskExposureGroup)}
                          title={t('EXAMINATION_SELECTION.ADD_ALL_TO_THE_LIST')}
                        >
                          <PlusCircleIcon className='w-5' />
                        </IconButton>
                      </div>
                      {riskExposureGroup.riskArea.map((riskArea: any, index: number) => {
                        if (riskArea.examinationTypes?.length > 0) {
                          return (
                            <div key={index} className='mt-4 first-of-type:mt-2'>
                              <div className='flex items-start gap-1'>
                                <p className='customParagraph'>{riskArea.name}</p>
                                <IconButton
                                  className='py-0'
                                  onClick={() => onSelectRiskArea(riskArea)}
                                  title={t('EXAMINATION_SELECTION.ADD_TO_THE_LIST')}
                                >
                                  <PlusCircleIcon className='w-5' />
                                </IconButton>
                              </div>
                              <div className='flex flex-wrap gap-2 mt-2 examination-types-holder'>
                                {riskArea.examinationTypes.map(
                                  (examinationType: ExaminationTypeEntity, index: number) => {
                                    return (
                                      <div key={index} className=''>
                                        <Button
                                          onClick={() => onSelectExamination(examinationType)}
                                          className='secondary-btn flex items-center gap-2'
                                          size='small'
                                          variant='contained'
                                        >
                                          {examinationType.name}
                                          <ChevronDoubleRightIcon className='w-3' />
                                        </Button>
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  )
                }
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

interface ExaminationTypesList {
  setSelectedExaminationTypes: any
  selectedExaminationTypes: SelectedExaminationsInput[]
  query: string
}
