import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Creatable from 'react-select/creatable'
import {
  editAppointmentRequestEtGroup,
  getAllAppointmentRequestEtGroup
} from 'src/redux/actions/appointmentRequestEtGroupActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { calculateGroupDuration } from '../helpers/internalFunctions'
import { GroupItem } from './GroupItem'

export const GroupList = ({
  setSelectedGroup,
  selectedGroup,
  examinations,
  setExaminations,
  myEvents,
  setMyEvents,
  setAddedAppointments,
  addedAppointments,
  setSavedExaminations,
  setAppointmentRequestEtGroupsState,
  appointmentRequestEtGroupsState,
  locationOptions,
  performAddress,
  setPerformAddress
}: {
  setSelectedGroup: (group: any) => void
  selectedGroup: { id: number; group: any[] }
  examinations: any
  setExaminations: (examinations: any) => void
  myEvents: any
  setMyEvents: (myEvents: any) => void
  setAddedAppointments: (appointments: any) => void
  addedAppointments: any[]
  setSavedExaminations: (examinations: any) => void
  appointmentRequestEtGroupsState: any
  setAppointmentRequestEtGroupsState: any
  locationOptions: { value: string; label: string }[]
  performAddress: string | undefined
  setPerformAddress: (location: string | undefined) => void
}) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [allowSaveDuration, setAllowSaveDuration] = useState(false)
  const [updatedData, setUpdatedData] = useState<{
    minDoctorPerPatient?: number
    minMfaPerPatient?: number
    groupId?: string
  }>({})

  const { appointmentRequestEtGroupGetAll } = useAppSelector((state) => state)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const { appointmentRequestEtGroups, error: getAppointmentRequestEtGroupsError } =
    appointmentRequestEtGroupGetAll

  useEffect(() => {
    setIsFirstLoad(false)
    if (id) {
      dispatch(getAllAppointmentRequestEtGroup({ appointmentRequestId: id }))
    }
  }, [])

  useEffect(() => {
    if (appointmentRequestEtGroups && !isFirstLoad) {
      appointmentRequestEtGroups.map(
        (group: {
          addedTimeframe: boolean
          IDANALink: string
          idanaLink: string
          addedSlots: string[]
        }) => {
          group.addedTimeframe = false
          group.addedSlots = []
          group.idanaLink = group.IDANALink
        }
      )
      setAppointmentRequestEtGroupsState(appointmentRequestEtGroups)
      setExaminations(appointmentRequestEtGroups)
      setSavedExaminations(appointmentRequestEtGroups)
      setSelectedGroup({ id: 0, group: appointmentRequestEtGroups[0] })
    }
    if (getAppointmentRequestEtGroupsError && !isFirstLoad) {
      dispatch(displayNotFoundElement(true))
    }
  }, [appointmentRequestEtGroups, getAppointmentRequestEtGroupsError])

  const selectGroup = (index: number, group: any) => {
    setSelectedGroup({ id: index, group: group })
  }

  const saveIdanaLink = (value: string, groupId: string) => {
    const groupHelper: any = []
    for (let group of appointmentRequestEtGroupsState) {
      if (groupId === group.id) {
        group = { ...group, idanaLink: value, IDANALink: value }
      }
      groupHelper.push(group)
    }
    const appointmentsHelper = []

    for (let appointment of addedAppointments) {
      if (appointment.etGroupId === groupId) {
        appointment = { ...appointment, idanaLink: value, IDANALink: value }
      }
      appointmentsHelper.push(appointment)
    }

    const examinationHelper: any = []
    for (let examination of examinations) {
      if (groupId === examination.id) {
        examination = { ...examination, idanaLink: value, IDANALink: value }
      }
      examinationHelper.push(examination)
    }
    setAddedAppointments(appointmentsHelper)
    setAppointmentRequestEtGroupsState(groupHelper)
    setExaminations(examinationHelper)
  }

  useEffect(() => {
    if (allowSaveDuration) {
      dispatch(
        editAppointmentRequestEtGroup(
          {
            minDoctorPerPatient: Number(updatedData.minDoctorPerPatient),
            minMfaPerPatient: Number(updatedData.minMfaPerPatient),
            minTotalGroup:
              Number(updatedData.minDoctorPerPatient) + Number(updatedData.minMfaPerPatient)
          },
          updatedData.groupId
        )
      )
    }
  }, [allowSaveDuration])

  const setDuration = (value: string, action: string, groupId: string, field: string) => {
    let minDoctorPerPatient
    let minMfaPerPatient
    const isValid = true
    if (!/^\d+$/.test(value) && value !== '') {
      return
    }
    let newValue = Number(value)
    if (action === 'increment') {
      newValue += 5
    } else {
      if (!(newValue - 5 <= 0)) {
        newValue -= 5
      }
    }
    const helper = appointmentRequestEtGroupsState.map((group: any) => {
      if (group.id === groupId) {
        group[field] = newValue
        minDoctorPerPatient = group.minDoctorPerPatient
        minMfaPerPatient = group.minMfaPerPatient
      }
      return group
    })
    setAppointmentRequestEtGroupsState(helper)

    if (isValid) {
      setAllowSaveDuration(false)
      setTimeout(() => {
        setAllowSaveDuration(true)
      }, 1000)
      const newEvents = myEvents.map((event: any) => {
        if (event.etGroupId == groupId) {
          event.groupDuration = calculateGroupDuration(groupId, helper)
        }
        return event
      })
      setMyEvents(newEvents)
      setUpdatedData({
        groupId,
        minDoctorPerPatient,
        minMfaPerPatient
      })
    }
  }

  const totalEstimatedTime = () => {
    if (appointmentRequestEtGroups) {
      const timeHelper: number[] = []

      appointmentRequestEtGroups.forEach(
        (ex: { minDoctorPerPatient: number; numberOfUsers: number }) => {
          timeHelper.push(ex.minDoctorPerPatient * ex.numberOfUsers)
        }
      )
      const sum = timeHelper.reduce((partialSum, a) => partialSum + a, 0)
      return sum + ' ' + t('GENERIC.MINUTES')
    }
  }

  return (
    <>
      <div className='mb-5 mt-3 max-w-xl space-y-4'>
        {appointmentRequestEtGroupsState.map(
          (
            group: {
              id: string
              examinationTypes: { examinationName: string }[]
              numberOfUsers: number
              minDoctorPerPatient: number
              minMfaPerPatient: number
              idanaLink: string
            },
            index: number
          ) => {
            return (
              <GroupItem
                myEvents={myEvents}
                key={index}
                group={group}
                index={index}
                selectedGroup={selectedGroup}
                selectGroup={selectGroup}
                setDuration={setDuration}
                saveIdanaLink={saveIdanaLink}
              />
            )
          }
        )}
      </div>
      <div className='flex flex-col gap-2 mb-3'>
        <p className='customParagraph'>
          {t('CREATE_PREVENTIVE_LOG_CUSTOMER.CHOOSE_LOCATION_TEXT')}
        </p>
        <Creatable
          options={locationOptions}
          placeholder={t('CREATE_PREVENTIVE_LOG_CUSTOMER.CHOOSE_LOCATION_DROPDOWN')}
          onChange={(e) => setPerformAddress(e?.value)}
          value={performAddress ? { value: performAddress, label: performAddress } : null}
          onBlur={(e) => {
            if (e.target.value) {
              setPerformAddress(e.target.value)
            }
          }}
          formatCreateLabel={(inputValue) =>
            t('CREATE_PREVENTIVE_LOG_CUSTOMER.CREATE_NEW', { value: inputValue })
          }
        />
      </div>
      <div className='flex flex-col'>
        <p className='customParagraph'>
          {t('EXAMINATION_TYPES.TOTAL_EXAMINATION_TIME')}: {totalEstimatedTime()}
        </p>
        {examinations && examinations[0]?.contactMessage && (
          <p className='customParagraph'>
            {t('CREATE_PREVENTIVE_LOG_CUSTOMER.CLIENT_CONTACT_MESSAGE')}:{' '}
            {examinations[0]?.contactMessage}
          </p>
        )}
      </div>
    </>
  )
}
