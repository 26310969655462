export const COMPANY_LOCATION_BY_RELATION_REQUEST = 'COMPANY_LOCATION_BY_RELATION_REQUEST'
export const COMPANY_LOCATION_BY_RELATION_SUCCESS = 'COMPANY_LOCATION_BY_RELATION_SUCCESS'
export const COMPANY_LOCATION_BY_RELATION_FAIL = 'COMPANY_LOCATION_BY_RELATION_FAIL'

export const COMPANY_LOCATION_BY_ID_REQUEST = 'COMPANY_LOCATION_BY_ID_REQUEST'
export const COMPANY_LOCATION_BY_ID_SUCCESS = 'COMPANY_LOCATION_BY_ID_SUCCESS'
export const COMPANY_LOCATION_BY_ID_FAIL = 'COMPANY_LOCATION_BY_ID_FAIL'

export const COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST =
  'COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST'
export const COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS =
  'COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS'
export const COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL =
  'COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL'
