/* eslint-disable */
export const de = {
  NAVBAR: {
    DASHBOARD: 'Dashboard',
    PRACTICES: 'Praxen',
    RISK_EXPOSURE: 'Risikoauslöser',
    COMPANIES: 'Unternehmen',
    EXAMINATION_TYPES: 'Vorsorgeuntersuchungen',
    USERS: 'Nutzer',
    OPEN_USER_MENU: 'Nutzermenü öffnen',
    CREATE_EMPLOYEE: 'Mitarbeiter anlegen',
    EDIT_EMPLOYEE: 'Mitarbeiter bearbeiten',
    CUSTOMERS: 'Neuen Kunden hinzufügen',
    CUSTOMER_OVERVIEW: 'Kundenübersicht',
    NEW_PREVENTIVE_LOG: 'Neuen Kunden für die Vorsorgekartei anlegen',
    PREVENTIVE_LOG: 'Vorsorgekartei',
    APPOINTMENT_REQUEST: 'Terminanfragen',
    CREATE_EXAMINATION_TYPES: 'Neue Vorsorgeuntersuchung anlegen',
    CREATE_RISK_EXPOSURE: 'Risikoauslöser anlegen',
    EXAMINATIONS_HISTORY: 'Vorsorgen-Historie',
    EDIT_EXAMINATION_TYPES: 'Details Vorsorgeuntersuchung anpassen',
    SCHEDULED_APPOINTMENTS_OVERVIEW: 'Übersicht geplanter Termine',
    EXAMINATIONS_SELECTION: 'Auswahl Vorsorgen',
    AGENDA: 'Agenda',
    EXAMINATIONS_RECORD: 'Historie Vorsorgeuntersuchungen',
    COMPANIES_LOCATIONS: 'Kunden',
    CUSTOMER_LOCATIONS: 'Kunden (Standorte)',
    EDIT_EXAMINATION_RECORD: 'Details Vorsorgeuntersuchung anpassen',
    FIRMEN: 'Firmen',
    CONTACT_PERSONS: 'Kontaktpersonen'
  },
  USER_ROLES: {
    ADMIN: 'Administrator',
    SUPERADMIN: 'Superadmin',
    MFA: 'MFA',
    DOCTOR: 'Arzt/Ärztin',
    EMPLOYEE: 'Mitarbeiter',
    EMPLOYEES: 'Mitarbeiter'
  },
  MODAL_ACTIONS_ROLES: {
    ADMIN: 'admin',
    SUPERADMIN: 'superadmin',
    MFA: 'mfa',
    DOCTOR: 'Arzt/Ärztin',
    EMPLOYEE: 'Mitarbeiter',
    EMPLOYEES: 'Mitarbeiter',
    COMPANY: 'Unternehmen'
  },
  GENERIC: {
    LOADING: 'Wird geladen',
    SELECTED: 'Ausgewählt',
    INSTRUCTIONS: 'Anweisungen',
    SELECTED_EXAMINATION_TYPES: 'Ausgewählte Vorsorgeuntersuchungen',
    OK: 'Ok',
    NEW: 'Neu',
    ERROR_MESSAGE: 'Fehlermeldung',
    UPDATE: 'aktualisieren',
    UNKNOWN: 'Unbekannt',
    CREATE: 'Anlegen',
    CONFIRM_CHOICE: 'Sind Sie sicher, dass Sie',
    TITLE: 'Titel',
    GROUP: 'Gruppe',
    DAYS: 'Tage',
    HOURS: 'Stunden',
    MINUTES: 'Minuten',
    STATUS: 'Status',
    SEARCH: 'Suche',
    DELETE: 'Löschen',
    EDIT: 'Bearbeiten',
    EDIT_PASSWORD: 'Passwort ändern',
    REQUIRED_FIELD: 'Pflichtfeld',
    YES: 'Ja',
    NO: 'Nein',
    SHOW_ALL: 'Alles anzeigen',
    FILTER: 'Filter',
    ROWS_PER_PAGE: 'Zeilen pro Seite'
  },
  USER_INFO: {
    FIRST_NAME: 'Vorname',
    FULL_NAME: 'Vollständiger Name',
    LAST_NAME: 'Nachname',
    EMAIL: 'E-Mail',
    PHONE_NUMBER: 'Telefonnummer',
    AGE: 'Alter',
    USERNAME: 'Nutzername',
    DATE_OF_BIRTH: 'Geburtsdatum',
    DEPARTMENT: 'Abteilung',
    POSITION: 'Position',
    CURRENT_DEPARTMENT: 'Aktuelle Abteilung',
    CURRENT_POSITION: 'Aktuelle Position',
    JOINED_COMPANY_DATE: 'Datum des Eintritts in das Unternehmen',
    LEFT_COMPANY_DATE: 'Datum des Austritts aus dem Unternehmen',
    LAST_EXAMINATION_DATE: 'Datum der letzten Untersuchungen',
    GENDER: 'Geschlecht',
    SUPERVISOR: 'Vorgesetzte/r'
  },
  BUTTONS: {
    SAVE: 'Sichern',
    CANCEL: 'Abbrechen',
    UPLOAD_FILE: 'Datei hochladen',
    PROCESSING: 'Wird verarbeitet…',
    SUCCESSFULLY: 'Erfolgreich',
    SEND: 'Senden'
  },
  AUTH: {
    SIGN_IN: 'Einloggen',
    SIGN: 'Registrieren',
    SIGN_OUT: 'Ausloggen',
    SIGN_IN_ACCOUNT: 'Melden Sie sich bei Ihrem Konto an',
    CREATE_ACCOUNT: 'Erstellen Sie Ihren Account',
    AGREE_TO: 'Ich stimme Folgendem zu:',
    BAS_TERMS: 'Betriebsarztservice Geschäftsbedingungen',
    ALREADY_REGISTERED: 'Bereits registriert',
    LOG_IN_HERE: 'Hier einloggen',
    TERMS_CONDITIONS: 'Geschäftsbedingungen',
    CHANGE_PASSWORD: 'Passwort ändern',
    CONFIRM_LOGOUT: 'Sind sie sicher, dass Sie sich ausloggen wollen?',
    PASSWORD: 'Passwort',
    EMAIL_OR_USERNAME: 'E-Mail oder Nutzername'
  },
  PRACTICES: {
    PRACTICE: 'Praxis',
    PRACTICE_NAME: 'Praxisname',
    EXTERNAL_PARTNER: 'Externer Partner',
    CREATE_PRACTICE: 'Neue Praxis anlegen',
    EDIT_PRACTICE: 'Details Praxis anpassen',
    BAS_PRACTICE: 'Interne Praxis',
    PRACTICE_PAGE_URL: 'URL der Praxisseite',
    PRACTICE_ADDRESS: 'Praxisadresse',
    PRACTICE_SWITCH_TEXT:
      'Ausschalten, falls es sich um eine externe Praxis im BAS-Netzwerk handelt',
    PAGE_URL: 'URL der Praxisseite',
    NO: 'Nein',
    YES: 'Ja',
    NUMBER_OF_ASSOCIATED_CUSTOMERS: 'Kunden'
  },
  COMPANIES_LOCATIONS: {
    CUSTOMER: 'Kunde',
    CONTACT_PERSONS: 'Kontaktperson',
    ASSOCIATED_PRACTICE: 'Zugehörige Praxis',
    NUMBER_OF_EMPLOYEES: 'Mitarbeiter'
  },
  COMPANY: {
    COMPANY: 'Unternehmen',
    COMPANY_NAME: 'Name Unternehmen',
    COMPANY_NUMBER: 'Unternehmens-ID',
    CONTRACT_IN_PLACE: 'Vertrag vorhanden',
    NUMBER_OF_LOCATIONS: 'Anzahl der Standorte',
    NUMBER_OF_PREVENTIVE_LOGS: 'Vorsorgekarteien',
    LOCATION: 'Standort',
    LOCATION_NAME: 'Name Standort',
    LOCATION_ADDRESS: 'Adresse Standort',
    ASSOCIATED_PRACTICE: 'Zugehörige Praxis',
    REMOVE_LOCATION: 'Diesen Standort entfernen',
    ADD_LOCATION: 'Neuen Standort hinzufügen',
    CREATE: 'Neue Firma anlegen',
    EDIT: 'Details Firma anpassen',
    PREVENTIVE_LOG_ERROR:
      'Dieser Standort kann nicht entfernt werden, da eine Vorsorgekartei mit diesem verbunden ist',
    CONTACT_EMAIL: 'Kontakt E-Mail',
    CUSTOMER: 'Kunde',
    CONTACT_PERSON: 'Kontaktperson',
    NO_OF_EMPLOYEES: 'Mitarbeiter',
    LAST_EXAMINATION_PERFORMED: 'Letzte Vorsorgeuntersuchung durchgeführt',
    NEXT_EXAMINATION_DUE: 'Nächste Vorsorgeuntersuchung fällig',
    YES: 'Ja',
    NO: 'Nein',
    STREET_ADDRESS: 'Adresse Standort',
    CONTACT_PERSON_GRID: 'Kontaktperson(en)'
  },
  COMPANY_LOCATION: {
    PREVENTIVE_LOG_ERROR:
      'Dieser Standort kann nicht entfernt werden, da eine Vorsorgekartei mit diesem verbunden ist'
  },
  CREATE_PREVENTIVE_LOG_CUSTOMER: {
    SELECT_LOCATION: 'Auswahl eines Kunden aus der Master-Datenbank',
    CONTACT_EMAIL: 'E-Mail Kontaktperson Kunde:',
    REMOVE_CONTACT: 'Zusätzlichen Kontakt entfernen',
    ADD_ADDITIONAL_CONTACT: 'Neuen Kontakt hinzufügen',
    NEW_CUSTOMER: 'Neuer Kunde',
    CLIENT: 'Kunde',
    CLIENT_CONTACT: 'Kontaktperson beim Kunden',
    NUMBER_OF_EMPLOYEES_TEXT: 'Zu untersuchende Mitarbeiter',
    CLIENT_CONTACT_MESSAGE: 'Nachricht an die Kontaktperson beim Kunden',
    SAVE_TIMEFRAME: 'Speichern',
    SUCCESS_CREATE:
      'Neuer Kunde wurde erfolgreich hinzugefügt und die Kontaktperson hat eine Einladung erhalten',
    ADDRESS: 'Address',
    INFO_MESSAGE:
      'Die Mitarbeiter werden links nach zugewiesenen Vorsorge-Kombos gruppiert. Wählen Sie eine Gruppe aus und markieren Sie im Kalender einen oder mehrere Zeitblöcke, die Sie dieser Gruppe zur Verfügung stellen möchten. Stellen Sie sicher, dass Sie genügend Zeit eingeplant haben, um die voraussichtliche Gesamtzeit für die Durchführung der Vorsorgeuntersuchzngen sicherzustellen.',
    INFO_MESSAGE_UNDER: 'Wiederholen Sie den Prozess für jede Gruppe, falls es mehrere gibt.',
    NEW_MANUAL_EXAMINATION: 'Vorsorgeuntersuchung durchführen',
    CHOOSE_LOCATION_DROPDOWN: 'Wählen Sie einen Standort oder fügen Sie einen neuen hinzu',
    CHOOSE_LOCATION_TEXT:
      'Bitte wählen Sie den Ort aus, an dem die Vorsorgeuntersuchungen durchgeführt werden.',
    CREATE_NEW: 'Neu "{{value}}"'
  },
  RISK_EXPOSURE: {
    RISK_EXPOSURE: 'Risikoauslöser',
    RISK_EXPOSURE_GROUPS: 'Gruppe',
    CREATE_RISK_EXPOSURE: 'Neuen Risikoauslöser anlegen',
    EDIT_RISK_EXPOSURE: 'Details Risikoauslöser anpassen',
    TITLE: 'Name Risikoauslöser',
    NAME: 'Name Risikoauslöser',
    RISK_EXPOSURES: 'Risikoauslöser'
  },
  EXAMINATIONS: {
    EXAMINATIONS: 'Vorsorgeuntersuchungen',
    NEXT_EXAMINATION: 'Nächste Vorsorgeuntersuchung',
    SCHEDULED: 'Geplant',
    DUE: 'Fällig',
    OVERDUE: 'Überfällig',
    RECOMMENDED_EXAMINATIONS: 'Angebotsvorsorge',
    RECOMMENDATIONS: 'Empfehlungen',
    EXAMINATION_TITLE: 'Vorsorge-Titel',
    EXAMINATION_INTRO: 'Einführungstext Vorsorgeuntersuchung für Mitarbeiter',
    EXAMINATION_DURATION: 'Dauer Vorsorgeuntersuchung',
    DATE_OF_REQUEST: 'Datum der Anfrage',
    BACK_TO_APPOINTMENT: 'Zurück zur Terminübersicht',
    BACK_TO_CUSTOMERS_OVERVIEW: 'Zurück zur Kundenübersicht',
    BACK_TO_EXAMINATION_HISTORY: 'Zurück zur Historie Vorsorgen',
    OPT_OUT: 'Ablehnen'
  },
  EXAMINATION_REQUEST: {
    WILL_TAKE: 'benötigen',
    ESTIMATED_TIME: 'Geschätzte Zeit',
    MIN_DOCTOR: 'Minuten Arzt / Ärztin',
    MIN_MFA: 'Minuten MFA',
    PER_PATIENT: 'pro Patient',
    SAVE_IDANA_LINK: 'Idana-Link speichern',
    PROVIDED: 'Sie haben bereitgestellt',
    OF: 'von',
    EMPLOYEES: 'Mitarbeiter',
    EMPLOYEE: 'Mitarbeiter'
  },
  EXAMINATION_TYPES: {
    MANDATORY: 'Pflichtvorsorge',
    RECOMMENDED: 'Angebotsvorsorge',
    OFF: 'Nicht zuordnen',
    EXAMINATION_TITLE: 'Vorsorge-Titel',
    ASSOCIATED_RISK_AREAS: 'Zugehörige Risikoauslöser',
    DOCTOR_DURATION: 'Dauer Arzt / Ärztin',
    MFA_DURATION: 'Dauer MFA',
    TIME_EXPRESSED: 'Zeitangabe in Minuten',
    TOTAL_EXAMINATION_TIME: 'Geschätze Gesamtzeit für alle Gruppen',
    EXAMINATION_TYPE: 'Vorsorgeuntersuchung',
    OCCUPIED: 'BELEGT'
  },
  USER: {
    USERS: 'Nutzer',
    ROLE: 'Rolle',
    CREATE_USER: 'Neuen Nutzer anlegen',
    EDIT_USER: 'Nutzerdetails anpassen',
    USER: 'Nutzer'
  },
  EXAMINATION_SELECTION: {
    CLIENT_CONTACT_INSTRUCTIONS: 'Anweisungen an die Kontaktperson beim Kunden',
    EXAMINATION_SELECTION: 'Auswahl Vorsorgeuntersuchung',
    REQUEST_FOR_EXAMINATION: 'Anfrage Vorsorgeuntersuchung',
    CREATE_TIME_SLOT: 'Zeitrahmen für Vorsorgeuntersuchungen bereitstellen für',
    SHARE_WITH_CLIENT: 'Mit dem Kunden teilen',
    ASSIGNED_PRACTICE: 'Zugeordnete Praxis',
    CLIENT_CONTACT_NOT_HAVE_CONTACT_PERSON:
      'Dem Kunden-Standort ist noch kein Ansprechpartner zugeordnet',
    UNSAVED_CHANGES: 'Es liegen bisher nicht gespeicherte Anpassungen vor',
    REMOVE_EXAMINATION_TYPE: 'Vorsorgeuntersuchung von der Liste entfernen',
    ADD_ALL_TO_THE_LIST: 'Alle der Liste hinzufügen',
    ADD_TO_THE_LIST: 'Der Liste hinzufügen',
    LAST_ASSIGNED_BY: 'Zuletzt zugeordnet von',
    ADD_ANOTHER_EXAMINATION: 'Vorsorgeuntersuchung hinzufügen',
    REMOVE_EXAMINATION: 'Vorsorgeuntersuchung entfernen',
    DELETE_EXAMINATION_PROMPT:
      'Sind Sie sicher, dass Sie diese Vorsorgeuntersuchung löschen möchten?',
    EMPTY_STATE_TITLE: 'Vorsorgeuntersuchungen',
    CANNOT_REMOVE_ASSIGNED_EXAMINATION:
      'Die Vorsorge ist Mitarbeitern zugeordnet und kann daher nicht entfernt werden'
  },
  EXAMINATION_TYPES_LIST: {
    SEARCH_BY_TEXT: 'Suche basierend auf Bereich / Risikoauslöser / Versorgeuntersuchung',
    SELECT_EXAMINATIONS: 'Vorsorgeuntersuchungen auswählen'
  },
  LOCATIONS: {
    LOCATION_REQUIREMENT: 'Sie müssen mindestens einen Standort hinzufügen',
    LOCATION: 'Standort',
    REMOVE_LOCATION: 'Standort entfernen',
    ADD_LOCATION: 'Neuen Standort hinzufügen'
  },
  ERROR_MESSAGES: {
    FIRST_NAME_UNDEFINED: 'Fehlender Vorname',
    LAST_NAME_UNDEFINED: 'Fehlender Nachname',
    EMAIL_FORMAT_IS_WRONG: 'Ungültige E-Mail-Adresse',
    INVALID_NUMBER: 'Ungültige Nummer',
    EMAIL_ALREADY_IN_USE: 'E-Mail-Adresse wird bereits verwendet',
    BIRTH_DATE_UNDEFINED: 'Fehlendes Geburtsdatum',
    NO_TIMEFRAMES: 'Sie müssen Zeitrahmen für alle Gruppen hinzufügen',
    AUTH_IS_REQUIRED: 'Dieses Feld ist ein Pflichtfeld',
    IS_REQUIRED: 'Das Feld enthält zu viele Zeichen',
    TOO_MANY_CHARACTERS: 'Dies ist keine gültige E-Mail-Adresse',
    INVALID_EMAIL: 'Dies ist kein gültiges E-Mail Format',
    INVALID_URL: 'Dies ist kein gültiges URL-Format',
    PASSWORD_VALIDATION:
      'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl und ein Symbol sowie einen Großbuchstaben enthalten',
    NO_GROUP: 'Sie müssen mindestens eine Gruppe auswählen',
    PRACTICE_REQUIRED: 'Mindestens eine Praxis ist erforderlich',
    DEFAULT: 'Etwas ist schief gelaufen. Bitte versuchen sie es erneut',
    COMPANY_NAME_LIMITED: 'Der Firmenname ist auf 100 Zeichen begrenzt',
    EXAMINATION_TITLE_LIMITED: 'Der Vorsorgetitel ist auf 100 Zeichen begrenzt',
    USER_NOT_FOUND: 'Der Benutzer / die Benutzerin wurde nicht gefunden',
    WRONG_PASSWORD:
      'Die eingegebene E-Mail-Adresse oder das eingegebene Passwort ist inkrorrekt. Bitte versuchen Sie es erneut',
    COMPANY_NUMBER_TAKEN: 'Diese Firmen-ID wird bereits verwendet',
    PRACTICE_DELETE:
      'Eine Praxis kann nicht gelöscht werden, solange ihr noch verbundene Unternehmen zugeordnet sind',
    USERNAME_TAKEN: 'Dieser Benutzername ist bereits vergeben',
    EMAIL_TAKEN: 'E-Mail-Adresse wird bereits verwendet',
    NO_ENOUGH_TIME: 'Bitte stellen sie ausreichend Zeit für alle Kombinationen zur Verfügung',
    IS_NOT_NUMBER: 'Dieser Wert muss eine Zahl sein',
    NO_CHOOSEN_LOCATION: 'Bitte geben Sie einen Standort an.',
    APPOINTMENT_NOT_FOUND: 'Termin nicht gefunden',
    APP_REQUEST_ETGROUP_NOT_FOUND: 'Terminanfragegruppe nicht gefunden',
    APP_REQUEST_NOT_FOUND: 'Terminanfrage nicht gefunden',
    APP_REQUEST_ET_TYPE_NOT_FOUND: 'Vorsorgeuntersuchung der Terminanfrage nicht gefunden',
    USER_ALREADY_EXISTS: 'Benutzer existiert bereits',
    INVALID_REFRESH_TOKEN: 'Ungültiges Aktualisierungstoken',
    PRACTICE_IN_USE: 'Die Praxis ist in Verwendung und kann nicht gelöscht werden',
    PRACTICE_NOT_FOUND: 'Praxis nicht gefunden',
    PRACTICE_ALREADY_EXISTS: 'Die Praxis existiert bereits',
    COMPANY_NUMBER_IN_USE: 'Die Unternehmens-ID ist bereits in Gebrauch',
    COMPANY_NOT_FOUND: 'Unternehmen nicht gefunden',
    COMPANY_HAS_CONTACTS: 'Das Unternehmen hat Kontaktpersonen und kann nicht gelöscht werden',
    COMPANY_LOCATION_NOT_FOUND: 'Standort nicht gefunden',
    CONTACT_CANNOT_BE_DELETED: 'Die Kontaktperson kann nicht gelöscht werden',
    EXAMINATION_TYPE_NOT_FOUND: 'Vorsorgeuntersuchung nicht gefunden',
    DOCTORAVAILABILITY_NOT_FOUND: 'Zeitrahmen für die Verfügbarkeit des Arztes nicht gefunden',
    EXAMINATION_NOT_FOUND: 'Vorsorge nicht gefunden',
    EXAMINATIONS_NOT_FOUND: 'Vorsorgen nicht gefunden',
    FILE_NOT_FOUND: 'Datei nicht gefunden',
    EXAMINATION_TYPE_IN_USE: 'Vorsorgeuntersuchung ist in Gebrauch und kann nicht gelöscht werden',
    MEDICAL_CHECK_NOT_FOUND: 'Medizinischer Check nicht gefunden',
    PREVENTIVE_LOG_NOT_FOUND: 'Vorsorgekartei nicht gefunden',
    PREVENTIVE_LOG_EXAMINATION_TYPE_NOT_FOUND:
      'Vorsorgeuntersuchung in der Vorsorgekartei nicht gefunden',
    RISK_AREA_NOT_FOUND: 'Risikoauslöser nicht gefunden',
    RISK_EXPOSURE_GROUP_NOT_FOUND: 'Gruppe nicht gefunden',
    ROLE_NOT_FOUND: 'Rolle nicht gefunden',
    ROLES_NOT_FOUND: 'Rollen nicht gefunden',
    EMPLOYEE_NOT_FOUND: 'Mitarbeiter nicht gefunden',
    ACCESS_DENIED: 'Zugriff verweigert',
    COMPANY_SLUG_NOT_SAFE: 'Die Verwendung dieser Kurzform des Unternehmensnamen ist nicht sicher',
    COMPANY_ALREADY_EXISTS: 'Das Unternehmen existiert bereits'
  },
  GENDER_OPTIONS: {
    UNDISCLOSED: 'Unbekannt',
    NON_BINARY: 'Nicht-binär'
  },
  SCHEDULING_SCREEN: {
    TILL: 'bis',
    NO_EMPLOYEES_ASSIGNED: 'Anzahl der zugeordneten Mitarbeiter',
    NO_EMPLOYEES_SCHEDULED: 'Anzahl der geplanten Mitarbeiter',
    NO_EMPLOYEES_EXAMINED: 'Anzahl der untersuchten Mitarbeiter',
    TOTAL_ESTIMATED_TIME: 'Gesamte geschätzte Untersuchungszeit',
    TOTAL_AVAILABILITY: 'Insgesamt zur Verfügung gestellte Zeit',
    TOTAL_BOOKED: 'Zeit insgesamt gebucht',
    MESSAGE: 'Nachricht',
    EXAMINATIONS_COMPLETE: 'Vorsorgeuntersuchungen vollständig',
    CERTIFICATES_UPLOADED: 'Bescheinigungen hochgeladen',
    CERTIFICATES_MISSING: 'Fehlende Bescheinigungen',
    OPTED_OUT_EMPLOYEES: 'Opted out',
    NEXT_DUE_DATES_PROVIDED: 'Nächste Fälligkeitstermine angegeben',
    MINUTES: 'Minuten'
  },
  PREVENTIVE_LOG: {
    SCHEDULED_AT: 'Geplant am',
    OVERDUE: 'Überfällig',
    DUE_SOON: 'Demnächst fällig',
    SCHEDULED: 'Geplant',
    REQUESTED: 'Angefragt',
    INVITED: 'Eingeladen',
    PENDING: 'Ausstehend',
    EXAMINATION_HISTORY: 'Vorsorge-Historie von {{firstName}} {{lastName}}'
  },
  ALERTS: {
    SUCCESS_CREATE: 'Erfolgreich angelegt',
    SUCCESS_EDIT: 'Erfolgreich aktualisiert',
    SUCCESS_DELETE: 'Erfolgreich gelöscht deleted',
    SUCCESS_TIMEFRAME:
      'Sie haben erfolgreich einen Zeitrahmen für die Vorsorgeuntersuchungen bereitgestellt',
    SUCCESS_SELECTION: 'Vorsorgeuntersuchungen wurden erfolgreich ausgewählt',
    SUCCESS_SHARE: 'Vorsorgeuntersuchungen wurden erfolgreich geteilt',
    SUCCESS_RECORD: 'Vorsorgeaufzeichnung erfolgreich gespeichert',
    SAME_USER_ERROR: 'Sie können nicht denselben Benutzer löschen, als den Sie angemeldet sind.'
  },
  MODAL: {
    DELETE: 'Sind Sie sicher, dass Sie das löschen',
    CANCEL: 'Sind Sie sicher, dass Sie abbrechen wollen?',
    UPDATE: 'Sind Sie sicher, dass Sie das',
    FINISH_SELECTION: 'Möchten Sie die Vorsorgeauswahl wirklich abschließen?',
    SHARE_SELECTION:
      'Sind Sie sicher, dass Sie die ausgewählten Vorsorgeuntersuchungen teilen möchten?',
    SAVE_RECORD: 'Möchten Sie die Vorsorgeaufzeichnung wirklich speichern?',
    PASSWORD_UPDATE: 'Sind Sie sicher, dass Sie das Passwort aktualisieren möchten?'
  },
  DASHBOARD: {
    CUSTOMERS: 'Kunden',
    EMPLOYEES: 'Mitarbeiter',
    EXAMINATIONS: 'Mitarbeiter',
    SCHEDULED: 'Geplant',
    OVERDUE: 'Überfällig',
    REQUESTS: 'Anfragen',
    DETAILS: 'Details',
    CONFIRMED_APPOINTMENTS: 'Bestätigte Termine',
    EXAMINATION_TYPES_TO_BE_PERFORMED: 'Durchzuführende Vosorgeuntersuchungen',
    EMPLOYEE: 'Mitarbeiter',
    NO_EMPLOYEES: 'Keine Mitarbeiter vorhanden',
    EMPLOYEES_OVERDUE: 'Überfällige Mitarbeiter',
    NO_EXAMINATIONS_OVERDUE: 'Es sind keine Vorsorgeuntersuchungen überfällig',
    SCHEDULED_APPOINTMENTS: 'Es sind keine Vorsorgeuntersuchungen überfällig',
    NO_SCHEDULED_APPOINTMENTS: 'Es sind keine Termine geplant',
    NEW_EXAMINATIONS_REQUEST: 'Neue Vorsorgeuntersuchungsanfrage',
    NO_NEW_EXAMINATIONS_REQUEST: 'Es gibt keine neue Vorsorgeuntersuchungsanfrage',
    EXAMINATION_TYPES: 'Vorsorgeuntersuchungen:',
    SEE_ALL: 'Alle anzeigen',
    COMPANY_LOCATIONS: 'Anzahl Firmenstandorte: ',
    LOADING: 'Wird verarbeitet…',
    CLIENT_NAME: 'Kunde:',
    CLIENT_LOCATION: 'Standort:',
    CLIENT_DATE_TIME: 'Datum und Uhrzeit:',
    CLIENT_DURATION: 'Dauer:',
    NO_SCHEDULED_APPOINTMENTS_HEADER: 'Geplante Termine werden hier erscheinen',
    APPOINTMENT_NUMBER: 'Termin #',
    UPCOMING_APPOINTMENTS: 'Anstehende Termine'
  },
  EXAMINATION_HISTORY: {
    MANDATORY: 'Verpflichtend:',
    YES: 'Ja',
    NO: 'Nein',
    BY: 'von',
    DR: 'Dr.',
    NOT_PROVIDED: 'Nicht angegeben',
    NOT_PERFORMED: 'Nicht durchgeführt',
    EMPLOYEER_CERTIFICATE: 'Arbeitgeberbescheinigungen herunterladen',
    DOCTOR: 'Arzt / Ärztin:',
    PRACTICE: 'Praxis:',
    DEPARTMENT: 'Abteilung:',
    POSITION: 'Position:',
    PERFORMED: 'Durchgeführt:',
    NEXT_DUE: 'Nächstes Mal fällig:',
    NOT_HAVE_SCHEDULED_EXAMINATIONS:
      'hat bisher keine Aufzeichungen über Vorsorgeuntersuchungen eingetragen',
    HISTORY: 'Historie',
    MANDATORY_EXAMINATIONS: 'Pflichtvorsorgen',
    RECOMMENDED_EXAMINATIONS: 'Angebotsvorsorgen',
    PRESCRIBED: 'zugeordnet',
    FIRST_EXAMINATION: 'Erstuntersuchung',
    SECOND_EXAMINATION: 'Zweite Untersuchung',
    FOLLOW_UP: 'Folgeuntersuchung',
    DOWNLOAD_CERTIFICATE: 'Arbeitgeberbescheinigung herunterladen'
  },
  APPOINTMENTS_REQUEST_LIST: {
    SCHEDULED_APPOINTMENTS_OVERVIEW: 'Geplante Termine',
    SCHEDULE_NEW_APPOINTMENT: 'In Planung',
    COMPLETED: 'Abgeschlossen',
    REQUESTED: 'Angefordert',
    SCHEDULED: 'Geplant'
  },
  EXAMINATIONS_RECORD: {
    UPLOAD_CERTIFICATE: 'Arbeitgeberbescheinigung hochladen',
    REMOVE_CERTIFICATE: 'Bescheinigung entfernen',
    PERFORMED_ON: 'Vorsorgeuntersuchung angeboten am:',
    PERFORMED_EXAMINATION: 'Untersuchung durchgeführt:',
    NEXT_EXAMINATION_DUE: 'Nächste Vorsorgeuntersuchung fällig',
    TYPE: 'Typ:',
    NOTE: 'Notiz:',
    CERTIFICATE: 'Arbeitgeberbescheinigung',
    MANDATORY_EXAMINATIONS: 'Pflichtvorsorgen:',
    RECOMMENDED_EXAMINATIONS: 'Angebotsvorsorgen:',
    OPTED_OUT: 'Declined examinations:'
  },
  NOT_FOUND: {
    TEXT_1: 'HOPPLA! SEITE NICHT GEFUNDEN!'
  },
  EMPTY_STATE: {
    EMPTY_STATE_HEADER: 'Noch keine {{type}}',
    TEXT: 'Beginnen Sie mit der Erstellung eines / einer neuen',
    COMPANIES: 'Firmen',
    APPOINTMENT_REQUESTS: 'Terminanfragen',
    CUSTOMER_OVERVIEW: 'Kunden',
    EXAMINATION_TYPES: 'Vorsorgeuntersuchungen',
    RISK_EXPOSURE: 'Risikoauslöser',
    PRACTICES: 'Praxen',
    USERS: 'Nutzer/innen',
    CUSTOMERS_LOCATIONS: 'Kunden (Standorte)',
    PREVENTIVE_LOG_EMPLOYEES: 'Mitarbeiter',
    EMPTY_STATE_ADDITIONAL: 'angelegt.',
    CONTACT_PERSONS: 'kontaktpersonen'
  },
  CUSTOMER_OVERVIEW: {
    MODAL_MESSAGE: 'Sind Sie sicher, dass Sie einen Kundenkontakt erstellen möchten?',
    NEW_CUSTOMER: 'Kunden hinzufügen',
    EMPTY_STATE_TEXT: 'Beginnen Sie mit der Erstellung eines neuen'
  },
  FOOTER: {
    IMPRESSUM: 'Impressum',
    AGB: 'AGB',
    DATASCHUTZ: 'Datenschutzerklärung'
  },
  WARNING_MODAL: {
    WARNING_EXAMINATIONS: 'Bitte füllen Sie alle Pflichtfelder aus.',
    WARNING_REMOVE_EXAMINATION: 'Es muss mindestens eine Prüfung durchgeführt werden'
  },
  COOKIES_MODAL: {
    HEADER: 'Diese Website verwendet essentielle Cookies.',
    MAIN_TEXT:
      'Notwendige Cookies tragen dazu bei, eine Website nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Website ermöglichen. Ohne diese Cookies kann die Website nicht ordnungsgemäß funktionieren und wird geschlossen, wenn diese abgelehnt werden.',
    ACCEPT: 'Akzeptieren',
    DECLINE: 'Ablehnen'
  },
  FEEDBACK_MODAL: {
    TITLE: 'Feedback',
    FROM: 'Von',
    SUBJECT: 'Betreff',
    DESCRIPTION: 'Beschreibung',
    FILES: 'Dateien',
    MAX_FILE_SIZE: 'Maximale Dateigröße 20 MB',
    IMAGE_ATTACHMENTS: 'Bildanhänge',
    SUCCESS_MESSAGE: 'Feedback erfolgreich versendet.',
    UPLOAD_BUTTON: 'Wähle Dateien'
  },
  CONTACT_PERSONS: {
    FIRST_NAME: 'Vorname',
    LAST_NAME: 'Nachname',
    EMAIL: 'E-Mail',
    CREATE_CONTACT_PERSON: 'Neue Kontaktperson',
    HEADER_NAME: 'Neue Kontaktperson'
  }
}
