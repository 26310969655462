import { AnyAction } from 'redux'
import {
  ENABLE_PREVENTIVE_LOG_FAIL,
  ENABLE_PREVENTIVE_LOG_REQUEST,
  ENABLE_PREVENTIVE_LOG_RESET,
  ENABLE_PREVENTIVE_LOG_SUCCESS
} from 'src/redux/constants/preventiveLogConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const enablePreventiveLogReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case ENABLE_PREVENTIVE_LOG_REQUEST:
      return { loading: true, success: false }
    case ENABLE_PREVENTIVE_LOG_SUCCESS:
      return { loading: false, success: true }
    case ENABLE_PREVENTIVE_LOG_FAIL:
      switch (action.payload.message) {
        case 'PRACTICE_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error, success: false }
    case ENABLE_PREVENTIVE_LOG_RESET:
      return {}
    default:
      return state
  }
}

export const preventiveLogReducers = {
  enablePreventiveLog: enablePreventiveLogReducer
}
