import { AnyAction } from 'redux'
import {
  COMPANY_CREATE_FAIL,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_RESET,
  COMPANY_CREATE_SUCCESS,
  COMPANY_DELETE_FAIL,
  COMPANY_DELETE_REQUEST,
  COMPANY_DELETE_RESET,
  COMPANY_DELETE_SUCCESS,
  COMPANY_EDIT_FAIL,
  COMPANY_EDIT_REQUEST,
  COMPANY_EDIT_RESET,
  COMPANY_EDIT_SUCCESS,
  COMPANY_GET_ALL_FAIL,
  COMPANY_GET_ALL_REQUEST,
  COMPANY_GET_ALL_SUCCESS,
  COMPANY_GET_ONE_FAIL,
  COMPANY_GET_ONE_REQUEST,
  COMPANY_GET_ONE_RESET,
  COMPANY_GET_ONE_SUCCESS,
  GET_COMPANIES_LOCATIONS_FAILURE,
  GET_COMPANIES_LOCATIONS_REQUEST,
  GET_COMPANIES_LOCATIONS_SUCCESS
} from 'src/redux/constants/companyConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const companyCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_CREATE_REQUEST:
      return { loading: true }
    case COMPANY_CREATE_SUCCESS:
      return { loading: false, success: true }
    case COMPANY_CREATE_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_NUMBER_IN_USE':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
      }
      return { loading: false, error }
    case COMPANY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const companyEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_EDIT_REQUEST:
      return { loading: true }
    case COMPANY_EDIT_SUCCESS:
      return { loading: false, success: true }
    case COMPANY_EDIT_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_NOT_FOUND':
        case 'COMPANY_NUMBER_IN_USE':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
      }
      return { loading: false, error }
    case COMPANY_EDIT_RESET:
      return {}
    default:
      return state
  }
}

const companyDeleteReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_DELETE_REQUEST:
      return { loading: true }
    case COMPANY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case COMPANY_DELETE_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_NOT_FOUND':
        case 'COMPANY_HAS_CONTACTS':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case COMPANY_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const companyGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_GET_ONE_REQUEST:
      return { loading: true }
    case COMPANY_GET_ONE_SUCCESS:
      return { loading: false, company: action.payload }
    case COMPANY_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
      }
      return { loading: false, error }
    case COMPANY_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const companyGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_GET_ALL_REQUEST:
      return { loading: true }
    case COMPANY_GET_ALL_SUCCESS:
      return { loading: false, companies: action.payload.companies, count: action.payload.count }
    case COMPANY_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const companyGetAllWithLocationsReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case GET_COMPANIES_LOCATIONS_REQUEST:
      return { loading: true }
    case GET_COMPANIES_LOCATIONS_SUCCESS:
      return {
        loading: false,
        companyLocations: action.payload.companyLocations,
        count: action.payload.count
      }
    case GET_COMPANIES_LOCATIONS_FAILURE:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

export const companyReducers = {
  companyCreate: companyCreateReducer,
  companyGetOne: companyGetOneReducer,
  companyEdit: companyEditReducer,
  companyGetAll: companyGetAllReducer,
  companyDelete: companyDeleteReducer,
  companyGetAllWithLocations: companyGetAllWithLocationsReducer
}
