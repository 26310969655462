import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BasLogo from 'src/assets/bas-login-signup.png'
import { ErrorAlert } from 'src/components/Alerts'
import { CookieModal } from 'src/components/CookieModal'
import { Input } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { SignInErrors, SignInInputs } from 'src/interfaces/auth'
import { signin } from 'src/redux/actions/authActions'
import { checkUserCookies } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { checkRequiredFields, validation } from 'src/services/validation/signInForm'

export const SignIn = () => {
  const { t } = useTranslation()
  let errors: SignInErrors = {
    userName: '',
    password: ''
  }
  const [cookiesPresent, setCookiesPresent] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [inputs, setInputs] = useState<SignInInputs>({
    password: '',
    userName: ''
  })

  const userCheckCookies = useAppSelector((state) => state.userCheckCookies)
  const userSignin = useAppSelector((state) => state.userSignin)
  const { loading, error } = userSignin
  const { cookies } = userCheckCookies

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(checkUserCookies())
  }, [])

  useEffect(() => {
    if (cookies && cookies['cookie_acceptance'] !== 'true') {
      setCookiesPresent(false)
    }
  }, [cookies])

  const setVal = (value: any, name: string) => {
    setInputs({ ...inputs, [name]: value })
  }

  useEffect(() => {
    const isDisabled = checkRequiredFields(inputs, ['password', 'userName'])
    if (isDisabled > 0) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs])

  const onSubmit = (e: any) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else dispatch(signin(inputs))
  }

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <>
      {!cookiesPresent ? (
        <CookieModal setCookiesPresent={setCookiesPresent} />
      ) : (
        <>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoadingOverlay />
            </div>
          ) : (
            <>
              {' '}
              <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <div className='flex justify-center items-center mb-10'>
                  <img src={BasLogo} className='max-w-[250px]' alt='BAS Logo' />
                </div>
              </div>
              <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
                <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative'>
                  <div className='mb-4'>{error && <ErrorAlert message={error} hide />}</div>
                  <form className='space-y-6' onSubmit={onSubmit}>
                    <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>{`[internal] ${t(
                      'AUTH.SIGN_IN_ACCOUNT'
                    )}`}</h2>
                    <Input
                      value={inputs['userName']}
                      type='text'
                      required
                      onChange={setVal}
                      error={errors['userName']}
                      name='userName'
                      label={t('AUTH.EMAIL_OR_USERNAME')}
                    />
                    <Input
                      value={inputs['password']}
                      type='password'
                      required
                      onChange={setVal}
                      error={errors['password']}
                      name='password'
                      label={t('AUTH.PASSWORD')}
                    />
                    <div>
                      <p className='customParagraph' style={{ marginTop: '-15px' }}>
                        <span className='text-red-700'>* </span>
                        {t('GENERIC.REQUIRED_FIELD')}
                      </p>
                    </div>
                    <div>
                      <button
                        type='submit'
                        disabled={isSaveDisabled}
                        onClick={onSubmit}
                        className='flex w-full justify-center rounded-md border disabled:bg-gray-300 border-transparent bg-brand-blue-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-blue-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2'
                      >
                        {t('AUTH.SIGN_IN')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SignIn
