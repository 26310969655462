import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import {
  InputLabelLeft,
  MultiSelectLabelLeft,
  TextAreaLabelLeft
} from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { ExaminationTypeErrors, ExaminationTypeInputs } from 'src/interfaces/examinationTypes'
import { RiskAreaOption } from 'src/interfaces/riskAreas'
import { create, edit, getOne } from 'src/redux/actions/examinationTypeActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { getAll } from 'src/redux/actions/riskExposureAction'
import {
  EXAMINATION_TYPE_CREATE_RESET,
  EXAMINATION_TYPE_GET_ONE_RESET
} from 'src/redux/constants/examinationTypeConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { formsChangesListener } from 'src/services/formsChangesListener.service'
import { checkRequiredFields, validation } from 'src/services/validation/examationsTypesForm'

export const ExaminationForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: examinationTypeId } = useParams()

  const [submitted, setSubmitted] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [riskAreaQuery, setRiskAreaQuery] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isCancelModalDisabled, setIsCancelModalDisabled] = useState(true)
  const [riskAreasOptions, setRiskAreasOptions] = useState<RiskAreaOption[]>([])
  const [inputs, setInputs] = useState<ExaminationTypeInputs>({
    name: '',
    description: '',
    doctorDuration: '',
    mfaDuration: '',
    riskArea: []
  })
  const [oldInputs, setOldInputs] = useState<ExaminationTypeInputs>({
    name: '',
    description: '',
    doctorDuration: '',
    mfaDuration: '',
    riskArea: []
  })
  let errors: ExaminationTypeErrors = {
    description: '',
    doctorDuration: '',
    mfaDuration: ''
  }
  const { examinationTypeCreate, riskExposureGetAll, examinationTypeGetOne, examinationTypeEdit } =
    useAppSelector((state) => state)
  const {
    loading: editExaminationTypeLoading,
    success: editExaminationTypeSuccess,
    error: editExaminationTypeError
  } = examinationTypeEdit
  const {
    loading: createExaminationTypeLoading,
    success: createExaminationTypeSuccess,
    error: createExaminationTypeError
  } = examinationTypeCreate
  const {
    loading: getExaminationTypeLoading,
    examinationType,
    error: getExaminationTypeError
  } = examinationTypeGetOne
  const { riskArea } = riskExposureGetAll

  useEffect(() => {
    dispatch({ type: EXAMINATION_TYPE_CREATE_RESET })
    setIsFirstLoad(false)
    if (location.pathname.includes('edit')) {
      dispatch(getOne(examinationTypeId))
    }
  }, [])

  useEffect(() => {
    dispatch(getAll({ page: 0, limit: 20, query: riskAreaQuery }))
  }, [riskAreaQuery])

  useEffect(() => {
    const options = []
    if (riskArea) {
      for (let i = 0; i < riskArea.length; i++) {
        const option = {
          value: riskArea[i].id,
          label: riskArea[i].name
        }
        options.push(option)
      }
    }
    setRiskAreasOptions(options)
  }, [riskArea])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (createExaminationTypeSuccess && !isFirstLoad) {
      setDisplaySuccessButton(true)
      navigate('/examinations-types')
    } else if (createExaminationTypeError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [createExaminationTypeSuccess, createExaminationTypeError])

  useEffect(() => {
    if (editExaminationTypeSuccess && !isFirstLoad) {
      setDisplaySuccessButton(true)
      navigate('/examinations-types')
    } else if (editExaminationTypeError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [editExaminationTypeSuccess, editExaminationTypeError])

  useEffect(() => {
    if (examinationType) {
      const riskAreaHelper = []
      for (let i = 0; i < examinationType.riskArea.length; i++) {
        const riskArea: RiskAreaOption = {
          value: examinationType.riskArea[i].id,
          label: examinationType.riskArea[i].name
        }
        riskAreaHelper.push(riskArea)
      }
      setInputs({
        name: examinationType.name,
        description: examinationType.description,
        doctorDuration: examinationType.doctorDuration,
        mfaDuration: examinationType.mfaDuration,
        riskArea: riskAreaHelper
      })
      setOldInputs({
        name: examinationType.name,
        description: examinationType.description,
        doctorDuration: examinationType.doctorDuration,
        mfaDuration: examinationType.mfaDuration,
        riskArea: riskAreaHelper
      })
    }
    if (getExaminationTypeError && !isFirstLoad) {
      dispatch({ type: EXAMINATION_TYPE_GET_ONE_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [examinationType, getExaminationTypeError])

  useEffect(() => {
    const isDisabledRequired = checkRequiredFields(inputs, [
      'name',
      'doctorDuration',
      'mfaDuration'
    ])
    let isDisabledChanges = false
    if (examinationType) {
      isDisabledChanges = formsChangesListener({
        inputs,
        oldInputs,
        multiSelectInputs: ['riskArea']
      })
    }
    if (!isDisabledChanges && examinationType) {
      setIsCancelModalDisabled(true)
    } else {
      setIsCancelModalDisabled(false)
    }
    if (isDisabledRequired > 0 || (!isDisabledChanges && examinationType)) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs, examinationType])

  const setVal = (value: any, name: string) => {
    if ((name === 'doctorDuration' || name === 'mfaDuration') && value !== '') {
      if (!/^\d+$/.test(value)) return
    }
    setInputs({ ...inputs, [name]: value })
  }

  const onSubmit = (action: string) => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      if (action === 'edit') {
        dispatch(edit(inputs, examinationTypeId))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else {
        dispatch(create(inputs))
      }
    }
  }

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <>
      {createExaminationTypeLoading || getExaminationTypeLoading || editExaminationTypeLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(createExaminationTypeError || editExaminationTypeError) && (
            <ErrorAlert message={createExaminationTypeError || editExaminationTypeError} hide />
          )}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/examinations-types')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                const action = examinationType ? 'edit' : 'create'
                onSubmit(action)
                setIsSaveModal(false)
              }}
              message={`${t('GENERIC.CONFIRM_CHOICE')} ${
                examinationType
                  ? t('GENERIC.UPDATE').toLowerCase()
                  : t('GENERIC.CREATE').toLowerCase()
              } ${t('EXAMINATION_TYPES.EXAMINATION_TYPE').toLowerCase()}?`}
            />
          )}
          <div className='space-y-6'>
            <InputLabelLeft
              value={inputs['name']}
              type='text'
              name='name'
              onChange={setVal}
              label={t('EXAMINATIONS.EXAMINATION_TITLE')}
              error={errors['name']}
              required
            />
            <TextAreaLabelLeft
              value={inputs['description']}
              type='text'
              name='description'
              onChange={setVal}
              label={t('EXAMINATIONS.EXAMINATION_INTRO')}
              error={errors['description']}
            />
            <InputLabelLeft
              value={inputs['doctorDuration']}
              type='text'
              name='doctorDuration'
              onChange={setVal}
              label={t('EXAMINATION_TYPES.DOCTOR_DURATION')}
              error={errors['doctorDuration']}
              placeholder={t('EXAMINATION_TYPES.TIME_EXPRESSED')}
              required
            />
            <InputLabelLeft
              value={inputs['mfaDuration']}
              type='text'
              name='mfaDuration'
              onChange={setVal}
              label={t('EXAMINATION_TYPES.MFA_DURATION')}
              error={errors['mfaDuration']}
              placeholder={t('EXAMINATION_TYPES.TIME_EXPRESSED')}
              required
            />
            <MultiSelectLabelLeft
              label={t('EXAMINATION_TYPES.ASSOCIATED_RISK_AREAS')}
              name='riskArea'
              options={riskAreasOptions}
              value={inputs['riskArea']}
              onChange={setVal}
              setQuery={setRiskAreaQuery}
            />
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (createExaminationTypeLoading ||
                  editExaminationTypeLoading ||
                  displaySuccessButton) &&
                'processing'
              }`}
              disabled={
                isSaveDisabled ||
                createExaminationTypeLoading ||
                editExaminationTypeLoading ||
                displaySuccessButton
              }
              variant='contained'
            >
              {createExaminationTypeLoading ||
              editExaminationTypeLoading ||
              displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              className='secondary-btn'
              variant='contained'
              onClick={() => {
                if (isCancelModalDisabled) {
                  navigate('/examinations-types')
                } else {
                  setIsCancelModal(true)
                }
              }}
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
