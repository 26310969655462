import {
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value)
)

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('PRACTICES.PRACTICE_NAME'),
    editable: false,
    sortable: false,
    filterable: true,
    filterOperators,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <Link to={`/practices/edit/${params.id}`}>{params.formattedValue}</Link>
    }
  },
  {
    field: 'basPractice',
    headerName: i18n.t('PRACTICES.EXTERNAL_PARTNER'),
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: GridCellParams) => {
      return <p>{params.formattedValue ? i18n.t('PRACTICES.NO') : i18n.t('PRACTICES.YES')}</p>
    }
  },
  {
    field: 'numberOfAssociatedCustomers',
    headerName: i18n.t('PRACTICES.NUMBER_OF_ASSOCIATED_CUSTOMERS'),
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: GridRenderCellParams) => {
      return <p>{params.formattedValue}</p>
    }
  },
  {
    field: 'actions',
    filterable: false,
    headerName: '',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    flex: 0.5,
    getApplyQuickFilterFn: undefined
  }
]
