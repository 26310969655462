import { GridRowId } from '@mui/x-data-grid'
import {
  CompanyLocationEntity,
  CreateCompanyAdminDto,
  CreateCompanyLocationAdminDto,
  UpdateCompanyAdminDto
} from 'src/entities/interfaces'
import {
  CompaniesLocationsSearch,
  CompanyInputs,
  CompanySearch,
  LocationElement
} from 'src/interfaces/companies'
import {
  COMPANY_CREATE_FAIL,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_DELETE_FAIL,
  COMPANY_DELETE_REQUEST,
  COMPANY_DELETE_SUCCESS,
  COMPANY_EDIT_FAIL,
  COMPANY_EDIT_REQUEST,
  COMPANY_EDIT_SUCCESS,
  COMPANY_GET_ALL_FAIL,
  COMPANY_GET_ALL_REQUEST,
  COMPANY_GET_ALL_SUCCESS,
  COMPANY_GET_ONE_FAIL,
  COMPANY_GET_ONE_REQUEST,
  COMPANY_GET_ONE_SUCCESS,
  GET_COMPANIES_LOCATIONS_FAILURE,
  GET_COMPANIES_LOCATIONS_REQUEST,
  GET_COMPANIES_LOCATIONS_SUCCESS
} from 'src/redux/constants/companyConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const create = (inputs: CompanyInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_CREATE_REQUEST, payload: {} })
  const { name, externalId, contractInPlace } = inputs
  api
    .post('/api/admin/company', { name, externalId, contractInPlace } as CreateCompanyAdminDto)
    .then((res) => {
      dispatch({ type: COMPANY_CREATE_SUCCESS, payload: res.data })

      inputs.locations.forEach((location: LocationElement) => {
        api.post('/api/admin/company-location', {
          name: location.name,
          address: location.address,
          basPracticeId: location.associatedPractice.value,
          companyId: res.data.id
        } as CreateCompanyLocationAdminDto)
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: COMPANY_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const edit = (inputs: CompanyInputs, id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_EDIT_REQUEST, payload: {} })
  const { name, externalId, contractInPlace } = inputs
  api
    .patch(`/api/admin/company/${id}`, {
      name,
      externalId,
      contractInPlace
    } as UpdateCompanyAdminDto)
    .then((res) => {
      dispatch({ type: COMPANY_EDIT_SUCCESS, payload: res.data })
      api
        .get(`/api/admin/company-location?page=0&limit=100&companyId=${id}`)
        .then((res) => {
          const CompanyLocation = res.data
          inputs.locations.forEach((location: LocationElement) => {
            if (typeof location.id === 'number') {
              api.post('/api/admin/company-location', {
                name: location.name,
                address: location.address,
                basPracticeId: location.associatedPractice.value,
                companyId: id
              } as CreateCompanyLocationAdminDto)
            }
          })
          CompanyLocation.forEach((location: CompanyLocationEntity) => {
            const isDeleted = inputs.locations.find(
              (item: LocationElement) => item.id === location.id
            )
              ? false
              : true
            if (isDeleted) {
              api.delete(`/api/admin/company-location/${location.id}`)
            } else {
              const updatedLocation = inputs.locations.find(
                (item: LocationElement) => item.id === location.id
              )
              api.patch(`/api/admin/company-location/${updatedLocation?.id}`, {
                name: updatedLocation?.name,
                address: updatedLocation?.address,
                basPracticeId: updatedLocation?.associatedPractice.value
              })
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: COMPANY_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const remove = (id?: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_DELETE_REQUEST, payload: {} })
  api
    .delete(`/api/admin/company/${id}`)
    .then((res) => {
      dispatch({ type: COMPANY_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: COMPANY_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getOne = (id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/company/${id}`)
    .then((res) => {
      dispatch({ type: COMPANY_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: COMPANY_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAll =
  ({
    page = 0,
    limit = 10,
    query = '',
    companyNameFilter = '',
    companyNameFilterType = '',
    companyNumberFilter = '',
    companyNumberFilterType = '',
    quickFilter = ''
  }: CompanySearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: COMPANY_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/company?page=${page}&limit=${limit}&query=${query}&companyNameFilter=${companyNameFilter}&companyNameFilterType=${companyNameFilterType}&companyNumberFilter=${companyNumberFilter}&companyNumberFilterType=${companyNumberFilterType}&quickFilter=${quickFilter}`
      )
      .then((res) => {
        dispatch({
          type: COMPANY_GET_ALL_SUCCESS,
          payload: { companies: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: COMPANY_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllWithLocations =
  ({
    page = 0,
    limit = 10,
    query = '',
    companyNameFilter = '',
    companyNameFilterType = '',
    contactPersonsFilter = '',
    contactPersonsFilterType = '',
    quickFilter = ''
  }: CompaniesLocationsSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: GET_COMPANIES_LOCATIONS_REQUEST, payload: {} })
    api
      .get('/api/admin/company/locations', {
        params: {
          page,
          limit,
          query,
          companyNameFilter,
          companyNameFilterType,
          contactPersonsFilter,
          contactPersonsFilterType,
          quickFilter
        }
      })
      .then((res) => {
        dispatch({
          type: GET_COMPANIES_LOCATIONS_SUCCESS,
          payload: { companyLocations: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: GET_COMPANIES_LOCATIONS_FAILURE,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
