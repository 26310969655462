import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getCompanyAndCompanyLocationByUserID } from 'src/pages/Admins/Companies/helper/externalFunctions'
import { getOneUser } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export function InfoBoxManual({
  setCompanyLocationId,
  setCompanyId
}: {
  setCompanyLocationId: React.Dispatch<React.SetStateAction<string>>
  setCompanyId: React.Dispatch<React.SetStateAction<string>>
}) {
  const [info, setInfo] = useState({
    employee: {
      name: '',
      gender: '',
      age: '',
      position: '',
      department: ''
    },
    company: {
      name: '',
      locationName: '',
      address: ''
    }
  })
  const { t } = useTranslation()
  const { userId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { userGetOne } = useAppSelector((state) => state)
  const { user } = userGetOne

  useEffect(() => {
    if (userId) {
      dispatch(getOneUser(userId))
    }
  }, [dispatch, userId])

  useEffect(() => {
    ;(async () => {
      if (user) {
        const companyData = await getCompanyAndCompanyLocationByUserID({ userId: user.id })
        if (companyData) {
          setCompanyLocationId(companyData.companyLocation[0].id)
          setCompanyId(companyData.companyLocation[0].company.id)
          setInfo({
            employee: {
              name: `${user.firstName} ${user.lastName}`,
              gender: user.gender,
              age:
                user.dateOfBirth &&
                Math.abs(moment().diff(moment(user.dateOfBirth, 'YYYYMMDD'), 'years')),
              position: user.currentPosition || '',
              department: user.currentDepartment || ''
            },
            company: {
              name: companyData.companyLocation[0].company.name,
              locationName: companyData.companyLocation[0].name,
              address: companyData.companyLocation[0].address
            }
          })
        }
      }
    })()
  }, [user])

  return (
    <div className='flex items-start justify-between'>
      <div className='flex flex-col'>
        <h2 className='customHead2'>
          {info.employee.name}, {info.employee.age}, {info.employee.gender}
        </h2>
        <h4 className='customHead4 my-1'>
          {info.company.name} {info.company.locationName}, {info.company.address}
        </h4>
        <h4 className='customHead4'>
          {info.employee.department && info.employee.department + ', '}
          {info.employee.position && info.employee.position}
        </h4>
      </div>
      <IconButton onClick={() => navigate(`/examinations-history/${userId}`)}>
        <ChevronLeftIcon className='w-6' />
        <label className='text-[13px]'>{t('EXAMINATIONS.BACK_TO_EXAMINATION_HISTORY')}</label>
      </IconButton>
    </div>
  )
}
