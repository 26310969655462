export const APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST = 'APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST'
export const APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS = 'APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS'
export const APPOINTMENT_REQUEST_GROUP_CREATE_FAIL = 'APPOINTMENT_REQUEST_GROUP_CREATE_FAIL'
export const APPOINTMENT_REQUEST_GROUP_CREATE_RESET = 'APPOINTMENT_REQUEST_GROUP_CREATE_RESET'

export const APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST = 'APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST'
export const APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS = 'APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS'
export const APPOINTMENT_REQUEST_GROUP_EDIT_FAIL = 'APPOINTMENT_REQUEST_GROUP_EDIT_FAIL'
export const APPOINTMENT_REQUEST_GROUP_EDIT_RESET = 'APPOINTMENT_REQUEST_GROUP_EDIT_RESET'

export const APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST = 'APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST'
export const APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS = 'APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS'
export const APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL = 'APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL'
export const APPOINTMENT_REQUEST_GROUP_GET_ONE_RESET = 'APPOINTMENT_REQUEST_GROUP_GET_ONE_RESET'

export const APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST = 'APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST'
export const APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS = 'APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS'
export const APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL = 'APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL'

export const APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST = 'APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST'
export const APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS = 'APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS'
export const APPOINTMENT_REQUEST_GROUP_DELETE_FAIL = 'APPOINTMENT_REQUEST_GROUP_DELETE_FAIL'
export const APPOINTMENT_REQUEST_GROUP_DELETE_RESET = 'APPOINTMENT_REQUEST_GROUP_DELETE_RESET'
