import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from 'src/App'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { InputLabelLeft } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { EditPasswordErrors, EditPasswordInputs } from 'src/interfaces/auth'
import { editUserPassword } from 'src/redux/actions/authActions'
import { getOneUser } from 'src/redux/actions/userActions'
import { USER_EDIT_PASSWORD_RESET } from 'src/redux/constants/authConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import {
  checkRequiredFieldsUserPassword,
  userPasswordValidation
} from 'src/services/validation/userForm'

export const UserEditPassword = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { setContext }: any = useContext(GlobalContext)

  const { id: userId } = useParams()
  const [password, setPassword] = useState('')
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const { userEditPassword, userGetOne } = useAppSelector((state) => state)
  const { user: oneUser, error: getOneError, loading: getOneLoading } = userGetOne
  const { error: editError, loading: editLoading, success: editSuccess } = userEditPassword
  let passwordError: EditPasswordErrors = {
    password: ''
  }

  useEffect(() => {
    dispatch({ type: USER_EDIT_PASSWORD_RESET })
    dispatch(getOneUser(userId))
  }, [])

  const setVal = (value: string) => {
    setPassword(value)
  }

  useEffect(() => {
    if (oneUser) {
      setContext({ header: `${oneUser.firstName} ${oneUser.lastName}` })
    }
    return () => {
      setContext({
        header: ''
      })
    }
  }, [oneUser])

  useEffect(() => {
    const isDisabled = checkRequiredFieldsUserPassword(password)
    if (isDisabled > 0) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [password])

  const onSubmit = () => {
    setSubmitted(true)
    passwordError = userPasswordValidation(password)
    if (!isEmpty(passwordError)) {
      return
    } else {
      const inputs: EditPasswordInputs = {
        userId,
        password
      }
      setPassword('')
      dispatch(editUserPassword(inputs))
    }
  }

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (editSuccess) {
      setDisplaySuccessButton(true)
      navigate('/users')
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [editSuccess])

  if (submitted) {
    passwordError = userPasswordValidation(password)
  }

  return (
    <>
      {getOneLoading || editLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(getOneError || editError) && <ErrorAlert message={editError || getOneError} hide />}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/users')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                onSubmit()
                setIsSaveModal(false)
              }}
              message={`${t('MODAL.PASSWORD_UPDATE')}`}
            />
          )}
          {oneUser && (
            <InputLabelLeft
              type='text'
              name='password'
              label={t('AUTH.PASSWORD')}
              onChange={setVal}
              error={passwordError['password']}
              value={password}
              required
            />
          )}
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (editLoading || displaySuccessButton) && 'processing'
              }`}
              disabled={isSaveDisabled || editLoading || displaySuccessButton}
              variant='contained'
            >
              {editLoading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              onClick={() => setIsCancelModal(true)}
              className='secondary-btn'
              variant='contained'
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
