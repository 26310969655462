import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Modal from 'src/components/Modal'
import Navbar from 'src/layouts/Dashboard/components/Navbar'
import { signout } from 'src/redux/actions/authActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export const NotFound = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isModal, setIsModal] = useState(false)
  const { userSignin, userSignOut } = useAppSelector((state) => state)
  const { user } = userSignin
  const { success } = userSignOut

  useEffect(() => {
    if (success) {
      navigate('/signin', { replace: true })
    }
  }, [success])

  return (
    <div>
      {user && <Navbar user={user} onSignout={() => setIsModal(true)} />}
      {isModal && (
        <Modal
          onCancel={() => setIsModal(false)}
          onConfirm={() => {
            dispatch(signout())
            setIsModal(false)
          }}
          message={t('AUTH.CONFIRM_LOGOUT')}
        />
      )}
      <div className='flex flex-col items-center justify-center'>
        <div className='md:text-[250px] text-gray-200 font-bold mt-12 text-[150px] mb-[-40px]'>
          404
        </div>
        <h1 className='md:text-[30px] text-[20px] font-bold text-gray-800'>
          {t('NOT_FOUND.TEXT_1')}
        </h1>
      </div>
    </div>
  )
}
