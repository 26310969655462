import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'src/components/LoadingOverlay'
import {
  getAppointmentsOverview,
  getOptedOutAppointmentsOverview
} from 'src/redux/actions/appointmentRequestActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import {
  AR_APPOINTMENTS_OVERVIEW_GET_RESET,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET
} from 'src/redux/constants/appointmentsRequestConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { AppointmentsList, InfoBox } from './components'

export const ScheduledAppointmentOverview = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const { aRAppointmentsOverviewGet } = useAppSelector((state) => state)
  const { loading, error } = aRAppointmentsOverviewGet

  useEffect(() => {
    setIsFirstLoad(false)
    dispatch({ type: AR_APPOINTMENTS_OVERVIEW_GET_RESET })
    dispatch({ type: AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET })
    if (id) {
      dispatch(getAppointmentsOverview(id))
      dispatch(getOptedOutAppointmentsOverview(id))
    }
  }, [id])

  useEffect(() => {
    if (error && !isFirstLoad) {
      dispatch({ type: AR_APPOINTMENTS_OVERVIEW_GET_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [error])

  return (
    <>
      {loading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
          <AppointmentsList />
          <InfoBox />
        </div>
      )}
    </>
  )
}
