import Switch from '@mui/material/Switch'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { FetchedRoles } from 'src/interfaces/users'
import { LabelLeftHolder } from './UIHolders/Form'
import { ReactSelectStyles } from './reactSelectStyles'

export function Input({
  value,
  onChange,
  error,
  name,
  type,
  label,
  placeholder,
  style,
  disabled,
  required
}: InputInterface) {
  return (
    <div className='mt-4'>
      <label className='block customLabel'>
        {label}
        {required && <span className='text-red-700'> *</span>}
      </label>
      <div className='mt-1'>
        <input
          disabled={disabled}
          style={style}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value, name)}
          id={name}
          name={name}
          type={type}
          className='block w-full customInput appearance-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200'
        />
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </div>
  )
}

export function TextArea({
  value,
  onChange,
  error,
  name,
  label,
  placeholder,
  style,
  required
}: TextAreaLabelLeftInterface) {
  return (
    <div className='mt-4'>
      <label className='block customLabel'>
        {label}
        {required && <span className='text-red-700'> *</span>}
      </label>
      <div className='mt-1'>
        <textarea
          style={style}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value, name)}
          id={name}
          name={name}
          className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-800 focus:outline-none focus:ring-gray-800 sm:text-sm'
        ></textarea>
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </div>
  )
}

interface InputInterface {
  value?: string
  onChange?(arg1: string | number, arg2?: string): void
  error?: string
  name?: string
  type?: string
  label?: string
  placeholder?: string
  style?: object
  disabled?: boolean
  required?: boolean
}

export const InputLabelLeft = ({
  value,
  onChange,
  error,
  name,
  type,
  label,
  placeholder,
  style,
  disabled,
  itemName,
  itemId,
  min,
  required
}: InputLabelLeftInterface) => {
  return (
    <LabelLeftHolder label={label} required={required}>
      <input
        disabled={disabled}
        min={min}
        style={style}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (itemId && itemName) {
            onChange?.(e.target.value, name, itemName, itemId)
          } else {
            onChange?.(e.target.value, name)
          }
        }}
        id={name}
        name={name}
        type={type}
        className='block w-full customInput disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200'
      />
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </LabelLeftHolder>
  )
}

interface InputLabelLeftInterface {
  value?: string | number
  onChange?(arg1: string | number, arg2?: string, arg3?: string, arg4?: string | number): void
  error?: string
  name?: string
  type?: string
  label?: string
  placeholder?: string
  style?: object
  disabled?: boolean
  itemName?: string
  itemId?: string | number
  min?: string
  required?: boolean
}

export const SwitchLabelLeft = ({
  onChange,
  name,
  value,
  label,
  tooltip
}: SwitchLabelLeftInterface) => {
  return (
    <LabelLeftHolder label={label} inputHolderStyle='flex items-center md:col-span-2'>
      <Switch className='ml-[-12px]' checked={value} onClick={() => onChange?.(!value, name)} />
      {tooltip && <p className='text-gray-700 text-[12px]'>{tooltip}</p>}
    </LabelLeftHolder>
  )
}

interface SwitchLabelLeftInterface {
  value?: boolean
  onChange?(arg1: boolean, arg2?: string): void
  name?: string
  label?: string
  tooltip?: string
}

export const SelectLabelLeft = ({
  value,
  onChange,
  error,
  name,
  label,
  options,
  searchText,
  setSearchText,
  itemName,
  itemId,
  required
}: SelectLabelLeftInterface) => {
  return (
    <LabelLeftHolder required={required} label={label}>
      <div className='custom-select-input'>
        <Select
          onChange={(value) => {
            if (itemId && itemName) {
              onChange?.(value, name, itemName, itemId)
            } else {
              onChange?.(value, name)
            }
          }}
          styles={ReactSelectStyles}
          onInputChange={(value) => setSearchText?.(value, searchText)}
          value={value}
          options={options}
        />
      </div>
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </LabelLeftHolder>
  )
}
interface SelectLabelLeftCreatableInterface {
  value?: object | string
  onChange?(arg1: object | null, arg2?: string, arg3?: string, arg4?: string | number): void
  error?: string
  name?: string
  label?: string
  searchText?: string
  setSearchText?(arg1: string, arg2?: string): void
  options?: { value?: string; label?: string }[]
  itemName?: string
  itemId?: string | number
  required?: boolean
}

export const SelectLabelLeftCreatable = ({
  value,
  onChange,
  error,
  name,
  label,
  options,
  searchText,
  setSearchText,
  itemName,
  itemId,
  required
}: SelectLabelLeftCreatableInterface) => {
  return (
    <LabelLeftHolder required={required} label={label}>
      <div className='custom-select-input'>
        <Creatable
          onChange={(value: any) => {
            if (itemId && itemName) {
              onChange?.(value, name, itemName, itemId)
            } else {
              onChange?.(value, name)
            }
          }}
          styles={ReactSelectStyles}
          onInputChange={(value) => setSearchText?.(value, searchText)}
          value={value}
          options={options}
        />
      </div>
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </LabelLeftHolder>
  )
}

interface SelectLabelLeftInterface {
  value?: object
  onChange?(arg1: object | null, arg2?: string, arg3?: string, arg4?: string | number): void
  error?: string
  name?: string
  label?: string
  searchText?: string
  setSearchText?(arg1: string, arg2?: string): void
  options?: { value?: string; label?: string }[]
  itemName?: string
  itemId?: string | number
  required?: boolean
}

export const TextAreaLabelLeft = ({
  value,
  onChange,
  error,
  name,
  label,
  placeholder,
  style,
  required
}: TextAreaLabelLeftInterface) => {
  return (
    <LabelLeftHolder required={required} label={label}>
      <textarea
        rows={3}
        style={style}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange?.(e.target.value, name)}
        id={name}
        name={name}
        className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200 sm:text-sm'
      ></textarea>
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </LabelLeftHolder>
  )
}

interface TextAreaLabelLeftInterface {
  value?: string
  onChange?(arg1: string, arg2?: string): void
  error?: string
  name?: string
  type?: string
  label?: string
  placeholder?: string
  style?: object
  required?: boolean
}

export const MultiSelectLabelLeft = ({
  label,
  error,
  onChange,
  value,
  options,
  setQuery,
  name,
  required
}: MultiSelectLabelLeftInterface) => {
  return (
    <LabelLeftHolder required={required} label={label}>
      <div className='custom-select-input'>
        <Select
          onChange={(value) => onChange?.(value, name)}
          onInputChange={(value) => (setQuery ? setQuery?.(value) : value)}
          options={options}
          isMulti
          value={value}
          styles={ReactSelectStyles}
        />
      </div>
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </LabelLeftHolder>
  )
}

interface MultiSelectLabelLeftInterface {
  value?: { value: string; label: string }[]
  label?: string
  error?: string
  onChange?(arg1: Readonly<{ value: string; label: string }[]>, arg2?: string): void
  setQuery?(arg1: string): void
  options?: { value: string; label: string }[]
  name?: string
  required?: boolean
}

export const RadioGroup = ({
  values,
  defaultValue,
  label,
  onChange,
  name,
  error,
  required
}: RadioGroupInterface) => {
  return (
    <LabelLeftHolder required={required} label={label}>
      <div>
        <fieldset className='mt-4'>
          <div className='flex w-full justify-between'>
            {values?.map((value: FetchedRoles) => (
              <div key={value.id} className='flex items-center gap-1'>
                <input
                  onChange={() => onChange?.(value.id, name)}
                  id={value.id}
                  name={name}
                  type='radio'
                  defaultChecked={
                    value.title === defaultValue?.charAt(0).toUpperCase() + defaultValue?.slice(1)
                  }
                  className='h-4 w-4 border-gray-300 text-brand-orange-700 focus:outline-0 focus:ring-brand-orange-700'
                />
                <label htmlFor={value.id} className='block text-sm font-medium text-gray-700'>
                  {value.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </LabelLeftHolder>
  )
}

interface RadioGroupInterface {
  values?: FetchedRoles[]
  label?: string
  error?: string
  defaultValue: string
  onChange?(arg1: string, arg2?: string): void
  name?: string
  required?: boolean
}
