import {
  APPOINTMENT_EDIT_FAIL,
  APPOINTMENT_EDIT_REQUEST,
  APPOINTMENT_EDIT_SUCCESS,
  AR_APPOINTMENTS_OVERVIEW_GET_FAIL,
  AR_APPOINTMENTS_OVERVIEW_GET_REQUEST,
  AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS,
  EXAMINATIONS_OPTED_OUT_GET_FAIL,
  EXAMINATIONS_OPTED_OUT_GET_REQUEST,
  EXAMINATIONS_OPTED_OUT_GET_SUCCESS
} from 'src/redux/constants/appointmentsRequestConstants'
import { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const edit = (id: string, data: any) => (dispatch: AppDispatch) => {
  dispatch({ type: APPOINTMENT_EDIT_REQUEST, payload: {} })
  api
    .patch(`/api/admin/appointment-request/${id}`, data)
    .then((res) => {
      dispatch({ type: APPOINTMENT_EDIT_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: APPOINTMENT_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAppointmentsOverview =
  (appointmentRequestId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: AR_APPOINTMENTS_OVERVIEW_GET_REQUEST, payload: {} })
    api
      .get(`/api/admin/appointment-request/appointments-overview/${appointmentRequestId}`)
      .then((res) => {
        dispatch({ type: AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: AR_APPOINTMENTS_OVERVIEW_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getOptedOutAppointmentsOverview =
  (appointmentRequestId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST, payload: {} })
    api
      .get(`/api/admin/appointment-request/opted-out-appointments-overview/${appointmentRequestId}`)
      .then((res) => {
        dispatch({ type: AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getOptedOutExaminationsForUser =
  (userId: string, appointmentRequestId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATIONS_OPTED_OUT_GET_REQUEST, payload: {} })
    api
      .get('/api/admin/appointment-request/user-examinations/get-opted-out', {
        params: {
          userId,
          appointmentRequestId
        }
      })
      .then((res) => {
        dispatch({ type: EXAMINATIONS_OPTED_OUT_GET_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATIONS_OPTED_OUT_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
