import { EyeIcon } from '@heroicons/react/20/solid'
import { Button, ThemeProvider } from '@mui/material'
import {
  DataGrid,
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { SortObject } from 'src/interfaces/appointmentRequest'
import { QueryObject } from 'src/interfaces/users'
import { getContactPersonForLocation } from 'src/redux/actions/userActions'
import { ENABLE_PREVENTIVE_LOG_RESET } from 'src/redux/constants/preventiveLogConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import i18n from 'src/services/language.service'
import { columns } from './helpers/TableColumnsHelper'

export const ContactPersonList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const savedLng = i18n.language
  const { id: companyLocationId } = useParams()
  const [tableRows, setTableRows] = useState([])
  const [isSuccessCreate, setIsSuccessCreate] = useState(false)
  const [sortObject, setSortObject] = useState<SortObject>({})
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number | string>(0)
  const [limit, setLimit] = useState(config.limit)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [language, setLanguage] = useState<string>('DE')
  const deleteButtonRef = useRef<HTMLInputElement>(null)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const { userGetAllContactsForLocation, enablePreventiveLog } = useAppSelector((state) => state)
  const { users, loading, count } = userGetAllContactsForLocation
  const { success } = enablePreventiveLog

  useEffect(() => {
    dispatch({ type: ENABLE_PREVENTIVE_LOG_RESET })
    setIsSuccessCreate(success)
    sessionStorage.clear()
  }, [])

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])

  useEffect(() => {
    ;(async () => {
      if (companyLocationId) {
        const sort = sortObject?.field ? sortObject.field : ''
        const order = sortObject?.sort ? sortObject.sort : ''
        const quickFilter =
          queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
        const firstNameFilter =
          queryObject.field === 'firstName' && queryObject.value ? queryObject.value : ''
        const firstNameFilterType =
          queryObject.field === 'firstName' && queryObject.operator
            ? queryObject.operator
            : 'contains'
        const lastNameFilter =
          queryObject.field === 'lastName' && queryObject.value ? queryObject.value : ''
        const lastNameFilterType =
          queryObject.field === 'lastName' && queryObject.operator
            ? queryObject.operator
            : 'contains'
        const emailFilter =
          queryObject.field === 'email' && queryObject.value ? queryObject.value : ''
        const emailFilterType =
          queryObject.field === 'email' && queryObject.operator ? queryObject.operator : 'contains'

        dispatch(
          getContactPersonForLocation({
            locationId: companyLocationId,
            page,
            limit,
            sort,
            order,
            quickFilter,
            firstNameFilter,
            firstNameFilterType,
            lastNameFilter,
            lastNameFilterType,
            emailFilter,
            emailFilterType
          })
        )
      }
    })()
  }, [companyLocationId, page, limit, sortObject, queryObject])

  useEffect(() => {
    if (users) {
      setTableRows(users)
      setFinishedLoading(true)
    }
  }, [users])

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const onSortChange = (sortModel: GridSortModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    setSortObject(sortModel[0])
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2 mt-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {isSuccessCreate && (
                <SuccessAlert
                  hide
                  message={`${t('CREATE_PREVENTIVE_LOG_CUSTOMER.SUCCESS_CREATE')}`}
                />
              )}
            </div>
            <Button
              className='main-btn'
              variant='contained'
              href={`/client/${companyLocationId}/add-contact`}
            >
              {t('CONTACT_PERSONS.CREATE_CONTACT_PERSON')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            <div style={{ width: '100%' }}>
              {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !isFirstLoad) ? (
                <ThemeProvider theme={setTheme(language)}>
                  <DataGrid
                    {...tableRows}
                    className='MuiDataGrid-cellContent-alignTop'
                    columns={columns}
                    rows={tableRows}
                    rowCount={count > 0 ? count : 0}
                    pagination
                    paginationMode='server'
                    sortingMode='server'
                    filterMode='server'
                    onSortModelChange={onSortChange}
                    onFilterModelChange={onFilterChange}
                    loading={loading}
                    paginationModel={{
                      page: Number(page),
                      pageSize: limit
                    }}
                    pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                    onPaginationModelChange={(params) => {
                      setPage(params.page)
                      setLimit(params.pageSize)
                    }}
                    slots={{ toolbar: Toolbar }}
                    localeText={{
                      toolbarFilters: t('GENERIC.FILTER')
                    }}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                      },
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          placeholder: t('GENERIC.SEARCH'),
                          inputProps: {
                            'aria-label': t('GENERIC.SEARCH')
                          },
                          debounceMs: 500
                        }
                      }
                    }}
                    sx={GridStyles}
                    columnVisibilityModel={{ leftCompanyDate: false }}
                    getRowHeight={() => 'auto'}
                    autoHeight={true}
                    rowSelection={false}
                    checkboxSelection={false}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick={true}
                  />
                </ThemeProvider>
              ) : (
                <EmptyState
                  titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                    type: t('EMPTY_STATE.CONTACT_PERSONS')
                  })}
                  additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
