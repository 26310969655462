import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useState } from 'react'

ErrorAlert.propTypes = {
  message: PropTypes.string,
  hide: PropTypes.bool
}

SuccessAlert.propTypes = {
  message: PropTypes.string,
  hide: PropTypes.bool
}

WarnAlert.propTypes = {
  message: PropTypes.string,
  hide: PropTypes.bool
}

export function ErrorAlert({ message, hide }: InferProps<typeof ErrorAlert.propTypes>) {
  const [display, setDisplay] = useState(true)
  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setDisplay(false)
      }, 5000)
    }
  }, [])
  return (
    <div className={`${!display && 'hidden'} customAlert text-red-700 bg-red-100 `} role='alert'>
      {message}
    </div>
  )
}

export function SuccessAlert({ message, hide }: InferProps<typeof SuccessAlert.propTypes>) {
  const [display, setDisplay] = useState(true)
  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setDisplay(false)
      }, 5000)
    }
  }, [])
  return (
    <div className={`${!display && 'hidden'} customAlert text-green-700 bg-green-100`} role='alert'>
      {message}
    </div>
  )
}

export function WarnAlert({ message, hide }: InferProps<typeof WarnAlert.propTypes>) {
  const [display, setDisplay] = useState(true)
  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setDisplay(false)
      }, 5000)
    }
  }, [])
  return (
    <div
      className={`${!display && 'hidden'} customAlert text-yellow-700 bg-yellow-100`}
      role='alert'
    >
      {message}
    </div>
  )
}
