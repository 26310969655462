import {
  DOWNLOAD_CERTIFICATES_FAIL,
  DOWNLOAD_CERTIFICATES_REQUEST,
  DOWNLOAD_CERTIFICATES_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAIL,
  DOWNLOAD_CERTIFICATE_REQUEST,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  EXAMINATION_HISTORY_GET_FAIL,
  EXAMINATION_HISTORY_GET_REQUEST,
  EXAMINATION_HISTORY_GET_SUCCESS,
  EXAMINATION_RECORD_MASS_MARK_FAIL,
  EXAMINATION_RECORD_MASS_MARK_REQUEST,
  EXAMINATION_RECORD_MASS_MARK_SUCCESS
} from 'src/redux/constants/examinationConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const massMarkExaminatiosRecord =
  (data?: any, file?: File | null) => (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_REQUEST, payload: {} })
    api
      .post(
        '/api/admin/examination/mass-mark-examination-record',
        { ...data, file },
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then((res) => {
        dispatch({ type: EXAMINATION_RECORD_MASS_MARK_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_RECORD_MASS_MARK_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const massCreateExaminatiosRecord =
  (data?: any, file?: File | null) => (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_REQUEST, payload: {} })
    api
      .post(
        '/api/admin/examination/mass-create-examination-record',
        { ...data, file },
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then((res) => {
        dispatch({ type: EXAMINATION_RECORD_MASS_MARK_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_RECORD_MASS_MARK_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const massUpdateExaminatiosRecord =
  (data?: any, file?: File | null) => (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_REQUEST, payload: {} })
    api
      .post(
        '/api/admin/examination/mass-update-examination-record',
        { ...data, file },
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then((res) => {
        dispatch({ type: EXAMINATION_RECORD_MASS_MARK_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_RECORD_MASS_MARK_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const downloadUserCertificates = (examinationIds: string[]) => (dispatch: AppDispatch) => {
  dispatch({ type: DOWNLOAD_CERTIFICATES_REQUEST, payload: {} })
  api
    .post('/api/admin/examination/download-certificates', {
      examinationIds
    })
    .then((res) => {
      dispatch({ type: DOWNLOAD_CERTIFICATES_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: DOWNLOAD_CERTIFICATES_FAIL, payload: err })
    })
}

export const downloadUserCertificate = (examinationId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: DOWNLOAD_CERTIFICATE_REQUEST, payload: {} })
  api
    .get(`/api/admin/examination/download-certificate/${examinationId}`)
    .then((res) => {
      dispatch({ type: DOWNLOAD_CERTIFICATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: DOWNLOAD_CERTIFICATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getExaminationHistory =
  ({ appointmentId = '', userId = '', limit = 20, page = 0 }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_HISTORY_GET_REQUEST, payload: {} })
    api
      .get('/api/admin/examination/user-examinations/get-history', {
        params: {
          appointmentId,
          userId,
          limit,
          page
        }
      })
      .then((res) => {
        dispatch({
          type: EXAMINATION_HISTORY_GET_SUCCESS,
          payload: { appointments: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_HISTORY_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
