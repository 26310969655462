import { MinusCircleIcon } from '@heroicons/react/20/solid'
import { IconButton, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { ReactSelectStyles } from 'src/components/reactSelectStyles'
import { ExaminationTypeListItem } from 'src/interfaces/examinationTypes'
import { getAll } from 'src/redux/actions/examinationTypeActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
export function ExaminationItemEdit({
  id,
  status,
  performedDate,
  examinationCheckupOption,
  nextExaminationDate,
  examinationType,
  onOpenModal,
  setVal
}: InferProps<typeof ExaminationItemEdit.propTypes>) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { examinationTypeGetAll } = useAppSelector((state) => state)
  const { examinationsTypes } = examinationTypeGetAll
  const [examinationOptions, setExaminationOptions] = useState<{ label: string; value: string }[]>(
    []
  )

  useEffect(() => {
    dispatch(getAll({ limit: 1000 }))
  }, [dispatch])

  useEffect(() => {
    if (examinationsTypes) {
      const examinationOptions = examinationsTypes.map((examination: ExaminationTypeListItem) => ({
        label: examination.name,
        value: examination.id
      }))
      setExaminationOptions(examinationOptions)
    }
  }, [examinationsTypes])

  const options = [
    {
      value: 'First examination',
      label: t('EXAMINATION_HISTORY.FIRST_EXAMINATION')
    },
    {
      value: 'Second examination',
      label: t('EXAMINATION_HISTORY.SECOND_EXAMINATION')
    },
    { value: 'Follow up', label: t('EXAMINATION_HISTORY.FOLLOW_UP') }
  ]
  return (
    <div className='border-b border-gray-300 flex flex-col my-5'>
      <div className='flex flex-col md:flex-row md:items-end gap-12 mb-5'>
        <div className='flex flex-col w-full md:min-w-[250px] md:max-w-[250px] gap-2'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.PERFORMED_EXAMINATION')}</p>
          <div className='flex items-center gap-3'>
            <>
              <input
                checked={status === 'Completed'}
                onChange={(e) => setVal?.(id, e.target.checked, 'status')}
                id={id ?? ''}
                name='check'
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-brand-orange-700 focus:brand-orange-700 focus:ring-brand-orange-700'
              />
            </>
            <div className='w-full md:min-w-[250px]'>
              <div className='custom-select-input'>
                <Select
                  defaultValue={
                    examinationType && { label: examinationType.name, value: examinationType.id }
                  }
                  options={examinationOptions}
                  styles={ReactSelectStyles}
                  onChange={(val: any) => setVal?.(id, val, 'examinationType')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.PERFORMED_ON')}</p>
          <div className='custom-datepicker-input'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={performedDate || null}
                onChange={(val: any) => setVal?.(id, val, 'performedDate')}
                inputFormat='DD.MM.YYYY'
                renderInput={(params: any) => <TextField {...params} />}
                maxDate={Date.now()}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='flex flex-col gap-2 w-full md:max-w-[250px]'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.TYPE')}</p>
          <div className='custom-select-input w-full'>
            <Select
              defaultValue={examinationCheckupOption}
              onChange={(val) => setVal?.(id, val, 'examinationCheckup')}
              options={options}
              styles={ReactSelectStyles}
            />
          </div>
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.NEXT_EXAMINATION_DUE')}</p>
          <div className='custom-datepicker-input'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={nextExaminationDate || null}
                onChange={(val: any) => setVal?.(id, val, 'nextExaminationDate')}
                inputFormat='MM.YYYY'
                renderInput={(params: any) => <TextField {...params} />}
                minDate={Date.now()}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='ml-auto'>
          <IconButton
            onClick={() => onOpenModal?.(id)}
            title={t('EXAMINATION_SELECTION.REMOVE_EXAMINATION')}
          >
            <MinusCircleIcon className='w-6' />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

ExaminationItemEdit.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  performedDate: PropTypes.object,
  examinationCheckupOption: PropTypes.object,
  nextExaminationDate: PropTypes.object,
  examinationNote: PropTypes.string,
  employerCertificate: PropTypes.string,
  examinationType: PropTypes.any,
  employeeOptedOut: PropTypes.string,
  setVal: PropTypes.func,
  onOpenModal: PropTypes.func
}
