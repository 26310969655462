import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { GlobalContext } from 'src/App'
import { AppointmentRequestList } from 'src/pages/Doctors/AppointmentRequests/AppointmentRequestList'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ContactPersonList } from './ContactPerson'
import ExaminationsSelection from './ExaminationsSelection'
import PreventiveLog from './PreventiveLog'
import { TabPanel } from './components'
import './index.scss'

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export const SingleCustomer = () => {
  const [tabValue, setTabValue] = useState<number>(0)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const tabQuery = searchParams.get('tab')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    sessionStorage.setItem('lastViewedTab', newValue.toString())
    setTabValue(newValue)
  }
  const { companyLocationByID } = useAppSelector((state) => state)
  const { error: getCompanyLocationError } = companyLocationByID

  const { setContext }: any = React.useContext(GlobalContext)
  const { id: companyLocationId } = useParams()

  useEffect(() => {
    setIsFirstLoad(false)
    if (tabQuery) {
      switch (tabQuery) {
        case 'preventive-log':
          setTabValue(0)
          break
        case 'appointment-requests':
          setTabValue(1)
          break
        case 'examinations-selection':
          setTabValue(2)
          break
        case 'contact-person-form':
          setTabValue(3)
          break
        default:
          break
      }
    }
  }, [tabQuery])

  useEffect(() => {
    if (getCompanyLocationError && !isFirstLoad) {
      dispatch(displayNotFoundElement(true))
    }
  }, [getCompanyLocationError])

  useEffect(() => {
    switch (tabValue) {
      case 0:
        setContext({ header: `${t('NAVBAR.PREVENTIVE_LOG')}` })
        break
      case 1:
        setContext({ header: `${t('NAVBAR.APPOINTMENT_REQUEST')}` })
        break
      case 2:
        setContext({ header: `${t('NAVBAR.EXAMINATIONS_SELECTION')}` })
        break
      case 3:
        setContext({ header: `${t('NAVBAR.CONTACT_PERSONS')}` })
        break
      default:
        break
    }
  }, [tabValue])

  useEffect(() => {
    if (sessionStorage.getItem('lastViewedTab')) {
      setTabValue(Number(sessionStorage.getItem('lastViewedTab')))
    }
  }, [])

  return (
    <div className='single-customer-page-holder'>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            className='custom-tab-panel'
            value={tabValue}
            onChange={handleChange}
            title='basic tabs example'
            TabIndicatorProps={{ style: { backgroundColor: '#fc6566', fontSize: '12px' } }}
          >
            <Tab label={`${t('NAVBAR.PREVENTIVE_LOG')}`} {...a11yProps(0)} />
            <Tab label={`${t('NAVBAR.APPOINTMENT_REQUEST')}`} {...a11yProps(1)} />
            <Tab label={`${t('NAVBAR.EXAMINATIONS_SELECTION')}`} {...a11yProps(2)} />
            <Tab label={`${t('NAVBAR.CONTACT_PERSONS')}`} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <PreventiveLog />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AppointmentRequestList companyLocationId={companyLocationId} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ExaminationsSelection />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ContactPersonList />
        </TabPanel>
      </Box>
    </div>
  )
}
