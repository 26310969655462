import { RiskAreaEntity, RiskExposureGroupEntity } from 'src/entities/interfaces'
import { RiskExposureGroupOption } from 'src/interfaces/riskExposureGroups'
import { api } from 'src/services/api.service'

export async function getExaminationGroupByRiskId(id: string): Promise<RiskExposureGroupOption[]> {
  return await api
    .get(`/api/admin/risk-exposure-group/riskarea/${id}`)
    .then((res) => {
      const options = res.data.map((group: RiskExposureGroupEntity) => {
        return { value: group.id, label: group.name }
      })
      return options
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function setRiskAreaRelation(inputs: {
  riskAreaId: string
  riskExposureGroupId: string[]
}): Promise<RiskAreaEntity> {
  return await api
    .patch('/api/admin/risk-area/riskExposure', inputs)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}
