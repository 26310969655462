import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { InputLabelLeft, SwitchLabelLeft } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { CompanyErrors, CompanyInputs, LocationElement } from 'src/interfaces/companies'
import { create, edit, getOne } from 'src/redux/actions/companyActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { COMPANY_CREATE_RESET, COMPANY_GET_ONE_RESET } from 'src/redux/constants/companyConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { formsChangesListener } from 'src/services/formsChangesListener.service'
import { checkRequiredFields, validation } from 'src/services/validation/companyForm'
import { LocationsForm } from './components/LocationsForm'
import {
  checkIfCompanyHasContact,
  getBasPracticeByCompanyLocationId,
  getCompanyLocationsByCompanyId
} from './helper/externalFunctions'
import { compareLocattions } from './helper/internalFunctions'
import './index.scss'

export const CompanyForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: companyId } = useParams()
  let errors: CompanyErrors = {
    name: '',
    contractInPlace: false,
    externalId: '',
    locations: [
      {
        id: '',
        name: '',
        address: '',
        associatedPractice: ''
      }
    ]
  }
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isCancelModalDisabled, setIsCancelModalDisabled] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [companyHasPreventiveLogError, setCompanyHasPreventiveLogError] = useState('')
  const [inputs, setInputs] = useState<CompanyInputs>({
    name: '',
    contractInPlace: true,
    externalId: '',
    locations: [
      {
        id: Date.now(),
        name: '',
        address: '',
        associatedPractice: { value: '', label: '' },
        createdAt: '',
        hasContact: false
      }
    ],
    companyId: ''
  })
  const [removedLocations, setRemovedLocations] = useState<LocationElement[]>([])

  const [oldInputs, setOldInputs] = useState<CompanyInputs>({
    name: '',
    contractInPlace: true,
    externalId: '',
    locations: [
      {
        id: Date.now(),
        name: '',
        address: '',
        associatedPractice: { value: '', label: '' },
        createdAt: '',
        hasContact: false
      }
    ],
    companyId: ''
  })

  const { companyGetOne, companyEdit, companyCreate } = useAppSelector((state) => state)
  const {
    loading: editCompanyLoading,
    success: editCompanySuccess,
    error: editCompanyError
  } = companyEdit
  const { loading: getCompanyLoading, company, error: getCompanyError } = companyGetOne
  const {
    loading: createCompanyLoading,
    success: createCompanySuccess,
    error: createCompanyError
  } = companyCreate

  useEffect(() => {
    dispatch({ type: COMPANY_CREATE_RESET })
    setIsFirstLoad(false)
    if (location.pathname.includes('edit')) {
      dispatch(getOne(companyId))
    }
  }, [])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if ((createCompanySuccess && !isFirstLoad) || (editCompanySuccess && !isFirstLoad)) {
      setDisplaySuccessButton(true)
      setTimeout(() => {
        navigate('/companies')
      }, 100)
    } else if (createCompanyError || editCompanyError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [createCompanySuccess, createCompanyError, editCompanySuccess, editCompanyError])

  useEffect(() => {
    ;(async () => {
      if (company && !isFirstLoad) {
        const locationHelper = []
        const locations = await getCompanyLocationsByCompanyId({ id: company.id })
        for (let i = 0; i < locations.length; i++) {
          const basPractice = await getBasPracticeByCompanyLocationId({ id: locations[i].id })
          const hasContact = await checkIfCompanyHasContact({
            companyId: company.id,
            companyLocationId: locations[i].id
          })
          const location = {
            id: locations[i].id,
            name: locations[i].name,
            address: locations[i].address,
            associatedPractice: {
              value: basPractice[0]?.id || '',
              label: basPractice[0]?.name || ''
            },
            createdAt: locations[i].createdAt,
            updatedAt: locations[i].updatedAt,
            hasContact: hasContact.length > 0 ? true : false
          }
          locationHelper.push(location)
        }
        setInputs({
          name: company.name,
          contractInPlace: company.contractInPlace,
          externalId: company.externalId,
          locations: locationHelper.sort(
            (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          ),
          companyId: company.id
        })
        setOldInputs({
          name: company.name,
          contractInPlace: company.contractInPlace,
          externalId: company.externalId,
          locations: locationHelper.sort(
            (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          ),
          companyId: company.id
        })
      }
    })()
    if (getCompanyError && !isFirstLoad) {
      dispatch({ type: COMPANY_GET_ONE_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [company, getCompanyError, isFirstLoad])

  const setVal: any = (value: any, name: string, itemName: string, itemId: string) => {
    if (name === 'locations') {
      setLocations('edit', itemId, value, itemName)
    } else {
      setInputs({ ...inputs, [name]: value })
    }
  }
  const setLocations = (action: string, id: string, value: any, itemName: string) => {
    if (action === 'add') {
      setInputs({
        ...inputs,
        locations: [
          ...inputs.locations,
          {
            id: Date.now(),
            name: '',
            address: '',
            associatedPractice: { value: '', label: '' },
            createdAt: '',
            hasContact: false
          }
        ]
      })
    } else if (action === 'remove') {
      const newLocations = inputs.locations.filter(
        (location: LocationElement) => location.id !== id
      )
      const removedLocation = inputs.locations.find(
        (location: LocationElement) => location.id === id
      )
      removedLocation?.id && setRemovedLocations([...removedLocations, removedLocation])
      setInputs({ ...inputs, locations: newLocations })
    } else if (action === 'edit') {
      const newLocations = inputs.locations.map((location: LocationElement) => {
        if (location.id === id) {
          location = { ...location, [itemName]: value }
          return location
        }
        return location
      })
      setInputs({ ...inputs, locations: newLocations })
    }
  }

  const checkIfCompanyHasPreventiveLog = async (locations: string | any[]) => {
    let error = ''
    for (let i = 0; i < locations.length; i++) {
      if (typeof locations[i].id === 'string') {
        const hasPreventiveLog = await checkIfCompanyHasContact({
          companyId: companyId,
          companyLocationId: locations[i].id
        })
        if (hasPreventiveLog == undefined) {
          continue
        } else if (hasPreventiveLog.length > 0) {
          error = t('COMPANY_LOCATION.PREVENTIVE_LOG_ERROR')
        }
      }
    }
    return error
  }

  useEffect(() => {
    const isDisabledRequired = checkRequiredFields(inputs, ['name', 'externalId', 'locations'])
    let isDisabledChanges = false
    if (company) {
      isDisabledChanges = formsChangesListener({ inputs, oldInputs, ignoreInputs: ['locations'] })
      isDisabledChanges = compareLocattions(inputs['locations'], oldInputs['locations'])
        ? true
        : isDisabledChanges
    }
    if (!isDisabledChanges && company) {
      setIsCancelModalDisabled(true)
    } else {
      setIsCancelModalDisabled(false)
    }
    if (isDisabledRequired > 0 || (!isDisabledChanges && company)) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs, company])

  const onSubmit = async (action: string) => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors) || inputs.locations.length === 0) {
      return
    } else {
      if (action === 'edit' && removedLocations.length === 0) {
        dispatch(edit(inputs, companyId))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else if (action === 'edit' && removedLocations.length > 0) {
        const error = await checkIfCompanyHasPreventiveLog(removedLocations)
        if (error.length === 0) {
          dispatch(edit(inputs, companyId))
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        } else {
          setCompanyHasPreventiveLogError(error)
        }
      } else {
        dispatch(create(inputs))
      }
    }
  }

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <>
      {getCompanyLoading || editCompanyLoading || createCompanyLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(editCompanyError || createCompanyError || companyHasPreventiveLogError) && (
            <ErrorAlert
              message={editCompanyError || createCompanyError || companyHasPreventiveLogError}
              hide
            />
          )}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/companies')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                const action = company ? 'edit' : 'create'
                onSubmit(action)
                setIsSaveModal(false)
              }}
              message={`${t('GENERIC.CONFIRM_CHOICE')} ${
                company ? t('GENERIC.UPDATE').toLowerCase() : t('GENERIC.CREATE').toLowerCase()
              } ${t('MODAL_ACTIONS_ROLES.COMPANY').toLowerCase()}?`}
            />
          )}
          <div className='space-y-6'>
            <InputLabelLeft
              value={inputs['name']}
              type='text'
              required
              onChange={setVal}
              error={errors['name']}
              name='name'
              label={t('COMPANY.COMPANY_NAME')}
            />
            <InputLabelLeft
              value={inputs['externalId']}
              type='text'
              required
              onChange={setVal}
              error={errors['externalId']}
              name='externalId'
              label={t('COMPANY.COMPANY_NUMBER')}
            />
            <SwitchLabelLeft
              value={inputs['contractInPlace']}
              onChange={setVal}
              name='contractInPlace'
              label={t('COMPANY.CONTRACT_IN_PLACE')}
            />
            <LocationsForm
              inputs={inputs}
              submitted={submitted}
              errors={errors}
              setVal={setVal}
              setLocations={setLocations}
            />
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (createCompanyLoading || editCompanyLoading || displaySuccessButton) && 'processing'
              }`}
              disabled={
                isSaveDisabled || createCompanyLoading || editCompanyLoading || displaySuccessButton
              }
              variant='contained'
            >
              {createCompanyLoading || editCompanyLoading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              onClick={() => {
                if (isCancelModalDisabled) {
                  navigate('/companies')
                } else {
                  setIsCancelModal(true)
                }
              }}
              className='secondary-btn'
              variant='contained'
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
