import { CalendarDaysIcon, EyeIcon, ListBulletIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import {
  AppointmentRequestEntity,
  AppointmentRequestListItem,
  AppointmentRequestSearch,
  SortObject
} from 'src/interfaces/appointmentRequest'
import { QueryObject } from 'src/interfaces/practices'
import {
  DOCTOR_AVAILABILLITY_CREATE_RESET,
  DOCTOR_AVAILABILLITY_GET_ALL_RESET
} from 'src/redux/constants/doctorAvailabillityContants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import dateFormattingService from 'src/services/dateFormatting.service'
import { columns } from './helpers/TableColumnsHelper'
import { getAppointmentRequests } from './helpers/externalFunctions'

export const AppointmentRequestList = ({
  companyLocationId
}: {
  companyLocationId?: string | null | undefined
}) => {
  const { t } = useTranslation()
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const navigate = useNavigate()
  const [language, setLanguage] = useState<string>('DE')
  const deleteButtonRef = useRef<HTMLInputElement>(null)
  const savedLng = i18next.language
  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])

  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.CUSTOMER')}</p>
  }
  columns[1].renderHeader = () => {
    return <p className='font-bold'>{t('EXAMINATIONS.DATE_OF_REQUEST')}</p>
  }
  columns[2].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.CONTACT_PERSON')}</p>
  }
  columns[3].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.NO_OF_EMPLOYEES')}</p>
  }
  columns[4].renderHeader = () => {
    return <p className='font-bold'>{t('GENERIC.STATUS')}</p>
  }
  columns[5].renderCell = (params: GridRenderCellParams) => {
    return (
      <div className='flex items-center gap-2'>
        <IconButton
          onClick={() => navigate(`/scheduled-appointment-overview/${params.id}`)}
          title={t('APPOINTMENTS_REQUEST_LIST.SCHEDULED_APPOINTMENTS_OVERVIEW')}
        >
          <ListBulletIcon className='w-5' />
        </IconButton>
        {params.row.allOptedOut != true && (
          <Link
            to={`/examination-request/${params.id}`}
            title={t('APPOINTMENTS_REQUEST_LIST.SCHEDULE_NEW_APPOINTMENT')}
            state={{ state: params.row }}
            className='hover:bg-[#1976d214] rounded-[5px] p-2'
          >
            <CalendarDaysIcon className='w-5' />
          </Link>
        )}
      </div>
    )
  }
  const dispatch = useAppDispatch()
  const [limit, setLimit] = useState(config.limit)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState<number | string>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [sortObject, setSortObject] = useState<SortObject>({})
  const [appointmentRequests, setAppointmentRequests] = useState<AppointmentRequestEntity[]>()
  const [tableRows, setTableRows] = useState<AppointmentRequestListItem[]>([])
  const { userSignin, createDoctorAvailabillity } = useAppSelector((state) => state)
  const { success } = createDoctorAvailabillity
  const { user } = userSignin
  useEffect(() => {
    ;(async () => {
      dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_RESET })
      const sort = sortObject?.field ? sortObject.field : ''
      const order = sortObject?.sort ? sortObject.sort : ''
      const companyLocationFilter =
        queryObject.field === 'companyLocation' && queryObject.value ? queryObject.value : ''
      const companyLocationFilterType =
        queryObject.field === 'companyLocation' && queryObject.operator
          ? queryObject.operator
          : 'contains'
      const contactFilter =
        queryObject.field === 'contact' && queryObject.value ? queryObject.value : ''
      const contactFilterType =
        queryObject.field === 'contact' && queryObject.operator ? queryObject.operator : 'contains'
      const quickFilter =
        queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''

      const appointmentRequests = await getAppointmentRequests({
        page,
        limit,
        userId: user.id,
        sort,
        order,
        companyLocationFilter,
        companyLocationFilterType,
        contactFilter,
        contactFilterType,
        companyLocationId,
        quickFilter
      } as AppointmentRequestSearch)

      setCount(Number(appointmentRequests.count))
      setAppointmentRequests(appointmentRequests.rows as AppointmentRequestEntity[])
    })()
    return () => {
      dispatch({ type: DOCTOR_AVAILABILLITY_CREATE_RESET })
    }
  }, [page, sortObject, queryObject, savedLng, limit])

  useEffect(() => {
    ;(async () => {
      const rows: AppointmentRequestListItem[] = []
      if (appointmentRequests) {
        for (const appointmentRequest of appointmentRequests) {
          const { companyLocation } = appointmentRequest
          const { company } = companyLocation
          const dates: string[] = []
          if (appointmentRequest.status === 'booked' || appointmentRequest.status === 'completed') {
            for (let i = 0; i < appointmentRequest.AppointmentRequestETGroup.length; i++) {
              const { DoctorAvailabillity } = appointmentRequest.AppointmentRequestETGroup[i]
              for (let j = 0; j < DoctorAvailabillity.length; j++) {
                const date = moment(DoctorAvailabillity[j].startDate).format('DD.MM.YYYY')
                const isExist = dates.find((item) => item === date)
                if (!isExist) dates.push(date)
              }
            }
          }
          rows.push({
            ...appointmentRequest,
            companyLocation: `${company.name} - ${companyLocation.name}`,
            createdAt: dateFormattingService.format(appointmentRequest.createdAt, 'DD.MM.YYYY'),
            contact: `${appointmentRequest.Contact.firstName} ${appointmentRequest.Contact.lastName}`,
            status:
              appointmentRequest.status === 'booked' || appointmentRequest.status === 'completed'
                ? dates.join(', ')
                : appointmentRequest.status
          })
        }
      }
      setTableRows(rows)
    })()
  }, [appointmentRequests])

  useEffect(() => {
    if (appointmentRequests && appointmentRequests.length === tableRows.length) {
      setFinishedLoading(true)
    }
  }, [appointmentRequests, tableRows])

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const onSortChange = (sortModel: GridSortModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    setSortObject(sortModel[0])
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='mt-8 flex flex-col relative'>
            {success && <SuccessAlert hide message={t('ALERTS.SUCCESS_TIMEFRAME')}></SuccessAlert>}
            <div style={{ width: '100%' }}>
              {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
                <ThemeProvider theme={setTheme(language)}>
                  <DataGrid
                    {...tableRows}
                    className='MuiDataGrid-cellContent-alignTop'
                    columns={columns}
                    rows={tableRows}
                    rowCount={count > 0 ? count : 0}
                    pagination
                    paginationMode='server'
                    sortingMode='server'
                    filterMode='server'
                    onSortModelChange={onSortChange}
                    onFilterModelChange={onFilterChange}
                    loading={!finishedLoading}
                    paginationModel={{
                      page: Number(page),
                      pageSize: limit
                    }}
                    pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                    onPaginationModelChange={(params) => {
                      setPage(params.page)
                      setLimit(params.pageSize)
                    }}
                    slots={{ toolbar: Toolbar }}
                    localeText={{
                      toolbarFilters: t('GENERIC.FILTER')
                    }}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                      },
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          placeholder: t('GENERIC.SEARCH'),
                          inputProps: {
                            'aria-label': t('GENERIC.SEARCH')
                          },
                          debounceMs: 500
                        }
                      }
                    }}
                    sx={GridStyles}
                    columnVisibilityModel={{ leftCompanyDate: false }}
                    getRowHeight={() => 'auto'}
                    autoHeight={true}
                    rowSelection={false}
                    checkboxSelection={false}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick={true}
                  />
                </ThemeProvider>
              ) : (
                <EmptyState
                  titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                    type: t('EMPTY_STATE.APPOINTMENT_REQUESTS')
                  })}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
