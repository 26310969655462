import { RoleSearch } from 'src/interfaces/role'
import {
  ROLE_GET_ALL_FAIL,
  ROLE_GET_ALL_REQUEST,
  ROLE_GET_ALL_SUCCESS,
  ROLE_GET_BY_USER_FAIL,
  ROLE_GET_BY_USER_REQUEST,
  ROLE_GET_BY_USER_SUCCESS,
  ROLE_GET_ONE_FAIL,
  ROLE_GET_ONE_REQUEST,
  ROLE_GET_ONE_SUCCESS
} from 'src/redux/constants/roleConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const getAllRoles =
  ({ page = 0, limit = 10 }: RoleSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: ROLE_GET_ALL_REQUEST, payload: {} })
    api
      .get(`/api/admin/role?page=${page}&limit=${limit}`)
      .then((res) => {
        dispatch({
          type: ROLE_GET_ALL_SUCCESS,
          payload: { roles: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: ROLE_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getOneRole = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: ROLE_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/role/${id}`)
    .then((res) => {
      dispatch({ type: ROLE_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: ROLE_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getRoleByUserId = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: ROLE_GET_BY_USER_REQUEST, payload: {} })
  api
    .get(`/api/admin/role/user/${id}`)
    .then((res) => {
      dispatch({ type: ROLE_GET_BY_USER_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: ROLE_GET_BY_USER_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
