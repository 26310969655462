import { CreateDoctorAvailabillityAdminDto } from 'src/entities/interfaces'
import {
  DOCTOR_AVAILABILLITY_CREATE_FAIL,
  DOCTOR_AVAILABILLITY_CREATE_REQUEST,
  DOCTOR_AVAILABILLITY_CREATE_SUCCESS,
  DOCTOR_AVAILABILLITY_GET_ALL_FAIL,
  DOCTOR_AVAILABILLITY_GET_ALL_REQUEST,
  DOCTOR_AVAILABILLITY_GET_ALL_SUCCESS
} from 'src/redux/constants/doctorAvailabillityContants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const createOrUpdate =
  (inputs: CreateDoctorAvailabillityAdminDto[]) => (dispatch: AppDispatch) => {
    dispatch({ type: DOCTOR_AVAILABILLITY_CREATE_REQUEST, payload: {} })
    api
      .post('/api/admin/doctor-availability/mass', inputs)
      .then((res) => {
        dispatch({ type: DOCTOR_AVAILABILLITY_CREATE_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: DOCTOR_AVAILABILLITY_CREATE_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllForUser =
  ({ page = 0, limit = 10, id = '', month = '', appointmentId = '', day = '' }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/doctor-availability?page=${page}&limit=${limit}&id=${id}&month=${month}&appointmentId=${appointmentId}&day=${day}`
      )
      .then((res) => {
        dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: DOCTOR_AVAILABILLITY_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllOtherTimeslots =
  ({ page = 0, limit = 10, id = '', month = '', appointmentId = '', day = '' }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/doctor-availability/other-timeslots?page=${page}&limit=${limit}&id=${id}&month=${month}&appointmentId=${appointmentId}&day=${day}`
      )
      .then((res) => {
        dispatch({ type: DOCTOR_AVAILABILLITY_GET_ALL_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: DOCTOR_AVAILABILLITY_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
