import { CheckCircleIcon, FolderArrowDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import dateFormattingService from 'src/services/dateFormatting.service'

export const ExaminationHistoryElement = ({
  examination,
  downloadCertificate
}: {
  examination: any
  downloadCertificate: (examinationId: string) => void
}) => {
  const { t } = useTranslation()

  // const checkRequiredLevel = (level: number) => {
  //   switch (level) {
  //     case 1:
  //       return t('EXAMINATION_HISTORY.RECOMMENDED_EXAMINATIONS')
  //     case 2:
  //       return t('EXAMINATION_HISTORY.MANDATORY_EXAMINATIONS')
  //     default:
  //       break
  //   }
  // }

  return (
    <div className='grid md:grid-cols-8 items-center' key={examination.id}>
      <p className='customParagraph pt-[9px] pr-2 col-span-4'>
        {examination?.type?.name}
        {/* {examination?.type?.name} - {checkRequiredLevel(examination?.examinationRequired)} */}
      </p>
      {examination?.status === 'Not performed' ? (
        <p className='customParagraph pt-[9px] col-span-2 !text-red-700'>
          {' '}
          {t('EXAMINATION_HISTORY.NOT_PERFORMED')}
        </p>
      ) : (
        <p className='customParagraph pt-[9px] col-span-2'>{examination?.examinationCheckup}</p>
      )}
      <div className='col-span-2 grid items-center pt-[9px]'>
        <div className='col-span-4 flex gap-3 justify-end'>
          {examination?.performedDate && <CheckCircleIcon className='w-5 text-green-500' />}
          {examination?.nextExaminationDate && (
            <p className='customParagraph col-span-4'>
              {t('EXAMINATION_HISTORY.NEXT_DUE')}{' '}
              {dateFormattingService.format(examination?.nextExaminationDate, 'MMMM YYYY')}
            </p>
          )}
          {examination?.employerCertificate && (
            <FolderArrowDownIcon
              className='w-5 hover:cursor-pointer'
              title={t('EXAMINATION_HISTORY.DOWNLOAD_CERTIFICATE')}
              color='gray'
              onClick={() => downloadCertificate(examination?.id)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
