import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const { t } = useTranslation()
  const links = [
    {
      to: 'https://www.betriebsarztservice.de/impressum/',
      text: t('FOOTER.IMPRESSUM')
    },
    {
      to: 'https://www.betriebsarztservice.de/datenschutzerklaerung/',
      text: t('FOOTER.DATASCHUTZ')
    },
    {
      to: 'https://www.betriebsarztservice.de/agb/',
      text: t('FOOTER.AGB')
    }
  ]

  return (
    <div className='bg-brand-footer-color w-full py-3'>
      <div className='mx-auto max-w-7xl text-sm md:text-base px-4 sm:px-6 lg:px-10 py-3'>
        <div className='flex h-full items-start justify-between'>
          <h1>Copyright © 2024 Betriebsarztservice Holding GmbH</h1>
          <div className='flex flex-col md:flex-row'>
            {links.map((link: any, index: number) => (
              <Link
                key={index}
                to={link.to}
                target='_blank'
                rel='noopener noreferrer'
                className='font-semibold '
              >
                <div className='flex'>
                  <p className='font-semibold'>{link.text}</p>
                  {index !== links.length - 1 ? (
                    <span className='hidden md:block font-semibold px-3 text-brand-orange-700'>
                      /
                    </span>
                  ) : null}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
