import { useNavigate } from 'react-router-dom'

export const ReportCard = ({ number, topText, bottomText, link }: ReportCardInterface) => {
  const navigate = useNavigate()

  const onNavigate = () => {
    if (link) {
      navigate(`/${link}`)
    }
  }
  return (
    <div
      onClick={() => onNavigate()}
      className={`card ${link ? 'hover:bg-brand-blue-300 cursor-pointer' : ''}`}
    >
      <div className='flex items-center gap-2'>
        <p className='number'>{number > 99 ? `${number}+` : number}</p>
        <div className='mt-[3px]'>
          <p className='topText'>{topText}</p>
          <p className='bottomText'>{bottomText}</p>
        </div>
      </div>
    </div>
  )
}

interface ReportCardInterface {
  number: number
  topText: string
  bottomText: string
  link: string | null
}
