import { PlusIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const EmptyState = ({
  buttonText,
  titleText,
  url,
  additionalText
}: {
  buttonText?: string
  titleText: string
  url?: string
  additionalText?: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [customText, setCustomText] = useState('')
  const [customButton, setCustomButton] = useState('')

  useEffect(() => {
    switch (true) {
      case location.pathname === '/clients':
        setCustomText(t('CUSTOMER_OVERVIEW.EMPTY_STATE_TEXT'))
        setCustomButton(t('CUSTOMER_OVERVIEW.NEW_CUSTOMER'))
        break
      default:
        setCustomText('')
        setCustomButton('')
        break
    }
  }, [location.pathname, t])
  return (
    <div className='text-center relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
      <svg
        className='mx-auto h-12 w-12 text-gray-400'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'
      >
        <path
          vectorEffect='non-scaling-stroke'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
        />
      </svg>
      <h3 className='mt-2 text-sm font-semibold text-gray-900'>
        {titleText} {additionalText}
      </h3>
      {url && url.length && (
        <>
          <p className='mt-1 text-sm text-gray-500'>
            {customText !== ''
              ? customText + ' ' + t('EMPTY_STATE.CUSTOMER_OVERVIEW')
              : t('EMPTY_STATE.TEXT')}
            .
          </p>
          <div className='mt-3'>
            <button
              onClick={() => navigate(url)}
              type='button'
              className=' main-btn inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              <PlusIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
              {/* {t('GENERIC.NEW')} {buttonText} */}
              {customButton || buttonText}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
