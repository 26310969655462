import axios from 'axios'
import mem from 'mem'

const DATA_KEY = 'S_DATA'

export const create = ({
  accessToken,
  refreshToken
}: {
  accessToken: string
  refreshToken: string
}) => {
  const userData = JSON.stringify({ accessToken, refreshToken })
  localStorage.setItem(DATA_KEY, userData)
}

export const update = (accessToken: string) => {
  const { refreshToken } = JSON.parse(localStorage.getItem(DATA_KEY) as string)
  const userData = JSON.stringify({ accessToken, refreshToken })
  localStorage.setItem(DATA_KEY, userData)
}

export const getAccessToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem(DATA_KEY) as string)
    return userData.accessToken
  } catch (error) {
    return null
  }
}

export const remove = () => localStorage.removeItem(DATA_KEY)

export const getRefreshToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem(DATA_KEY) as string)
    return userData.refreshToken
  } catch (ex) {
    return null
  }
}

const refreshToken = async (): Promise<{ accessToken?: string; error?: string }> => {
  try {
    const refreshToken = getRefreshToken()
    const response = await axios.get('/api/admin/auth/refresh', {
      headers: {
        Authorization: 'Bearer ' + refreshToken
      }
    })
    const { accessToken } = response.data
    update(accessToken)
    const newToken = {
      accessToken
    }
    return newToken
  } catch (error) {
    const newToken = {
      error
    }
    return newToken
  }
}

const maxAge = 10000

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge
})
