import { CompanyLocationCostumerOverviewSearch } from 'src/interfaces/companyLocations'
import {
  COMPANY_LOCATION_BY_ID_FAIL,
  COMPANY_LOCATION_BY_ID_REQUEST,
  COMPANY_LOCATION_BY_ID_SUCCESS,
  COMPANY_LOCATION_BY_RELATION_FAIL,
  COMPANY_LOCATION_BY_RELATION_REQUEST,
  COMPANY_LOCATION_BY_RELATION_SUCCESS,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS
} from 'src/redux/constants/companyLocationsConstants'
import { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const getLocationByPractice = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_LOCATION_BY_RELATION_REQUEST, payload: {} })
  api
    .get(`/api/admin/company-location/practice/${id}`)
    .then((res) => {
      dispatch({
        type: COMPANY_LOCATION_BY_RELATION_SUCCESS,
        payload: { companyLocactions: res.data, count: Number(res.headers['x-count']) }
      })
    })
    .catch((error) => {
      dispatch({ type: COMPANY_LOCATION_BY_RELATION_FAIL, payload: {} })
      console.log('Error fetching location by practice:', error)
    })
}

export const getCompanyLocationByID = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: COMPANY_LOCATION_BY_ID_REQUEST, payload: {} })
  api
    .get(`/api/admin/company-location/${id}`)
    .then((res) => {
      dispatch({ type: COMPANY_LOCATION_BY_ID_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: COMPANY_LOCATION_BY_ID_FAIL, payload: {} })
      console.log('Error fetching location by practice:', error)
    })
}

export const getCompanyLocationsCustomerOverview =
  ({
    page = 0,
    limit = 10,
    id = '',
    sort = '',
    order = '',
    companyNameFilter,
    companyNameFilterType,
    quickFilter = ''
  }: CompanyLocationCostumerOverviewSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST, payload: {} })
    api
      .get(`/api/admin/company-location/customerOverview/${id}`, {
        params: {
          page,
          limit,
          order,
          sort,
          companyNameFilter,
          companyNameFilterType,
          quickFilter
        }
      })
      .then((res) => {
        dispatch({
          type: COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS,
          payload: { companyLocactions: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        dispatch({ type: COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL, payload: {} })
        console.log('Error fetching location by practice:', error)
      })
  }
