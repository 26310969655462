import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'src/redux/hook'
import { checkRole } from 'src/services/role.service'

export const RoleLayout = ({ authorizedRoles }: { authorizedRoles: any }) => {
  const { rolesGetByUser } = useAppSelector((state) => state)
  const { role } = rolesGetByUser
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (role) {
      if (!checkRole(authorizedRoles, role)) {
        const isAdmin = role.find((r: string) => r === 'admin' || r === 'superadmin')
        if (isAdmin) {
          navigate('/practices')
        } else {
          navigate('/')
        }
      }
    }
  }, [role, location])

  return (
    <div>
      <Outlet />
    </div>
  )
}

RoleLayout.propTypes = {
  authorizedRoles: PropTypes.array
}
