import { omit } from 'lodash'
import {
  PreventiveLogCostumersErrors,
  PreventiveLogCustomersInputs
} from 'src/interfaces/preventiveLogCostumers'
import i18n from '../language.service'
import { emailValidation, isRequired } from './index'

export const validation = (inputs: PreventiveLogCustomersInputs) => {
  let errors: PreventiveLogCostumersErrors = {}
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'email':
        errors[key] =
          isRequired(value, `${i18n.t('COMPANY.CONTACT_EMAIL')} ${required}`) ||
          emailValidation(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'companyLocation':
        errors[key] = isRequired(value?.value, `${i18n.t('COMPANY.CUSTOMER')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'additionalEmails':
        errors[key] = []
        for (let i = 0; i < value.length; i++) {
          const message = emailValidation(value[i].email)
          if (message) {
            errors[key]?.push({ id: value[i].id, message: message })
          } else {
            errors[key]?.filter((error) => error.id !== value[i].id)
          }
        }
        if (errors[key]?.length === 0) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: PreventiveLogCustomersInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if (key === 'additionalEmails' && input) {
      for (const email of value) {
        isDisabled = email.email === '' ? isDisabled + 1 : isDisabled
      }
    } else if (key === 'companyLocation' && ((input && value?.value === '') || (input && !value))) {
      isDisabled += 1
    } else if (input && isRequired(value)) {
      isDisabled += 1
    }
  }
  return isDisabled
}
