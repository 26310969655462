import { AnyAction } from 'redux'
import {
  COMPANY_LOCATION_BY_ID_FAIL,
  COMPANY_LOCATION_BY_ID_REQUEST,
  COMPANY_LOCATION_BY_ID_SUCCESS,
  COMPANY_LOCATION_BY_RELATION_FAIL,
  COMPANY_LOCATION_BY_RELATION_REQUEST,
  COMPANY_LOCATION_BY_RELATION_SUCCESS,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST,
  COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS
} from 'src/redux/constants/companyLocationsConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const companyLocationByRelationReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_LOCATION_BY_RELATION_REQUEST:
      return { loading: true }
    case COMPANY_LOCATION_BY_RELATION_SUCCESS:
      return {
        loading: false,
        companyLocations: action.payload.companyLocactions,
        count: action.payload.count
      }
    case COMPANY_LOCATION_BY_RELATION_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const companyLocationByIDReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_LOCATION_BY_ID_REQUEST:
      return { loading: true }
    case COMPANY_LOCATION_BY_ID_SUCCESS:
      return { loading: false, companyLocation: action.payload }
    case COMPANY_LOCATION_BY_ID_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_LOCATION_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    default:
      return state
  }
}

const companyLocationCostumerOverviewGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_REQUEST:
      return { loading: true }
    case COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_SUCCESS:
      return {
        loading: false,
        companyLocations: action.payload.companyLocactions,
        count: action.payload.count
      }
    case COMPANY_LOCATION_GET_ALL_COSTUMER_OVERVIEW_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

export const companyLocationReducers = {
  companyLocationByRelation: companyLocationByRelationReducer,
  companyLocationByID: companyLocationByIDReducer,
  companyLocationCostumerOverviewGetAll: companyLocationCostumerOverviewGetAllReducer
}
