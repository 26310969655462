import { getGridStringOperators, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { RiskExposureGroupEntity } from 'src/entities/interfaces'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value)
)

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('RISK_EXPOSURE.RISK_EXPOSURE'),
    editable: false,
    sortable: false,
    filterOperators,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <Link to={`/risk-exposures/edit/${params.id}`}>{params.formattedValue}</Link>
    }
  },
  {
    field: 'riskExposureGroups',
    headerName: i18n.t('RISK_EXPOSURE.RISK_EXPOSURE_GROUPS'),
    editable: false,
    sortable: false,
    filterable: true,
    filterOperators,
    flex: 1,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className='flex flex-wrap gap-1 my-1'>
          {params.formattedValue &&
            params.formattedValue.length > 0 &&
            params.formattedValue.map((area: RiskExposureGroupEntity, index: number) => (
              <div title={area?.name} className='customTableChip' key={index}>
                {area?.name?.length > 50 ? area?.name.slice(0, 50) + '...' : area?.name}
              </div>
            ))}
        </div>
      )
    }
  },
  {
    field: 'actions',
    filterable: false,
    headerName: '',
    editable: false,
    sortable: false,
    flex: 0.5,
    disableColumnMenu: true,
    align: 'right',
    getApplyQuickFilterFn: undefined
  }
]
