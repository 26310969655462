import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { APPOINTMENT_EXAMINATION_RECORD_GET_RESET } from 'src/redux/constants/appointmentConstants'
import {
  EXAMINATION_HISTORY_GET_RESET,
  EXAMINATION_RECORD_MASS_MARK_RESET
} from 'src/redux/constants/examinationConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationHistory, ExaminationsListEdit, InfoBoxEdit } from './components'

export const ExaminationsRecordManualEdit = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [companyLocationId, setCompanyLocationId] = useState<string>('')
  const [companyId, setCompanyId] = useState<string>('')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { appointmentExaminationRecordGet, examinationRecordMassMark } = useAppSelector(
    (state) => state
  )
  const { loading: examinationsRecordLoading, error: examinationsRecordError } =
    appointmentExaminationRecordGet
  const {
    loading: massMarkLoading,
    error: massMarkError,
    success: massMarkSuccess
  } = examinationRecordMassMark

  useEffect(() => {
    setIsFirstLoad(false)
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_RESET })
    dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_RESET })
    dispatch({ type: EXAMINATION_HISTORY_GET_RESET })
  }, [dispatch])

  useEffect(() => {
    if (examinationsRecordError && !isFirstLoad) {
      dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [dispatch, examinationsRecordError])

  return (
    <>
      {massMarkLoading || examinationsRecordLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='relative'>
          {massMarkSuccess && <SuccessAlert message={t('ALERTS.SUCCESS_EDIT')} hide />}
          {massMarkError && <ErrorAlert message={massMarkError} hide />}
          <InfoBoxEdit setCompanyLocationId={setCompanyLocationId} setCompanyId={setCompanyId} />
          <ExaminationsListEdit companyLocationId={companyLocationId} companyId={companyId} />
          <ExaminationHistory />
        </div>
      )}
    </>
  )
}
