export const ROLE_GET_ALL_REQUEST = 'ROLE_GET_ALL_REQUEST'
export const ROLE_GET_ALL_SUCCESS = 'ROLE_GET_ALL_SUCCESS'
export const ROLE_GET_ALL_FAIL = 'ROLE_GET_ALL_FAIL'

export const ROLE_GET_ONE_REQUEST = 'ROLE_GET_ONE_REQUEST'
export const ROLE_GET_ONE_SUCCESS = 'ROLE_GET_ONE_SUCCESS'
export const ROLE_GET_ONE_FAIL = 'ROLE_GET_ONE_FAIL'
export const ROLE_GET_ONE_RESET = 'ROLE_GET_ONE_RESET'

export const ROLE_GET_BY_USER_REQUEST = 'ROLE_GET_BY_USER_REQUEST'
export const ROLE_GET_BY_USER_SUCCESS = 'ROLE_GET_BY_USER_SUCCESS'
export const ROLE_GET_BY_USER_FAIL = 'ROLE_GET_BY_USER_FAIL'
