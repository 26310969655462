import { useTranslation } from 'react-i18next'
import { getTotalUsers } from '../helpers/internalFunctions'

export const InfoBox = ({
  clientContact,
  appointmentRequestEtGroups,
  companyAddress
}: {
  companyAddress: any
  clientContact: any
  appointmentRequestEtGroups: any
}) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col customInfoBox'>
        <p>
          <span>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.CLIENT_CONTACT')}: </span>
          {clientContact.name} / {clientContact.email} {clientContact.phone}{' '}
        </p>
        <p>
          <span>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.ADDRESS')}: </span>
          {companyAddress}
        </p>
        <p>
          <span>{getTotalUsers(appointmentRequestEtGroups)}</span>{' '}
          <span>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.NUMBER_OF_EMPLOYEES_TEXT')}</span>
        </p>
      </div>
      <div className='flex flex-col gap-2 mt-3'>
        <p className='customParagraph'>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.INFO_MESSAGE')}</p>
        <p className='customParagraph'>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.INFO_MESSAGE_UNDER')}</p>
      </div>
    </div>
  )
}
