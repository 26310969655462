import { BasPracticeEntity, RoleEntity, UserEntity } from 'src/entities/interfaces'
import { api } from 'src/services/api.service'

export async function findUserByRoleConnection(id?: string): Promise<RoleEntity[]> {
  return api
    .get(`/api/admin/role/user/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function findUserByPracticeConnection(id?: string): Promise<BasPracticeEntity[]> {
  return api
    .get(`/api/admin/baspractice/user/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function findUserByLocation(companyLocationId?: string): Promise<UserEntity[]> {
  return api
    .get(`/api/admin/user?companyLocationId=${companyLocationId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function getAvailableUserName(
  email: string,
  firstName: string,
  lastName: string,
  userId: string | null
): Promise<string> {
  return api
    .get(`/api/admin/user/available-user-name/${email}/${firstName}/${lastName}`, {
      params: {
        userId
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function editConnection(
  id?: string,
  inputs?: { roleId: string[]; practiceId: string[] }
): Promise<UserEntity | undefined> {
  try {
    const res = await api.patch(`/api/admin/user/relation/${id}`, inputs)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export async function getUsersWithScheduledAppointments(employeeId: string): Promise<string> {
  return api
    .get(`/api/admin/appointment?employeeId=${employeeId}&status=Scheduled`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function getAllOverdueUsers(doctorId: string): Promise<string[]> {
  return api
    .get(`/api/admin/widgets/overdueUsers?doctorId=${doctorId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}

export async function getAllScheduledAppointments(doctorId: string): Promise<string[]> {
  return api
    .get(`/api/admin/widgets/scheduledAppointments?doctorId=${doctorId}`) // eslint-disable-line
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('err', error)
    })
}
