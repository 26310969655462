import { AnyAction } from 'redux'
import {
  APPOINTMENT_AGENDA_GET_FAIL,
  APPOINTMENT_AGENDA_GET_REQUEST,
  APPOINTMENT_AGENDA_GET_RESET,
  APPOINTMENT_AGENDA_GET_SUCCESS,
  APPOINTMENT_EXAMINATION_RECORD_GET_FAIL,
  APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST,
  APPOINTMENT_EXAMINATION_RECORD_GET_RESET,
  APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS
} from 'src/redux/constants/appointmentConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const appointmentExaminationRecordGetReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST:
      return { loading: true }
    case APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS:
      return { loading: false, appointment: action.payload }
    case APPOINTMENT_EXAMINATION_RECORD_GET_FAIL:
      switch (action.payload.message) {
        case 'APPOINTMENT_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case APPOINTMENT_EXAMINATION_RECORD_GET_RESET:
      return {}
    default:
      return state
  }
}

const appointmentAgendaGetReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_AGENDA_GET_REQUEST:
      return { loading: true }
    case APPOINTMENT_AGENDA_GET_SUCCESS:
      return { loading: false, appointments: action.payload }
    case APPOINTMENT_AGENDA_GET_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case APPOINTMENT_AGENDA_GET_RESET:
      return {}
    default:
      return state
  }
}

export const appointmentReducers = {
  appointmentExaminationRecordGet: appointmentExaminationRecordGetReducer,
  appointmentAgendaGet: appointmentAgendaGetReducer
}
