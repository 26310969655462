import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/redux/hook'
import { groupingExaminations } from '../helper/internalFunctions'
import { Actions, ExaminationItem } from './index'

export function ExaminationsList() {
  const [examinations, setExaminations] = useState([])
  const { appointmentExaminationRecordGet } = useAppSelector((state) => state)
  const { appointment } = appointmentExaminationRecordGet
  const { t } = useTranslation()
  const [certificate, setCertificate] = useState({ name: '' })
  const [presentExaminations, setPresentExaminations] = useState({
    recommended: false,
    mandatory: false
  })

  useEffect(() => {
    if (appointment) {
      const {
        AppointmentRequestETGroup: { AppointmentRequestEmployeeExaminationTypes },
        examination
      } = appointment

      setExaminations(groupingExaminations(AppointmentRequestEmployeeExaminationTypes, examination))
      examination.forEach((ex: { employerCertificate: any }) => {
        if (ex.employerCertificate) {
          const employeerCertificate = {
            name: ex.employerCertificate
          }
          setCertificate(employeerCertificate)
        }
      })
    }
  }, [appointment])

  useEffect(() => {
    examinations.map((examination: any) => {
      if (examination.examinationRequired === 1) {
        setPresentExaminations((prev) => ({ ...prev, recommended: true }))
      } else if (examination.examinationRequired === 2) {
        setPresentExaminations((prev) => ({ ...prev, mandatory: true }))
      }
    })
  }, [examinations])

  const setVal = (id: string, value: any, name: string) => {
    const newExaminations: any = examinations.map((examination: any) => {
      if (examination.id === id) {
        const performedDate = examination.performedDate ? examination.performedDate : moment()
        switch (name) {
          case 'status':
            if (value) {
              examination.status = 'Completed'
            } else {
              examination.status = 'Not performed'
            }
            break
          case 'performedDate':
            examination.performedDate = value
            break
          case 'examinationCheckup':
            examination.examinationCheckup = value.value
            examination.examinationCheckupOption = value
            if (value.value === 'First examination') {
              examination.nextExaminationDate = moment(performedDate).add(12, 'M')
            } else if (value.value === 'Second examination') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            } else if (value.value === 'Follow up') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            }
            break
          case 'nextExaminationDate':
            examination.nextExaminationDate = value
            break
          case 'examinationNote':
            examination.examinationNote = value
            break
        }
      }
      return examination
    })
    setExaminations(newExaminations)
  }

  return (
    <div className='mt-6'>
      {presentExaminations.mandatory && (
        <>
          <h3 className='customHead3'>{t('EXAMINATIONS_RECORD.MANDATORY_EXAMINATIONS')}</h3>
          {examinations.map((examination) => {
            const {
              id,
              status,
              performedDate,
              examinationCheckupOption,
              nextExaminationDate,
              employerCertificate,
              examinationName,
              examinationRequired
            } = examination
            if (examinationRequired === 2) {
              return (
                <ExaminationItem
                  key={id}
                  id={id}
                  status={status}
                  performedDate={performedDate}
                  examinationCheckupOption={examinationCheckupOption}
                  nextExaminationDate={nextExaminationDate}
                  employerCertificate={employerCertificate}
                  examinationName={examinationName}
                  setVal={setVal}
                />
              )
            }
          })}
        </>
      )}
      {presentExaminations.recommended && (
        <div className='mt-6'>
          <h3 className='customHead3'>{t('EXAMINATIONS_RECORD.RECOMMENDED_EXAMINATIONS')}</h3>
          {examinations.map((examination) => {
            const {
              id,
              status,
              performedDate,
              examinationCheckupOption,
              nextExaminationDate,
              examinationNote,
              employerCertificate,
              examinationName,
              examinationRequired,
              employeeOptedIn
            } = examination
            if (examinationRequired === 1) {
              return (
                <ExaminationItem
                  key={id}
                  id={id}
                  status={status}
                  employeeOptedOut={employeeOptedIn ? '' : ` (${t('EXAMINATIONS.OPT_OUT')})`}
                  performedDate={performedDate}
                  examinationCheckupOption={examinationCheckupOption}
                  nextExaminationDate={nextExaminationDate}
                  examinationNote={examinationNote}
                  employerCertificate={employerCertificate}
                  examinationName={examinationName}
                  setVal={setVal}
                />
              )
            }
          })}
        </div>
      )}
      <Actions certificate={certificate} examinations={examinations} />
    </div>
  )
}
