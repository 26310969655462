import {
  WIDGET_LOCATION_USER_NUMBER_GET_FAIL,
  WIDGET_LOCATION_USER_NUMBER_GET_REQUEST,
  WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS,
  WIDGET_SEND_FEEDBACK_FAIL,
  WIDGET_SEND_FEEDBACK_REQUEST,
  WIDGET_SEND_FEEDBACK_SUCCESS,
  WIDGET_UPCOMING_AR_GET_FAIL,
  WIDGET_UPCOMING_AR_GET_REQUEST,
  WIDGET_UPCOMING_AR_GET_SUCCESS
} from 'src/redux/constants/widgetConstants'
import { api } from 'src/services/api.service'

import { AppDispatch } from 'src/redux/store'

export const getUpcomingAppointmentRequests = (doctorId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: WIDGET_UPCOMING_AR_GET_REQUEST, payload: {} })
  api
    .get(`/api/admin/widgets/upcoming-appointment-requests/${doctorId}`)
    .then((res) => {
      dispatch({
        type: WIDGET_UPCOMING_AR_GET_SUCCESS,
        payload: { appointmentRequests: res.data, count: Number(res.headers['x-count']) }
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: WIDGET_UPCOMING_AR_GET_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getNumberOfLocationsEmployees = (doctorId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: WIDGET_LOCATION_USER_NUMBER_GET_REQUEST, payload: {} })
  api
    .get(`/api/admin/widgets/locations-employees-number/${doctorId}`)
    .then((res) => {
      dispatch({
        type: WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS,
        payload: res.data
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: WIDGET_LOCATION_USER_NUMBER_GET_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const sendFeedback = (feedback: any, files: any) => (dispatch: AppDispatch) => {
  dispatch({ type: WIDGET_SEND_FEEDBACK_REQUEST, payload: {} })

  const formData = new FormData()
  formData.append('data', JSON.stringify(feedback))
  files.forEach((file: any) => {
    formData.append('files', file)
  })

  api
    .post('/api/admin/widgets/send-feedback', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      dispatch({
        type: WIDGET_SEND_FEEDBACK_SUCCESS,
        payload: res.data
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: WIDGET_SEND_FEEDBACK_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
