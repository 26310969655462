import moment from 'moment'

export const groupingExaminations = function (
  scheduledExaminations: any,
  userId: string,
  companyId: string,
  companyLocationId: string,
  listType?: string
) {
  const examinations: any = []
  for (let i = 0; i < scheduledExaminations.length; i++) {
    const {
      id,
      status,
      performedDate,
      examinationCheckup,
      nextExaminationDate,
      examinationNote,
      employerCertificate,
      employeeOptedIn,
      examinationType,
      examinationRequired,
      type
    } = scheduledExaminations[i]
    examinations.push({
      id: listType === 'preventive' ? moment().valueOf().toString() + i : id,
      status: listType === 'preventive' ? 'Not performed' : status,
      performedDate: performedDate ? moment(performedDate) : new Date(),
      examinationCheckup,
      examinationCheckupOption: {
        value: examinationCheckup,
        label: examinationCheckup
      },
      nextExaminationDate: nextExaminationDate ? moment(nextExaminationDate) : null,
      examinationNote,
      employeeId: userId,
      companyId,
      companyLocationId,
      employerCertificate,
      examinationName: examinationType ? examinationType.name : type.name,
      examinationTypeId: examinationType ? examinationType.id : type.id,
      examinationRequired: examinationRequired,
      employeeOptedIn
    })
  }
  return examinations
}
