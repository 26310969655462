import { SignInDto } from 'src/entities/interfaces'
import { EditPasswordInputs, SignInInputs } from 'src/interfaces/auth'
import {
  USER_EDIT_PASSWORD_FAIL,
  USER_EDIT_PASSWORD_REQUEST,
  USER_EDIT_PASSWORD_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT_FAIL,
  USER_SIGNOUT_SUCCESS
} from 'src/redux/constants/authConstants'
import { GLOBAL_RESET } from 'src/redux/constants/globalConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'
import { create, remove } from 'src/services/token.service'

export const signin = (inputs: SignInInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: {} })
  api
    .post('/api/admin/auth/signin', { ...inputs } as SignInDto)
    .then((res) => {
      create(res.data.tokens)
      const userInfo = JSON.stringify(res.data.user)
      localStorage.setItem('USER_INFO', userInfo)
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: res.data.user })
    })
    .catch((error) => {
      dispatch({
        type: USER_SIGNIN_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const signout = () => (dispatch: AppDispatch) => {
  api
    .get('/api/admin/auth/logout')
    .then(() => {
      remove()
      localStorage.removeItem('USER_INFO')
      if (document.getElementById('atlwdg-trigger') !== null) {
        document.getElementById('atlwdg-trigger')?.remove()
      }
      dispatch({ type: GLOBAL_RESET, payload: true })
      dispatch({ type: USER_SIGNOUT_SUCCESS, payload: true })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_SIGNOUT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const editUserPassword = (inputs: EditPasswordInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: USER_EDIT_PASSWORD_REQUEST, payload: {} })
  // CHANGE edit password inputs u editpassworddto on line 44
  api
    .patch('/api/admin/auth/password', { ...inputs } as EditPasswordInputs)
    .then((res) => {
      dispatch({ type: USER_EDIT_PASSWORD_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_EDIT_PASSWORD_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
