import { AppointmentRequestEntity, CompanyEntity } from 'src/entities/interfaces'
import { AppointmentRequestSearch } from 'src/interfaces/appointmentRequest'
import { api } from 'src/services/api.service'

export const getAppointmentRequests = async ({
  page = 0,
  limit = 10,
  userId = '',
  sort = '',
  order = '',
  companyLocationFilter = '',
  companyLocationFilterType = '',
  contactFilter = '',
  contactFilterType = '',
  companyLocationId = '',
  quickFilter = ''
}: AppointmentRequestSearch) => {
  return api
    .get('/api/admin/appointment-request', {
      params: {
        page,
        limit,
        userId,
        sort,
        order,
        companyLocationFilter,
        companyLocationFilterType,
        contactFilter,
        contactFilterType,
        companyLocationId,
        quickFilter
      }
    })
    .then((res) => {
      return {
        rows: res.data as AppointmentRequestEntity[],
        count: res.headers['x-count'] as number
      }
    })
    .catch((error) => {
      console.log('Error fetching appointment requests:', error)
      return { rows: [] as AppointmentRequestEntity[], count: 0 }
    })
}

export async function getCompanyByCompanyLocation(id: string): Promise<CompanyEntity> {
  return api
    .get(`/api/admin/company/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getAppointemntRequestETGroups(appointmentRequestId: string) {
  return api
    .get('/api/admin/appointment-request-etgroup', {
      params: {
        appointmentRequestId
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getOneAppointmentRequestETGroup(id: string) {
  return api
    .get(`/api/admin/appointment-request-etgroup/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getCompanyLocationByAppointmentETGroupId(id: string) {
  return api
    .get(`/api/admin/appointment-request-etgroup/find-company/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}
