import { AnyAction } from 'redux'
import {
  EXAMINATION_REQUEST_GET_ONE_FAIL,
  EXAMINATION_REQUEST_GET_ONE_REQUEST,
  EXAMINATION_REQUEST_GET_ONE_RESET,
  EXAMINATION_REQUEST_GET_ONE_SUCCESS
} from 'src/redux/constants/examinationRequestConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const examinationRequestGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case EXAMINATION_REQUEST_GET_ONE_REQUEST:
      return { loading: true }
    case EXAMINATION_REQUEST_GET_ONE_SUCCESS:
      return { loading: false, examinationRequest: action.payload }
    case EXAMINATION_REQUEST_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case EXAMINATION_REQUEST_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

export const examinationRequestReducers = {
  examinationRequestGetOne: examinationRequestGetOneReducer
}
