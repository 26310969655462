import { createTheme } from '@mui/material/styles'
import { deDE, enUS } from '@mui/x-data-grid'

export const germanTheme = createTheme({}, deDE)

const englishTheme = createTheme({}, enUS)
export const setTheme = (lng: string) => {
  let theme
  if (lng === 'DE') {
    theme = germanTheme
  } else {
    theme = englishTheme
  }
  return theme
}
