import {
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import moment from 'moment'
import { Link } from 'react-router-dom'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

type Props = {
  value?: any
}
const operators = [
  ...getGridStringOperators(),
  {
    label: 'all',
    value: 'all',
    getApplyFilterFn: () => {
      return ({ value }: Props): any => {
        if (value) {
          return value
        }
      }
    },
    InputComponent: () => null
  },
  {
    label: 'overdue',
    value: 'overdue',
    getApplyFilterFn: () => {
      return ({ value }: Props): any => {
        if (value) {
          return value
        }
      }
    },
    InputComponent: () => null
  },
  {
    label: 'due soon',
    value: 'due soon',
    getApplyFilterFn: () => {
      return ({ value }: Props): any => {
        if (value) {
          return value
        }
      }
    },
    InputComponent: () => null
  },
  {
    label: 'scheduled',
    value: 'scheduled',
    getApplyFilterFn: () => {
      return ({ value }: Props): any => {
        if (value) {
          return value
        }
      }
    },
    InputComponent: () => null
  }
]

const filteredOperator = operators.filter(({ value }) =>
  ['all', 'overdue', 'due soon', 'scheduled' /* add more over time */].includes(value)
)

export const employeeColumns: GridColDef[] = [
  {
    field: 'fullName',
    headerName: i18n.t('USER_INFO.FULL_NAME'),
    editable: false,
    flex: 1,
    sortable: true,
    filterOperators,
    renderCell: (params: GridRenderCellParams) => {
      return <Link to={`/examinations-history/${params.id}`}>{params.formattedValue}</Link>
    }
  },
  {
    field: 'dateOfBirth',
    headerName: i18n.t('USER_INFO.AGE'),
    flex: 0.5,
    editable: false,
    sortable: true,
    filterable: false,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'gender',
    headerName: i18n.t('USER_INFO.GENDER'),
    flex: 0.5,
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'email',
    headerName: i18n.t('USER_INFO.EMAIL'),
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'department',
    headerName: i18n.t('USER_INFO.DEPARTMENT'),
    flex: 1,
    editable: false,
    sortable: false,
    filterOperators
  },
  {
    field: 'position',
    headerName: i18n.t('USER_INFO.POSITION'),
    flex: 1,
    editable: false,
    sortable: false,
    filterOperators,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'examinations',
    headerName: i18n.t('EXAMINATIONS.EXAMINATIONS'),
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className='flex flex-wrap gap-1 my-1'>
          {params.formattedValue &&
            params.formattedValue.length > 0 &&
            params.formattedValue.map((examination: any, index: number) => (
              <div title={examination} className='customTableChip' key={index}>
                {examination?.length > 20 ? examination?.slice(0, 16) + '...' : examination}
              </div>
            ))}
        </div>
      )
    },
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'nextExamination',
    headerName: i18n.t('EXAMINATIONS.NEXT_EXAMINATION'),
    flex: 1,
    editable: false,
    sortable: true,
    filterOperators: filteredOperator,
    valueFormatter: (params: GridValueFormatterParams) => {
      if (params.value.date) {
        const date = setDateText(params.value)
        return date
      }
      return params.value
    },
    renderCell: (params: GridRenderCellParams) => {
      const dateText = setDateText(params.value)
      const examinationStatus = params.value.date && checkDate(params.value.date)

      return (
        <div className='customScheduleWrapper'>
          <div className='customScheduleContainer'>
            <p className='' style={{ fontSize: '14px', lineHeight: '21px', color: '#585858' }}>
              {dateText}
            </p>
            {examinationStatus?.class && params.row.status === '' && (
              <p className={`status-chip ${examinationStatus.class}`}>{examinationStatus.text}</p>
            )}
            {params?.formattedValue?.scheduled && (
              <p className='status-chip scheduled mt-1'>{i18n.t('PREVENTIVE_LOG.SCHEDULED')}</p>
            )}
            {params.row.status == i18n.t('PREVENTIVE_LOG.REQUESTED') ||
              params.row.status == i18n.t('PREVENTIVE_LOG.PENDING') ||
              (params.row.status == i18n.t('PREVENTIVE_LOG.INVITED') && (
                <p className='status-chip'>{params.row.status}</p>
              ))}
          </div>
        </div>
      )
    },
    getApplyQuickFilterFn: undefined
  }
]

export const types = [
  {
    value: 0,
    name: 'Off'
  },
  {
    value: 1,
    name: 'Recommended'
  },
  {
    value: 2,
    name: 'Mandatory'
  }
]

const numDaysBetween = function (passedDate: Date, today: Date) {
  const diff = Math.abs(passedDate.getTime() - today.getTime())
  return diff / (1000 * 60 * 60 * 24)
}

const checkDate = (date: string) => {
  const today = new Date()
  const passedDate = new Date(date)
  if (passedDate.getTime() < today.getTime()) {
    return { class: 'overdue', text: i18n.t('PREVENTIVE_LOG.OVERDUE') }
  } else if (numDaysBetween(passedDate, today) <= 60) {
    return { class: 'due', text: i18n.t('PREVENTIVE_LOG.DUE_SOON') }
  } else {
    return {}
  }
}

const setDateText = (formattedValue: any) => {
  if (typeof formattedValue === 'string') {
    return formattedValue
  } else {
    return moment(formattedValue.date).format(formattedValue.format)
  }
}
