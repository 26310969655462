import {
  APPOINTMENT_AGENDA_GET_FAIL,
  APPOINTMENT_AGENDA_GET_REQUEST,
  APPOINTMENT_AGENDA_GET_SUCCESS,
  APPOINTMENT_EXAMINATION_RECORD_GET_FAIL,
  APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST,
  APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS
} from 'src/redux/constants/appointmentConstants'
import { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const getAppointmentExaminationRecord =
  (appointmentId: string, userId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_REQUEST, payload: {} })
    api
      .get(`/api/admin/appointment/get-appointment-examination-record/${appointmentId}/${userId}`)
      .then((res) => {
        dispatch({ type: APPOINTMENT_EXAMINATION_RECORD_GET_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: APPOINTMENT_EXAMINATION_RECORD_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAppointmentAgenda =
  (userId: string, dates: { start?: Date; end?: Date }) => (dispatch: AppDispatch) => {
    dispatch({ type: APPOINTMENT_AGENDA_GET_REQUEST, payload: {} })
    api
      .get(`/api/admin/appointment/agenda/${userId}`, {
        params: {
          startDate: dates.start,
          endDate: dates.end
        }
      })
      .then((res) => {
        dispatch({ type: APPOINTMENT_AGENDA_GET_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: APPOINTMENT_AGENDA_GET_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
