import { BasPracticeEntity, UserEntity } from 'src/entities/interfaces'
import { CompanyLocationEntity } from 'src/interfaces/companyLocationEntity'
import { CompanyInformation } from 'src/interfaces/users'
import { api } from 'src/services/api.service'

export async function getCompanyLocationsByCompanyId({
  id = '',
  limit = 100,
  page = 0
}): Promise<CompanyLocationEntity[]> {
  return api
    .get(`/api/admin/company-location?page=${page}&limit=${limit}&companyId=${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getBasPracticeByCompanyLocationId({
  id = '',
  limit = 30,
  page = 0
}): Promise<BasPracticeEntity[]> {
  return api
    .get(`/api/admin/baspractice?page=${page}&limit=${limit}&companyLocationId=${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function checkIfCompanyHasContact({
  companyId = '',
  companyLocationId = ''
}): Promise<UserEntity[]> {
  return api
    .get(
      `/api/admin/user?companyId=${companyId}&companyLocationId=${companyLocationId}&roleType=contact`
    )
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getCompanyAndCompanyLocationByUserID({
  userId = ''
}): Promise<CompanyInformation> {
  return api
    .get(`/api/admin/user/company-location/${userId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getAllDoctorsFromBasPractice({
  id = '',
  limit = 100,
  page = 0
}): Promise<UserEntity[]> {
  return api
    .get(`/api/admin/user?page=${page}&limit=${limit}&basPracticeId=${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}
