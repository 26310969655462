import { GridRowId } from '@mui/x-data-grid'
import {
  CreateRiskAreaAdminDto,
  RiskExposureGroupEntity,
  UpdateRiskAreaAdminDto
} from 'src/entities/interfaces'
import { RiskAreaInputs, RiskAreaSearch } from 'src/interfaces/riskAreas'
import {
  RISK_EXPOSURE_CREATE_FAIL,
  RISK_EXPOSURE_CREATE_REQUEST,
  RISK_EXPOSURE_CREATE_SUCCESS,
  RISK_EXPOSURE_DELETE_FAIL,
  RISK_EXPOSURE_DELETE_REQUEST,
  RISK_EXPOSURE_DELETE_SUCCESS,
  RISK_EXPOSURE_EDIT_FAIL,
  RISK_EXPOSURE_EDIT_REQUEST,
  RISK_EXPOSURE_EDIT_SUCCESS,
  RISK_EXPOSURE_GET_ALL_FAIL,
  RISK_EXPOSURE_GET_ALL_REQUEST,
  RISK_EXPOSURE_GET_ALL_SUCCESS,
  RISK_EXPOSURE_GET_ONE_FAIL,
  RISK_EXPOSURE_GET_ONE_REQUEST,
  RISK_EXPOSURE_GET_ONE_SUCCESS,
  RISK_EXPOSURE_GROUP_GET_ALL_FAIL,
  RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS
} from 'src/redux/constants/riskExposureConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const create = (inputs: RiskAreaInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: RISK_EXPOSURE_CREATE_REQUEST, payload: {} })
  api
    .post('/api/admin/risk-area', { ...inputs } as CreateRiskAreaAdminDto)
    .then((res) => {
      dispatch({ type: RISK_EXPOSURE_CREATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: RISK_EXPOSURE_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const edit = (inputs: RiskAreaInputs, id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: RISK_EXPOSURE_EDIT_REQUEST, payload: {} })

  api
    .patch(`/api/admin/risk-area/${id}`, { ...inputs } as UpdateRiskAreaAdminDto)
    .then((res) => {
      dispatch({ type: RISK_EXPOSURE_EDIT_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: RISK_EXPOSURE_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const remove = (id?: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: RISK_EXPOSURE_DELETE_REQUEST, payload: {} })
  api
    .delete(`/api/admin/risk-area/${id}`)
    .then((res) => {
      dispatch({ type: RISK_EXPOSURE_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: RISK_EXPOSURE_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getOne = (id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: RISK_EXPOSURE_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/risk-area/${id}`)
    .then((res) => {
      dispatch({ type: RISK_EXPOSURE_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: RISK_EXPOSURE_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAll =
  ({
    page = 0,
    limit = 10,
    query = '',
    riskAreaNameFilter = '',
    riskAreaNameFilterType = '',
    riskAreaExposureGroupFilter = '',
    riskAreaExposureGroupFilterType = '',
    riskExposureGroupId = '',
    quickFilter = ''
  }: RiskAreaSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: RISK_EXPOSURE_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/risk-area?page=${page}&limit=${limit}&query=${query}&riskAreaNameFilter=${riskAreaNameFilter}&riskAreaNameFilterType=${riskAreaNameFilterType}&riskAreaExposureGroupFilter=${riskAreaExposureGroupFilter}&riskAreaExposureGroupFilterType=${riskAreaExposureGroupFilterType}&riskExposureGroupId=${riskExposureGroupId}&quickFilter=${quickFilter}`
      )
      .then((res) => {
        dispatch({
          type: RISK_EXPOSURE_GET_ALL_SUCCESS,
          payload: { riskAreas: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: RISK_EXPOSURE_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllGroups =
  ({ page = 0, limit = 10 }) =>
  (dispatch: AppDispatch) => {
    api
      .get(`/api/admin/risk-exposure-group?page=${page}&limit=${limit}`)
      .then((res) => {
        const selectOptions = res.data.map((group: RiskExposureGroupEntity) => {
          return { value: group.id, label: group.name }
        })
        dispatch({
          type: RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS,
          payload: selectOptions,
          count: Number(res.headers['x-count'])
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: RISK_EXPOSURE_GROUP_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
