import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { MultiSelectLabelLeft, TextAreaLabelLeft } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { RiskAreaErrors, RiskAreaInputs, RiskAreaOption } from 'src/interfaces/riskAreas'
import { RiskExposureGroupOption } from 'src/interfaces/riskExposureGroups'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { create, edit, getAllGroups, getOne } from 'src/redux/actions/riskExposureAction'
import { RISK_EXPOSURE_GET_ONE_RESET } from 'src/redux/constants/riskExposureConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { formsChangesListener } from 'src/services/formsChangesListener.service'
import { checkRequiredFields, validation } from 'src/services/validation/riskExposureForm'
import { getExaminationGroupByRiskId, setRiskAreaRelation } from './helper/externalFunctions'

export const RiskExposureForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [submitted, setSubmitted] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isCancelModalDisabled, setIsCancelModalDisabled] = useState(true)
  const [riskExposureId, setRiskExposureId] = useState<RiskExposureGroupOption[]>([])
  const [inputs, setInputs] = useState<RiskAreaInputs>({
    name: '',
    riskExposureGroupId: []
  })
  const [oldInputs, setOldInputs] = useState<RiskAreaInputs>({
    name: '',
    riskExposureGroupId: []
  })
  let errors: RiskAreaErrors = {
    riskExposureGroupId: ''
  }
  const { id: riskAreaId } = useParams()
  const { riskExposureCreate, riskExposureGetOne, riskExposureEdit, riskExposureGroupGetAll } =
    useAppSelector((state) => state)
  const { riskArea: riskExposureGroup } = riskExposureGroupGetAll
  const {
    loading: editRiskAreaLoading,
    success: editRiskAreaSuccess,
    error: editRiskAreaError
  } = riskExposureEdit
  const { loading: getRiskAreaLoading, riskArea, error: getRiskAreaError } = riskExposureGetOne
  const {
    loading: createRiskAreaLoading,
    success: createRiskAreaSuccess,
    error: createRiskAreaError,
    riskArea: riskAreaCreated
  } = riskExposureCreate

  useEffect(() => {
    setInitialLoad(false)
    if (location.pathname.includes('edit')) {
      dispatch(getOne(riskAreaId))
    }
    dispatch(getAllGroups({ page: 0, limit: 20 }))
  }, [])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if ((createRiskAreaSuccess && !initialLoad) || (editRiskAreaSuccess && !initialLoad)) {
      setDisplaySuccessButton(true)
      setTimeout(() => {
        navigate('/risk-exposures')
      }, 100)
    } else if (createRiskAreaError || editRiskAreaError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [createRiskAreaSuccess, createRiskAreaError, editRiskAreaSuccess, editRiskAreaError])

  useEffect(() => {
    ;(async () => {
      if (riskArea) {
        const riskExposure = await getExaminationGroupByRiskId(riskArea.id)
        setInputs({
          name: riskArea.name,
          riskExposureGroupId: riskExposure
        })
        setOldInputs({
          name: riskArea.name,
          riskExposureGroupId: riskExposure
        })
      }
    })()
    if (getRiskAreaError && !initialLoad) {
      dispatch({ type: RISK_EXPOSURE_GET_ONE_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [riskArea, getRiskAreaError])

  useEffect(() => {
    if ((submitted && riskAreaCreated) || (submitted && editRiskAreaSuccess)) {
      ;(async () => {
        const riskExposureHelper: string[] = []
        riskExposureId.forEach((id: RiskAreaOption) => {
          riskExposureHelper.push(id.value)
        })
        const data: { riskAreaId: string; riskExposureGroupId: string[] } = {
          riskAreaId: riskAreaId ? riskAreaId : riskAreaCreated.id,
          riskExposureGroupId: riskExposureHelper
        }
        await setRiskAreaRelation(data)
      })()
    }
  }, [submitted, riskAreaCreated, editRiskAreaSuccess])

  useEffect(() => {
    const isDisabledRequired = checkRequiredFields(inputs, ['name', 'riskExposureGroupId'])
    let isDisabledChanges = false
    if (riskArea) {
      isDisabledChanges = formsChangesListener({
        inputs,
        oldInputs,
        multiSelectInputs: ['riskExposureGroupId']
      })
    }
    if (!isDisabledChanges && riskArea) {
      setIsCancelModalDisabled(true)
    } else {
      setIsCancelModalDisabled(false)
    }
    if (isDisabledRequired > 0 || (!isDisabledChanges && riskArea)) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs, riskArea])

  const setVal = (value: any, name: string) => {
    setInputs({ ...inputs, [name]: value })
  }

  const onSubmit = (action: string) => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      setRiskExposureId(inputs.riskExposureGroupId)
      if (action === 'edit') {
        dispatch(edit(inputs, riskAreaId as string))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else {
        dispatch(create(inputs))
      }
    }
  }

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <>
      {getRiskAreaLoading || editRiskAreaLoading || createRiskAreaLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(editRiskAreaError || createRiskAreaError) && (
            <ErrorAlert message={editRiskAreaError || createRiskAreaError} hide />
          )}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/risk-exposures')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                const action = riskArea ? 'edit' : 'create'
                onSubmit(action)
                setIsSaveModal(false)
              }}
              message={`${t('GENERIC.CONFIRM_CHOICE')} ${
                riskArea ? t('GENERIC.UPDATE').toLowerCase() : t('GENERIC.CREATE').toLowerCase()
              } ${t('RISK_EXPOSURE.RISK_EXPOSURE').toLowerCase()}?`}
            />
          )}
          <div className='space-y-6'>
            <TextAreaLabelLeft
              value={inputs['name']}
              type='text'
              name='name'
              required
              onChange={setVal}
              label={t('RISK_EXPOSURE.TITLE')}
              error={errors['name']}
            />
            <MultiSelectLabelLeft
              label={t('RISK_EXPOSURE.RISK_EXPOSURE_GROUPS')}
              name='riskExposureGroupId'
              options={riskExposureGroup}
              error={errors['riskExposureGroupId']}
              value={inputs['riskExposureGroupId']}
              onChange={setVal}
              required
            />
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (editRiskAreaLoading || createRiskAreaLoading || displaySuccessButton) &&
                'processing'
              }`}
              disabled={
                isSaveDisabled ||
                editRiskAreaLoading ||
                createRiskAreaLoading ||
                displaySuccessButton
              }
              variant='contained'
            >
              {editRiskAreaLoading || createRiskAreaLoading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              className='secondary-btn'
              variant='contained'
              onClick={() => {
                if (isCancelModalDisabled) {
                  navigate('/risk-exposures')
                } else {
                  setIsCancelModal(true)
                }
              }}
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
