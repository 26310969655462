import { LocationElement } from 'src/interfaces/companies'
import { formsChangesListener } from 'src/services/formsChangesListener.service'

export const compareLocattions = (
  locations: LocationElement[],
  oldLocations: LocationElement[]
) => {
  let objectsAreSame = false
  for (const location of locations) {
    const element = oldLocations.find((oldLocation) => location.id === oldLocation.id)
    if (!element) {
      objectsAreSame = true
      break
    } else {
      objectsAreSame = formsChangesListener({
        inputs: location,
        oldInputs: element,
        singleSelectInputs: ['associatedPractice'],
        ignoreInputs: ['createdAt', 'updatedAt']
      })
        ? true
        : objectsAreSame
    }
  }
  for (const oldLocation of oldLocations) {
    const element = locations.find((location) => oldLocation.id === location.id)
    if (!element) {
      objectsAreSame = true
      break
    } else {
      objectsAreSame = formsChangesListener({
        inputs: element,
        oldInputs: oldLocation,
        singleSelectInputs: ['associatedPractice'],
        ignoreInputs: ['createdAt', 'updatedAt']
      })
        ? true
        : objectsAreSame
    }
  }
  return objectsAreSame
}
