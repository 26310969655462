import { GridRowId } from '@mui/x-data-grid'
import Cookie from 'js-cookie'
import { CreateUserAdminDto, UpdateUserAdminDto } from 'src/entities/interfaces'
import { UserInputs, UserSearch, UserSearchPreventiveLog } from 'src/interfaces/users'
import {
  GET_CONTACT_PERSON_FAIL,
  GET_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON_SUCCESS,
  USER_CHECK_COOKIES_FAIL,
  USER_CHECK_COOKIES_REQUEST,
  USER_CHECK_COOKIES_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_GET_ALL_INTERNAL_FAIL,
  USER_GET_ALL_INTERNAL_REQUEST,
  USER_GET_ALL_INTERNAL_SUCCESS,
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ONE_FAIL,
  USER_GET_ONE_REQUEST,
  USER_GET_ONE_SUCCESS,
  USER_PREVENTIVE_LOG_GET_ALL_FAIL,
  USER_PREVENTIVE_LOG_GET_ALL_REQUEST,
  USER_PREVENTIVE_LOG_GET_ALL_SUCCESS
} from 'src/redux/constants/userConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const create = (inputs: UserInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: USER_CREATE_REQUEST, payload: {} })
  api
    .post('/api/admin/user', { ...inputs } as CreateUserAdminDto)
    .then((res) => {
      dispatch({ type: USER_CREATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const editUser = (inputs: UserInputs, id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: USER_EDIT_REQUEST, payload: {} })
  api
    .patch(`/api/admin/user/${id}`, { ...inputs } as UpdateUserAdminDto)
    .then((res) => {
      dispatch({ type: USER_EDIT_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const remove = (id: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: {} })
  api
    .delete(`api/admin/user/${id}`)
    .then((res) => {
      dispatch({ type: USER_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAllUsers =
  ({
    page = 0,
    limit = 10,
    firstNameFilter = '',
    firstNameFilterType = '',
    lastNameFilter = '',
    lastNameFilterType = '',
    practiceNameFilter = '',
    practiceNameFilterType = '',
    roleType = '',
    companyLocationId = ''
  }: UserSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: USER_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/user?page=${page}&limit=${limit}&firstNameFilter=${firstNameFilter}&firstNameFilterType=${firstNameFilterType}&lastNameFilter=${lastNameFilter}&lastNameFilterType=${lastNameFilterType}&practiceNameFilter=${practiceNameFilter}&practiceNameFilterType=${practiceNameFilterType}&roleType=${roleType}&companyLocationId=${companyLocationId}`
      )
      .then((res) => {
        dispatch({
          type: USER_GET_ALL_SUCCESS,
          payload: { users: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: USER_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllInternalUsers =
  ({
    page = 0,
    limit = 10,
    firstNameFilter = '',
    firstNameFilterType = '',
    lastNameFilter = '',
    lastNameFilterType = '',
    practiceNameFilter = '',
    practiceNameFilterType = '',
    quickFilter = ''
  }: UserSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: USER_GET_ALL_INTERNAL_REQUEST, payload: {} })

    api
      .get(
        `/api/admin/user/internal?page=${page}&limit=${limit}&firstNameFilter=${firstNameFilter}&firstNameFilterType=${firstNameFilterType}&lastNameFilter=${lastNameFilter}&lastNameFilterType=${lastNameFilterType}&practiceNameFilter=${practiceNameFilter}&practiceNameFilterType=${practiceNameFilterType}&quickFilter=${quickFilter}`
      )
      .then((res) => {
        dispatch({
          type: USER_GET_ALL_INTERNAL_SUCCESS,
          payload: { users: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: USER_GET_ALL_INTERNAL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

//REVIEW: Is this the right place to fetch user?(fetching appointmentRequest.contact, not logged in user)
export const getOneUser = (id?: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: USER_GET_ONE_REQUEST, payload: {} })
  return api
    .get(`/api/admin/user/${id}`)
    .then((res) => {
      dispatch({ type: USER_GET_ONE_SUCCESS, payload: res.data })
      return res.data
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: USER_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAllUsersPreventiveLog =
  ({
    page = 0,
    limit = 10,
    companyLocationId = '',
    sort = '',
    order = '',
    userNameFilter = '',
    userNameFilterType = '',
    positionFilter = '',
    positionFilterType = '',
    departmentFilter = '',
    departmentFilterType = '',
    nextExaminationFilter = '',
    nextExaminationFilterType = '',
    roleType = '',
    quickFilter = '',
    scheduledFilter = ''
  }: UserSearchPreventiveLog) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: USER_PREVENTIVE_LOG_GET_ALL_REQUEST, payload: {} })
    api
      .get('/api/admin/user/preventive-log-users/get-all', {
        params: {
          page,
          limit,
          sort,
          order,
          roleType,
          companyLocationId,
          userNameFilter,
          userNameFilterType,
          positionFilter,
          positionFilterType,
          departmentFilter,
          departmentFilterType,
          nextExaminationFilter,
          nextExaminationFilterType,
          quickFilter,
          scheduledFilter
        }
      })
      .then((res) => {
        dispatch({
          type: USER_PREVENTIVE_LOG_GET_ALL_SUCCESS,
          payload: { users: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: USER_PREVENTIVE_LOG_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const checkUserCookies = () => (dispatch: AppDispatch) => {
  dispatch({ type: USER_CHECK_COOKIES_REQUEST, payload: {} })
  const cookie_acceptance = Cookie.get('cookie_acceptance')
  if (cookie_acceptance === 'true') {
    dispatch({
      type: USER_CHECK_COOKIES_SUCCESS,
      payload: {
        cookie_acceptance
      }
    })
  } else {
    dispatch({
      type: USER_CHECK_COOKIES_FAIL,
      payload: {
        cookie_acceptance: false
      }
    })
  }
}

export const getContactPersonForLocation =
  ({
    page = 0,
    limit = 10,
    sort = '',
    order = '',
    quickFilter = '',
    locationId = '',
    firstNameFilter = '',
    firstNameFilterType = '',
    lastNameFilter = '',
    lastNameFilterType = '',
    emailFilter = '',
    emailFilterType = ''
  }: {
    page?: number | string
    limit?: number
    sort?: string
    order?: string
    quickFilter?: string
    locationId?: string
    firstNameFilter?: string
    firstNameFilterType?: string
    lastNameFilter?: string
    lastNameFilterType?: string
    emailFilter?: string
    emailFilterType?: string
  }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: GET_CONTACT_PERSON_REQUEST, payload: {} })
    api
      .get(`/api/admin/user/contact-person/${locationId}`, {
        params: {
          page,
          limit,
          sort,
          order,
          quickFilter,
          firstNameFilter,
          firstNameFilterType,
          lastNameFilter,
          lastNameFilterType,
          emailFilter,
          emailFilterType
        }
      })
      .then((res) => {
        dispatch({
          type: GET_CONTACT_PERSON_SUCCESS,
          payload: { users: res.data, count: Number(res.headers['x-count']) }
        })
        return res.data
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: GET_CONTACT_PERSON_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
