import { getGridStringOperators } from '@mui/x-data-grid'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

export const columns = [
  {
    field: 'firstName',
    headerName: i18n.t('CONTACT_PERSONS.FIRST_NAME'),
    editable: false,
    sortable: false,
    filterable: true,
    disableColumnMenu: true,
    filterOperators,
    flex: 0.75
  },
  {
    field: 'lastName',
    headerName: i18n.t('CONTACT_PERSONS.LAST_NAME'),
    editable: false,
    sortable: false,
    filterable: true,
    disableColumnMenu: true,
    filterOperators,
    flex: 0.75
  },
  {
    field: 'email',
    headerName: i18n.t('CONTACT_PERSONS.EMAIL'),
    editable: false,
    sortable: false,
    filterable: true,
    disableColumnMenu: true,
    filterOperators,
    flex: 0.75
  }
]
