import moment from 'moment'

export const getGroupedData = (groups: any) => {
  const dates: any = []
  let confirmedAppointments = 0
  const examinationTypes: any = []
  for (const group of groups) {
    confirmedAppointments += group?._count?.Appointment
    for (const availabillity of group.DoctorAvailabillity) {
      const formatedDate = moment(availabillity.startDate).format('DD.MM.YYYY')
      const exist = dates.find((date: string) => date === formatedDate)
      if (!exist) {
        dates.push(formatedDate)
      }
    }
    for (const examinationType of group.AppointmentRequestEmployeeExaminationTypes) {
      const exist = examinationTypes.find(
        (savedExaminationType: any) => savedExaminationType.id === examinationType.Examination.id
      )
      if (!exist) {
        examinationTypes.push({
          id: examinationType.Examination.id,
          name: examinationType.Examination.name
        })
      }
    }
  }
  return { dates, confirmedAppointments, examinationTypes }
}
