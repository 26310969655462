import moment from 'moment'

export const groupingExaminations = function (
  employeeExaminationTypes: any,
  scheduledExaminations: any
) {
  const examinations: any = []
  for (let i = 0; i < scheduledExaminations.length; i++) {
    const {
      id,
      status,
      performedDate,
      examinationCheckup,
      nextExaminationDate,
      examinationNote,
      employeeId,
      employerCertificate,
      employeeOptedIn,
      type,
      examinationRequired
    } = scheduledExaminations[i]
    examinations.push({
      id,
      status,
      performedDate: performedDate ? moment(performedDate) : new Date(),
      examinationCheckup,
      examinationCheckupOption: {
        value: examinationCheckup,
        label: examinationCheckup
      },
      nextExaminationDate: nextExaminationDate ? moment(nextExaminationDate) : null,
      examinationNote,
      employeeId,
      employerCertificate,
      examinationName: type.name,
      examinationRequired: examinationRequired,
      employeeOptedIn
    })
  }
  return examinations
}
