import { ThemeProvider } from '@emotion/react'
import { EyeIcon } from '@heroicons/react/20/solid'
import {
  DataGrid,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { CompaniesLocationsListItem, QueryObject } from 'src/interfaces/companies'
import { getAllWithLocations } from 'src/redux/actions/companyActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { columns } from './helpers/TableColumnsHelper'

export const CustomersList = () => {
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('DE')
  const savedLng = i18next.language
  const [firstLoad, setFirstLoad] = useState(true)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [limit, setLimit] = useState(config.limit)
  const [page, setPage] = useState<string | number>(0)
  const [tableRows, setTableRows] = useState<any>([])
  const dispatch = useAppDispatch()
  const { companyGetAllWithLocations } = useAppSelector((state) => state)
  const { companyLocations, loading, count } = companyGetAllWithLocations
  const deleteButtonRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANIES_LOCATIONS.CUSTOMER')}</p>
  }
  columns[1].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANIES_LOCATIONS.CONTACT_PERSONS')}</p>
  }
  columns[2].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANIES_LOCATIONS.ASSOCIATED_PRACTICE')}</p>
  }
  columns[3].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANIES_LOCATIONS.NUMBER_OF_EMPLOYEES')}</p>
  }

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])

  useEffect(() => {
    if (firstLoad) {
      setPage(0)
    }
  }, [firstLoad])

  useEffect(() => {
    const companyNameFilter =
      queryObject.field === 'name' && queryObject.value ? queryObject.value : ''
    const companyNameFilterType =
      queryObject.field === 'name' && queryObject.operator ? queryObject.operator : 'contains'
    const contactPersonsFilter =
      queryObject.field === 'contactPersons' && queryObject.value ? queryObject.value : ''
    const contactPersonsFilterType =
      queryObject.field === 'contactPersons' && queryObject.operator
        ? queryObject.operator
        : 'contains'
    const quickFilter =
      queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
    dispatch(
      getAllWithLocations({
        limit,
        page,
        companyNameFilter,
        companyNameFilterType,
        contactPersonsFilter,
        contactPersonsFilterType,
        quickFilter
      })
    )
  }, [limit, page, queryObject])

  useEffect(() => {
    ;(async () => {
      const companiesLocationsHelper = []
      if (companyLocations) {
        for (let i = 0; i < companyLocations.length; i++) {
          const company: CompaniesLocationsListItem = {
            id: companyLocations[i].id,
            name: companyLocations[i].company.name + ' - ' + companyLocations[i].name,
            contactPersons: companyLocations[i].users,
            practice: companyLocations[i].BASPractice?.name,
            numberOfEmployees: companyLocations[i]._count?.users
          }
          companiesLocationsHelper.push(company)
        }
        setTableRows(companiesLocationsHelper)
        setFinishedLoading(true)
      }
    })()
  }, [companyLocations])

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }
  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
            <ThemeProvider theme={setTheme(language)}>
              <DataGrid
                {...tableRows}
                className='MuiDataGrid-cellContent-alignTop'
                columns={columns}
                rows={tableRows}
                rowCount={count > 0 ? count : 0}
                pagination
                paginationMode='server'
                sortingMode='server'
                filterMode='server'
                onFilterModelChange={onFilterChange}
                loading={loading}
                paginationModel={{
                  page: Number(page),
                  pageSize: limit
                }}
                pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                onPaginationModelChange={(params) => {
                  setPage(params.page)
                  setLimit(params.pageSize)
                }}
                slots={{ toolbar: Toolbar }}
                localeText={{
                  toolbarFilters: t('GENERIC.FILTER')
                }}
                slotProps={{
                  pagination: {
                    labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                  },
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      placeholder: t('GENERIC.SEARCH'),
                      inputProps: {
                        'aria-label': t('GENERIC.SEARCH')
                      },
                      debounceMs: 500
                    }
                  }
                }}
                sx={GridStyles}
                columnVisibilityModel={{ leftCompanyDate: false }}
                getRowHeight={() => 'auto'}
                autoHeight={true}
                rowSelection={false}
                checkboxSelection={false}
                disableColumnSelector={true}
                disableRowSelectionOnClick={true}
              />
            </ThemeProvider>
          ) : (
            <EmptyState
              titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                type: t('EMPTY_STATE.CUSTOMERS_LOCATIONS')
              })}
              additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
            />
          )}
        </div>
      )}
    </>
  )
}
