export const WIDGET_UPCOMING_AR_GET_REQUEST = 'WIDGET_UPCOMING_AR_GET_REQUEST'
export const WIDGET_UPCOMING_AR_GET_SUCCESS = 'WIDGET_UPCOMING_AR_GET_SUCCESS'
export const WIDGET_UPCOMING_AR_GET_FAIL = 'WIDGET_UPCOMING_AR_GET_FAIL'

export const WIDGET_LOCATION_USER_NUMBER_GET_REQUEST = 'WIDGET_LOCATION_USER_NUMBER_GET_REQUEST'
export const WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS = 'WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS'
export const WIDGET_LOCATION_USER_NUMBER_GET_FAIL = 'WIDGET_LOCATION_USER_NUMBER_GET_FAIL'

export const WIDGET_SEND_FEEDBACK_REQUEST = 'WIDGET_SEND_FEEDBACK_REQUEST'
export const WIDGET_SEND_FEEDBACK_SUCCESS = 'WIDGET_SEND_FEEDBACK_SUCCESS'
export const WIDGET_SEND_FEEDBACK_FAIL = 'WIDGET_SEND_FEEDBACK_FAIL'
export const WIDGET_SEND_FEEDBACK_RESET = 'WIDGET_SEND_FEEDBACK_RESET'
