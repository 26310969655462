import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Button, IconButton } from '@mui/material'
import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from 'src/App'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { InputLabelLeft } from 'src/components/FormComponents'
import Modal from 'src/components/Modal'
import {
  PreventiveLogCostumersErrors,
  PreventiveLogCustomersInputs
} from 'src/interfaces/preventiveLogCostumers'
import { enable } from 'src/redux/actions/preventiveLogActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { checkRequiredFields, validation } from 'src/services/validation/customersForm'

export const ContactPersonForm = () => {
  const dispatch = useAppDispatch()
  const { id: companyLocationId } = useParams()
  const { setContext }: any = useContext(GlobalContext)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)

  useEffect(() => {
    setContext({ header: t('CONTACT_PERSONS.HEADER_NAME') })
  }, [])

  const { t } = useTranslation()
  const [inputs, setInputs] = useState<PreventiveLogCustomersInputs>({
    email: '',
    companyLocation: { value: companyLocationId ?? '', label: '' },
    additionalEmails: [],
    locations: []
  })

  const navigate = useNavigate()
  let errors: PreventiveLogCostumersErrors = {}
  const { enablePreventiveLog, userSignin } = useAppSelector((state) => state)
  const { loading, success } = enablePreventiveLog
  const { user } = userSignin

  useEffect(() => {
    const isDisabled = checkRequiredFields(inputs, ['email', 'companyLocation', 'additionalEmails'])
    if (isDisabled > 0) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs])

  const setVal = (value: any, name: string) => {
    setInputs({ ...inputs, [name]: value })
  }

  const onSubmit = () => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      const data = {
        userEmail: inputs.email,
        companyLocationId: inputs.companyLocation.value,
        additionalUserEmails: inputs.additionalEmails.map((email) => email.email),
        senderEmail: user.email
      }
      dispatch(enable(data))
    }
  }

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (success) {
      setDisplaySuccessButton(true)
      navigate(`/client/${companyLocationId}`)
    }
  }, [success])

  if (submitted) {
    errors = validation(inputs)
  }

  const editAdditionalEmails = (value: string, id: string) => {
    const editedAdditionalEmails = inputs.additionalEmails.map((email) => {
      if (email.id == id) {
        email.email = value
      }
      return email
    })
    setInputs({
      ...inputs,
      additionalEmails: [...editedAdditionalEmails]
    })
  }

  const setAdditionalEmails = (id: string, action: string) => {
    if (action === 'add') {
      setInputs({
        ...inputs,
        additionalEmails: [...inputs.additionalEmails, { id: String(Date.now()), email: '' }]
      })
    } else {
      const filteredAdditionalEmails = inputs.additionalEmails.filter((email) => email.id !== id)
      setInputs({
        ...inputs,
        additionalEmails: [...filteredAdditionalEmails]
      })
    }
  }

  return (
    <form
      className='space-y-8 relative'
      onSubmit={(e) => {
        e.preventDefault()
        setIsSaveModal(true)
      }}
    >
      {isCancelModal && (
        <Modal
          onCancel={() => setIsCancelModal(false)}
          onConfirm={() => navigate('/clients')}
          message={t('MODAL.CANCEL')}
        />
      )}
      {isSaveModal && (
        <Modal
          onCancel={() => setIsSaveModal(false)}
          onConfirm={() => {
            onSubmit()
            setIsSaveModal(false)
          }}
          message={t('CUSTOMER_OVERVIEW.MODAL_MESSAGE')}
        />
      )}
      <div className='space-y-6'>
        <InputLabelLeft
          type='text'
          name='email'
          label={`#1 ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
          value={inputs['email']}
          onChange={setVal}
          error={errors['email']}
          required
        />
        <div className='space-y-6'>
          {inputs.additionalEmails &&
            inputs.additionalEmails.map((email, index: number) => {
              const error = errors.additionalEmails?.find((error) => error.id === email.id)
              return (
                <div key={index} className='relative flex sm:block'>
                  <InputLabelLeft
                    type='text'
                    name={email.id}
                    label={`#${index + 2} ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
                    value={email.email}
                    onChange={editAdditionalEmails}
                    error={error?.message}
                    required
                  />
                  <div className='absolute right-0 sm:bottom-[14px]'>
                    <IconButton
                      className='ml-auto flex gap-2 items-center px-0 hover:bg-white'
                      onClick={() => setAdditionalEmails(email.id, 'remove')}
                      title={t('CREATE_PREVENTIVE_LOG_CUSTOMER.REMOVE_CONTACT')}
                    >
                      <XCircleIcon className='w-7 text-brand-orange-700' />
                    </IconButton>
                  </div>
                </div>
              )
            })}
        </div>
        <IconButton
          className='flex items-center gap-2 px-0 hover:bg-white'
          onClick={() => setAdditionalEmails('', 'add')}
        >
          <PlusCircleIcon className='w-7 text-brand-orange-700' />
          <p className='text-sm text-brand-orange-700'>
            {t('CREATE_PREVENTIVE_LOG_CUSTOMER.ADD_ADDITIONAL_CONTACT')}
          </p>
        </IconButton>
      </div>
      <div>
        <p className='customParagraph mt-10'>
          <span className='text-red-700'>* </span>
          {t('GENERIC.REQUIRED_FIELD')}
        </p>
      </div>
      <div className='flex justify-start gap-3'>
        <Button
          type='submit'
          className={`main-btn ${isSaveDisabled && 'disabled'} ${
            (loading || displaySuccessButton) && 'processing'
          }`}
          disabled={isSaveDisabled || loading || displaySuccessButton}
          variant='contained'
        >
          {loading || displaySuccessButton ? (
            <ButtonProcessing displaySuccessButton={displaySuccessButton} />
          ) : (
            t('BUTTONS.SAVE')
          )}
        </Button>
        <Button
          className='secondary-btn'
          variant='contained'
          onClick={() => setIsCancelModal(true)}
        >
          {t('BUTTONS.CANCEL')}
        </Button>
      </div>
    </form>
  )
}
