import { omit } from 'lodash'
import { PracticeErrors, PracticeInputs } from 'src/interfaces/practices'
import i18n from '../language.service'
import { isRequired, urlValidation } from './index'

export const validation = (inputs: PracticeInputs) => {
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  let errors: PracticeErrors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'name':
        errors[key] = isRequired(value, `${i18n.t('PRACTICES.PRACTICE_NAME')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'URL':
        if (value !== null && value.trim().length > 0) {
          errors[key] = urlValidation(value)
          if (!errors[key]) {
            errors = omit(errors, key)
          }
        } else {
          errors = omit(errors, key)
        }
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: PracticeInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if ((input && !value) || (input && value && isRequired(value))) {
      isDisabled += 1
    }
  }
  return isDisabled
}
