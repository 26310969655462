import { EyeIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { CompanyListItem, CompanySearch, QueryObject } from 'src/interfaces/companies'
import { getAll, remove } from 'src/redux/actions/companyActions'
import {
  COMPANY_CREATE_RESET,
  COMPANY_DELETE_RESET,
  COMPANY_EDIT_RESET
} from 'src/redux/constants/companyConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { columns } from './helper/TableColumnsHelper'

export const CompaniesList = () => {
  const { t } = useTranslation()
  const savedLng = i18next.language
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('DE')
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const deleteButtonRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.COMPANY_NAME')}</p>
  }
  columns[1].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.COMPANY_NUMBER')}</p>
  }
  columns[2].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.CONTRACT_IN_PLACE')}</p>
  }
  columns[3].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.NUMBER_OF_LOCATIONS')}</p>
  }
  columns[4].renderHeader = () => {
    return <p className='font-bold'>{t('COMPANY.NUMBER_OF_PREVENTIVE_LOGS')}</p>
  }
  columns[5].renderCell = (params: GridRenderCellParams) => {
    return (
      <div>
        <IconButton onClick={() => onOpenModal(params.id)} title={t('GENERIC.DELETE')}>
          <TrashIcon className='w-5' />
        </IconButton>
        <IconButton
          onClick={() => navigate(`/companies/edit/${params.id}`)}
          title={t('GENERIC.EDIT')}
        >
          <PencilSquareIcon className='w-5' />
        </IconButton>
      </div>
    )
  }

  const [limit, setLimit] = useState(config.limit)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSuccessCreate, setIsSuccessCreate] = useState(false)
  const [companyId, setCompanyId] = useState<GridRowId>('')
  const [page, setPage] = useState<string | number>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [tableRows, setTableRows] = useState<CompanyListItem[]>([])
  const { companyGetAll, companyDelete, companyCreate, companyEdit } = useAppSelector(
    (state) => state
  )
  const { loading: getAllLoading, companies, count } = companyGetAll
  const { success: editSuccess } = companyEdit
  const {
    loading: companyDeleteLoading,
    success: successDelete,
    error: deleteError
  } = companyDelete
  const { success: successCreate } = companyCreate
  const [companyHasPreventiveLogError, setCompanyHasPreventiveLogError] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: COMPANY_DELETE_RESET })
    setIsSuccessCreate(successCreate)
    return () => {
      dispatch({ type: COMPANY_EDIT_RESET })
      dispatch({ type: COMPANY_CREATE_RESET })
    }
  }, [])

  useEffect(() => {
    const companyNameFilter =
      queryObject.field === 'name' && queryObject.value ? queryObject.value : ''
    const companyNameFilterType =
      queryObject.field === 'name' && queryObject.operator ? queryObject.operator : 'contains'
    const companyNumberFilter =
      queryObject.field === 'externalId' && queryObject.value ? queryObject.value : ''
    const companyNumberFilterType =
      queryObject.field === 'externalId' && queryObject.operator ? queryObject.operator : 'contains'
    const quickFilter =
      queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
    dispatch(
      getAll({
        limit,
        page,
        companyNameFilter,
        companyNameFilterType,
        companyNumberFilter,
        companyNumberFilterType,
        quickFilter
      } as CompanySearch)
    )
  }, [page, queryObject, limit])

  useEffect(() => {
    ;(async () => {
      const companiesHelper = []
      if (companies) {
        for (let i = 0; i < companies.length; i++) {
          const company: CompanyListItem = {
            id: companies[i].id,
            name: companies[i].name,
            externalId: companies[i].externalId,
            contractInPlace: companies[i].contractInPlace,
            numberOfLocations: companies[i]._count.CompanyLocation,
            numberOfAssociatedPreventiveLogs: companies[i]._count.contacts
          }
          companiesHelper.push(company)
        }
        setTableRows(companiesHelper)
        setFinishedLoading(true)
      }
    })()
  }, [companies])

  useEffect(() => {
    if (successDelete) {
      typeof page === 'string' ? setPage(0) : setPage('0')
      setIsSuccessCreate(false)
    }
  }, [successDelete])

  const onDelete = async (companyId: GridRowId) => {
    setCompanyHasPreventiveLogError('')
    setIsDeleteModal(false)
    dispatch(remove(companyId))
  }

  const onOpenModal = (id: GridRowId) => {
    setIsDeleteModal(true)
    setCompanyId(id)
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    if (quickFilterValue && !isNaN(Number(quickFilterValue))) {
      setQueryObject({
        field: 'externalId',
        value: quickFilterValue,
        operator: 'contains'
      })
    } else {
      setQueryObject(
        quickFilterValue
          ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
          : filterItem || {}
      )
    }
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {successDelete && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_DELETE')} ${t('COMPANY.COMPANY').toLowerCase()}`}
                />
              )}
              {isSuccessCreate && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_CREATE')} ${t(
                    'COMPANY.COMPANY'
                  ).toLocaleLowerCase()}`}
                />
              )}
              {editSuccess && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_EDIT')} ${t('COMPANY.COMPANY').toLowerCase()}`}
                />
              )}
              {deleteError && (
                <ErrorAlert hide message={deleteError || companyHasPreventiveLogError} />
              )}
            </div>
            <Button className='main-btn' variant='contained' href='/companies/create'>
              {t('COMPANY.CREATE')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            {isDeleteModal && (
              <Modal
                onCancel={() => setIsDeleteModal(false)}
                onConfirm={() => onDelete(companyId)}
                message={`${t('MODAL.DELETE')} ${t('COMPANY.COMPANY').toLowerCase()}?`}
              />
            )}
            <div style={{ width: '100%' }}>
              {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
                <ThemeProvider theme={setTheme(language)}>
                  <DataGrid
                    {...tableRows}
                    className='MuiDataGrid-cellContent-alignTop'
                    columns={columns}
                    rows={tableRows}
                    rowCount={count > 0 ? count : 0}
                    pagination
                    paginationMode='server'
                    sortingMode='server'
                    filterMode='server'
                    onFilterModelChange={onFilterChange}
                    loading={getAllLoading || companyDeleteLoading}
                    paginationModel={{
                      page: Number(page),
                      pageSize: limit
                    }}
                    pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                    onPaginationModelChange={(params) => {
                      setPage(params.page)
                      setLimit(params.pageSize)
                    }}
                    slots={{ toolbar: Toolbar }}
                    localeText={{
                      toolbarFilters: t('GENERIC.FILTER')
                    }}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                      },
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          placeholder: t('GENERIC.SEARCH'),
                          inputProps: {
                            'aria-label': t('GENERIC.SEARCH')
                          },
                          debounceMs: 500
                        }
                      }
                    }}
                    sx={GridStyles}
                    columnVisibilityModel={{ leftCompanyDate: false }}
                    getRowHeight={() => 'auto'}
                    autoHeight={true}
                    rowSelection={false}
                    checkboxSelection={false}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick={true}
                  />
                </ThemeProvider>
              ) : (
                <EmptyState
                  titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                    type: t('EMPTY_STATE.COMPANIES')
                  })}
                  additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
                  buttonText={t('COMPANY.COMPANY').toLowerCase()}
                  url='/companies/create'
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
