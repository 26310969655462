import { AnyAction } from 'redux'
import {
  BAS_PRACTICE_FIND_RELATION_FAIL,
  BAS_PRACTICE_FIND_RELATION_REQUEST,
  BAS_PRACTICE_FIND_RELATION_SUCCESS,
  PRACTICE_CREATE_FAIL,
  PRACTICE_CREATE_REQUEST,
  PRACTICE_CREATE_RESET,
  PRACTICE_CREATE_SUCCESS,
  PRACTICE_DELETE_FAIL,
  PRACTICE_DELETE_REQUEST,
  PRACTICE_DELETE_RESET,
  PRACTICE_DELETE_SUCCESS,
  PRACTICE_EDIT_FAIL,
  PRACTICE_EDIT_REQUEST,
  PRACTICE_EDIT_RESET,
  PRACTICE_EDIT_SUCCESS,
  PRACTICE_GET_ALL_FAIL,
  PRACTICE_GET_ALL_REQUEST,
  PRACTICE_GET_ALL_SUCCESS,
  PRACTICE_GET_ONE_FAIL,
  PRACTICE_GET_ONE_REQUEST,
  PRACTICE_GET_ONE_RESET,
  PRACTICE_GET_ONE_SUCCESS,
  PRACTICE_WORKING_HOURS_GET_FAIL,
  PRACTICE_WORKING_HOURS_GET_REQUEST,
  PRACTICE_WORKING_HOURS_GET_SUCCESS
} from 'src/redux/constants/practiceConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const practiceCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_CREATE_REQUEST:
      return { loading: true }
    case PRACTICE_CREATE_SUCCESS:
      return { loading: false, success: true }
    case PRACTICE_CREATE_FAIL:
      error = errorMessage
      return { loading: false, error }
    case PRACTICE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const practiceEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_EDIT_REQUEST:
      return { loading: true }
    case PRACTICE_EDIT_SUCCESS:
      return { loading: false, success: true }
    case PRACTICE_EDIT_FAIL:
      switch (action.payload.message) {
        case 'PRACTICE_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case PRACTICE_EDIT_RESET:
      return {}
    default:
      return state
  }
}

const practiceDeleteReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_DELETE_REQUEST:
      return { loading: true }
    case PRACTICE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRACTICE_DELETE_FAIL:
      switch (action.payload.message) {
        case 'PRACTICE_NOT_FOUND':
        case 'PRACTICE_IN_USE':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case PRACTICE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const practiceGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_GET_ONE_REQUEST:
      return { loading: true }
    case PRACTICE_GET_ONE_SUCCESS:
      return { loading: false, practice: action.payload }
    case PRACTICE_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'PRACTICE_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case PRACTICE_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const practiceGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_GET_ALL_REQUEST:
      return { loading: true }
    case PRACTICE_GET_ALL_SUCCESS:
      return { loading: false, practices: action.payload.practices, count: action.payload.count }
    case PRACTICE_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const practiceFindRelationReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case BAS_PRACTICE_FIND_RELATION_REQUEST:
      return { loading: true }
    case BAS_PRACTICE_FIND_RELATION_SUCCESS:
      return {
        loading: false,
        practiceRelation: action.payload.practices,
        count: action.payload.count
      }
    case BAS_PRACTICE_FIND_RELATION_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const practiceWorkingHoursGetReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case PRACTICE_WORKING_HOURS_GET_REQUEST:
      return { loading: true }
    case PRACTICE_WORKING_HOURS_GET_SUCCESS:
      return { loading: false, hours: action.payload }
    case PRACTICE_WORKING_HOURS_GET_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

export const practicesReducers = {
  practiceCreate: practiceCreateReducer,
  practiceGetOne: practiceGetOneReducer,
  practiceEdit: practiceEditReducer,
  practiceGetAll: practiceGetAllReducer,
  practiceDelete: practiceDeleteReducer,
  basPracticeUserRelation: practiceFindRelationReducer,
  practiceWorkingHoursGet: practiceWorkingHoursGetReducer
}
