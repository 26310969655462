import { EyeIcon } from '@heroicons/react/20/solid'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { QueryObject } from 'src/interfaces/appointmentRequest'
import { CompanyLocationCostumerOverviewSearch } from 'src/interfaces/companyLocations'
import { PreventiveLogCostumersListItem, SortObject } from 'src/interfaces/preventiveLogCostumers'
import { getCompanyLocationsCustomerOverview } from 'src/redux/actions/companyLocationActions'
import { ENABLE_PREVENTIVE_LOG_RESET } from 'src/redux/constants/preventiveLogConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import i18n from 'src/services/language.service'
import { columns } from './helpers/TableColumnsHelper'

export const CustomerOverview = () => {
  const savedLng = i18n.language
  const { t } = useTranslation()
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('DE')
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  const dispatch = useAppDispatch()
  const [page, setPage] = useState<number | string>(0)
  const [sortObject, setSortObject] = useState<SortObject>({})
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [tableRows, setTableRows] = useState<PreventiveLogCostumersListItem[]>([])
  const [isSuccessCreate, setIsSuccessCreate] = useState(false)
  const { enablePreventiveLog, userSignin, companyLocationCostumerOverviewGetAll } = useAppSelector(
    (state) => state
  )
  const { user } = userSignin
  const { success } = enablePreventiveLog
  const { loading, count, companyLocations } = companyLocationCostumerOverviewGetAll
  const [limit, setLimit] = useState(config.limit)
  const deleteButtonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    dispatch({ type: ENABLE_PREVENTIVE_LOG_RESET })
    setIsSuccessCreate(success)
    sessionStorage.clear()
  }, [])

  useEffect(() => {
    if (user) {
      const sort = sortObject?.field ? sortObject.field : ''
      const order = sortObject?.sort ? sortObject.sort : ''
      const companyNameFilter =
        queryObject.field === 'companyName' && queryObject.value ? queryObject.value : ''
      const companyNameFilterType =
        queryObject.field === 'companyName' && queryObject.operator
          ? queryObject.operator
          : 'contains'
      const quickFilter =
        queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''

      dispatch(
        getCompanyLocationsCustomerOverview({
          page,
          limit,
          id: user.id,
          sort,
          order,
          companyNameFilter,
          companyNameFilterType,
          quickFilter
        } as CompanyLocationCostumerOverviewSearch)
      )
    }
  }, [user, page, sortObject, savedLng, limit, queryObject])

  useEffect(() => {
    if (companyLocations) {
      ;(async () => {
        const customerHelper: PreventiveLogCostumersListItem[] = []
        for (let i = 0; i < companyLocations.length; i++) {
          const {
            id,
            company,
            BASPractice,
            _count,
            name,
            nextExaminationDate,
            lastExaminationDate
          } = companyLocations[i]

          const customer: PreventiveLogCostumersListItem = {
            id: id,
            companyName: company ? `${company?.name} - ${name}` : '',
            lastExamination: lastExaminationDate ? formatDate(lastExaminationDate, 'first') : '',
            nextExamination: nextExaminationDate ? formatDate(nextExaminationDate, 'last') : '',
            numberOfEmployees: _count.users,
            associatedPractice: BASPractice.name,
            contactPerson: companyLocations[i].users
          }
          customerHelper.push(customer)
        }
        setTableRows(customerHelper)
        setFinishedLoading(true)
      })()
    }
  }, [companyLocations, limit])

  const formatDate = (d: Date, examination: string) => {
    if (d) {
      return examination === 'first'
        ? moment(d).format('DD.MM.YYYY')
        : moment(d).format('MMMM YYYY')
    }
    return ''
  }

  const onSortChange = (sortModel: GridSortModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    setSortObject(sortModel[0])
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {isSuccessCreate && (
                <SuccessAlert
                  hide
                  message={`${t('CREATE_PREVENTIVE_LOG_CUSTOMER.SUCCESS_CREATE')}`}
                />
              )}
            </div>
            <Button className='main-btn' variant='contained' href='/clients/create'>
              {t('CREATE_PREVENTIVE_LOG_CUSTOMER.NEW_CUSTOMER')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            <div style={{ width: '100%' }}>
              {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !isFirstLoad) ? (
                <ThemeProvider theme={setTheme(language)}>
                  <DataGrid
                    {...tableRows}
                    className='MuiDataGrid-cellContent-alignTop'
                    columns={columns}
                    rows={tableRows}
                    rowCount={count > 0 ? count : 0}
                    pagination
                    paginationMode='server'
                    sortingMode='server'
                    filterMode='server'
                    onSortModelChange={onSortChange}
                    onFilterModelChange={onFilterChange}
                    loading={loading}
                    paginationModel={{
                      page: Number(page),
                      pageSize: limit
                    }}
                    pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                    onPaginationModelChange={(params) => {
                      setPage(params.page)
                      setLimit(params.pageSize)
                    }}
                    slots={{ toolbar: Toolbar }}
                    localeText={{
                      toolbarFilters: t('GENERIC.FILTER')
                    }}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                      },
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          placeholder: t('GENERIC.SEARCH'),
                          inputProps: {
                            'aria-label': t('GENERIC.SEARCH')
                          },
                          debounceMs: 500
                        }
                      }
                    }}
                    sx={GridStyles}
                    columnVisibilityModel={{ leftCompanyDate: false }}
                    getRowHeight={() => 'auto'}
                    autoHeight={true}
                    rowSelection={false}
                    checkboxSelection={false}
                    disableColumnSelector={true}
                    disableRowSelectionOnClick={true}
                  />
                </ThemeProvider>
              ) : (
                <EmptyState
                  titleText={t('NAVBAR.CUSTOMERS')}
                  buttonText={t('COMPANY.CUSTOMER').toLowerCase()}
                  url={'/clients/create'}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
