export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAIL = 'COMPANY_CREATE_FAIL'
export const COMPANY_CREATE_RESET = 'COMPANY_CREATE_RESET'

export const COMPANY_EDIT_REQUEST = 'COMPANY_EDIT_REQUEST'
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS'
export const COMPANY_EDIT_FAIL = 'COMPANY_EDIT_FAIL'
export const COMPANY_EDIT_RESET = 'COMPANY_EDIT_RESET'

export const COMPANY_GET_ONE_REQUEST = 'COMPANY_GET_ONE_REQUEST'
export const COMPANY_GET_ONE_SUCCESS = 'COMPANY_GET_ONE_SUCCESS'
export const COMPANY_GET_ONE_FAIL = 'COMPANY_GET_ONE_FAIL'
export const COMPANY_GET_ONE_RESET = 'COMPANY_GET_ONE_RESET'

export const COMPANY_GET_ALL_REQUEST = 'COMPANY_GET_ALL_REQUEST'
export const COMPANY_GET_ALL_SUCCESS = 'COMPANY_GET_ALL_SUCCESS'
export const COMPANY_GET_ALL_FAIL = 'COMPANY_GET_ALL_FAIL'

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST'
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS'
export const COMPANY_DELETE_FAIL = 'COMPANY_DELETE_FAIL'
export const COMPANY_DELETE_RESET = 'COMPANY_DELETE_RESET'

export const GET_COMPANIES_LOCATIONS_REQUEST = 'GET_COMPANIES_LOCATIONS_REQUEST'
export const GET_COMPANIES_LOCATIONS_SUCCESS = 'GET_COMPANIES_LOCATIONS_SUCCESS'
export const GET_COMPANIES_LOCATIONS_FAILURE = 'GET_COMPANIES_LOCATIONS_FAILURE'
