import { GridRowId } from '@mui/x-data-grid'
import {
  CreateExaminationTypeAdminDto,
  UpdateExaminationTypeAdminDto
} from 'src/entities/interfaces'
import { ExaminationTypeInputs, ExaminationTypeSearch } from 'src/interfaces/examinationTypes'
import { RiskAreaOption } from 'src/interfaces/riskAreas'
import {
  EXAMINATION_TYPE_CREATE_FAIL,
  EXAMINATION_TYPE_CREATE_REQUEST,
  EXAMINATION_TYPE_CREATE_SUCCESS,
  EXAMINATION_TYPE_DELETE_FAIL,
  EXAMINATION_TYPE_DELETE_REQUEST,
  EXAMINATION_TYPE_DELETE_SUCCESS,
  EXAMINATION_TYPE_EDIT_FAIL,
  EXAMINATION_TYPE_EDIT_REQUEST,
  EXAMINATION_TYPE_EDIT_SUCCESS,
  EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_FAIL,
  EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_REQUEST,
  EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_SUCCESS,
  EXAMINATION_TYPE_GET_ALL_FAIL,
  EXAMINATION_TYPE_GET_ALL_REQUEST,
  EXAMINATION_TYPE_GET_ALL_SUCCESS,
  EXAMINATION_TYPE_GET_ONE_FAIL,
  EXAMINATION_TYPE_GET_ONE_REQUEST,
  EXAMINATION_TYPE_GET_ONE_SUCCESS
} from 'src/redux/constants/examinationTypeConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const create = (inputs: ExaminationTypeInputs) => (dispatch: AppDispatch) => {
  dispatch({ type: EXAMINATION_TYPE_CREATE_REQUEST, payload: {} })
  const riskAreaId = inputs.riskArea.map((tag: RiskAreaOption) => tag.value)
  const { name, description, doctorDuration, mfaDuration } = inputs
  api
    .post('/api/admin/examination-type', {
      riskAreaId,
      name,
      description,
      doctorDuration: Number(doctorDuration),
      mfaDuration: Number(mfaDuration)
    } as CreateExaminationTypeAdminDto)
    .then((res) => {
      dispatch({ type: EXAMINATION_TYPE_CREATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXAMINATION_TYPE_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const edit = (inputs: ExaminationTypeInputs, id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: EXAMINATION_TYPE_EDIT_REQUEST, payload: {} })
  const riskAreaId = inputs.riskArea.map((tag: RiskAreaOption) => tag.value)
  const { name, description, doctorDuration, mfaDuration } = inputs
  api
    .patch(`/api/admin/examination-type/${id}`, {
      riskAreaId,
      name,
      description,
      doctorDuration: Number(doctorDuration),
      mfaDuration: Number(mfaDuration)
    } as UpdateExaminationTypeAdminDto)
    .then((res) => {
      dispatch({ type: EXAMINATION_TYPE_EDIT_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXAMINATION_TYPE_EDIT_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAll =
  ({
    page = 0,
    limit = 10,
    query = '',
    examinationTypeNameFilter = '',
    examinationTypeNameFilterType = '',
    examinationTypeRiskAreaFilter = '',
    examinationTypeRiskAreaFilterType = '',
    quickFilter = '',
    riskAreaId = ''
  }: ExaminationTypeSearch) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_TYPE_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/examination-type?page=${page}&limit=${limit}&query=${query}&examinationTypeNameFilter=${examinationTypeNameFilter}&examinationTypeNameFilterType=${examinationTypeNameFilterType}&examinationTypeRiskAreaFilter=${examinationTypeRiskAreaFilter}&examinationTypeRiskAreaFilterType=${examinationTypeRiskAreaFilterType}&quickFilter=${quickFilter}&riskAreaId=${riskAreaId}`
      )
      .then((res) => {
        dispatch({
          type: EXAMINATION_TYPE_GET_ALL_SUCCESS,
          payload: { examinationsTypes: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_TYPE_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const remove = (id: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: EXAMINATION_TYPE_DELETE_REQUEST, payload: {} })
  api
    .delete(`/api/admin/examination-type/${id}`)
    .then((res) => {
      dispatch({ type: EXAMINATION_TYPE_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXAMINATION_TYPE_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getOne = (id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: EXAMINATION_TYPE_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/examination-type/${id}`)
    .then((res) => {
      dispatch({ type: EXAMINATION_TYPE_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXAMINATION_TYPE_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getAllExaminationTypeExaminationSelectionSearch =
  ({ page = 0, limit = 10, query = '' }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_REQUEST, payload: {} })
    api
      .get(
        `/api/admin/examination-type/examinationSelection?page=${page}&limit=${limit}&query=${query}`
      )
      .then((res) => {
        dispatch({
          type: EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_SUCCESS,
          payload: res.data
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: EXAMINATION_TYPE_EXAMINATION_SELECTION_SEARCH_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }
