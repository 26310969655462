export const CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST =
  'CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST'
export const CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS =
  'CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS'
export const CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL =
  'CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL'
export const CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET =
  'CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET'

export const CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST =
  'CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST'
export const CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS =
  'CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS'
export const CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL = 'CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL'
export const CL_SELECTED_EXAMINATION_TYPE_GET_ALL_RESET =
  'CL_SELECTED_EXAMINATION_TYPE_GET_ALL_RESET'

export const CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST =
  'CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST'
export const CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS =
  'CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS'
export const CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL =
  'CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL'
export const CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET =
  'CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET'
