import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import { InputLabelLeft, SwitchLabelLeft } from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { PracticeErrors, PracticeInputs } from 'src/interfaces/practices'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { create, edit, getOne } from 'src/redux/actions/practiceActions'
import {
  PRACTICE_CREATE_RESET,
  PRACTICE_GET_ONE_RESET
} from 'src/redux/constants/practiceConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { formsChangesListener } from 'src/services/formsChangesListener.service'
import { checkRequiredFields, validation } from 'src/services/validation/practiceForm'

export const PracticeForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: practiceId } = useParams()

  let errors: PracticeErrors = {}
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isCancelModalDisabled, setIsCancelModalDisabled] = useState(true)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)

  const [inputs, setInputs] = useState<PracticeInputs>({
    name: '',
    address: '',
    URL: '',
    basPractice: true
  })
  const [oldInputs, setOldInputs] = useState<PracticeInputs>({
    name: '',
    address: '',
    URL: '',
    basPractice: true
  })

  const { practiceGetOne, practiceEdit, practiceCreate } = useAppSelector((state) => state)
  const {
    loading: editPracticeLoading,
    success: editPracticeSuccess,
    error: editPracticeError
  } = practiceEdit
  const { loading: getPracticeLoading, practice, error: getPracticeError } = practiceGetOne
  const {
    loading: createPracticeLoading,
    success: createPracticeSuccess,
    error: createPracticeError
  } = practiceCreate

  useEffect(() => {
    dispatch({ type: PRACTICE_CREATE_RESET })
    setIsFirstLoad(false)
    if (location.pathname.includes('edit')) {
      dispatch(getOne(practiceId as string))
    }
  }, [])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if ((createPracticeSuccess && !isFirstLoad) || (editPracticeSuccess && !isFirstLoad)) {
      setDisplaySuccessButton(true)
      navigate('/practices')
    } else if (createPracticeError || editPracticeError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [createPracticeSuccess, createPracticeError, editPracticeSuccess, editPracticeError])

  useEffect(() => {
    if (practice && !isFirstLoad) {
      setInputs({
        name: practice.name || '',
        address: practice.address || '',
        URL: practice.URL || '',
        basPractice: practice.basPractice === null ? true : practice.basPractice
      })
      setOldInputs({
        name: practice.name || '',
        address: practice.address || '',
        URL: practice.URL || '',
        basPractice: practice.basPractice === null ? true : practice.basPractice
      })
    }
    if (getPracticeError && !isFirstLoad) {
      dispatch({ type: PRACTICE_GET_ONE_RESET })
      dispatch(displayNotFoundElement(true))
    }
  }, [practice, getPracticeError, isFirstLoad])

  const setVal = (value: any, name: string) => {
    setInputs({ ...inputs, [name]: value })
  }

  useEffect(() => {
    const isDisabledRequired = checkRequiredFields(inputs, ['name'])
    let isDisabledChanges = false
    if (practice) {
      isDisabledChanges = formsChangesListener({ inputs, oldInputs })
    }
    if (!isDisabledChanges && practice) {
      setIsCancelModalDisabled(true)
    } else {
      setIsCancelModalDisabled(false)
    }
    if (isDisabledRequired > 0 || (!isDisabledChanges && practice)) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs, practice])

  const onSubmit = (action: string) => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      if (action === 'edit') {
        dispatch(edit(inputs, practiceId as string))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else {
        dispatch(create(inputs))
      }
    }
  }

  if (submitted) {
    errors = validation(inputs)
  }
  return (
    <>
      {getPracticeLoading || editPracticeLoading || createPracticeLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {(editPracticeError || createPracticeError) && (
            <ErrorAlert message={editPracticeError || createPracticeError} hide />
          )}
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/practices')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                const action = practice ? 'edit' : 'create'
                onSubmit(action)
                setIsSaveModal(false)
              }}
              message={`${t('GENERIC.CONFIRM_CHOICE')} ${
                practice ? t('GENERIC.UPDATE').toLowerCase() : t('GENERIC.CREATE').toLowerCase()
              } ${t('PRACTICES.PRACTICE').toLowerCase()}?`}
            />
          )}
          <div className='space-y-6'>
            <InputLabelLeft
              value={inputs['name']}
              type='text'
              onChange={setVal}
              error={errors['name']}
              name='name'
              label={t('PRACTICES.PRACTICE_NAME')}
              required
            />
            <SwitchLabelLeft
              value={inputs['basPractice']}
              onChange={setVal}
              name='basPractice'
              label={t('PRACTICES.BAS_PRACTICE')}
              tooltip={t('PRACTICES.PRACTICE_SWITCH_TEXT')}
            />
            <InputLabelLeft
              placeholder={'https://example.com'}
              value={inputs['URL']}
              type='text'
              onChange={setVal}
              error={errors['URL']}
              name='URL'
              label={t('PRACTICES.PAGE_URL')}
            />
            <InputLabelLeft
              value={inputs['address']}
              type='text'
              onChange={setVal}
              error={errors['address']}
              name='address'
              label={t('PRACTICES.PRACTICE_ADDRESS')}
            />
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (editPracticeLoading || createPracticeLoading || displaySuccessButton) &&
                'processing'
              }`}
              disabled={
                isSaveDisabled ||
                editPracticeLoading ||
                createPracticeLoading ||
                displaySuccessButton
              }
              variant='contained'
            >
              {editPracticeLoading || createPracticeLoading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              onClick={() => {
                if (isCancelModalDisabled) {
                  navigate('/practices')
                } else {
                  setIsCancelModal(true)
                }
              }}
              className='secondary-btn'
              variant='contained'
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
