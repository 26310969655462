export interface FormChangesEntity {
  inputs: any
  oldInputs: any
  multiSelectInputs?: string[]
  ignoreInputs?: string[]
  singleSelectInputs?: string[]
}

export const formsChangesListener = ({
  inputs,
  oldInputs,
  multiSelectInputs = [],
  ignoreInputs = [],
  singleSelectInputs = []
}: FormChangesEntity) => {
  let objectsAreSame = false
  for (const propertyName in inputs) {
    // simple values checker
    if (
      !singleSelectInputs.includes(propertyName) &&
      !ignoreInputs.includes(propertyName) &&
      !multiSelectInputs.includes(propertyName) &&
      inputs[propertyName] != oldInputs[propertyName]
    ) {
      objectsAreSame = true
      break
    }
    // multiselect
    if (multiSelectInputs.includes(propertyName)) {
      for (const input of inputs[propertyName]) {
        const element = oldInputs[propertyName].find(
          (oldInput: any) => oldInput.value === input.value
        )
        if (!element) {
          objectsAreSame = true
          break
        }
      }
      for (const oldInput of oldInputs[propertyName]) {
        const element = inputs[propertyName].find((input: any) => oldInput.value === input.value)
        if (!element) {
          objectsAreSame = true
          break
        }
      }
    }
    // singleselect
    if (singleSelectInputs.includes(propertyName)) {
      if (inputs[propertyName]?.value !== oldInputs[propertyName]?.value) {
        objectsAreSame = true
        break
      }
    }
  }
  return objectsAreSame
}
