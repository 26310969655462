import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/EmptyState'
import Modal from 'src/components/Modal'
import { groupingExaminations } from '../helper/internalFunctions'
import { ActionsEdit, ExaminationItemEdit } from './index'

export function ExaminationsListEdit({
  companyLocationId,
  companyId
}: {
  companyLocationId: string
  companyId: string
}) {
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()
  const { state } = useLocation()
  const [deleteID, setDeleteID] = useState('')
  const { examinations: stateExaminations } = state
  const [certificate, setCertificate] = useState({ name: '' })
  const [deletedExaminations, setDeletedExaminations] = useState<any>([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [examinationsValues, setExaminationsValues] = useState([
    {
      id: moment().format('x'),
      status: 'Completed',
      performedDate: moment(),
      examinationCheckup: null,
      examinationCheckupOption: { label: '', value: '' },
      nextExaminationDate: null,
      examinationNote: null,
      employerCertificate: null,
      examinationType: null,
      employeeId: userId,
      examinationRequired: 2,
      employeeOptedIn: true,
      companyLocationId: companyLocationId && companyLocationId,
      companyId: companyId && companyId
    }
  ])

  useEffect(() => {
    if (stateExaminations) {
      setExaminationsValues(groupingExaminations('', stateExaminations))
      setCertificate({ name: '' })
    }
  }, [stateExaminations])

  const onOpenModal = (deleteID: string) => {
    setDeleteID(deleteID)
    setOpenDeleteModal(true)
  }

  const addExaminationItem = () => {
    const newExamination = {
      id: moment().format('x'),
      status: 'Completed',
      performedDate: moment(),
      examinationCheckup: null,
      examinationCheckupOption: { label: '', value: '' },
      nextExaminationDate: null,
      examinationNote: null,
      employerCertificate: null,
      examinationType: null,
      employeeId: userId,
      examinationRequired: 2,
      employeeOptedIn: true,
      companyLocationId: companyLocationId && companyLocationId,
      companyId: companyId && companyId
    }

    setExaminationsValues((prevExaminations) => [...prevExaminations, newExamination])
  }

  const removeExaminationItem = (id: string) => {
    const newExaminations: any = []
    examinationsValues.forEach((examination: any) => {
      if (examination.id === id) {
        if (id.length !== 13) {
          setDeletedExaminations((prevDeletedExaminations: any) => [
            ...prevDeletedExaminations,
            examination.id
          ])
        }
      } else {
        newExaminations.push(examination)
      }
    })
    setExaminationsValues(newExaminations)
  }

  const setVal = (id: string, value: any, name: string) => {
    const newExaminations: any = examinationsValues.map((examination: any) => {
      if (examination.id === id) {
        const performedDate = examination.performedDate ? examination.performedDate : moment()
        switch (name) {
          case 'status':
            if (value) {
              examination.status = 'Completed'
              examination.performedDate = moment()
            } else {
              examination.status = 'Not performed'
            }
            break
          case 'performedDate':
            examination.performedDate = value
            break
          case 'examinationCheckup':
            examination.examinationCheckup = value.value
            examination.examinationCheckupOption = value
            if (value.value === 'First examination') {
              examination.nextExaminationDate = moment(performedDate).add(12, 'M')
            } else if (value.value === 'Second examination') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            } else if (value.value === 'Follow up') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            }
            break
          case 'nextExaminationDate':
            examination.nextExaminationDate = value
            break
          case 'examinationNote':
            examination.examinationNote = value
            break
          default:
            examination[name] = value
            break
        }
      }
      return examination
    })
    setExaminationsValues(newExaminations)
  }

  return (
    <>
      {openDeleteModal && (
        <Modal
          message={t('EXAMINATION_SELECTION.DELETE_EXAMINATION_PROMPT')}
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={() => {
            removeExaminationItem(deleteID)
            setOpenDeleteModal(false)
          }}
        />
      )}
      <div className=''>
        <div className='flex flex-col'>
          {examinationsValues && examinationsValues.length > 0 ? (
            examinationsValues.map((examination) => {
              const {
                id,
                status,
                performedDate,
                examinationCheckupOption,
                nextExaminationDate,
                employerCertificate,
                examinationType
              } = examination
              return (
                <ExaminationItemEdit
                  key={id}
                  id={id}
                  status={status}
                  performedDate={performedDate}
                  examinationCheckupOption={examinationCheckupOption}
                  nextExaminationDate={nextExaminationDate}
                  employerCertificate={employerCertificate}
                  examinationType={examinationType}
                  setVal={setVal}
                  onOpenModal={onOpenModal}
                />
              )
            })
          ) : (
            <div className='my-5'>
              <EmptyState titleText={t('EXAMINATION_SELECTION.EMPTY_STATE_TITLE')} />
            </div>
          )}
          <div className='ml-auto'>
            <IconButton
              onClick={addExaminationItem}
              title={t('EXAMINATION_SELECTION.ADD_ANOTHER_EXAMINATION')}
            >
              <PlusCircleIcon className='w-6' />
            </IconButton>
          </div>
        </div>
        <ActionsEdit
          certificate={certificate}
          examinations={examinationsValues}
          companyLocationId={companyLocationId}
          deletedExaminations={deletedExaminations}
        />
      </div>
    </>
  )
}
