import { AnyAction } from 'redux'
import {
  APPOINTMENT_REQUEST_GROUP_CREATE_FAIL,
  APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_CREATE_RESET,
  APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_DELETE_FAIL,
  APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_DELETE_RESET,
  APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_EDIT_FAIL,
  APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST,
  APPOINTMENT_REQUEST_GROUP_EDIT_RESET,
  APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_RESET,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS
} from 'src/redux/constants/appointmentRequestEtGroupConstats'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const appointmentRequestEtGroupCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST:
      return { loading: true }
    case APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_REQUEST_GROUP_CREATE_FAIL:
      error = errorMessage
      return { loading: false, error }
    case APPOINTMENT_REQUEST_GROUP_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const appointmentRequestEtGroupDeleteReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST:
      return { loading: true }
    case APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_REQUEST_GROUP_DELETE_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_ETGROUP_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case APPOINTMENT_REQUEST_GROUP_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const appointmentRequestEtGroupGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST:
      return { loading: true }
    case APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS:
      return {
        loading: false,
        appointmentRequestEtGroups: action.payload.appointmentRequestEtGroups,
        count: action.payload.count
      }
    case APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const appointmentRequestEtGroupGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST:
      return { loading: true }
    case APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS:
      return { loading: false, appointmentRequestEtGroup: action.payload }
    case APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_ETGROUP_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case APPOINTMENT_REQUEST_GROUP_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const appointmentRequestEtGroupEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST:
      return { loading: true }
    case APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_REQUEST_GROUP_EDIT_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_ETGROUP_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case APPOINTMENT_REQUEST_GROUP_EDIT_RESET:
      return {}
    default:
      return state
  }
}

export const appointmentRequestEtGroupReducers = {
  appointmentRequestEtGroupCreate: appointmentRequestEtGroupCreateReducer,
  appointmentRequestEtGroupGetAll: appointmentRequestEtGroupGetAllReducer,
  appointmentRequestEtGroupDelete: appointmentRequestEtGroupDeleteReducer,
  appointmentRequestEtGroupGetOne: appointmentRequestEtGroupGetOneReducer,
  appointmentRequestEtGroupEdit: appointmentRequestEtGroupEditReducer
}
