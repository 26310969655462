import {
  EXAMINATION_REQUEST_GET_ONE_FAIL,
  EXAMINATION_REQUEST_GET_ONE_REQUEST,
  EXAMINATION_REQUEST_GET_ONE_SUCCESS
} from 'src/redux/constants/examinationRequestConstants'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const getOne = (id?: string) => (dispatch: AppDispatch) => {
  dispatch({ type: EXAMINATION_REQUEST_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/appointment-request/grouping/${id}`)
    .then((res) => {
      dispatch({ type: EXAMINATION_REQUEST_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXAMINATION_REQUEST_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
