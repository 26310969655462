import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { getOptedOutExaminationsForUser } from 'src/redux/actions/appointmentRequestActions'
import { EXAMINATION_RECORD_MASS_MARK_RESET } from 'src/redux/constants/examinationConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationHistory } from './components/ExaminationsHistory'
import { ExaminationsList } from './components/ExaminationsList'
import { InfoBox } from './components/InfoBox'

export const ExaminationsRecordOptedOut = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { userId } = useParams<{
    userId: string
  }>()
  const { appReqId } = location.state
  const [companyLocationId, setCompanyLocationId] = useState<string>('')
  const [companyId, setCompanyId] = useState<string>('')

  const { examinationRecordMassMark } = useAppSelector((state) => state)

  const {
    loading: massMarkLoading,
    error: massMarkError,
    success: massMarkSuccess
  } = examinationRecordMassMark

  useEffect(() => {
    dispatch({ type: EXAMINATION_RECORD_MASS_MARK_RESET })
  }, [dispatch, userId])

  useEffect(() => {
    if (userId && appReqId) {
      dispatch(getOptedOutExaminationsForUser(userId, appReqId))
    }
  }, [userId, appReqId, dispatch])

  return (
    <>
      {massMarkLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='relative'>
          {massMarkSuccess && <SuccessAlert message={t('ALERTS.SUCCESS_EDIT')} hide />}
          {massMarkError && <ErrorAlert message={massMarkError} hide />}
          <InfoBox setCompanyId={setCompanyId} setCompanyLocationId={setCompanyLocationId} />
          <ExaminationsList companyLocationId={companyLocationId} companyId={companyId} />
          <ExaminationHistory />
        </div>
      )}
    </>
  )
}
