import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button, IconButton } from '@mui/material'
import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import ButtonProcessing from 'src/components/ButtonProcessing'
import Modal from 'src/components/Modal'
import { ReactSelectStyles } from 'src/components/reactSelectStyles'
import WarningModal from 'src/components/WarningModal'
import {
  getAllDoctorsFromBasPractice,
  getBasPracticeByCompanyLocationId
} from 'src/pages/Admins/Companies/helper/externalFunctions'
import { massCreateExaminatiosRecord } from 'src/redux/actions/examinationActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export function ActionsManual({
  examinations,
  certificate,
  companyLocationId
}: InferProps<typeof ActionsManual.propTypes>) {
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [doctorsOptions, setDoctorsOptions] = useState([])
  const [doctorOption, setDoctorsOption] = useState<{ value: string; label: string } | null>(null)
  const [file, setFile] = useState<File | null>(certificate)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { examinationRecordMassMark, userSignin } = useAppSelector((state) => state)
  const { loading: massMarkLoading, success: massMarkSuccess } = examinationRecordMassMark
  const { user: loggedUser } = userSignin
  const { userId } = useParams()

  useEffect(() => {
    if (loggedUser) {
      setDoctorsOption({
        label: `${loggedUser.firstName} ${loggedUser.lastName}`,
        value: loggedUser.id
      })
    }
  }, [loggedUser])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 1000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (massMarkSuccess) {
      setTimeout(() => {
        navigate(`/examinations-history/${userId}`, {
          replace: true
        })
        dispatch({ type: 'EXAMINATION_RECORD_MASS_MARK_RESET' })
      }, 2000)
      setDisplaySuccessButton(true)
    }
  }, [massMarkSuccess])

  const onRemoveCertificat = () => {
    if (inputFileRef.current) {
      setFile(null)
      inputFileRef.current.value = ''
    }
  }

  const checkExaminationLength = (examinations: any) => {
    const isAllExaminationHaveName = examinations?.every(
      (examination: {
        examinationName: any
        performedDate: any
        examinationRequired: any
        nextExaminationDate: any
      }) => {
        const { examinationName, performedDate, examinationRequired, nextExaminationDate } =
          examination
        if (!examinationName || !performedDate || !examinationRequired || !nextExaminationDate) {
          return false
        }
        return true
      }
    )
    if (!isAllExaminationHaveName) {
      setIsWarningModal(true)
      return
    }
    setIsSaveModal(true)
  }

  const onSubmit = () => {
    const filteredExaminations: any = []
    examinations?.forEach((examination) => {
      const {
        id,
        status,
        examinationNote,
        examinationCheckup,
        performedDate,
        nextExaminationDate,
        examinationRequired,
        employeeId,
        companyId,
        companyLocationId,
        examinationName,
        employeeOptedIn
      } = examination

      filteredExaminations.push({
        id,
        status,
        examinationNote,
        examinationCheckup,
        performedDate,
        nextExaminationDate,
        examinationRequired,
        employeeId,
        companyId,
        companyLocationId,
        employeeOptedIn,
        examinationTypeId: examinationName
      })
    })
    const data = {
      examinations: filteredExaminations,
      doctorId: doctorOption?.value
    }
    dispatch(massCreateExaminatiosRecord(data, file))
    setIsSaveModal(false)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const inputFileRef = useRef<HTMLInputElement>(null)
  const triggerInput = () => {
    inputFileRef?.current?.click()
  }
  const handleFileChange = (e: any) => {
    if (e.target.files[0]?.name) {
      setFile(e.target.files[0])
    } else {
      setFile(null)
    }
  }
  useEffect(() => {
    if (certificate) {
      setFile(certificate)
    }
  }, [certificate])

  useEffect(() => {
    ;(async () => {
      const doctorsHelper: any = []
      if (companyLocationId) {
        const response = await getBasPracticeByCompanyLocationId({ id: companyLocationId })
        if (response) {
          const doctors = await getAllDoctorsFromBasPractice({ id: response[0].id })
          if (doctors) {
            doctors.forEach((doctor: any) => {
              doctorsHelper.push({
                label: `${doctor.firstName} ${doctor.lastName}`,
                value: doctor.id
              })
            })
            setDoctorsOptions(doctorsHelper)
          }
        }
      }
    })()
  }, [companyLocationId])

  return (
    <div className='mt-8'>
      {isSaveModal && (
        <Modal
          onCancel={() => setIsSaveModal(false)}
          onConfirm={() => onSubmit()}
          message={t('MODAL.SAVE_RECORD')}
        />
      )}
      {isWarningModal && (
        <WarningModal
          onCancel={() => setIsWarningModal(false)}
          message={t('WARNING_MODAL.WARNING_EXAMINATIONS')}
        />
      )}
      {isCancelModal && (
        <Modal
          onCancel={() => setIsCancelModal(false)}
          onConfirm={() => navigate('/clients')}
          message={t('MODAL.CANCEL')}
        />
      )}
      <div className='flex flex-col items-center justify-between md:flex-row-reverse gap-2'>
        <div className='flex items-center gap-3 w-full justify-end'>
          <Button
            onClick={triggerInput}
            className='main-btn border'
            size='small'
            variant='contained'
          >
            {t('EXAMINATIONS_RECORD.UPLOAD_CERTIFICATE')}
          </Button>
          {file?.name && (
            <>
              <h4 className='customHead4 !text-blue-500'>{t('EXAMINATIONS_RECORD.CERTIFICATE')}</h4>
              <IconButton
                className='removeCerticifateBtn ml-auto flex gap-2 items-center px-0 hover:bg-white'
                onClick={() => onRemoveCertificat()}
              >
                <XCircleIcon className='w-7 text-brand-orange-700' />
                <p className='text-sm text-brand-orange-700'>
                  {t('EXAMINATIONS_RECORD.REMOVE_CERTIFICATE')}
                </p>
              </IconButton>
            </>
          )}
          <input
            onChange={handleFileChange}
            ref={inputFileRef}
            type='file'
            accept='.pdf'
            className='hidden'
          />
        </div>
        <div className='flex gap-2 w-full'>
          <div className='custom-select-input w-full md:max-w-[200px]'>
            <Select
              value={doctorOption}
              options={doctorsOptions}
              onChange={(val) => setDoctorsOption(val)}
              styles={ReactSelectStyles}
            />
          </div>
          <Button
            onClick={() => checkExaminationLength(examinations)}
            className={`main-btn border ${
              (massMarkLoading || displaySuccessButton) && 'processing'
            }`}
            disabled={massMarkLoading || displaySuccessButton}
            variant='contained'
          >
            {massMarkLoading || displaySuccessButton ? (
              <ButtonProcessing displaySuccessButton={displaySuccessButton} />
            ) : (
              t('BUTTONS.SAVE')
            )}
          </Button>
          <Button
            onClick={() => setIsCancelModal(true)}
            className='secondary-btn border'
            variant='contained'
          >
            {t('BUTTONS.CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  )
}

ActionsManual.propTypes = {
  examinations: PropTypes.array,
  certificate: PropTypes.any,
  companyLocationId: PropTypes.string
}
