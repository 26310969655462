import { AnyAction } from 'redux'
import { DISPLAY_NOT_FOUNT_ELEMENT } from 'src/redux/constants/notFoundConstants'

const notFoundElementDisplayReducer = (state = '', action: AnyAction) => {
  switch (action.type) {
    case DISPLAY_NOT_FOUNT_ELEMENT:
      return { displayed: action.payload }
    default:
      return state
  }
}

export const notFoundElementReducers = {
  notFoundElementDisplay: notFoundElementDisplayReducer
}
