import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/redux/hook'

export function InfoBox() {
  const { t } = useTranslation()
  const { aRAppointmentsOverviewGet } = useAppSelector((state) => state)
  const { appointmentRequest } = aRAppointmentsOverviewGet

  return (
    <div>
      {appointmentRequest?.infoData && (
        <div className='customInfoBox flex flex-col gap-2'>
          <p>
            <span>{t('COMPANY.CUSTOMER')}:</span> {appointmentRequest?.infoData.customer}
          </p>
          <p>
            <span>{t('COMPANY.LOCATION')}:</span> {appointmentRequest?.infoData.location}
          </p>
          <div className='flex gap-2'>
            <p>
              <span>{t('CREATE_PREVENTIVE_LOG_CUSTOMER.CLIENT_CONTACT')}:</span>
            </p>
            <ul className='customUl'>
              <li className='strong'>{appointmentRequest?.infoData.clientContact.name}</li>
              <li className='strong'>{appointmentRequest?.infoData.clientContact.phone}</li>
              <li className='strong'>{appointmentRequest?.infoData.clientContact.email}</li>
            </ul>
          </div>
          <p>
            <span>{t('SCHEDULING_SCREEN.NO_EMPLOYEES_ASSIGNED')}:</span>{' '}
            {appointmentRequest?.infoData.numOfEmployeesAssigned}
          </p>
          <p>
            <span>{t('SCHEDULING_SCREEN.NO_EMPLOYEES_SCHEDULED')}:</span>{' '}
            {appointmentRequest?.infoData.numberOfEmployeesScheduled}
          </p>
          <p>
            <span>{t('SCHEDULING_SCREEN.NO_EMPLOYEES_EXAMINED')}:</span>{' '}
            {appointmentRequest?.infoData.numberOfEmployeesExamined}
          </p>
          <p>
            <span>{t('SCHEDULING_SCREEN.TOTAL_ESTIMATED_TIME')}:</span>{' '}
            {appointmentRequest?.infoData.totalEstimatedExaminationsTime}{' '}
            {t('SCHEDULING_SCREEN.MINUTES')}
          </p>
          <p>
            <span>{t('SCHEDULING_SCREEN.TOTAL_AVAILABILITY')}:</span>{' '}
            {appointmentRequest?.infoData.totalAvailabilityProvided}{' '}
            {t('SCHEDULING_SCREEN.MINUTES')}
          </p>
          <p>
            <span>{t('SCHEDULING_SCREEN.TOTAL_BOOKED')}:</span>{' '}
            {appointmentRequest?.infoData.totalTimeBooked} {t('SCHEDULING_SCREEN.MINUTES')}
          </p>
          <p>
            <span className='block mb-1'>{t('SCHEDULING_SCREEN.MESSAGE')}:</span>{' '}
            {appointmentRequest?.infoData.clientMessage}
          </p>
        </div>
      )}
    </div>
  )
}
