import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert, WarnAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { UserEntity } from 'src/entities/interfaces'
import { SelectedExaminationsInput } from 'src/interfaces/companyLocationSelectedExaminationType'
import { getOne as getOneCompany } from 'src/redux/actions/companyActions'
import { getCompanyLocationByID } from 'src/redux/actions/companyLocationActions'
import { shareWithClient } from 'src/redux/actions/companyLocationSelectedExaminationTypeActions'
import { getOne as getOnePractice } from 'src/redux/actions/practiceActions'
import { getAllUsers } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationTypesList, SelectedExaminations } from './components'

const ExaminationsSelection = () => {
  const { t } = useTranslation()
  const [selectedExaminationTypes, setSelectedExaminationTypes] = useState<
    SelectedExaminationsInput[]
  >([])
  const [isSaved, setIsSaved] = useState(true)
  const [isShareModal, setIsShareModal] = useState(false)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [query, setQuery] = useState<string>('')

  const { id: companyLocationId } = useParams()
  const dispatch = useAppDispatch()

  const {
    companyLocationSelectedExaminationTypeMassCreate,
    userSignin,
    companyLocationByID,
    userGetAll,
    companyLocationSelectedExaminationTypeShareWithClient
  } = useAppSelector((state) => state)

  const { success: successSelected, error: errorSelected } =
    companyLocationSelectedExaminationTypeMassCreate
  const { companyLocation } = companyLocationByID
  const { user } = userSignin
  const { users } = userGetAll
  const {
    success: successShare,
    error: errorShare,
    loading: loadingShare
  } = companyLocationSelectedExaminationTypeShareWithClient

  useEffect(() => {
    if (companyLocationId) {
      dispatch(getCompanyLocationByID(companyLocationId))
    }
  }, [companyLocationId])

  useEffect(() => {
    if (companyLocation) {
      dispatch(getOnePractice(companyLocation.basPracticeId))
      dispatch(getOneCompany(companyLocation.companyId))
      dispatch(
        getAllUsers({
          page: 0,
          limit: 100,
          roleType: 'contact',
          companyLocationId: companyLocation.id
        })
      )
    }
  }, [companyLocation])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (successShare) {
      setDisplaySuccessButton(true)
    }
  }, [successShare])

  const onShareWithClient = () => {
    const clientsEmails = users.map((user: UserEntity) => user.email)
    const data = {
      companyLocationId: companyLocation.id,
      receiverEmail: clientsEmails,
      senderEmail: user.email
    }
    dispatch(shareWithClient(data))
  }

  return (
    <>
      {loadingShare ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex items-center gap-3 mt-4'>
            {users?.length === 0 && (
              <div className='max-w-xl grow mb-3'>
                <WarnAlert
                  message={`${t('EXAMINATION_SELECTION.CLIENT_CONTACT_NOT_HAVE_CONTACT_PERSON')}`}
                />
              </div>
            )}
            {users?.length > 0 && !isSaved && (
              <div className='max-w-xl grow mb-3'>
                <WarnAlert message={`${t('EXAMINATION_SELECTION.UNSAVED_CHANGES')}`} />
              </div>
            )}
          </div>
          {successSelected && <SuccessAlert hide message={t('ALERTS.SUCCESS_SELECTION')} />}
          {successShare && <SuccessAlert hide message={t('ALERTS.SUCCESS_SHARE')} />}
          {(errorSelected || errorShare) && <ErrorAlert message={errorSelected || errorShare} />}
          {isShareModal && (
            <Modal
              onCancel={() => setIsShareModal(false)}
              onConfirm={() => {
                onShareWithClient()
                setIsShareModal(false)
              }}
              message={t('MODAL.SHARE_SELECTION')}
            />
          )}
          <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-2 md:gap-0'>
            <div className='md:w-full'>
              <label className='mb-2 block customLabel'>
                {t('EXAMINATION_TYPES_LIST.SEARCH_BY_TEXT')}
              </label>
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t('GENERIC.SEARCH')}
                type='text'
                className='w-full customInput max-w-[560px] block appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200'
              />
            </div>
            <Button
              disabled={
                users?.length === 0 ||
                (users?.length > 0 && !isSaved) ||
                loadingShare ||
                displaySuccessButton
              }
              onClick={() => setIsShareModal(true)}
              type='submit'
              className={`main-btn whitespace-pre ${
                (loadingShare || displaySuccessButton) && 'processing'
              }`}
              variant='contained'
            >
              {loadingShare || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('EXAMINATION_SELECTION.SHARE_WITH_CLIENT')
              )}
            </Button>
          </div>
          <div className='flex flex-col-reverse md:flex-row mt-12'>
            <div className='border-r border-gray-200 w-full'>
              <ExaminationTypesList
                setSelectedExaminationTypes={setSelectedExaminationTypes}
                selectedExaminationTypes={selectedExaminationTypes}
                query={query}
              />
            </div>
            <div className='w-full md:pl-5'>
              <SelectedExaminations
                setSelectedExaminationTypes={setSelectedExaminationTypes}
                selectedExaminationTypes={selectedExaminationTypes}
                setIsSaved={setIsSaved}
                isSaved={isSaved}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ExaminationsSelection
