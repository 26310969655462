import moment from 'moment'
import { AppointmentRequestETGroupEntity } from 'src/entities/interfaces'
import { api } from 'src/services/api.service'

export const getBookedAppointments = async (id: string) => {
  return await api
    .get(`/api/admin/appointment/find-occupied-slot/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const generateUid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2)
}

export const getTotalUsers = (appointmentRequestEtGroups: any) => {
  if (appointmentRequestEtGroups) {
    const userHelper: any[] = []
    appointmentRequestEtGroups.map((ex: { numberOfUsers: any }) => {
      userHelper.push(ex.numberOfUsers)
    })
    return userHelper.reduce((acc: number, value: number) => acc + value, 0)
  }
}

export const calculateGroupDuration = (
  groupId: string,
  appointmentRequestEtGroups: AppointmentRequestETGroupEntity[]
) => {
  const group = appointmentRequestEtGroups.find((group) => group.id === groupId)
  if (group) {
    return group.numberOfUsers * group.minDoctorPerPatient
  }
}

export const getMinutesDiff = (str1: string, str2: string) => {
  //MUST BE HH:mm format
  const str1Split = str1.split(':')
  const str2Split = str2.split(':')
  const time1 = Number(str1Split[0]) * 60 + Number(str1Split[1])
  const time2 = Number(str2Split[0]) * 60 + Number(str2Split[1])
  if (time1 > time2) {
    return time1 - time2
  }
  return false
}

export const compareEvents = (events: any, savedEvents: any) => {
  let eventsAreSame = true
  for (const event of events) {
    const eventExist = savedEvents.find((savedEvent: any) => savedEvent.id === event.id)
    if (eventExist) {
      const startDifference = moment(event.start).diff(eventExist.start) === 0
      const endDifference = moment(event.end).diff(moment(eventExist.end)) === 0
      if (!endDifference || !startDifference) {
        eventsAreSame = false
      }
    } else {
      eventsAreSame = false
    }
    if (!eventsAreSame) {
      break
    }
  }

  return eventsAreSame
}

export const getProvidedSlotsTime = (events: any, groupId: string) => {
  let providedMinutes = 0
  events.forEach((event: any) => {
    if (event?.etGroupId === groupId) {
      const minutesDiff = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes()
      providedMinutes += minutesDiff
    }
  })
  return providedMinutes
}
