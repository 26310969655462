import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { GLOBAL_RESET } from 'src/redux/constants/globalConstants'
import { appointmentReducers } from 'src/redux/reducers/appointmentReducer'
import { appointmentRequestEtGroupReducers } from 'src/redux/reducers/appointmentRequestEtGroupReducer'
import { appointmentRequestReducers } from 'src/redux/reducers/appointmentRequestReducer'
import { authReducers } from 'src/redux/reducers/authReducers'
import { companyLocationReducers } from 'src/redux/reducers/companyLocationReducer'
import { companyLocationSelectedExaminationTypeReducers } from 'src/redux/reducers/companyLocationSelectedEaminationTypeReducer'
import { companyReducers } from 'src/redux/reducers/companyReducers'
import { doctorAvailabillityReducers } from 'src/redux/reducers/doctorAvailabillityReducer'
import { examinationReducers } from 'src/redux/reducers/examinationReducer'
import { examinationRequestReducers } from 'src/redux/reducers/examinationRequestReducer'
import { examinationTypeReducers } from 'src/redux/reducers/examinationTypeReducers'
import { notFoundElementReducers } from 'src/redux/reducers/notFoundReducers'
import { practicesReducers } from 'src/redux/reducers/practiceReducers'
import { preventiveLogReducers } from 'src/redux/reducers/preventiveLogReducer'
import { riskExposureReducers } from 'src/redux/reducers/riskExposureReducers'
import { roleReducers } from 'src/redux/reducers/roleReducers'
import { userReducers } from 'src/redux/reducers/userReducers'
import { widgetReducers } from 'src/redux/reducers/widgetReducer'
import { getAccessToken } from 'src/services/token.service'

const initialState = {
  userSignin: {
    // eslint-disable-next-line
    isSignin: !!getAccessToken() ? true : false,
    user: localStorage.getItem('USER_INFO')
      ? JSON.parse(localStorage.getItem('USER_INFO') as string)
      : null
  }
}

const reducer = combineReducers({
  ...authReducers,
  ...practicesReducers,
  ...riskExposureReducers,
  ...companyReducers,
  ...examinationTypeReducers,
  ...userReducers,
  ...roleReducers,
  ...preventiveLogReducers,
  ...companyLocationReducers,
  ...companyLocationSelectedExaminationTypeReducers,
  ...examinationRequestReducers,
  ...doctorAvailabillityReducers,
  ...appointmentRequestEtGroupReducers,
  ...appointmentRequestReducers,
  ...appointmentReducers,
  ...examinationReducers,
  ...notFoundElementReducers,
  ...widgetReducers
})

const rootReducer = (state: any, action: any) => {
  if (action.type === GLOBAL_RESET) {
    state = undefined
  }

  return reducer(state, action)
}
const store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk)))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
