/* eslint-disable */
export const en = {
  NAVBAR: {
    DASHBOARD: 'Dashboard',
    PRACTICES: 'Practices',
    RISK_EXPOSURE: 'Risk exposures',
    COMPANIES: 'Companies',
    EXAMINATION_TYPES: 'Examination types',
    USERS: 'Users',
    OPEN_USER_MENU: 'Open user menu',
    CREATE_EMPLOYEE: 'Create employee',
    EDIT_EMPLOYEE: 'Edit employee',
    CUSTOMERS: 'Add new customer',
    CUSTOMER_OVERVIEW: 'Customers overview',
    NEW_PREVENTIVE_LOG: 'Create new Preventive log customer',
    PREVENTIVE_LOG: 'Preventive log',
    APPOINTMENT_REQUEST: 'Appointment requests',
    CREATE_EXAMINATION_TYPES: 'Create new examination type',
    CREATE_RISK_EXPOSURE: 'Create risk exposure',
    EXAMINATIONS_HISTORY: 'Examinations history',
    EDIT_EXAMINATION_TYPES: 'Edit examination type details',
    SCHEDULED_APPOINTMENTS_OVERVIEW: 'Scheduled appointments overview',
    EXAMINATIONS_SELECTION: 'Examinations selection',
    AGENDA: 'Agenda',
    EXAMINATIONS_RECORD: 'Examinations record',
    COMPANIES_LOCATIONS: 'Customers',
    CUSTOMER_LOCATIONS: 'Customers (locations)',
    EDIT_EXAMINATION_RECORD: 'Edit examination record',
    CONTACT_PERSONS: 'Contact persons'
  },
  USER_ROLES: {
    ADMIN: 'Admin',
    SUPERADMIN: 'Superadmin',
    MFA: 'Mfa',
    DOCTOR: 'Doctor',
    EMPLOYEE: 'Employee',
    EMPLOYEES: 'Employees'
  },
  MODAL_ACTIONS_ROLES: {
    ADMIN: 'admin',
    SUPERADMIN: 'superadmin',
    MFA: 'mfa',
    DOCTOR: 'doctor',
    EMPLOYEE: 'employee',
    EMPLOYEES: 'employees',
    COMPANY: 'company'
  },
  GENERIC: {
    LOADING: 'Loading',
    SELECTED: 'Selected',
    INSTRUCTIONS: 'Instructions',
    SELECTED_EXAMINATION_TYPES: 'Selected examination types',
    OK: 'Ok',
    NEW: 'New',
    ERROR_MESSAGE: 'Error message',
    UPDATE: 'Update',
    UNKNOWN: 'unknown',
    CREATE: 'Create',
    CONFIRM_CHOICE: 'Are you sure you want to',
    TITLE: 'Title',
    GROUP: 'Group',
    DAYS: 'days',
    HOURS: 'hours',
    MINUTES: 'minutes',
    STATUS: 'Status',
    SEARCH: 'Search',
    DELETE: 'Delete',
    EDIT: 'Edit',
    EDIT_PASSWORD: 'Edit password',
    REQUIRED_FIELD: 'Required field',
    YES: 'Yes',
    NO: 'No',
    SHOW_ALL: 'Show all',
    FILTER: 'Filter',
    ROWS_PER_PAGE: 'Rows per page'
  },
  USER_INFO: {
    FIRST_NAME: 'First name',
    FULL_NAME: 'Full name',
    LAST_NAME: 'Last name',
    EMAIL: 'Email',
    PHONE_NUMBER: 'Phone number',
    AGE: 'Age',
    USERNAME: 'User name',
    DATE_OF_BIRTH: 'Date of birth',
    DEPARTMENT: 'Department',
    POSITION: 'Position',
    CURRENT_DEPARTMENT: 'Current department',
    CURRENT_POSITION: 'Current position',
    JOINED_COMPANY_DATE: 'Joined company date',
    LEFT_COMPANY_DATE: 'Left company date',
    LAST_EXAMINATION_DATE: 'Date of last examinations',
    GENDER: 'Gender',
    SUPERVISOR: 'Supervisor'
  },
  BUTTONS: {
    SAVE: 'Save',
    CANCEL: 'Cancel',
    UPLOAD_FILE: 'Upload file',
    PROCESSING: 'Processing...',
    SUCCESSFULLY: 'Successfully',
    SEND: 'Send'
  },
  AUTH: {
    SIGN_IN: 'Sign in',
    SIGN: 'Sign up',
    SIGN_OUT: 'Sign out',
    SIGN_IN_ACCOUNT: 'Sign in to your account',
    CREATE_ACCOUNT: 'Create your account',
    AGREE_TO: 'I agree to the',
    BAS_TERMS: 'Terms & Conditions',
    ALREADY_REGISTERED: 'Already registered',
    LOG_IN_HERE: 'Log in here',
    TERMS_CONDITIONS: 'Terms & Conditions',
    CHANGE_PASSWORD: 'Change password',
    CONFIRM_LOGOUT: 'Are you sure to want to sign out?',
    PASSWORD: 'Password',
    EMAIL_OR_USERNAME: 'Email or user name'
  },
  PRACTICES: {
    PRACTICE: 'Practice',
    PRACTICE_NAME: 'Practice name',
    EXTERNAL_PARTNER: 'External partner',
    CREATE_PRACTICE: 'Create new practice',
    EDIT_PRACTICE: 'Edit practice details',
    BAS_PRACTICE: 'BAS Practice',
    PRACTICE_PAGE_URL: 'Practice page URL',
    PRACTICE_ADDRESS: 'Practice address',
    PRACTICE_SWITCH_TEXT: 'switch off if this is an external practice in the BAS network',
    PAGE_URL: 'Practice page URL',
    NO: 'No',
    YES: 'Yes',
    NUMBER_OF_ASSOCIATED_CUSTOMERS: 'Customers'
  },
  RISK_EXPOSURE: {
    RISK_EXPOSURE: 'Risk exposure',
    RISK_EXPOSURE_GROUPS: 'Risk exposure groups',
    CREATE_RISK_EXPOSURE: 'Create new risk exposure',
    EDIT_RISK_EXPOSURE: 'Edit risk exposure details',
    TITLE: 'Risk exposure title',
    NAME: 'Risk exposure name',
    RISK_EXPOSURES: 'Risk exposures'
  },
  COMPANIES_LOCATIONS: {
    CUSTOMER: 'Customer',
    CONTACT_PERSONS: 'Contact person(s)',
    ASSOCIATED_PRACTICE: 'Associated practice',
    NUMBER_OF_EMPLOYEES: 'Employees',
    NO_COMPANIES: 'customers'
  },
  COMPANY: {
    COMPANY: 'Company',
    COMPANY_NAME: 'Company name',
    COMPANY_NUMBER: 'Company number',
    CONTRACT_IN_PLACE: 'Contract in Place',
    NUMBER_OF_LOCATIONS: 'Locations',
    NUMBER_OF_PREVENTIVE_LOGS: 'Preventive logs',
    LOCATION: 'Location',
    LOCATION_NAME: 'Location name',
    LOCATION_ADDRESS: 'Location address',
    ASSOCIATED_PRACTICE: 'Associated practice',
    REMOVE_LOCATION: 'Remove this location',
    ADD_LOCATION: 'Add new location',
    CREATE: 'Create new company',
    EDIT: 'Edit company details',
    PREVENTIVE_LOG_ERROR: `This location can't be removed since it has an associated preventive log`,
    CONTACT_EMAIL: 'Contact email',
    CUSTOMER: 'Customer',
    CONTACT_PERSON: 'Contact person',
    NO_OF_EMPLOYEES: 'Employees',
    LAST_EXAMINATION_PERFORMED: 'Last examinations',
    NEXT_EXAMINATION_DUE: 'Next examination due',
    YES: 'Yes',
    NO: 'No',
    STREET_ADDRESS: 'Street address',
    CONTACT_PERSON_GRID: 'Contact person(s)'
  },
  COMPANY_LOCATION: {
    PREVENTIVE_LOG_ERROR: `This company can't be removed since at least one of its locations has an associated preventive log`
  },
  CREATE_PREVENTIVE_LOG_CUSTOMER: {
    SELECT_LOCATION: 'Select the customer location from master database:',
    CONTACT_EMAIL: 'Customer contact email:',
    REMOVE_CONTACT: 'Remove this additional contact',
    ADD_ADDITIONAL_CONTACT: 'Add additional contact',
    NEW_CUSTOMER: 'New customer',
    CLIENT: 'Customer',
    CLIENT_CONTACT: 'Customer contact',
    ADDRESS: 'Address',
    NUMBER_OF_EMPLOYEES_TEXT: 'employees to be examined',
    CLIENT_CONTACT_MESSAGE: 'Customer contact message',
    SAVE_TIMEFRAME: 'Save',
    SUCCESS_CREATE: 'New customer successfully added and customer contact invited.',
    INFO_MESSAGE:
      'Employees are grouped on the left by assigned examination combos. Select one group and mark one or more timeframes in the calendar that you want to provide to that group. Make sure you have provided enough time to meet the total expected time to perform the examinations.',
    INFO_MESSAGE_UNDER: 'Repeat the process for each group if there is more than one.',
    NEW_MANUAL_EXAMINATION: 'Perform examination',
    CHOOSE_LOCATION_DROPDOWN: 'Choose a location or add a new one',
    CHOOSE_LOCATION_TEXT:
      'Please select the location where the preventive check-ups will be carried out.',
    CREATE_NEW: 'Create "{{value}}"'
  },
  EXAMINATIONS: {
    EXAMINATIONS: 'Examinations',
    NEXT_EXAMINATION: 'Next examination',
    SCHEDULED: 'Scheduled',
    DUE: 'Due',
    OVERDUE: 'Overdue',
    RECOMMENDED_EXAMINATIONS: 'Recommended examinations',
    RECOMMENDATIONS: 'Recommendations',
    EXAMINATION_TITLE: 'Examination title',
    EXAMINATION_INTRO: 'Examination intro for employees',
    EXAMINATION_DURATION: 'Examination duration',
    DATE_OF_REQUEST: 'Date of request',
    BACK_TO_APPOINTMENT: 'Back to appointment overview',
    BACK_TO_CUSTOMERS_OVERVIEW: 'Back to customers overview',
    BACK_TO_EXAMINATION_HISTORY: 'Back to examination history',
    OPT_OUT: 'Opt out'
  },
  EXAMINATION_REQUEST: {
    WILL_TAKE: 'will take',
    ESTIMATED_TIME: 'Estimated time',
    MIN_DOCTOR: 'min doctor',
    MIN_MFA: 'min MFA',
    PER_PATIENT: 'per patient',
    SAVE_IDANA_LINK: 'Save IDANA link',
    PROVIDED: 'You provided',
    OF: 'of',
    EMPLOYEES: 'employees',
    EMPLOYEE: 'employee'
  },
  EXAMINATION_TYPES: {
    MANDATORY: 'Mandatory',
    RECOMMENDED: 'Recommended',
    OFF: 'Off',
    EXAMINATION_TITLE: 'Examination title',
    ASSOCIATED_RISK_AREAS: 'Associated risk areas',
    DOCTOR_DURATION: 'Doctor duration',
    MFA_DURATION: 'MFA duration',
    TIME_EXPRESSED: 'Time is expressed in minutes',
    TOTAL_EXAMINATION_TIME: 'Total estimated time for all groups',
    EXAMINATION_TYPE: 'Examination type',
    OCCUPIED: 'OCCUPIED'
  },
  USER: {
    USERS: 'Users',
    ROLE: 'Role',
    CREATE_USER: 'Create new user',
    EDIT_USER: 'Edit user details',
    USER: 'User'
  },
  EXAMINATION_SELECTION: {
    EXAMINATION_SELECTION: 'Examination selection',
    REQUEST_FOR_EXAMINATION: 'Request for examination',
    CREATE_TIME_SLOT: 'Provide examinations timeframe for',
    SHARE_WITH_CLIENT: 'Share with customer',
    ASSIGNED_PRACTICE: 'Assigned practice',
    CLIENT_CONTACT_INSTRUCTIONS: 'Customer contact instructions',
    CLIENT_CONTACT_NOT_HAVE_CONTACT_PERSON: `This customer's location does not have a contact person assigned yet`,
    UNSAVED_CHANGES: 'You have unsaved changes',
    REMOVE_EXAMINATION_TYPE: 'Remove examination type from the list',
    ADD_ALL_TO_THE_LIST: 'Add all to the list',
    ADD_TO_THE_LIST: 'Add to the list',
    LAST_ASSIGNED_BY: 'Last assigned by',
    ADD_ANOTHER_EXAMINATION: 'Add examination',
    REMOVE_EXAMINATION: 'Remove examination',
    DELETE_EXAMINATION_PROMPT: 'Are you sure you want to delete this examination?',
    EMPTY_STATE_TITLE: 'examinations.',
    CANNOT_REMOVE_ASSIGNED_EXAMINATION:
      'This examination is assigned to some employees and can not be removed'
  },
  EXAMINATION_TYPES_LIST: {
    SEARCH_BY_TEXT: 'Search by exposure group / risk area / examination type',
    SELECT_EXAMINATIONS: 'Select examinations'
  },
  LOCATIONS: {
    LOCATION_REQUIREMENT: 'You need to add at least one location',
    LOCATION: 'Location',
    REMOVE_LOCATION: 'Remove location',
    ADD_LOCATION: 'Add new location'
  },
  ERROR_MESSAGES: {
    FIRST_NAME_UNDEFINED: 'Missing First name',
    LAST_NAME_UNDEFINED: 'Missing Last name',
    EMAIL_FORMAT_IS_WRONG: 'Invalid email address',
    INVALID_NUMBER: 'Invalid number',
    EMAIL_ALREADY_IN_USE: 'Email address already in use',
    BIRTH_DATE_UNDEFINED: 'Missing Date of birth',
    NO_TIMEFRAMES: 'You need to add timeframes for all groups',
    AUTH_IS_REQUIRED: 'This field is required',
    IS_REQUIRED: 'is required',
    TOO_MANY_CHARACTERS: 'This field has too many characters',
    INVALID_EMAIL: 'This is not a valid email',
    INVALID_URL: 'This is not a valid URL format',
    PASSWORD_VALIDATION:
      'Password must be at least 8 characters long, include one number and symbol and one upper case character',
    NO_GROUP: 'You need to choose at least one group',
    PRACTICE_REQUIRED: 'At least one practice',
    DEFAULT: 'Something went wrong, please try again',
    COMPANY_NAME_LIMITED: 'Company name is limited to 100 characters',
    EXAMINATION_TITLE_LIMITED: 'Examination title is limited to 100 characters',
    USER_NOT_FOUND: 'User not found',
    WRONG_PASSWORD: 'Email or password you entered is incorrect. Please try again',
    COMPANY_NUMBER_TAKEN: 'This company number is already in use',
    PRACTICE_DELETE: `Practice can't be deleted while there are still associated companies to it`,
    USERNAME_TAKEN: 'This user name is already in use',
    EMAIL_TAKEN: 'This email address is already in use',
    NO_ENOUGH_TIME: 'Please provide enough time for all groups',
    IS_NOT_NUMBER: 'This value must be a number',
    NO_CHOOSEN_LOCATION: 'Please provide a location',
    APPOINTMENT_NOT_FOUND: 'Appointment not found',
    APP_REQUEST_ETGROUP_NOT_FOUND: 'Appointment request group not found',
    APP_REQUEST_NOT_FOUND: 'Appointment request not found',
    APP_REQUEST_ET_TYPE_NOT_FOUND: 'Appointment request examination type not found',
    USER_ALREADY_EXISTS: 'User already exists',
    INVALID_REFRESH_TOKEN: 'Invalid refresh token',
    PRACTICE_IN_USE: 'Practice is in use and can not be deleted',
    PRACTICE_NOT_FOUND: 'Practice not found',
    PRACTICE_ALREADY_EXISTS: 'Practice already exists',
    COMPANY_NUMBER_IN_USE: 'Company number is already in use',
    COMPANY_NOT_FOUND: 'Company not found',
    COMPANY_HAS_CONTACTS: 'Company has contact persons and can not be deleted',
    COMPANY_LOCATION_NOT_FOUND: 'Company location not found',
    CONTACT_CANNOT_BE_DELETED: 'Contact person can not be deleted',
    EXAMINATION_TYPE_NOT_FOUND: 'Examination type not found',
    DOCTORAVAILABILITY_NOT_FOUND: 'Doctor availability timeframe not found',
    EXAMINATION_NOT_FOUND: 'Examination not found',
    EXAMINATIONS_NOT_FOUND: 'Examinations not found',
    FILE_NOT_FOUND: 'File not found',
    EXAMINATION_TYPE_IN_USE: 'Examination type is in use and can not be deleted',
    MEDICAL_CHECK_NOT_FOUND: 'Medical check not found',
    PREVENTIVE_LOG_NOT_FOUND: 'Preventive log not found',
    PREVENTIVE_LOG_EXAMINATION_TYPE_NOT_FOUND: 'Preventive log examination type not found',
    RISK_AREA_NOT_FOUND: 'Risk area not found',
    RISK_EXPOSURE_GROUP_NOT_FOUND: 'Risk exposure group not found',
    ROLE_NOT_FOUND: 'Role not found',
    ROLES_NOT_FOUND: 'Roles not found',
    EMPLOYEE_NOT_FOUND: 'Employee not found',
    ACCESS_DENIED: 'Access denied',
    COMPANY_SLUG_NOT_SAFE: 'Company slug is not safe to use',
    COMPANY_ALREADY_EXISTS: 'Company already exists'
  },
  GENDER_OPTIONS: {
    UNDISCLOSED: 'Undisclosed',
    NON_BINARY: 'Non-binary'
  },
  SCHEDULING_SCREEN: {
    TILL: 'until',
    NO_EMPLOYEES_ASSIGNED: 'Number of employees assigned',
    NO_EMPLOYEES_SCHEDULED: 'Number of employees scheduled',
    NO_EMPLOYEES_EXAMINED: 'Number of employees examined',
    TOTAL_ESTIMATED_TIME: 'Total estimated examinations time',
    TOTAL_AVAILABILITY: 'Total availability provided',
    TOTAL_BOOKED: 'Total time booked',
    MESSAGE: 'Message',
    EXAMINATIONS_COMPLETE: 'Examination complete',
    CERTIFICATES_UPLOADED: 'Certificate uploaded',
    CERTIFICATES_MISSING: 'Certificate missing',
    OPTED_OUT: 'Opted out',
    NEXT_DUE_DATES_PROVIDED: 'Next due dates provided',
    MINUTES: 'minutes'
  },
  PREVENTIVE_LOG: {
    SCHEDULED_AT: 'Scheduled at',
    OVERDUE: 'Overdue',
    DUE_SOON: 'Due soon',
    SCHEDULED: 'Scheduled',
    REQUESTED: 'Requested',
    INVITED: 'Invited',
    PENDING: 'Pending',
    EXAMINATION_HISTORY: 'Examination history for {{firstName}} {{lastName}}'
  },
  ALERTS: {
    SUCCESS_CREATE: 'Successfully created',
    SUCCESS_EDIT: 'Successfully updated',
    SUCCESS_DELETE: 'Successfully deleted',
    SUCCESS_TIMEFRAME: 'Sucessfully provided timeframe for examination',
    SUCCESS_SELECTION: 'Examinations were successfully selected',
    SUCCESS_SHARE: 'Examinations were successfully shared',
    SUCCESS_RECORD: 'Examinatios record saved successfully.',
    SAME_USER_ERROR: `You can't delete the user you are logged in with.`
  },
  MODAL: {
    DELETE: 'Are you sure you want to delete this',
    UPDATE: 'Are you sure you want to update',
    CANCEL: 'Are you sure you want to cancel?',
    FINISH_SELECTION: 'Are you sure you want to finish the examination selection?',
    SHARE_SELECTION: 'Are you sure you want to share the selected examinations?',
    SAVE_RECORD: 'Are you sure to want to save the examinations record?',
    PASSWORD_UPDATE: 'Are you sure you want to update password?'
  },
  DASHBOARD: {
    CUSTOMERS: 'customers',
    EMPLOYEES: 'employees',
    EXAMINATIONS: 'Examinations',
    SCHEDULED: 'Scheduled',
    OVERDUE: 'Overdue',
    REQUESTS: 'Requests',
    DETAILS: 'Details',
    CONFIRMED_APPOINTMENTS: 'appointments confirmed',
    EXAMINATION_TYPES_TO_BE_PERFORMED: 'Examination types to be performed:',
    EMPLOYEE: 'employee',
    NO_EMPLOYEES: 'No employees',
    EMPLOYEES_OVERDUE: 'Employees overdue',
    NO_EXAMINATIONS_OVERDUE: 'No examinations overdue',
    SCHEDULED_APPOINTMENTS: 'scheduled appointments',
    NO_SCHEDULED_APPOINTMENTS: 'No scheduled appointments',
    NEW_EXAMINATIONS_REQUEST: 'New examinations request',
    NO_NEW_EXAMINATIONS_REQUEST: 'No new examinations request',
    EXAMINATION_TYPES: 'Examination types:',
    SEE_ALL: 'See all',
    COMPANY_LOCATIONS: 'Total company locations: ',
    LOADING: 'Loading...',
    CLIENT_NAME: 'Customer:',
    CLIENT_LOCATION: 'Location:',
    CLIENT_DATE_TIME: 'Date and time:',
    CLIENT_DURATION: 'Duration:',
    NO_SCHEDULED_APPOINTMENTS_HEADER: 'Scheduled appointments will appear here',
    APPOINTMENT_NUMBER: 'Appointment #',
    UPCOMING_APPOINTMENTS: 'Upcoming appointments'
  },
  EXAMINATION_HISTORY: {
    MANDATORY: 'Mandatory:',
    YES: 'Yes',
    NO: 'No',
    BY: 'by',
    DR: 'Dr.',
    NOT_PROVIDED: 'Not provided',
    NOT_PERFORMED: 'Not performed',
    EMPLOYEER_CERTIFICATE: 'Download certificates',
    DOCTOR: 'Doctor:',
    PRACTICE: 'Practice:',
    DEPARTMENT: 'Department:',
    POSITION: 'Position:',
    PERFORMED: 'performed:',
    NEXT_DUE: 'next due in',
    NOT_HAVE_SCHEDULED_EXAMINATIONS: 'does not have any examination records yet',
    HISTORY: 'History',
    MANDATORY_EXAMINATIONS: 'Mandatory',
    RECOMMENDED_EXAMINATIONS: 'Recommended',
    PRESCRIBED: 'assigned',
    FIRST_EXAMINATION: 'First examination',
    SECOND_EXAMINATION: 'Second examination',
    FOLLOW_UP: 'Follow-up',
    DOWNLOAD_CERTIFICATE: 'Download certificate'
  },
  APPOINTMENTS_REQUEST_LIST: {
    SCHEDULED_APPOINTMENTS_OVERVIEW: 'Scheduled appointments',
    SCHEDULE_NEW_APPOINTMENT: 'Scheduling',
    COMPLETED: 'Completed',
    REQUESTED: 'Requested',
    SCHEDULED: 'Scheduled'
  },
  EXAMINATIONS_RECORD: {
    UPLOAD_CERTIFICATE: 'Upload employer certificate',
    REMOVE_CERTIFICATE: 'Remove certificate',
    PERFORMED_ON: 'Examination offered on:',
    PERFORMED_EXAMINATION: 'Examination performed:',
    NEXT_EXAMINATION_DUE: 'Next examination due:',
    TYPE: 'Type',
    NOTE: 'Note:',
    CERTIFICATE: 'Certificate',
    MANDATORY_EXAMINATIONS: 'Mandatory examinations:',
    RECOMMENDED_EXAMINATIONS: 'Recommended examinations:',
    OPTED_OUT: 'Declined examinations:'
  },
  NOT_FOUND: {
    TEXT_1: 'OOPS! PAGE NOT FOUND!'
  },
  EMPTY_STATE: {
    EMPTY_STATE_HEADER: 'No {{type}} yet.',
    TEXT: 'Get started by creating a new',
    COMPANIES: 'companies',
    APPOINTMENT_REQUESTS: 'appointment requests',
    CUSTOMER_OVERVIEW: 'customers',
    EXAMINATION_TYPES: 'examination types',
    RISK_EXPOSURE: 'risk exposures',
    PRACTICES: 'practices',
    USERS: 'users',
    CUSTOMERS_LOCATIONS: 'customers (locations)',
    PREVENTIVE_LOG_EMPLOYEES: 'employees',
    CONTACT_PERSONS: 'contact persons',
    EMPTY_STATE_ADDITIONAL: ''
  },
  CUSTOMER_OVERVIEW: {
    MODAL_MESSAGE: 'Are you sure you want to create customer contact?',
    NEW_CUSTOMER: 'New customer',
    EMPTY_STATE_TEXT: 'Get started by creating a new'
  },
  FOOTER: {
    IMPRESSUM: 'Imprint',
    AGB: 'Terms & conditions',
    DATASCHUTZ: 'Data protection'
  },
  WARNING_MODAL: {
    WARNING_EXAMINATIONS: 'Please fill in all the required fields',
    WARNING_REMOVE_EXAMINATION: 'There must be at least one examination performed'
  },
  COOKIES_MODAL: {
    HEADER: 'This website uses essential Cookies.',
    MAIN_TEXT: `Essential cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website can't function properly without these cookies.`,
    ACCEPT: 'Accept',
    DECLINE: 'Decline'
  },
  FEEDBACK_MODAL: {
    TITLE: 'Feedback',
    FROM: 'From',
    SUBJECT: 'Subject',
    DESCRIPTION: 'Description',
    FILES: 'Files',
    MAX_FILE_SIZE: 'Max file size 20MB',
    IMAGE_ATTACHMENTS: 'Image attachments',
    SUCCESS_MESSAGE: 'Feedback successfully sent.',
    UPLOAD_BUTTON: 'Choose files'
  },
  CONTACT_PERSONS: {
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMAIL: 'Email',
    CREATE_CONTACT_PERSON: 'New contact person',
    HEADER_NAME: 'Add new customer'
  }
}
