export const APPOINTMENT_EDIT_FAIL = 'APPOINTMENT_EDIT_FAIL'
export const APPOINTMENT_EDIT_REQUEST = 'APPOINTMENT_EDIT_REQUEST'
export const APPOINTMENT_EDIT_SUCCESS = 'APPOINTMENT_UPDATE_SUCCESS'

export const AR_APPOINTMENTS_OVERVIEW_GET_FAIL = 'AR_APPOINTMENTS_OVERVIEW_GET_FAIL'
export const AR_APPOINTMENTS_OVERVIEW_GET_REQUEST = 'AR_APPOINTMENTS_OVERVIEW_GET_REQUEST'
export const AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS = 'AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS'
export const AR_APPOINTMENTS_OVERVIEW_GET_RESET = 'AR_APPOINTMENTS_OVERVIEW_GET_RESET'

export const AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL =
  'AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL'
export const AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST =
  'AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST'
export const AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS =
  'AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS'
export const AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET =
  'AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET'

export const EXAMINATIONS_OPTED_OUT_GET_REQUEST = 'EXAMINATIONS_OPTED_OUT_GET_REQUEST'
export const EXAMINATIONS_OPTED_OUT_GET_SUCCESS = 'EXAMINATIONS_OPTED_OUT_GET_SUCCESS'
export const EXAMINATIONS_OPTED_OUT_GET_FAIL = 'EXAMINATIONS_OPTED_OUT_GET_FAIL'
export const EXAMINATIONS_OPTED_OUT_GET_RESET = 'EXAMINATIONS_OPTED_OUT_GET_RESET'
