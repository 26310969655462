import { omit } from 'lodash'
import { EditPasswordErrors } from 'src/interfaces/auth'
import { UserErrors, UserInputs } from 'src/interfaces/users'
import i18n from '../language.service'
import { emailValidation, isRequired, passwordValidation } from './index'

export const validation = (inputs: UserInputs, action: string, role: string) => {
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  let errors: UserErrors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'firstName':
        errors[key] = isRequired(value, `${i18n.t('USER_INFO.FIRST_NAME')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'lastName':
        errors[key] = isRequired(value, `${i18n.t('USER_INFO.LAST_NAME')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'email':
        errors[key] =
          isRequired(value, `${i18n.t('USER_INFO.EMAIL')} ${required}`) || emailValidation(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'password':
        if (action === 'create') {
          errors[key] =
            isRequired(value, `${i18n.t('AUTH.PASSWORD')} ${required}`) || passwordValidation(value)
          if (!errors[key]) {
            errors = omit(errors, key)
          }
        } else {
          errors = omit(errors, key)
        }
        break
      case 'practice':
        if (role === 'Doctor' || role === 'Mfa' || role === undefined) {
          errors[key] = isRequired(
            value,
            `${i18n.t('ERROR_MESSAGES.PRACTICE_REQUIRED')} ${required}`
          )
          if (!errors[key]) {
            errors = omit(errors, key)
          }
        } else {
          errors = omit(errors, key)
        }
        break
      case 'role':
        errors[key] = isRequired(value, `${i18n.t('USER.ROLE')} ${required}`)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

export const userPasswordValidation = (value: string) => {
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  let error: EditPasswordErrors = {}
  const errorMessage =
    isRequired(value, `${i18n.t('AUTH.PASSWORD')} ${required}`) || passwordValidation(value)
  error = { password: errorMessage }
  if (!error['password']) {
    error = omit(error, 'password')
  }
  return error
}

export const checkRequiredFields = (inputs: UserInputs, fields: string[], action: string) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if (key === 'practice') {
      if ((inputs['role'] === 'Doctor' || inputs['role'] === 'Mfa') && input && value?.length === 0)
        isDisabled += 1
    } else if (key === 'password') {
      isDisabled = action === 'create' && value === '' ? isDisabled + 1 : isDisabled
    } else if ((input && !value) || (input && value && isRequired(value))) {
      isDisabled += 1
    }
  }
  return isDisabled
}

export const checkRequiredFieldsUserPassword = (value: string) => {
  let isDisabled = 0
  if (value === '') {
    isDisabled += 1
  }
  return isDisabled
}
