export const RISK_EXPOSURE_CREATE_REQUEST = 'RISK_EXPOSURE_CREATE_REQUEST'
export const RISK_EXPOSURE_CREATE_SUCCESS = 'RISK_EXPOSURE_CREATE_SUCCESS'
export const RISK_EXPOSURE_CREATE_FAIL = 'RISK_EXPOSURE_CREATE_FAIL'
export const RISK_EXPOSURE_CREATE_RESET = 'RISK_EXPOSURE_CREATE_RESET'

export const RISK_EXPOSURE_EDIT_REQUEST = 'RISK_EXPOSURE_EDIT_REQUEST'
export const RISK_EXPOSURE_EDIT_SUCCESS = 'RISK_EXPOSURE_EDIT_SUCCESS'
export const RISK_EXPOSURE_EDIT_FAIL = 'RISK_EXPOSURE_EDIT_FAIL'
export const RISK_EXPOSURE_EDIT_RESET = 'RISK_EXPOSURE_EDIT_RESET'

export const RISK_EXPOSURE_GET_ONE_REQUEST = 'RISK_EXPOSURE_GET_ONE_REQUEST'
export const RISK_EXPOSURE_GET_ONE_SUCCESS = 'RISK_EXPOSURE_GET_ONE_SUCCESS'
export const RISK_EXPOSURE_GET_ONE_FAIL = 'RISK_EXPOSURE_GET_ONE_FAIL'
export const RISK_EXPOSURE_GET_ONE_RESET = 'RISK_EXPOSURE_GET_ONE_RESET'

export const RISK_EXPOSURE_GET_ALL_REQUEST = 'RISK_EXPOSURE_GET_ALL_REQUEST'
export const RISK_EXPOSURE_GET_ALL_SUCCESS = 'RISK_EXPOSURE_GET_ALL_SUCCESS'
export const RISK_EXPOSURE_GET_ALL_FAIL = 'RISK_EXPOSURE_GET_ALL_FAIL'
export const RISK_EXPOSURE_GET_ALL_RESET = 'RISK_EXPOSURE_GET_ALL_RESET'

export const RISK_EXPOSURE_DELETE_REQUEST = 'RISK_EXPOSURE_DELETE_REQUEST'
export const RISK_EXPOSURE_DELETE_SUCCESS = 'RISK_EXPOSURE_DELETE_SUCCESS'
export const RISK_EXPOSURE_DELETE_FAIL = 'RISK_EXPOSURE_DELETE_FAIL'
export const RISK_EXPOSURE_DELETE_RESET = 'RISK_EXPOSURE_DELETE_RESET'

export const RISK_EXPOSURE_GROUP_GET_ALL_REQUEST = 'RISK_EXPOSURE_GROUP_GET_ALL_REQUEST'
export const RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS = 'RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS'
export const RISK_EXPOSURE_GROUP_GET_ALL_FAIL = 'RISK_EXPOSURE_GROUP_GET_ALL_FAIL'
