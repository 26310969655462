import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { changeLanguage } from 'i18next'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'
import Logo from 'src/assets/bas_logo.png'
import { UserEntity } from 'src/entities/interfaces'
import { useAppSelector } from 'src/redux/hook'
import 'src/services/language.service'
import { checkRole } from 'src/services/role.service'

const Navbar = ({ onSignout, user }: { onSignout: any; user: UserEntity }) => {
  const { t } = useTranslation()
  const selectedLanguage = localStorage.getItem('SELECTED_LNG')
  const [initialLoad, setInitialLoad] = useState(true)
  const { id, appointmentId, userId } = useParams<{
    id: string
    appointmentId: string
    userId: string
  }>()
  const formatLanguage = (language: string | undefined) => {
    if (language === 'DE') {
      return 'Deutsch'
    }
    return 'English'
  }
  const [language, setLanguage] = useState(formatLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE))
  const languages = ['English', 'Deutsch']

  useEffect(() => {
    if (selectedLanguage) {
      setLanguage(formatLanguage(selectedLanguage))
    }
  }, [selectedLanguage])

  const setSelectedLanguage = (language: string) => {
    const lngName = language === 'English' ? 'EN' : 'DE'
    changeLanguage(lngName)
    localStorage.setItem('SELECTED_LNG', lngName)
    setLanguage(language)
  }

  const navigation = [
    { name: t('NAVBAR.DASHBOARD'), href: '/', roles: ['mfa', 'doctor'] },
    {
      name: t('NAVBAR.COMPANIES'),
      href: '/companies',
      roles: ['admin', 'superadmin'],
      children: [{ href: '/companies/create' }, { href: `/companies/edit/${id}` }]
    },
    {
      name: t('NAVBAR.COMPANIES_LOCATIONS'),
      href: '/customers',
      roles: ['admin', 'superadmin']
    },
    {
      name: t('NAVBAR.PRACTICES'),
      href: '/practices',
      roles: ['admin', 'superadmin'],
      children: [{ href: '/practices/create' }, { href: `/practices/edit/${id}` }]
    },
    {
      name: t('NAVBAR.USERS'),
      href: '/users',
      roles: ['admin', 'superadmin'],
      children: [
        { href: '/users/create' },
        { href: `/users/edit/${id}` },
        { href: `/users/edit-password/${id}` }
      ]
    },
    { name: t('NAVBAR.AGENDA'), href: `/agenda/${user?.id}`, roles: ['mfa', 'doctor'] },
    {
      name: t('NAVBAR.EXAMINATION_TYPES'),
      href: '/examinations-types',
      roles: ['admin', 'superadmin'],
      children: [{ href: '/examinations-types/create' }, { href: `/examinations-types/edit/${id}` }]
    },
    {
      name: t('NAVBAR.RISK_EXPOSURE'),
      href: '/risk-exposures',
      roles: ['admin', 'superadmin'],
      children: [{ href: '/risk-exposures/create' }, { href: `/risk-exposures/edit/${id}` }]
    },
    {
      name: t('NAVBAR.CUSTOMER_OVERVIEW'),
      href: '/clients',
      roles: ['mfa', 'doctor'],
      children: [
        { href: '/clients/create' },
        { href: `/client/${id}` },
        { href: `/client/${id}/add-contact` },
        { href: `/examinations-history/${userId}` },
        { href: `/examinations-record/create/${userId}` },
        { href: `/examinations-record/edit/${userId}` }
      ]
    },
    {
      name: t('NAVBAR.APPOINTMENT_REQUEST'),
      href: '/appointment-requests',
      roles: ['doctor'],
      children: [
        { href: `/examination-request/${id}` },
        { href: `/scheduled-appointment-overview/${id}` },
        { href: `/examinations-record/${appointmentId}/${userId}` },
        { href: `/examinations-record/${userId}` }
      ]
    }
  ]

  const location = useLocation()
  const { rolesGetByUser } = useAppSelector((state) => state)
  const { role } = rolesGetByUser

  useEffect(() => {
    if (role && role.length > 0 && initialLoad) {
      setInitialLoad(false)
    }
  }, [role, initialLoad])

  const isActive = (href: string, index: number) => {
    return (
      location.pathname === href ||
      navigation[index].children?.find((child) => location.pathname === child.href)
    )
  }
  return (
    <Disclosure as='nav' className='bg-white'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-3 sm:px-6 lg:px-8'>
            <div className='flex h-16 items-center gap-2 sm:gap-0'>
              <div className='flex items-center'>
                <Link to='/companies' className='flex-shrink-0 max-w-[45px] mr-[30px]'>
                  <img src={Logo} alt='logo' />
                </Link>
                <div className='hidden lg:block'>
                  <div className='flex items-baseline space-x-4'>
                    {navigation.map(
                      (item, index) =>
                        checkRole(role, item.roles) && (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={`px-3 py-2 customLinkHeader ${
                              isActive(item.href, index) && 'active'
                            }`}
                            aria-current={isActive(item.href, index) ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className='flex items-center ml-auto'>
                <div className='block'>
                  <div className='flex items-center'>
                    <Menu as='div' className='relative ml-3'>
                      <div className='flex items-center gap-3'>
                        <Menu.Button className='flex items-center gap-2 customCompanyLocationSwitcher'>
                          {language}
                          <ChevronDownIcon className='w-4' />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute w-max max-w-[280px] right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          {languages.map((language: string, index: number) => (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <button
                                  onClick={() => setSelectedLanguage(language)}
                                  className={`${
                                    active && 'bg-gray-100'
                                  } block w-full text-left px-4 py-2 customCompanyLocationSwitcher`}
                                >
                                  {language}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                {user && (
                  <div className='hidden lg:block'>
                    <div className='flex items-center'>
                      {/* Dropdown menu that might be used later in the future */}
                      {/* <Menu as='div' className='relative ml-3'>
                        <div className='flex flex-row  items-center gap-3'>
                          <Menu.Button>
                            <div className='flex flex-row gap-2'>
                              <div className='w-9 h-9 bg-brand-orange-700 text-white flex max-w-xs justify-center items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                                <span className='sr-only'>{t('NAVBAR.OPEN_USER_MENU')}</span>
                                <p className='font-medium'>{user.firstName[0]}</p>
                              </div>
                              <ChevronDownIcon className='w-4' />
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item>
                              <button
                                onClick={onSignout}
                                className='block w-full text-left px-4 py-2 customHeaderUserDropDown'
                              >
                                {t('AUTH.SIGN_OUT')}
                              </button>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu> */}
                      <div className='ml-5'>
                        <ArrowLeftOnRectangleIcon
                          onClick={onSignout}
                          className='w-6 h-6 cursor-pointer rotate-180'
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='-mr-2 flex lg:hidden'>
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-900 hover:bg-brand-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2'>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          {user && (
            <Disclosure.Panel className='lg:hidden'>
              <div className='space-y-1 px-3 pt-2 pb-3'>
                {navigation &&
                  navigation.map(
                    (
                      item: {
                        name: string
                        href: string
                        roles: string[]
                        children: { name: string; href: string; roles: string[] }[]
                      },
                      index
                    ) =>
                      checkRole(role, item.roles) && (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={`block py-2 customLinkHeader ${
                            isActive(item.href, index) && 'active'
                          }`}
                          aria-current={isActive(item.href, index) ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      )
                  )}
              </div>
              <div className='border-t border-gray-200 px-3 py-2'>
                {/* <div className='flex items-center px-5'>
                  <div className='flex-shrink-0 w-10 h-10 flex items-center justify-center bg-brand-orange-700 text-white rounded-full'>
                    <p className='font-medium'>{user.firstName[0]}</p>
                  </div>
                  <div className='ml-3'>
                    <div className='text-base font-medium leading-none text-white'>
                      {user.userName}
                    </div>
                    <div className='text-sm font-medium leading-none text-gray-400'>
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className='mt-3 space-y-1 px-2'>
                  <Disclosure.Button
                    as='button'
                    onClick={onSignout}
                    className='block w-full text-left px-3 py-2 customHeaderUserDropDown'
                  >
                    {t('AUTH.SIGN_OUT')}
                  </Disclosure.Button>
                </div> */}
                <ArrowLeftOnRectangleIcon
                  onClick={onSignout}
                  className='w-6 h-6 cursor-pointer rotate-180'
                />
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}

Navbar.propTypes = {
  onSignout: PropTypes.func,
  user: PropTypes.object
}

export default Navbar
