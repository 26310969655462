import { AnyAction } from 'redux'
import {
  APPOINTMENT_EDIT_FAIL,
  APPOINTMENT_EDIT_REQUEST,
  APPOINTMENT_EDIT_SUCCESS,
  AR_APPOINTMENTS_OVERVIEW_GET_FAIL,
  AR_APPOINTMENTS_OVERVIEW_GET_REQUEST,
  AR_APPOINTMENTS_OVERVIEW_GET_RESET,
  AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET,
  AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS,
  EXAMINATIONS_OPTED_OUT_GET_FAIL,
  EXAMINATIONS_OPTED_OUT_GET_REQUEST,
  EXAMINATIONS_OPTED_OUT_GET_RESET,
  EXAMINATIONS_OPTED_OUT_GET_SUCCESS
} from 'src/redux/constants/appointmentsRequestConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const appointmentRequestEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case APPOINTMENT_EDIT_REQUEST:
      return { loading: true }
    case APPOINTMENT_EDIT_SUCCESS:
      return { loading: false, success: true }
    case APPOINTMENT_EDIT_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    default:
      return state
  }
}

const aRAppointmentsOverviewGetReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case AR_APPOINTMENTS_OVERVIEW_GET_REQUEST:
      return { loading: true }
    case AR_APPOINTMENTS_OVERVIEW_GET_SUCCESS:
      return { loading: false, appointmentRequest: action.payload }
    case AR_APPOINTMENTS_OVERVIEW_GET_FAIL:
      switch (action.payload.message) {
        case 'APP_REQUEST_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case AR_APPOINTMENTS_OVERVIEW_GET_RESET:
      return {}
    default:
      return state
  }
}

const aROptedOutAppointmentsOverviewGetReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_REQUEST:
      return { loading: true }
    case AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_SUCCESS:
      return { loading: false, optedAppointments: action.payload }
    case AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_FAIL:
      return { loading: false, error: errorMessage }
    case AR_OPTED_OUT_APPOINTMENTS_OVERVIEW_GET_RESET:
      return {}
    default:
      return state
  }
}

const examinationsOptedOutGetReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case EXAMINATIONS_OPTED_OUT_GET_REQUEST:
      return { loading: true }
    case EXAMINATIONS_OPTED_OUT_GET_SUCCESS:
      return { loading: false, optedExaminations: action.payload }
    case EXAMINATIONS_OPTED_OUT_GET_FAIL:
      return { loading: false, error: errorMessage }
    case EXAMINATIONS_OPTED_OUT_GET_RESET:
      return {}
    default:
      return state
  }
}

export const appointmentRequestReducers = {
  appointmentRequestEdit: appointmentRequestEditReducer,
  aRAppointmentsOverviewGet: aRAppointmentsOverviewGetReducer,
  aROptedOutAppointmentsOverviewGet: aROptedOutAppointmentsOverviewGetReducer,
  examinationsOptedOutGet: examinationsOptedOutGetReducer
}
