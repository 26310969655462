import { TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes, { InferProps } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { ReactSelectStyles } from 'src/components/reactSelectStyles'

export function ExaminationItem({
  id,
  performedDate,
  examinationCheckupOption,
  nextExaminationDate,
  examinationName,
  setVal
}: InferProps<typeof ExaminationItem.propTypes>) {
  const { t } = useTranslation()
  const options = [
    {
      value: 'First examination',
      label: t('EXAMINATION_HISTORY.FIRST_EXAMINATION')
    },
    {
      value: 'Second examination',
      label: t('EXAMINATION_HISTORY.SECOND_EXAMINATION')
    },
    { value: 'Follow up', label: t('EXAMINATION_HISTORY.FOLLOW_UP') }
  ]

  return (
    <div className='border-b border-gray-300 flex flex-col my-5'>
      <label htmlFor={id ?? ''} className='block customHead4'>
        {examinationName}
      </label>
      <div className='flex flex-col md:flex-row md:items-end gap-12 my-5'>
        <div className='flex flex-col gap-2'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.PERFORMED_ON')}</p>
          <div className='custom-datepicker-input'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={performedDate || new Date()}
                onChange={(val: any) => setVal?.(id, val, 'performedDate')}
                inputFormat='DD.MM.YYYY'
                renderInput={(params: any) => <TextField {...params} />}
                maxDate={Date.now()}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='flex flex-col gap-2 w-full max-w-[250px]'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.TYPE')}</p>
          <div className='custom-select-input'>
            <Select
              value={examinationCheckupOption}
              onChange={(val) => setVal?.(id, val, 'examinationCheckup')}
              options={options}
              styles={ReactSelectStyles}
            />
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='customParagraph'>{t('EXAMINATIONS_RECORD.NEXT_EXAMINATION_DUE')}</p>
          <div className='custom-datepicker-input'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={nextExaminationDate || null}
                onChange={(val: any) => setVal?.(id, val, 'nextExaminationDate')}
                inputFormat='MM.YYYY'
                renderInput={(params: any) => <TextField {...params} />}
                minDate={Date.now()}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

ExaminationItem.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  performedDate: PropTypes.object,
  examinationCheckupOption: PropTypes.object,
  nextExaminationDate: PropTypes.object,
  examinationNote: PropTypes.string,
  employerCertificate: PropTypes.string,
  examinationName: PropTypes.string,
  employeeOptedOut: PropTypes.string,
  setVal: PropTypes.func
}
