import { XMarkIcon } from '@heroicons/react/24/outline'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function EventContent({
  eventInfo,
  savedEvents,
  removeSlot
}: {
  eventInfo: any
  savedEvents: any
  removeSlot: any
}) {
  const examinationsList: string[] = []
  const { t } = useTranslation()

  if (eventInfo?.event?.extendedProps?.examinationTypes) {
    for (let i = 0; i < eventInfo.event.extendedProps.examinationTypes.length; i++) {
      examinationsList.push(eventInfo.event.extendedProps.examinationTypes[i].examinationName)
    }
  }
  const isNew = savedEvents.find((event: any) => event.id === eventInfo.event.id) ? false : true
  return (
    <div
      className={`${
        eventInfo.event.extendedProps.belongsToAppointment
          ? 'bg-brand-blue-700'
          : isNew
            ? 'bg-brand-blue-700'
            : 'bg-brand-orange-700'
      } h-full w-full p-3`}
    >
      {isNew && (
        <IconButton
          className='absolute right-[0] top-[0] z-[100]'
          onClick={() => {
            removeSlot(eventInfo.event.id)
          }}
        >
          <XMarkIcon className='w-5 h-5 text-white' />
        </IconButton>
      )}
      <div className='flex items-center'>
        <p className='mr-1 !font-medium customParagraph !text-white'>{eventInfo.timeText}</p>
        {!eventInfo.event.extendedProps.belongsToAppointment && !isNew && (
          <p className='mr-1 !font-medium customParagraph !text-white'>
            {' - '}
            {t('EXAMINATION_TYPES.OCCUPIED')}
          </p>
        )}
        <p className='customParagraph !text-white'>{eventInfo.event.title}</p>
      </div>
      <div className='mt-2'>
        {examinationsList.map((examination, index) => (
          <p key={index} className='customParagraph !text-white'>
            {examination}
          </p>
        ))}
      </div>
      {/* {savedEvents.length > 0 &&
        savedEvents
          .find((savedEvent: any) => savedEvent.id === eventInfo.event.id)
          ?.occupied_timeslots.map((timeslot: any, index: number) => {
            return (
              <div
                key={index}
                className={`mt-2 p-1 mr-2 rounded ${
                  eventInfo.event.extendedProps.belongsToAppointment
                    ? 'bg-brand-blue-300'
                    : 'bg-inherit'
                }`}
              >
                <p className='customParagraph !text-white'>{timeslot}</p>
              </div>
            )
          })} */}
    </div>
  )
}
