import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { Button, IconButton, Pagination } from '@mui/material'
import { Buffer } from 'buffer'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from 'src/App'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { ExaminationHistoryInterface } from 'src/interfaces/examination'
import {
  downloadUserCertificate,
  downloadUserCertificates,
  getExaminationHistory
} from 'src/redux/actions/examinationActions'
import { displayNotFoundElement } from 'src/redux/actions/notFoundActions'
import { getOneUser } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { ExaminationHistoryElement } from '../ExaminationsRecord/components'

export const ExaminationsHistory = () => {
  const limit = 10
  const navigate = useNavigate()
  const language = localStorage.getItem('SELECTED_LNG')
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [groupedExaminations, setGroupedExaminations] = useState<{
    [performedDate: string]: ExaminationHistoryInterface[]
  }>({})
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [page, setPage] = useState(1)
  const { userId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setContext }: any = useContext(GlobalContext)

  const {
    examinationHistoryGet,
    userGetOne,
    examinationDownloadCertificates,
    examinationDownloadCertificate
  } = useAppSelector((state) => state)
  const { examinations, count, loading, error } = examinationHistoryGet
  const { user } = userGetOne
  // Multiple certificates download
  const { certificatesBuffer } = examinationDownloadCertificates
  // Single certificate download
  const { certificateBuffer } = examinationDownloadCertificate

  useEffect(() => {
    setIsFirstLoad(false)
    if (userId) {
      dispatch(getOneUser(userId))
      dispatch(getExaminationHistory({ userId, limit, page: page - 1 }))
    }
  }, [userId, dispatch, page])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (error && !isFirstLoad) {
      dispatch(displayNotFoundElement(true))
    }
  }, [error, dispatch])

  useEffect(() => {
    if (user) {
      setContext((prev: any) => ({
        ...prev,
        header: `${t('PREVENTIVE_LOG.EXAMINATION_HISTORY', {
          firstName: user.firstName,
          lastName: user.lastName
        })}`
      }))
    }
  }, [user, language])

  useEffect(() => {
    if (examinations) {
      setGroupedExaminations(examinations)
    }
  }, [examinations])

  useEffect(() => {
    if (certificatesBuffer) {
      certificatesBuffer.forEach((buffer: any) => {
        const blob = new Blob([Buffer.from(buffer.buffer.data)])
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none;')
        a.href = url
        a.download = `${buffer.fileName}`
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
      })
      setDisplaySuccessButton(true)
      dispatch({ type: 'DOWNLOAD_CERTIFICATES_RESET' })
    }
  }, [certificatesBuffer, dispatch])

  useEffect(() => {
    if (certificateBuffer) {
      const blob = new Blob([Buffer.from(certificateBuffer[0].buffer.data)])
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display: none;')
      a.href = url
      a.download = `${certificateBuffer[0].fileName}`
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
      dispatch({ type: 'DOWNLOAD_CERTIFICATE_RESET' })
    }
  }, [certificateBuffer, dispatch])

  const downloadCertificates = (groupId: number) => {
    const employerCertificates = groupedExaminations[Object.keys(groupedExaminations)[groupId]]
      .filter((examination) => examination.id)
      .map((examination) => examination.id)

    dispatch(downloadUserCertificates(employerCertificates))
  }

  const downloadCertificate = (examinationId: string) => {
    dispatch(downloadUserCertificate(examinationId))
  }

  return (
    <>
      {loading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          {user && (
            <div className='flex'>
              {groupedExaminations && Object.keys(groupedExaminations).length === 0 && (
                <p className='customParagraph'>
                  {user.firstName} {user.lastName}{' '}
                  {t('EXAMINATION_HISTORY.NOT_HAVE_SCHEDULED_EXAMINATIONS')}
                </p>
              )}
              <Button
                className='main-btn ml-auto'
                variant='contained'
                href={`/examinations-record/create/${user.id}`}
              >
                {t('CREATE_PREVENTIVE_LOG_CUSTOMER.NEW_MANUAL_EXAMINATION')}
              </Button>
            </div>
          )}
          {Object.entries(groupedExaminations).map(([performedDate, examinations], index) => (
            <div key={index}>
              <div className='mt-5 border-b border-gray-200 pb-2 flex justify-between mb-3'>
                <h3 className='customHead3'>{performedDate}</h3>
                <div className='flex items-center'>
                  <h3 className='customHead3'>{` ${examinations[0]?.companyLocation?.BASPractice?.name} `}</h3>
                  <IconButton
                    onClick={() =>
                      navigate(`/examinations-record/edit/${userId}`, {
                        state: { examinations: examinations }
                      })
                    }
                    title={t('GENERIC.EDIT')}
                  >
                    <PencilSquareIcon className='w-5' />
                  </IconButton>
                </div>
              </div>
              {examinations.map((examination, index) => (
                <ExaminationHistoryElement
                  examination={examination}
                  key={index}
                  downloadCertificate={downloadCertificate}
                />
              ))}
              {examinations.some((examination) => examination.employerCertificate) && (
                <Button
                  onClick={() => downloadCertificates(index)}
                  className='main-btn mt-5'
                  variant='contained'
                >
                  {t('EXAMINATION_HISTORY.EMPLOYEER_CERTIFICATE')}
                </Button>
              )}
            </div>
          ))}
          {count && Math.ceil(count / limit) > 1 ? (
            <div className='mt-10 flex w-full justify-center items-center'>
              <Pagination
                count={Math.ceil(count / limit)}
                shape='rounded'
                page={page}
                onChange={(e, v) => {
                  setPage(v)
                }}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}
