import { getGridStringOperators, GridColDef } from '@mui/x-data-grid'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value)
)

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('COMPANIES_LOCATIONS.CUSTOMER'),
    editable: false,
    sortable: false,
    flex: 1,
    filterOperators
  },
  {
    field: 'contactPersons',
    headerName: i18n.t('COMPANIES_LOCATIONS.CONTACT_PERSONS'),
    editable: false,
    sortable: false,
    flex: 1,
    filterOperators,
    renderCell: (params) => {
      return (
        <div className='flex flex-col'>
          {params.formattedValue &&
            params.formattedValue.map((contactPerson: any) => (
              <div key={contactPerson.id}>
                <h1>
                  {contactPerson.firstName} {contactPerson.lastName}
                </h1>
              </div>
            ))}
        </div>
      )
    }
  },
  {
    field: 'practice',
    headerName: i18n.t('COMPANIES_LOCATIONS.ASSOCIATED_PRACTICE'),
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'numberOfEmployees',
    headerName: i18n.t('COMPANIES_LOCATIONS.NUMBER_OF_EMPLOYEES'),
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    getApplyQuickFilterFn: undefined
  }
]
