import { PreventiveLogCostumersData } from 'src/interfaces/preventiveLogCostumers'
import {
  ENABLE_PREVENTIVE_LOG_FAIL,
  ENABLE_PREVENTIVE_LOG_REQUEST,
  ENABLE_PREVENTIVE_LOG_SUCCESS
} from 'src/redux/constants/preventiveLogConstants'
import { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const enable = (inputs: PreventiveLogCostumersData) => (dispatch: AppDispatch) => {
  dispatch({ type: ENABLE_PREVENTIVE_LOG_REQUEST, payload: {} })
  api
    .post('/api/admin/preventive-log/enable', inputs)
    .then((res) => {
      dispatch({ type: ENABLE_PREVENTIVE_LOG_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: ENABLE_PREVENTIVE_LOG_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
