import { getGridStringOperators, GridRenderCellParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

export const columns = [
  {
    field: 'companyName',
    headerName: i18n.t('COMPANY.COMPANY_NAME'),
    editable: false,
    sortable: false,
    filterable: true,
    disableColumnMenu: true,
    filterOperators,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <Link to={`/client/${params.id}`}>{params.formattedValue}</Link>
    }
  },
  {
    field: 'contactPerson',
    headerName: i18n.t('COMPANY.CONTACT_PERSON'),
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    filterOperators,
    flex: 1,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className='flex flex-col'>
          {params.formattedValue &&
            params.formattedValue.map((contactPerson: any) => (
              <div key={contactPerson.id}>
                <h1>
                  {contactPerson.firstName} {contactPerson.lastName}
                </h1>
              </div>
            ))}
        </div>
      )
    }
  },
  {
    field: 'associatedPractice',
    headerName: i18n.t('COMPANY.ASSOCIATED_PRACTICE'),
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    filterOperators,
    flex: 1,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'numberOfEmployees',
    headerName: i18n.t('COMPANY.NO_OF_EMPLOYEES'),
    editable: false,
    sortable: true,
    filterable: false,
    filterOperators,
    flex: 0.75,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'lastExamination',
    headerName: i18n.t('COMPANY.LAST_EXAMINATION_PERFORMED'),
    editable: false,
    sortable: true,
    filterable: false,
    filterOperators,
    flex: 1,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'nextExamination',
    headerName: i18n.t('COMPANY.NEXT_EXAMINATION_DUE'),
    editable: false,
    sortable: true,
    filterable: false,
    filterOperators,
    flex: 1,
    getApplyQuickFilterFn: undefined
  }
]
