export const ReactSelectStyles = {
  option: (styles: any, state: any) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: '400',
    color: '#111',
    backgroundColor: state.isSelected ? '#F5F5F5' : '#fff',
    '&:hover': {
      ...styles,
      fontSize: '14px',
      backgroundColor: '#F5F5F5',
      color: '#111'
    }
  })
}
