import { getGridStringOperators, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Link, useLocation } from 'react-router-dom'
import i18n from 'src/services/language.service'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

export const columns: GridColDef[] = [
  {
    field: 'companyLocation',
    headerName: i18n.t('COMPANY.CUSTOMER'),
    editable: false,
    sortable: false,
    filterable: true,
    flex: 1,
    filterOperators,
    renderCell: (params: GridRenderCellParams) => {
      const location = useLocation()
      return location.pathname == '/appointment-requests' ? (
        <Link to={`/client/${params.row.companyLocationId}`} state={{ data: params.row }}>
          {params.formattedValue}
        </Link>
      ) : (
        params.formattedValue
      )
    }
  },
  {
    field: 'createdAt',
    headerName: i18n.t('EXAMINATIONS.DATE_OF_REQUEST'),
    editable: false,
    sortable: true,
    filterable: false,
    flex: 1,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'contact',
    headerName: i18n.t('COMPANY.CONTACT_PERSON'),
    editable: false,
    sortable: false,
    filterable: true,
    flex: 1,
    filterOperators,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'numberOfEmployees',
    headerName: i18n.t('COMPANY.NO_OF_EMPLOYEES'),
    editable: false,
    sortable: true,
    filterable: false,
    flex: 1,
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'status',
    headerName: i18n.t('GENERIC.STATUS'),
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const j = params.formattedValue.split(',')

      const determineStatus = (status: string) => {
        switch (status) {
          case 'requested':
            return i18n.t('APPOINTMENTS_REQUEST_LIST.REQUESTED')
          case 'booked':
          case 'completed':
            return i18n.t('APPOINTMENTS_REQUEST_LIST.SCHEDULED')
          default:
            return `${i18n.t('APPOINTMENTS_REQUEST_LIST.SCHEDULED')} ${
              status != '' ? `- ${status}` : ''
            }`
        }
      }

      return (
        <div className='flex flex-col gap-1'>
          {j.map((item: string, index: number) => (
            <p key={index}>
              {determineStatus(item)}
              {index < j.length - 1 && ','}
            </p>
          ))}
        </div>
      )
    },
    getApplyQuickFilterFn: undefined
  },
  {
    field: 'actions',
    filterable: false,
    headerName: '',
    editable: false,
    sortable: false,
    flex: 0.5,
    disableColumnMenu: true,
    align: 'right',
    getApplyQuickFilterFn: undefined
  }
  // {
  //   field: 'actionsTwo',
  //   filterable: false,
  //   headerName: '',
  //   editable: false,
  //   sortable: false,
  //   disableColumnMenu: true,
  //   align: 'right',
  //   flex: 0.1,
  //   getApplyQuickFilterFn: undefined
  // }
]
