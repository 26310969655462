import { setDotBg } from 'src/pages/Doctors/Agenda/helpers/internalFunctions'

export function EventContent({ eventInfo }: { eventInfo: any }) {
  const { status, companyName, companyLocationName, employeeName } = eventInfo.event.extendedProps
  const dotBgColor = setDotBg(status)
  const gridViewType = eventInfo?.view?.type
  return (
    <div
      title={`${companyName} - ${companyLocationName}`}
      className={`${gridViewType !== 'dayGridMonth' && 'px-2 py-3'}`}
    >
      <div className='flex items-center flex-wrap whitespace-pre-line'>
        <div style={{ backgroundColor: dotBgColor }} className='w-2 h-2 rounded-full mr-1' />
        <p
          className={`mr-1 !font-medium customParagraph ${
            gridViewType !== 'dayGridMonth' && '!text-white'
          }`}
        >
          {eventInfo.timeText}
        </p>
        <p className={`customParagraph ${gridViewType !== 'dayGridMonth' && '!text-white'}`}>
          {companyName} - {companyLocationName}
        </p>
      </div>
      <p className={`customParagraph ${gridViewType !== 'dayGridMonth' && '!text-white'}`}>
        {employeeName}
      </p>
    </div>
  )
}
