import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import WarningModal from 'src/components/WarningModal'
import { ActionsManual, ExaminationItemManual } from './index'

export function ExaminationsListManual({
  companyLocationId,
  companyId
}: {
  companyLocationId: string
  companyId: string
}) {
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()
  const [certificate, setCertificate] = useState({ name: '' })
  const [warningModalActive, setWarningModalActive] = useState(false)
  const [examinationsValues, setExaminationsValues] = useState(() => {
    return [
      {
        id: '1',
        status: 'Completed',
        performedDate: moment(),
        examinationCheckup: null,
        examinationCheckupOption: { label: '', value: '' },
        nextExaminationDate: null,
        examinationNote: null,
        employerCertificate: null,
        examinationName: '',
        employeeId: userId,
        examinationRequired: 2,
        employeeOptedIn: true,
        companyLocationId: companyLocationId && companyLocationId,
        companyId: companyId && companyId
      }
    ]
  })

  useEffect(() => {
    setCertificate({ name: '' })
  }, [])

  useEffect(() => {
    if (companyLocationId) {
      setExaminationsValues((prevExaminations) =>
        prevExaminations.map((examination) => ({
          ...examination,
          companyLocationId: companyLocationId
        }))
      )
    }
  }, [companyLocationId])

  useEffect(() => {
    if (companyId) {
      setExaminationsValues((prevExaminations) =>
        prevExaminations.map((examination) => ({
          ...examination,
          companyId: companyId
        }))
      )
    }
  }, [companyId])

  const addExaminationItem = () => {
    setExaminationsValues((prevExaminations) => [
      ...prevExaminations,
      {
        id: moment().valueOf().toString(),
        status: 'Completed',
        performedDate: moment(),
        examinationCheckup: null,
        examinationCheckupOption: { label: '', value: '' },
        nextExaminationDate: null,
        examinationNote: null,
        employerCertificate: null,
        examinationName: '',
        employeeId: userId,
        examinationRequired: 2,
        employeeOptedIn: true,
        companyLocationId: companyLocationId,
        companyId: companyId
      }
    ])
  }

  const removeExaminationItem = (id: string) => {
    if (examinationsValues.length === 1) {
      setWarningModalActive(true)
      return
    }
    setExaminationsValues((prevExaminations) => prevExaminations.filter((e) => e.id !== id))
  }

  const setVal = (id: string, value: any, name: string) => {
    const newExaminations: any = examinationsValues.map((examination: any) => {
      if (examination.id === id) {
        const performedDate = examination.performedDate ? examination.performedDate : moment()
        switch (name) {
          case 'status':
            if (value) {
              examination.status = 'Completed'
              examination.performedDate = moment()
            } else {
              examination.status = 'Not performed'
            }
            break
          case 'performedDate':
            examination.performedDate = value
            break
          case 'examinationCheckup':
            examination.examinationCheckup = value.value
            examination.examinationCheckupOption = value
            if (value.value === 'First examination') {
              examination.nextExaminationDate = moment(performedDate).add(12, 'M')
            } else if (value.value === 'Second examination') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            } else if (value.value === 'Follow up') {
              examination.nextExaminationDate = moment(performedDate).add(36, 'M')
            }
            break
          case 'nextExaminationDate':
            examination.nextExaminationDate = value
            break
          case 'examinationNote':
            examination.examinationNote = value
            break
          default:
            examination[name] = value
            break
        }
      }
      return examination
    })
    setExaminationsValues(newExaminations)
  }

  return (
    <div className=''>
      {warningModalActive && (
        <WarningModal
          onCancel={() => setWarningModalActive(false)}
          message={t('WARNING_MODAL.WARNING_REMOVE_EXAMINATION')}
        />
      )}
      <div className='flex flex-col'>
        {examinationsValues.map((examination) => {
          const {
            id,
            status,
            performedDate,
            examinationCheckupOption,
            nextExaminationDate,
            employerCertificate,
            examinationName
          } = examination
          return (
            <ExaminationItemManual
              key={id}
              id={id}
              status={status}
              performedDate={performedDate}
              examinationCheckupOption={examinationCheckupOption}
              nextExaminationDate={nextExaminationDate}
              employerCertificate={employerCertificate}
              examinationName={examinationName}
              setVal={setVal}
              removeExaminationItem={removeExaminationItem}
            />
          )
        })}
        <div className='ml-auto'>
          <IconButton
            onClick={addExaminationItem}
            title={t('EXAMINATION_SELECTION.ADD_ANOTHER_EXAMINATION')}
          >
            <PlusCircleIcon className='w-6' />
          </IconButton>
        </div>
      </div>
      <ActionsManual
        certificate={certificate}
        examinations={examinationsValues}
        companyLocationId={companyLocationId}
      />
    </div>
  )
}
