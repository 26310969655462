import { EyeIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from 'src/components/Alerts'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import {
  ExaminationTypeListItem,
  ExaminationTypeSearch,
  QueryObject
} from 'src/interfaces/examinationTypes'
import { getAll, remove } from 'src/redux/actions/examinationTypeActions'
import {
  EXAMINATION_TYPE_CREATE_RESET,
  EXAMINATION_TYPE_DELETE_RESET,
  EXAMINATION_TYPE_EDIT_RESET
} from 'src/redux/constants/examinationTypeConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { columns } from './helper/TableColumnsHelper'

export const ExaminationsList = () => {
  const { t } = useTranslation()
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('DE')
  const savedLng = i18next.language
  const [firstLoad, setFirstLoad] = useState(true)
  const deleteButtonRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  columns[0].renderHeader = () => {
    return <p className='font-bold'>{t('EXAMINATIONS.EXAMINATION_TITLE')}</p>
  }
  columns[1].renderHeader = () => {
    return <p className='font-bold'>{t('EXAMINATION_TYPES.ASSOCIATED_RISK_AREAS')}</p>
  }
  columns[2].renderCell = (params: GridRenderCellParams) => {
    return (
      <div className=''>
        <IconButton onClick={() => onOpenModal(params.id)} title={t('GENERIC.DELETE')}>
          <TrashIcon className='w-5' />
        </IconButton>
        <IconButton
          onClick={() => navigate(`/examinations-types/edit/${params.id}`)}
          title={t('GENERIC.EDIT')}
        >
          <PencilSquareIcon className='w-5' />
        </IconButton>
      </div>
    )
  }

  const [limit, setLimit] = useState(config.limit)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSuccessCreate, setIsSuccessCreate] = useState(false)
  const [examinationId, setExaminationId] = useState<GridRowId>('')
  const [page, setPage] = useState<string | number>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [tableRows, setTableRows] = useState<ExaminationTypeListItem[]>([])
  const {
    examinationTypeGetAll,
    examinationTypeDelete,
    examinationTypeCreate,
    examinationTypeEdit
  } = useAppSelector((state) => state)
  const { loading: getAllLoading, examinationsTypes, count } = examinationTypeGetAll
  const { success: editSuccess } = examinationTypeEdit
  const {
    loading: examinationTypeDeleteLoading,
    success: successDelete,
    error: deleteError
  } = examinationTypeDelete
  const { success: successCreate } = examinationTypeCreate

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: EXAMINATION_TYPE_DELETE_RESET })
    dispatch({ type: EXAMINATION_TYPE_CREATE_RESET })
    setIsSuccessCreate(successCreate)
    return () => {
      dispatch({ type: EXAMINATION_TYPE_EDIT_RESET })
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const examinationsTypesHelper = []
      if (examinationsTypes) {
        for (let i = 0; i < examinationsTypes.length; i++) {
          const examinationsType: ExaminationTypeListItem = {
            id: examinationsTypes[i].id,
            name: examinationsTypes[i].name,
            riskAreas: examinationsTypes[i].riskArea
          }
          examinationsTypesHelper.push(examinationsType)
        }
        setTableRows(examinationsTypesHelper)
        setFinishedLoading(true)
      }
    })()
  }, [examinationsTypes])

  useEffect(() => {
    const examinationTypeNameFilter =
      queryObject.field === 'name' && queryObject.value ? queryObject.value : ''
    const examinationTypeNameFilterType =
      queryObject.field === 'name' && queryObject.operator ? queryObject.operator : 'contains'
    const examinationTypeRiskAreaFilter =
      queryObject.field === 'riskAreas' && queryObject.value ? queryObject.value : ''
    const examinationTypeRiskAreaFilterType =
      queryObject.field === 'riskAreas' && queryObject.operator ? queryObject.operator : 'contains'
    const quickFilter =
      queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
    dispatch(
      getAll({
        limit,
        page,
        queryObject,
        examinationTypeNameFilter,
        examinationTypeNameFilterType,
        examinationTypeRiskAreaFilter,
        examinationTypeRiskAreaFilterType,
        quickFilter
      } as ExaminationTypeSearch)
    )
  }, [page, queryObject, limit])

  useEffect(() => {
    if (successDelete) {
      typeof page === 'string' ? setPage(0) : setPage('0')
      setIsSuccessCreate(false)
    }
  }, [successDelete])

  const onDelete = (examinationId: GridRowId) => {
    dispatch(remove(examinationId))
    setIsDeleteModal(false)
  }

  const onOpenModal = (id: GridRowId) => {
    setIsDeleteModal(true)
    setExaminationId(id)
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {!finishedLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className='flex justify-end md:justify-between items-start flex-wrap gap-2'>
            <div className='flex flex-col justify-center gap-2 w-full md:w-fit'>
              {successDelete && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_DELETE')} ${t(
                    'EXAMINATION_TYPES.EXAMINATION_TYPE'
                  ).toLowerCase()}`}
                />
              )}
              {isSuccessCreate && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_CREATE')} ${t(
                    'EXAMINATION_TYPES.EXAMINATION_TYPE'
                  ).toLowerCase()}`}
                />
              )}
              {editSuccess && (
                <SuccessAlert
                  hide
                  message={`${t('ALERTS.SUCCESS_EDIT')} ${t(
                    'EXAMINATION_TYPES.EXAMINATION_TYPE'
                  ).toLowerCase()}`}
                />
              )}
              {deleteError && <ErrorAlert message={deleteError} hide />}
            </div>
            <Button className='main-btn' variant='contained' href='/examinations-types/create'>
              {t('NAVBAR.CREATE_EXAMINATION_TYPES')}
            </Button>
          </div>
          <div className='flex flex-col relative' style={{ marginTop: 10 }}>
            {isDeleteModal && (
              <Modal
                onCancel={() => setIsDeleteModal(false)}
                onConfirm={() => onDelete(examinationId)}
                message={`${t('MODAL.DELETE')} ${t(
                  'EXAMINATION_TYPES.EXAMINATION_TYPE'
                ).toLowerCase()}?`}
              />
            )}
            {!finishedLoading ? (
              <LoadingOverlay />
            ) : (
              <div className='' style={{ width: '100%' }}>
                {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
                  <ThemeProvider theme={setTheme(language)}>
                    <DataGrid
                      {...tableRows}
                      className='MuiDataGrid-cellContent-alignTop'
                      columns={columns}
                      rows={tableRows}
                      rowCount={count > 0 ? count : 0}
                      pagination
                      paginationMode='server'
                      sortingMode='server'
                      filterMode='server'
                      onFilterModelChange={onFilterChange}
                      loading={getAllLoading || examinationTypeDeleteLoading}
                      paginationModel={{
                        page: Number(page),
                        pageSize: limit
                      }}
                      pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                      onPaginationModelChange={(params) => {
                        setPage(params.page)
                        setLimit(params.pageSize)
                      }}
                      slots={{ toolbar: Toolbar }}
                      localeText={{
                        toolbarFilters: t('GENERIC.FILTER')
                      }}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                        },
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: {
                            placeholder: t('GENERIC.SEARCH'),
                            inputProps: {
                              'aria-label': t('GENERIC.SEARCH')
                            },
                            debounceMs: 500
                          }
                        }
                      }}
                      sx={GridStyles}
                      columnVisibilityModel={{ leftCompanyDate: false }}
                      getRowHeight={() => 'auto'}
                      autoHeight={true}
                      rowSelection={false}
                      checkboxSelection={false}
                      disableColumnSelector={true}
                      disableRowSelectionOnClick={true}
                    />
                  </ThemeProvider>
                ) : (
                  <EmptyState
                    titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                      type: t('EMPTY_STATE.EXAMINATION_TYPES')
                    })}
                    additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
                    buttonText={t('EXAMINATION_TYPES.EXAMINATION_TYPE').toLowerCase()}
                    url='/examinations-types/create'
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
