import { omit } from 'lodash'
import { SignInErrors, SignInInputs } from 'src/interfaces/auth'
import { isRequired } from './index'

export const validation = (inputs: SignInInputs) => {
  let errors: SignInErrors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'userName':
      case 'password':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: SignInInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if (input && isRequired(value)) {
      isDisabled += 1
    }
  }
  return isDisabled
}
