import { AnyAction } from 'redux'
import {
  RISK_EXPOSURE_CREATE_FAIL,
  RISK_EXPOSURE_CREATE_REQUEST,
  RISK_EXPOSURE_CREATE_RESET,
  RISK_EXPOSURE_CREATE_SUCCESS,
  RISK_EXPOSURE_DELETE_FAIL,
  RISK_EXPOSURE_DELETE_REQUEST,
  RISK_EXPOSURE_DELETE_RESET,
  RISK_EXPOSURE_DELETE_SUCCESS,
  RISK_EXPOSURE_EDIT_FAIL,
  RISK_EXPOSURE_EDIT_REQUEST,
  RISK_EXPOSURE_EDIT_RESET,
  RISK_EXPOSURE_EDIT_SUCCESS,
  RISK_EXPOSURE_GET_ALL_FAIL,
  RISK_EXPOSURE_GET_ALL_REQUEST,
  RISK_EXPOSURE_GET_ALL_RESET,
  RISK_EXPOSURE_GET_ALL_SUCCESS,
  RISK_EXPOSURE_GET_ONE_FAIL,
  RISK_EXPOSURE_GET_ONE_REQUEST,
  RISK_EXPOSURE_GET_ONE_RESET,
  RISK_EXPOSURE_GET_ONE_SUCCESS,
  RISK_EXPOSURE_GROUP_GET_ALL_FAIL,
  RISK_EXPOSURE_GROUP_GET_ALL_REQUEST,
  RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS
} from 'src/redux/constants/riskExposureConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const riskExposureCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_CREATE_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_CREATE_SUCCESS:
      return { loading: false, success: true, riskArea: action.payload }
    case RISK_EXPOSURE_CREATE_FAIL:
      error = errorMessage
      return { loading: false, error }
    case RISK_EXPOSURE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const riskExposureEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_EDIT_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_EDIT_SUCCESS:
      return { loading: false, success: true }
    case RISK_EXPOSURE_EDIT_FAIL:
      switch (action.payload.message) {
        case 'RISK_AREA_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case RISK_EXPOSURE_EDIT_RESET:
      return {}
    default:
      return state
  }
}

const riskExposureDeleteReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_DELETE_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case RISK_EXPOSURE_DELETE_FAIL:
      switch (action.payload.message) {
        case 'RISK_AREA_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case RISK_EXPOSURE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const riskExposureGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_GET_ONE_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_GET_ONE_SUCCESS:
      return { loading: false, riskArea: action.payload }
    case RISK_EXPOSURE_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'RISK_AREA_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case RISK_EXPOSURE_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const riskExposureGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_GET_ALL_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_GET_ALL_SUCCESS:
      return { loading: false, riskArea: action.payload.riskAreas, count: action.payload.count }
    case RISK_EXPOSURE_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    case RISK_EXPOSURE_GET_ALL_RESET:
      return {}
    default:
      return state
  }
}

const riskExposureGroupGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case RISK_EXPOSURE_GROUP_GET_ALL_REQUEST:
      return { loading: true }
    case RISK_EXPOSURE_GROUP_GET_ALL_SUCCESS:
      return { loading: false, riskArea: action.payload }
    case RISK_EXPOSURE_GROUP_GET_ALL_FAIL:
      error = action.payload
      return { lodaing: false, error }
    default:
      return state
  }
}

export const riskExposureReducers = {
  riskExposureCreate: riskExposureCreateReducer,
  riskExposureGetOne: riskExposureGetOneReducer,
  riskExposureEdit: riskExposureEditReducer,
  riskExposureGetAll: riskExposureGetAllReducer,
  riskExposureDelete: riskExposureDeleteReducer,
  riskExposureGroupGetAll: riskExposureGroupGetAllReducer
}
