import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonProcessing from 'src/components/ButtonProcessing'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { MassCreateCompanyLocationSelectedExaminationTypeAdminDto } from 'src/entities/interfaces'
import {
  SelectedExaminationsESSearch,
  SelectedExaminationsInput
} from 'src/interfaces/companyLocationSelectedExaminationType'
import { UserEntityForLastExaminationDate } from 'src/interfaces/users'
import {
  getAll as getAllSelectedExaminationTypes,
  massCreate
} from 'src/redux/actions/companyLocationSelectedExaminationTypeActions'
import { CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET } from 'src/redux/constants/companyLocationSelectedExaminationTypeConstants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import dateFormattingService from 'src/services/dateFormatting.service'
import {
  getAssignedByUser,
  getCompanyLocationById,
  getExaminationTypeById
} from '../helpers/externalFunctions'

export const SelectedExaminations = ({
  setSelectedExaminationTypes,
  selectedExaminationTypes,
  setIsSaved,
  isSaved
}: SelectedExaminations) => {
  const { t } = useTranslation()
  const [doctorInstruction, setDoctorInstruction] = useState<string>('')
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [doctorInstructionBackend, setDoctorInstructionBackend] = useState<string>('')
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [lastAssignedBy, setLastAssignedBy] = useState<UserEntityForLastExaminationDate>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id: companyLocationId } = useParams()

  const {
    companyLocationSelectedExaminationTypeMassCreate,
    companyLocationSelectedExaminationTypeGetAll
  } = useAppSelector((state) => state)

  const {
    loading: getAllCompanyLocationSelectedExaminationTypeLoading,
    companyLocationSelectedExaminationTypes
  } = companyLocationSelectedExaminationTypeGetAll

  const {
    loading: createCompanyLocationSelectedExaminationTypeLoading,
    success: createCompanyLocationSelectedExaminationTypeSuccess,
    error: createCompanyLocationSelectedExaminationTypeError
  } = companyLocationSelectedExaminationTypeMassCreate

  useEffect(() => {
    dispatch({ type: CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET })
    dispatch(
      getAllSelectedExaminationTypes({
        page: 0,
        limit: 500,
        companyLocationId
      } as SelectedExaminationsESSearch)
    )
    setIsFirstLoad(false)
  }, [])

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (createCompanyLocationSelectedExaminationTypeSuccess && !isFirstLoad) {
      setTimeout(() => {
        dispatch(
          getAllSelectedExaminationTypes({
            page: 0,
            limit: 1000,
            companyLocationId
          } as SelectedExaminationsESSearch)
        )
      }, 100)
      setDisplaySuccessButton(true)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [
    createCompanyLocationSelectedExaminationTypeSuccess,
    createCompanyLocationSelectedExaminationTypeError
  ])

  useEffect(() => {
    ;(async () => {
      if (companyLocationSelectedExaminationTypes && !isFirstLoad && companyLocationId) {
        const selectedExaminationTypesHelper: SelectedExaminationsInput[] = []
        const companyLocation = await getCompanyLocationById(companyLocationId)
        for (let j = 0; j < companyLocationSelectedExaminationTypes.length; j++) {
          const examinationType = await getExaminationTypeById(
            companyLocationSelectedExaminationTypes[j].examinationTypeId
          )
          const selectedExaminationType: SelectedExaminationsInput = {
            examinationTypeId: companyLocationSelectedExaminationTypes[j].examinationTypeId,
            label: examinationType.name,
            examinationNote: companyLocationSelectedExaminationTypes[j].examinationNote,
            companyLocationId: companyLocationSelectedExaminationTypes[j].companyLocationId,
            assignedBy: companyLocationSelectedExaminationTypes[j].assignedBy,
            isAssigned: companyLocationSelectedExaminationTypes[
              j
            ].companyLocation.preventiveLog?.some((preventiveLog: any) =>
              preventiveLog.preventiveLogExaminationTypes.some(
                (preventiveLogExaminationType: any) =>
                  preventiveLogExaminationType.examinationTypeId ===
                    companyLocationSelectedExaminationTypes[j].examinationTypeId &&
                  preventiveLogExaminationType.examinationRequired !== 0
              )
            )
          }
          selectedExaminationTypesHelper.push(selectedExaminationType)
        }
        setDoctorInstruction(
          companyLocation.doctorInstruction ? companyLocation.doctorInstruction : ''
        )
        setDoctorInstructionBackend(
          companyLocation.doctorInstruction ? companyLocation.doctorInstruction : ''
        )
        setSelectedExaminationTypes(selectedExaminationTypesHelper)
      }
    })()
  }, [companyLocationSelectedExaminationTypes])

  const onDeleteExaminationType = (id: string) => {
    const newSelectedExaminationTypes = selectedExaminationTypes.filter(
      (examinationType) => examinationType.examinationTypeId !== id
    )
    setSelectedExaminationTypes(newSelectedExaminationTypes)
  }

  const onExaminationTypeInputChange = (value: string, examinationTypeId: string) => {
    const editedSelectedExaminationTypes = selectedExaminationTypes.map(
      (selectedExaminationType) => {
        if (selectedExaminationType.examinationTypeId === examinationTypeId) {
          selectedExaminationType.examinationNote = value
        }
        return selectedExaminationType
      }
    )
    setSelectedExaminationTypes(editedSelectedExaminationTypes)
  }

  const onSubmit = () => {
    const companyLocationExaminationTypesHelper = []
    for (let i = 0; i < selectedExaminationTypes.length; i++) {
      const examinationType = {
        examinationTypeId: selectedExaminationTypes[i].examinationTypeId,
        companyLocationId: selectedExaminationTypes[i].companyLocationId,
        examinationNote: selectedExaminationTypes[i].examinationNote,
        assignedBy: selectedExaminationTypes[i].assignedBy
      }
      companyLocationExaminationTypesHelper.push(examinationType)
    }
    dispatch(
      massCreate({
        companyLocationExaminationTypes: companyLocationExaminationTypesHelper,
        doctorInstruction: doctorInstruction,
        companyLocationId: companyLocationId
      } as MassCreateCompanyLocationSelectedExaminationTypeAdminDto)
    )
  }

  const compareSelectedExaminationBackendFronted = (
    arr1: SelectedExaminationsInput[],
    arr2: SelectedExaminationsInput[]
  ): number => {
    let savedCount = 0
    for (let i = 0; i < arr1.length; i++) {
      const arr1Item = arr1[i]
      const isItem = arr2.find(
        (arr2Item) =>
          arr2Item.examinationTypeId === arr1Item.examinationTypeId &&
          arr2Item.examinationNote === arr1Item.examinationNote
      )
      if (!isItem) {
        savedCount = savedCount + 1
      }
    }
    return savedCount
  }

  useEffect(() => {
    if (companyLocationSelectedExaminationTypes && selectedExaminationTypes && !isFirstLoad) {
      const saveCount1 = compareSelectedExaminationBackendFronted(
        companyLocationSelectedExaminationTypes,
        selectedExaminationTypes
      )
      const saveCount2 = compareSelectedExaminationBackendFronted(
        selectedExaminationTypes,
        companyLocationSelectedExaminationTypes
      )
      const saveCount3 = doctorInstructionBackend === doctorInstruction ? 0 : 1
      if (saveCount1 + saveCount2 + saveCount3 > 0) {
        setIsSaved(false)
      } else {
        setIsSaved(true)
      }
    }
  }, [selectedExaminationTypes, doctorInstruction])

  useEffect(() => {
    ;(async () => {
      if (companyLocationId) {
        const lastAssignedBy = await getAssignedByUser(companyLocationId)
        if (lastAssignedBy) {
          setLastAssignedBy(lastAssignedBy)
        }
      }
    })()
  }, [companyLocationId])

  return (
    <div>
      {(createCompanyLocationSelectedExaminationTypeLoading ||
        getAllCompanyLocationSelectedExaminationTypeLoading) && <LoadingOverlay />}
      {isCancelModal && (
        <Modal
          onCancel={() => setIsCancelModal(false)}
          onConfirm={() => navigate('/clients')}
          message={t('MODAL.CANCEL')}
        />
      )}
      {isSaveModal && (
        <Modal
          onCancel={() => setIsSaveModal(false)}
          onConfirm={() => {
            onSubmit()
            setIsSaveModal(false)
          }}
          message={t('MODAL.FINISH_SELECTION')}
        />
      )}
      <h2 className='block customHead2'>{t('GENERIC.SELECTED_EXAMINATION_TYPES')}</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setIsSaveModal(true)
        }}
      >
        <div className='divide-y overflow-scroll max-h-[400px]'>
          {selectedExaminationTypes &&
            selectedExaminationTypes.map(
              (selectedExaminationType: SelectedExaminationsInput, index: number) => {
                return (
                  <div key={index} className='flex flex-col gap-2 mt-4 first-of-type:mt-2'>
                    <div className='flex flex-col'>
                      <div className='flex justify-between items-center'>
                        <h3 className='shrink-0 customParagraph'>
                          {selectedExaminationType.label}
                        </h3>
                        <IconButton
                          onClick={() =>
                            onDeleteExaminationType(selectedExaminationType.examinationTypeId)
                          }
                          disabled={selectedExaminationType.isAssigned}
                          title={t('EXAMINATION_SELECTION.REMOVE_EXAMINATION_TYPE')}
                        >
                          <XCircleIcon className='w-5' />
                        </IconButton>
                      </div>
                      {selectedExaminationType.isAssigned && (
                        <p className='text-red-500 text-sm'>
                          {t('EXAMINATION_SELECTION.CANNOT_REMOVE_ASSIGNED_EXAMINATION')}
                        </p>
                      )}
                    </div>
                    <div className='flex items-center grow'>
                      <input
                        placeholder={t('EXAMINATION_SELECTION.CLIENT_CONTACT_INSTRUCTIONS')}
                        value={selectedExaminationType.examinationNote}
                        onChange={(e) =>
                          onExaminationTypeInputChange(
                            e.target.value,
                            selectedExaminationType.examinationTypeId
                          )
                        }
                        type='text'
                        className='block customInput grow min-w-[250px] appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200'
                      />
                    </div>
                  </div>
                )
              }
            )}
        </div>
        <div className='mt-4'>
          <h2 className='block customHead2 sm:mt-px sm:pt-2'>{t('GENERIC.INSTRUCTIONS')}</h2>
          <textarea
            value={doctorInstruction}
            onChange={(e) => setDoctorInstruction(e.target.value)}
            rows={5}
            className='mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-200 focus:outline-none focus:ring-blue-200 sm:text-sm'
          ></textarea>
        </div>
        <div className='flex justify-start gap-3 mt-3'>
          <Button
            type='submit'
            className={`main-btn ${
              (createCompanyLocationSelectedExaminationTypeLoading || displaySuccessButton) &&
              'processing'
            }${isSaved && 'disabled'}`}
            disabled={
              createCompanyLocationSelectedExaminationTypeLoading || displaySuccessButton || isSaved
            }
            variant='contained'
          >
            {createCompanyLocationSelectedExaminationTypeLoading || displaySuccessButton ? (
              <ButtonProcessing displaySuccessButton={displaySuccessButton} />
            ) : (
              t('BUTTONS.SAVE')
            )}
          </Button>
          <Button
            className='secondary-btn'
            variant='contained'
            onClick={() => {
              if (isSaved) {
                navigate('/clients')
              } else {
                setIsCancelModal(true)
              }
            }}
          >
            {t('BUTTONS.CANCEL')}
          </Button>
        </div>
        {lastAssignedBy && lastAssignedBy.firstName && (
          <p className='text-sm mt-2 text-gray-500'>
            {t('EXAMINATION_SELECTION.LAST_ASSIGNED_BY')} {lastAssignedBy.firstName}{' '}
            {lastAssignedBy.lastName}
            {' - '}
            {lastAssignedBy.lastUpdatedExaminationDate &&
              dateFormattingService.format(lastAssignedBy.lastUpdatedExaminationDate, 'DD.MM.YYYY')}
          </p>
        )}
      </form>
    </div>
  )
}

interface SelectedExaminations {
  selectedExaminationTypes: SelectedExaminationsInput[]
  setSelectedExaminationTypes: any
  setIsSaved: any
  isSaved: boolean
}
