import { omit } from 'lodash'
import { ExaminationTypeErrors, ExaminationTypeInputs } from 'src/interfaces/examinationTypes'
import i18n from '../language.service'
import { isRequired, tooManyCharacters } from './index'

export const validation = (inputs: ExaminationTypeInputs) => {
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')
  let errors: ExaminationTypeErrors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'name':
        errors[key] =
          isRequired(value, `${i18n.t('EXAMINATIONS.EXAMINATION_TITLE')} ${required}`) ||
          tooManyCharacters(value, i18n.t('ERROR_MESSAGES.EXAMINATION_TITLE_LIMITED'))
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'doctorDuration':
        errors[key] = isRequired(
          value,
          `${i18n.t('EXAMINATIONS.EXAMINATION_DURATION')} ${required}`
        )
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'mfaDuration':
        errors[key] = isRequired(
          value,
          `${i18n.t('EXAMINATIONS.EXAMINATION_DURATION')} ${required}`
        )
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: ExaminationTypeInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if ((input && !value) || (input && value && isRequired(value))) {
      isDisabled += 1
    }
  }
  return isDisabled
}
