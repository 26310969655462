import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getGroupedData } from '../helpers/internalFunctions'

export const UpcomingAppointmentRequestsItem = ({
  appointmentRequest
}: {
  appointmentRequest: any
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [groupedData, setGroupedData] = useState<any>({})
  useEffect(() => {
    if (appointmentRequest) {
      setGroupedData(getGroupedData(appointmentRequest?.AppointmentRequestETGroup))
    }
  }, [appointmentRequest])

  return (
    <div className='py-5 item-list grid grid-cols-1 md:grid-cols-6 md:items-start w-full gap-3 md:gap-0'>
      <div className='col-span-2'>
        <h2 className='customHead2'>
          {appointmentRequest.companyLocation.company.name}
          {' - '}
          {appointmentRequest.companyLocation.name}
        </h2>
        <div>
          {groupedData?.dates?.map((date: any, index: any) => {
            return (
              <h4 key={index} className='customHead4 !font-normal'>
                {date}
              </h4>
            )
          })}
        </div>
        <h4 className='customHead4'>
          {groupedData?.confirmedAppointments} {t('DASHBOARD.CONFIRMED_APPOINTMENTS')}
        </h4>
      </div>
      <div className='mt-[3px] col-span-3'>
        <h2 className='customHead2'>{t('DASHBOARD.EXAMINATION_TYPES_TO_BE_PERFORMED')}</h2>
        {groupedData?.examinationTypes?.map((examinationType: any, index: number) => {
          return (
            <h4 key={index} className='customHead4'>
              {examinationType?.name}
            </h4>
          )
        })}
      </div>
      <div className='flex md:justify-end'>
        <Button
          onClick={() => navigate(`/scheduled-appointment-overview/${appointmentRequest.id}`)}
          className='main-btn self-center'
          variant='contained'
        >
          {t('DASHBOARD.DETAILS')}
        </Button>
      </div>
    </div>
  )
}
