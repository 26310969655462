import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getRoleByUserId } from 'src/redux/actions/roleActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export const AuthLayout = () => {
  const { userSignin, rolesGetByUser } = useAppSelector((state) => state)
  const { isSignin, user } = userSignin
  const { role } = rolesGetByUser

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSignin && user) {
      dispatch(getRoleByUserId(user.id))
    }
  }, [isSignin, user])

  useEffect(() => {
    if (role) {
      const adminRole = role.find((r: string) => r === 'admin' || r === 'superadmin')
      if (isSignin && adminRole) {
        navigate('/companies', { replace: true })
      } else if (isSignin && !adminRole) {
        navigate('/')
      }
    }
  }, [role, isSignin])

  return (
    <div className='flex min-h-screen flex-col justify-center pb-14 pt-2 sm:px-6 lg:px-8 px-4'>
      <Outlet />
    </div>
  )
}
