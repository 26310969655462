import { AnyAction } from 'redux'
import {
  GET_CONTACT_PERSON_FAIL,
  GET_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON_RESET,
  GET_CONTACT_PERSON_SUCCESS,
  USER_CHECK_COOKIES_FAIL,
  USER_CHECK_COOKIES_REQUEST,
  USER_CHECK_COOKIES_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_RESET,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_RESET,
  USER_DELETE_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_RESET,
  USER_EDIT_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_GET_ALL_INTERNAL_FAIL,
  USER_GET_ALL_INTERNAL_REQUEST,
  USER_GET_ALL_INTERNAL_SUCCESS,
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ONE_FAIL,
  USER_GET_ONE_REQUEST,
  USER_GET_ONE_RESET,
  USER_GET_ONE_SUCCESS,
  USER_PREVENTIVE_LOG_GET_ALL_FAIL,
  USER_PREVENTIVE_LOG_GET_ALL_REQUEST,
  USER_PREVENTIVE_LOG_GET_ALL_SUCCESS
} from 'src/redux/constants/userConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const userCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true }
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case USER_CREATE_FAIL:
      switch (action.payload.message) {
        case 'EMAIL_ALREADY_IN_USE':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case USER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const userEditReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { loading: true }
    case USER_EDIT_SUCCESS:
      return { loading: false, success: true }
    case USER_EDIT_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
        case 'EMAIL_ALREADY_IN_USE':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case USER_EDIT_RESET:
      return {}
    default:
      return state
  }
}

const employeeDeleteReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      error = errorMessage
      return { loading: false, error }
    case USER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const userGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_GET_ALL_REQUEST:
      return { loading: true }
    case USER_GET_ALL_SUCCESS:
      return { loading: false, users: action.payload.users, count: action.payload.count }
    case USER_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const userGetAllInternalReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_GET_ALL_INTERNAL_REQUEST:
      return { loading: true }
    case USER_GET_ALL_INTERNAL_SUCCESS:
      return { loading: false, users: action.payload.users, count: action.payload.count }
    case USER_GET_ALL_INTERNAL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const userGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_GET_ONE_REQUEST:
      return { loading: true }
    case USER_GET_ONE_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'USER_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case USER_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const userGetAllPreventiveLogReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_PREVENTIVE_LOG_GET_ALL_REQUEST:
      return { loading: true }
    case USER_PREVENTIVE_LOG_GET_ALL_SUCCESS:
      return { loading: false, users: action.payload.users, count: action.payload.count }
    case USER_PREVENTIVE_LOG_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const userCheckCookiesReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case USER_CHECK_COOKIES_REQUEST:
      return { loading: true }
    case USER_CHECK_COOKIES_SUCCESS:
      return { loading: false, cookies: action.payload }
    case USER_CHECK_COOKIES_FAIL:
      error = errorMessage
      return { loading: false, cookies: action.payload, error }
    default:
      return state
  }
}

const getContactPersonForLocationReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case GET_CONTACT_PERSON_REQUEST:
      return { loading: true }
    case GET_CONTACT_PERSON_SUCCESS:
      return { loading: false, users: action.payload.users, count: action.payload.count }
    case GET_CONTACT_PERSON_FAIL:
      error = errorMessage
      return { loading: false, error }
    case GET_CONTACT_PERSON_RESET:
      return {}
    default:
      return state
  }
}

export const userReducers = {
  userGetOne: userGetOneReducer,
  userGetAll: userGetAllReducer,
  userGetAllInternal: userGetAllInternalReducer,
  userCreate: userCreateReducer,
  userEdit: userEditReducer,
  userDelete: employeeDeleteReducer,
  userGetAllPreventiveLog: userGetAllPreventiveLogReducer,
  userCheckCookies: userCheckCookiesReducer,
  userGetAllContactsForLocation: getContactPersonForLocationReducer
}
