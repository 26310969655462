import { AnyAction } from 'redux'
import {
  DOWNLOAD_CERTIFICATES_FAIL,
  DOWNLOAD_CERTIFICATES_REQUEST,
  DOWNLOAD_CERTIFICATES_RESET,
  DOWNLOAD_CERTIFICATES_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAIL,
  DOWNLOAD_CERTIFICATE_REQUEST,
  DOWNLOAD_CERTIFICATE_RESET,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  EXAMINATION_HISTORY_GET_FAIL,
  EXAMINATION_HISTORY_GET_REQUEST,
  EXAMINATION_HISTORY_GET_RESET,
  EXAMINATION_HISTORY_GET_SUCCESS,
  EXAMINATION_RECORD_MASS_MARK_FAIL,
  EXAMINATION_RECORD_MASS_MARK_REQUEST,
  EXAMINATION_RECORD_MASS_MARK_RESET,
  EXAMINATION_RECORD_MASS_MARK_SUCCESS
} from 'src/redux/constants/examinationConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERRORS.DEFAULT')
const examinationRecordMassMarkReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case EXAMINATION_RECORD_MASS_MARK_REQUEST:
      return { loading: true }
    case EXAMINATION_RECORD_MASS_MARK_SUCCESS:
      return { loading: false, success: action.payload }
    case EXAMINATION_RECORD_MASS_MARK_FAIL:
      return { loading: false, error: action.payload.message }
    case EXAMINATION_RECORD_MASS_MARK_RESET:
      return {}
    default:
      return state
  }
}

const examinationHistoryGetReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case EXAMINATION_HISTORY_GET_REQUEST:
      return { loading: true }
    case EXAMINATION_HISTORY_GET_SUCCESS:
      return {
        loading: false,
        examinations: action.payload.appointments,
        count: action.payload.count
      }
    case EXAMINATION_HISTORY_GET_FAIL:
      return { loading: false, error: action.payload.message }
    case EXAMINATION_HISTORY_GET_RESET:
      return {}
    default:
      return state
  }
}

const examinationDownloadCertificatesReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case DOWNLOAD_CERTIFICATES_REQUEST:
      return { loading: true }
    case DOWNLOAD_CERTIFICATES_SUCCESS:
      return { loading: false, certificatesBuffer: action.payload }
    case DOWNLOAD_CERTIFICATES_FAIL:
      error = 'Something went wrong, please try again'
      return { loading: false, error }
    case DOWNLOAD_CERTIFICATES_RESET:
      return {}
    default:
      return state
  }
}

const examinationDownloadCertificateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case DOWNLOAD_CERTIFICATE_REQUEST:
      return { loading: true }
    case DOWNLOAD_CERTIFICATE_SUCCESS:
      return { loading: false, certificateBuffer: action.payload }
    case DOWNLOAD_CERTIFICATE_FAIL:
      switch (action.payload.message) {
        case 'EXAMINATIONS_NOT_FOUND':
        case 'FILE_NOT_FOUND':
          error = i18n.t(`ERRORS.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case DOWNLOAD_CERTIFICATE_RESET:
      return {}
    default:
      return state
  }
}

export const examinationReducers = {
  examinationRecordMassMark: examinationRecordMassMarkReducer,
  examinationHistoryGet: examinationHistoryGetReducer,
  examinationDownloadCertificates: examinationDownloadCertificatesReducer,
  examinationDownloadCertificate: examinationDownloadCertificateReducer
}
