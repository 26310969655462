export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const USER_CREATE_RESET = 'USER_CREATE_RESET'

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST'
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS'
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL'
export const USER_EDIT_RESET = 'USER_EDIT_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST'
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS'
export const USER_GET_ALL_FAIL = 'USER_GET_ALL_FAIL'

export const USER_PREVENTIVE_LOG_GET_ALL_REQUEST = 'USER_PREVENTIVE_LOG_GET_ALL_REQUEST'
export const USER_PREVENTIVE_LOG_GET_ALL_SUCCESS = 'USER_PREVENTIVE_LOG_GET_ALL_SUCCESS'
export const USER_PREVENTIVE_LOG_GET_ALL_FAIL = 'USER_PREVENTIVE_LOG_GET_ALL_FAIL'

export const USER_GET_ONE_REQUEST = 'USER_GET_ONE_REQUEST'
export const USER_GET_ONE_SUCCESS = 'USER_GET_ONE_SUCCESS'
export const USER_GET_ONE_FAIL = 'USER_GET_ONE_FAIL'
export const USER_GET_ONE_RESET = 'USER_GET_ONE_RESET'

export const USER_GET_ALL_INTERNAL_REQUEST = 'USER_GET_ALL_INTERNAL_REQUEST'
export const USER_GET_ALL_INTERNAL_SUCCESS = 'USER_GET_ALL_INTERNAL_SUCCESS'
export const USER_GET_ALL_INTERNAL_FAIL = 'USER_GET_ALL_INTERNAL_FAIL'

export const USER_CHECK_COOKIES_REQUEST = 'USER_CHECK_COOKIES_REQUEST'
export const USER_CHECK_COOKIES_SUCCESS = 'USER_CHECK_COOKIES_SUCCESS'
export const USER_CHECK_COOKIES_FAIL = 'USER_CHECK_COOKIES_FAIL'

export const GET_CONTACT_PERSON_REQUEST = 'GET_CONTACT_PERSON_REQUEST'
export const GET_CONTACT_PERSON_SUCCESS = 'GET_CONTACT_PERSON_SUCCESS'
export const GET_CONTACT_PERSON_FAIL = 'GET_CONTACT_PERSON_FAIL'
export const GET_CONTACT_PERSON_RESET = 'GET_CONTACT_PERSON_RESET'
