import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputLabelLeft, SelectLabelLeft } from 'src/components/FormComponents'
import {
  CompanyErrors,
  CompanyInputs,
  LocationElement,
  LocationElementErrors
} from 'src/interfaces/companies'
import { PracticeOption } from 'src/interfaces/practices'
import { getAll } from 'src/redux/actions/practiceActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'

export const LocationsForm = ({
  inputs,
  submitted,
  errors,
  setVal,
  setLocations
}: LocationsFormInterface) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<PracticeOption[]>([])
  const [practiceSearchQuery, setPracticeSearchQuery] = useState('')

  const { practiceGetAll } = useAppSelector((state) => state)
  const { practices } = practiceGetAll

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAll({ limit: 20, page: 0, query: practiceSearchQuery }))
  }, [practiceSearchQuery])

  useEffect(() => {
    if (practices) {
      const filteredOptions = []
      for (let i = 0; i < practices.length; i++) {
        const item = { value: practices[i].id, label: practices[i].name }
        filteredOptions.push(item)
      }
      setOptions(filteredOptions)
    }
  }, [practices])

  return (
    <div>
      {inputs?.locations.length === 0 && submitted && (
        <p className='text-red-700 text-[14px] mt-2'>{t('LOCATIONS.LOCATION_REQUIREMENT')}</p>
      )}
      <div className='divide-y divide-gray'>
        {inputs?.locations.map((location: LocationElement, index: number) => {
          let locationErrors: LocationElementErrors = {}
          if (errors?.['locations'] && submitted) {
            locationErrors = errors?.['locations']?.filter(
              (error: LocationElementErrors) => error.id === location.id
            )[0]
          }
          return (
            <div key={index} className='locations-form-holder py-5 first-of-type:pt-0'>
              {(index !== 0 || inputs.locations.length > 0) && (
                <>
                  <div className='flex items-center justify-between'>
                    <h1 className='text-gray-700 text-sm font-medium'>
                      {t('LOCATIONS.LOCATION')} #{index + 1}{' '}
                      {location.hasContact ? (
                        <span className='text-gray-700 text-xs font-medium ml-2'>
                          Has preventive log
                        </span>
                      ) : null}
                    </h1>
                    <div className=''>
                      {!location.hasContact && (
                        <IconButton
                          className='ml-auto flex gap-2 items-center px-0 hover:bg-white'
                          onClick={() => setLocations('remove', location.id)}
                        >
                          <XCircleIcon className='w-7 text-brand-orange-700' />
                          <p className='text-sm text-brand-orange-700'>
                            {t('LOCATIONS.REMOVE_LOCATION')}
                          </p>
                        </IconButton>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className='md:px-12 md:mt-5 space-y-6'>
                <InputLabelLeft
                  value={location.name}
                  required
                  type='text'
                  onChange={setVal}
                  error={locationErrors ? locationErrors['name'] : undefined}
                  itemId={location.id}
                  itemName='name'
                  name='locations'
                  label={t('COMPANY.LOCATION_NAME')}
                />
                <InputLabelLeft
                  value={location.address}
                  required
                  type='text'
                  onChange={setVal}
                  error={locationErrors ? locationErrors['address'] : undefined}
                  itemId={location.id}
                  itemName='address'
                  name='locations'
                  label={t('COMPANY.STREET_ADDRESS')}
                />
                <SelectLabelLeft
                  value={location.associatedPractice}
                  required
                  onChange={setVal}
                  error={locationErrors ? locationErrors['associatedPractice'] : undefined}
                  itemId={location.id}
                  itemName='associatedPractice'
                  setSearchText={setPracticeSearchQuery}
                  searchText={'practiceSearchQuery'}
                  name='locations'
                  label={t('COMPANY.ASSOCIATED_PRACTICE')}
                  options={options}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className='mt-5'>
        <IconButton
          className='flex items-center gap-2 px-0 hover:bg-white'
          onClick={() => setLocations('add')}
        >
          <PlusCircleIcon className='w-7 text-brand-orange-700' />
          <p className='text-sm text-brand-orange-700'>{t('LOCATIONS.ADD_LOCATION')}</p>
        </IconButton>
      </div>
    </div>
  )
}

interface LocationsFormInterface {
  inputs?: CompanyInputs
  submitted?: boolean
  errors?: CompanyErrors
  setVal?: any
  setLocations?: any
}
