import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/redux/hook'
import { UpcomingAppointmentRequestsItem } from './UpcomingAppointmentRequestsItem'

export const UpcomingAppointmentRequestsList = () => {
  const { t } = useTranslation()
  const { widgetUpcomingARGet } = useAppSelector((state) => state)
  const { appointmentRequests } = widgetUpcomingARGet

  return (
    <div>
      <h2 className='customHead2 gray'>{t('DASHBOARD.UPCOMING_APPOINTMENTS')}</h2>
      <div className='divide-y divide-gray-200 flex flex-col'>
        {appointmentRequests?.map((appointmentRequest: any, index: number) => (
          <UpcomingAppointmentRequestsItem key={index} appointmentRequest={appointmentRequest} />
        ))}
      </div>
    </div>
  )
}
