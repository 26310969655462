import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ListDataEntity } from 'src/entities/interfaces'
import { useAppSelector } from 'src/redux/hook'

export function AppointmentsList() {
  const { t } = useTranslation()
  const { aRAppointmentsOverviewGet, aROptedOutAppointmentsOverviewGet } = useAppSelector(
    (state) => state
  )
  const { appointmentRequest } = aRAppointmentsOverviewGet
  const { optedAppointments } = aROptedOutAppointmentsOverviewGet
  const [filtertedExaminations, setFilteredExaminations] = useState<any[]>([])

  useEffect(() => {
    if (appointmentRequest) {
      const examinationHelper: any[] = []
      appointmentRequest.listData.map((data: ListDataEntity) => {
        data.appointments.map((app) => {
          app.examinations.map((examination) => {
            examinationHelper?.push({ userId: app.userId, examination })
          })
        })
      })
      setFilteredExaminations(examinationHelper)
    }
  }, [appointmentRequest])

  return (
    <div className='h-screen overflow-y-scroll'>
      {appointmentRequest && appointmentRequest.listData.length > 0 && (
        <>
          {appointmentRequest.listData.map((data: ListDataEntity, index: number) => {
            return (
              <div key={index} className='mt-12 first-of-type:mt-0'>
                <h3 className='customHead3'>
                  {moment(data.startDate).format('dddd, DD.MM.YYYY')},{' '}
                  {moment(data.startDate).format('HH:mm')}
                  <span className='customHead3 mx-1'>{t('SCHEDULING_SCREEN.TILL')}</span>
                  {moment(data.endDate).format('HH:mm')}
                </h3>
                <div className='mt-4 h-full'>
                  {data.appointments.map((appointment, index: number) => {
                    return (
                      <div
                        key={index}
                        className='mt-4 first-of-type:mt-0 border-gray-200 border-b pb-2 flex justify-between'
                      >
                        <div>
                          <div>
                            <h4 className='customHead4'>
                              {moment(appointment.startDate).format('HH:mm')}
                              <Link
                                to={`/examinations-record/${appointment.appointmentId}/${appointment.userId}`}
                                className='underline ml-2 !text-brand-orange-700'
                              >
                                {appointment.userName}
                              </Link>
                            </h4>
                          </div>
                          <div className='mt-4'>
                            <h4 className='customHead4'>{t('EXAMINATIONS.EXAMINATIONS')}:</h4>
                            <ul className='customUl mt-3'>
                              {filtertedExaminations &&
                                filtertedExaminations.map((filtertedExamination, index) => {
                                  const { userId, examination } = filtertedExamination
                                  if (userId === appointment?.userId) {
                                    return (
                                      <li key={index} className='flex justify-between mt-2'>
                                        {examination?.examinationName}
                                      </li>
                                    )
                                  }
                                })}
                            </ul>
                          </div>
                        </div>
                        <div className='shrink-0 mt-1'>
                          <div>
                            {appointment.status === 'Completed' && (
                              <p className='customParagraph flex gap-1 items-center'>
                                <CheckCircleIcon className='w-5 text-green-500' />
                                {t('SCHEDULING_SCREEN.EXAMINATIONS_COMPLETE')}
                              </p>
                            )}
                            {appointment.status === 'Completed' && (
                              <p className='customParagraph flex gap-1 items-center'>
                                {appointment.employerCertificate ? (
                                  <CheckCircleIcon className='w-5 text-green-500' />
                                ) : (
                                  <ExclamationCircleIcon className='w-5 text-red-500' />
                                )}
                                {appointment.employerCertificate
                                  ? `${t('SCHEDULING_SCREEN.CERTIFICATES_UPLOADED')}`
                                  : `${t('SCHEDULING_SCREEN.CERTIFICATES_MISSING')}`}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </>
      )}
      {optedAppointments && optedAppointments?.usersAndExaminations.length > 0 && (
        <div
          className={`${appointmentRequest && appointmentRequest.listData.length > 0 && 'mt-4'}`}
        >
          <div className='mt-12 first-of-type:mt-0'>
            <h2 className='customHead3'>{t('SCHEDULING_SCREEN.OPTED_OUT')}</h2>
          </div>
          <div className='mt-4'>
            {optedAppointments?.usersAndExaminations.map((optedAppointment: any, index: number) => {
              const { examinations, employee, userId, appointmentRequestId, company, status } =
                optedAppointment
              const { firstName, lastName } = employee
              return (
                <div
                  key={index}
                  className='mt-4 first-of-type:mt-0 border-gray-200 border-b pb-2 flex justify-between'
                >
                  <div>
                    <div>
                      <h4 className='customHead4'>
                        <Link
                          to={`/examinations-record/${userId}`}
                          state={{ appReqId: appointmentRequestId, employee, company }}
                          className='underline !text-brand-orange-700'
                        >
                          {firstName} {lastName}{' '}
                          <span className='text-sm'>({t('SCHEDULING_SCREEN.OPTED_OUT')})</span>
                        </Link>
                      </h4>
                    </div>
                    <div className='mt-4'>
                      <h4 className='customHead4'>{t('EXAMINATIONS.EXAMINATIONS')}:</h4>
                      <ul className='customUl mt-3'>
                        {examinations &&
                          examinations.map((examination: any, index: number) => {
                            return (
                              <li key={index} className='flex justify-between mt-2'>
                                {examination?.examinationName}
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className='shrink-0 mt-1'>
                    <div>
                      {status === 'Skipped' && (
                        <p className='customParagraph flex gap-1 items-center'>
                          <CheckCircleIcon className='w-5 text-green-500' />
                          {t('SCHEDULING_SCREEN.NEXT_DUE_DATES_PROVIDED')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
