import { AnyAction } from 'redux'
import {
  ROLE_GET_ALL_FAIL,
  ROLE_GET_ALL_REQUEST,
  ROLE_GET_ALL_SUCCESS,
  ROLE_GET_BY_USER_FAIL,
  ROLE_GET_BY_USER_REQUEST,
  ROLE_GET_BY_USER_SUCCESS,
  ROLE_GET_ONE_FAIL,
  ROLE_GET_ONE_REQUEST,
  ROLE_GET_ONE_RESET,
  ROLE_GET_ONE_SUCCESS
} from 'src/redux/constants/roleConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const roleGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case ROLE_GET_ALL_REQUEST:
      return { loading: true }
    case ROLE_GET_ALL_SUCCESS:
      return { loading: false, roles: action.payload.roles, count: action.payload.count }
    case ROLE_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const roleGetOneReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case ROLE_GET_ONE_REQUEST:
      return { loading: true }
    case ROLE_GET_ONE_SUCCESS:
      return { loading: false, role: action.payload }
    case ROLE_GET_ONE_FAIL:
      switch (action.payload.message) {
        case 'ROLE_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case ROLE_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

const rolesGetByUserReducer = (state = {}, action: AnyAction) => {
  let error = ''
  const roleHelper: string[] = []
  switch (action.type) {
    case ROLE_GET_BY_USER_REQUEST:
      return { loading: true }
    case ROLE_GET_BY_USER_SUCCESS:
      action.payload.forEach((role: { role: string }) => {
        roleHelper.push(role.role)
      })
      return { loading: false, role: roleHelper }
    case ROLE_GET_BY_USER_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

export const roleReducers = {
  roleGetOne: roleGetOneReducer,
  rolesGetAll: roleGetAllReducer,
  rolesGetByUser: rolesGetByUserReducer
}
