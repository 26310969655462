import { AnyAction } from 'redux'
import {
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET,
  CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET,
  CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS
} from 'src/redux/constants/companyLocationSelectedExaminationTypeConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const companyLocationSelectedExaminationTypeMassCreateReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_REQUEST:
      return { loading: true }
    case CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_SUCCESS:
      return { loading: false, success: action.payload }
    case CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_FAIL:
      error = errorMessage
      return { loading: false, error }
    case CL_SELECTED_EXAMINATION_TYPE_MASS_CREATE_RESET:
      return {}
    default:
      return state
  }
}

const companyLocationSelectedExaminationTypeGetAllReducer = (state = {}, action: AnyAction) => {
  let error = ''
  switch (action.type) {
    case CL_SELECTED_EXAMINATION_TYPE_GET_ALL_REQUEST:
      return { loading: true }
    case CL_SELECTED_EXAMINATION_TYPE_GET_ALL_SUCCESS:
      return {
        loading: false,
        companyLocationSelectedExaminationTypes:
          action.payload.companyLocationSelectedExaminationTypes,
        count: action.payload.count
      }
    case CL_SELECTED_EXAMINATION_TYPE_GET_ALL_FAIL:
      error = errorMessage
      return { loading: false, error }
    default:
      return state
  }
}

const companyLocationSelectedExaminationTypeShareWithClientReducer = (
  state = {},
  action: AnyAction
) => {
  let error = ''
  switch (action.type) {
    case CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_REQUEST:
      return { loading: true }
    case CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_SUCCESS:
      return { loading: false, success: action.payload }
    case CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_FAIL:
      switch (action.payload.message) {
        case 'COMPANY_LOCATION_NOT_FOUND':
          error = i18n.t(`ERROR_MESSAGES.${action.payload.message}`)
          break
        default:
          error = errorMessage
          break
      }
      return { loading: false, error }
    case CL_SELECTED_EXAMINATION_TYPE_SHARE_WITH_CLIENT_RESET:
      return {}
    default:
      return state
  }
}

export const companyLocationSelectedExaminationTypeReducers = {
  companyLocationSelectedExaminationTypeMassCreate:
    companyLocationSelectedExaminationTypeMassCreateReducer,
  companyLocationSelectedExaminationTypeGetAll: companyLocationSelectedExaminationTypeGetAllReducer,
  companyLocationSelectedExaminationTypeShareWithClient:
    companyLocationSelectedExaminationTypeShareWithClientReducer
}
