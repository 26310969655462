export const EXAMINATION_RECORD_MASS_MARK_REQUEST = 'EXAMINATION_RECORD_MASS_MARK_REQUEST'
export const EXAMINATION_RECORD_MASS_MARK_SUCCESS = 'EXAMINATION_RECORD_MASS_MARK_SUCCESS'
export const EXAMINATION_RECORD_MASS_MARK_FAIL = 'EXAMINATION_RECORD_MASS_MARK_FAIL'
export const EXAMINATION_RECORD_MASS_MARK_RESET = 'EXAMINATION_RECORD_MASS_MARK_RESET'

export const EXAMINATION_HISTORY_GET_FAIL = 'EXAMINATION_HISTORY_GET_FAIL'
export const EXAMINATION_HISTORY_GET_REQUEST = 'EXAMINATION_HISTORY_GET_REQUEST'
export const EXAMINATION_HISTORY_GET_SUCCESS = 'EXAMINATION_HISTORY_GET_SUCCESS'
export const EXAMINATION_HISTORY_GET_RESET = 'EXAMINATION_HISTORY_GET_RESET'

export const DOWNLOAD_CERTIFICATES_REQUEST = 'DOWNLOAD_CERTIFICATES_REQUEST'
export const DOWNLOAD_CERTIFICATES_SUCCESS = 'DOWNLOAD_CERTIFICATES_SUCCESS'
export const DOWNLOAD_CERTIFICATES_FAIL = 'DOWNLOAD_CERTIFICATES_FAIL'
export const DOWNLOAD_CERTIFICATES_RESET = 'DOWNLOAD_CERTIFICATES_RESET'

export const DOWNLOAD_CERTIFICATE_REQUEST = 'DOWNLOAD_CERTIFICATE_REQUEST'
export const DOWNLOAD_CERTIFICATE_SUCCESS = 'DOWNLOAD_CERTIFICATE_SUCCESS'
export const DOWNLOAD_CERTIFICATE_FAIL = 'DOWNLOAD_CERTIFICATE_FAIL'
export const DOWNLOAD_CERTIFICATE_RESET = 'DOWNLOAD_CERTIFICATE_RESET'
