import { GridRowId } from '@mui/x-data-grid'
import {
  APPOINTMENT_REQUEST_GROUP_CREATE_FAIL,
  APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_DELETE_FAIL,
  APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_EDIT_FAIL,
  APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST,
  APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST,
  APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST,
  APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS
} from 'src/redux/constants/appointmentRequestEtGroupConstats'
import type { AppDispatch } from 'src/redux/store'
import { api } from 'src/services/api.service'

export const create = (inputs: any) => (dispatch: AppDispatch) => {
  dispatch({ type: APPOINTMENT_REQUEST_GROUP_CREATE_REQUEST, payload: {} })
  api
    .post('/api/admin/appointment-request-etgroup', inputs)
    .then((res) => {
      dispatch({ type: APPOINTMENT_REQUEST_GROUP_CREATE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: APPOINTMENT_REQUEST_GROUP_CREATE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const editAppointmentRequestEtGroup =
  (data: any, id?: string) => (dispatch: AppDispatch) => {
    dispatch({ type: APPOINTMENT_REQUEST_GROUP_EDIT_REQUEST, payload: {} })
    api
      .patch(`/api/admin/appointment-request-etgroup/${id}`, data)
      .then((res) => {
        dispatch({ type: APPOINTMENT_REQUEST_GROUP_EDIT_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: APPOINTMENT_REQUEST_GROUP_EDIT_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const getAllAppointmentRequestEtGroup =
  ({ appointmentRequestId = '' }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: APPOINTMENT_REQUEST_GROUP_GET_ALL_REQUEST, payload: {} })
    api
      .get(`/api/admin/appointment-request-etgroup?appointmentRequestId=${appointmentRequestId}`)
      .then((res) => {
        dispatch({
          type: APPOINTMENT_REQUEST_GROUP_GET_ALL_SUCCESS,
          payload: { appointmentRequestEtGroups: res.data, count: Number(res.headers['x-count']) }
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: APPOINTMENT_REQUEST_GROUP_GET_ALL_FAIL,
          payload: error.response.data.message || error.response.data.error
        })
      })
  }

export const remove = (id: GridRowId) => (dispatch: AppDispatch) => {
  dispatch({ type: APPOINTMENT_REQUEST_GROUP_DELETE_REQUEST, payload: {} })
  api
    .delete(`/api/admin/appointment-request-etgroup/${id}`)
    .then((res) => {
      dispatch({ type: APPOINTMENT_REQUEST_GROUP_DELETE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: APPOINTMENT_REQUEST_GROUP_DELETE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}

export const getOne = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: APPOINTMENT_REQUEST_GROUP_GET_ONE_REQUEST, payload: {} })
  api
    .get(`/api/admin/appointment-request-etgroup/${id}`)
    .then((res) => {
      dispatch({ type: APPOINTMENT_REQUEST_GROUP_GET_ONE_SUCCESS, payload: res.data })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: APPOINTMENT_REQUEST_GROUP_GET_ONE_FAIL,
        payload: error.response.data.message || error.response.data.error
      })
    })
}
