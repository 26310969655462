import { omit } from 'lodash'
import { CompanyErrors, CompanyInputs } from 'src/interfaces/companies'
import i18n from '../language.service'
import { isNumber, isRequired, tooManyCharacters } from './index'

export const validation = (inputs: CompanyInputs) => {
  let errors: CompanyErrors = {}
  const required = i18n.t('ERROR_MESSAGES.IS_REQUIRED')

  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'name':
        errors[key] =
          isRequired(value, `${i18n.t('COMPANY.COMPANY_NAME')} ${required}`) ||
          tooManyCharacters(value, i18n.t('ERROR_MESSAGES.COMPANY_NAME_LIMITED'))
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'externalId':
        errors[key] =
          isRequired(value, `${i18n.t('COMPANY.COMPANY_NUMBER')} ${required}`) ||
          isNumber(value, i18n.t('ERROR_MESSAGES.IS_NOT_NUMBER'))
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'locations':
        errors[key] = []
        for (let i = 0; i < value.length; i++) {
          const item = value[i]
          const itemError = {
            id: item.id,
            name: isRequired(item.name, `${i18n.t('COMPANY.LOCATION_NAME')} ${required}`),
            address: isRequired(item.address, `${i18n.t('COMPANY.LOCATION_ADDRESS')} ${required}`),
            associatedPractice: isRequired(
              item.associatedPractice.value,
              `${i18n.t('COMPANY.ASSOCIATED_PRACTICE')} ${required}`
            )
          }
          if (itemError.name || itemError.address || itemError.associatedPractice) {
            errors[key]?.push(itemError)
          }
        }
        if (errors[key]?.length === 0) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

export const checkRequiredFields = (inputs: CompanyInputs, fields: string[]) => {
  let isDisabled = 0
  for (const [key, value] of Object.entries(inputs)) {
    const input = fields.find((field) => field === key)
    if (key === 'locations' && input) {
      if (value.length === 0) {
        isDisabled += 1
      } else {
        for (const location of value) {
          isDisabled = location.name === '' ? isDisabled + 1 : isDisabled
          isDisabled = location.address === '' ? isDisabled + 1 : isDisabled
          isDisabled = location.associatedPractice.value === '' ? isDisabled + 1 : isDisabled
        }
      }
    } else if ((input && !value) || (input && value && isRequired(value))) {
      isDisabled += 1
    }
  }
  return isDisabled
}
