import { Button } from '@mui/material'
import { t } from 'i18next'
import Cookies from 'js-cookie'

export const CookieModal = ({
  setCookiesPresent
}: {
  setCookiesPresent: (value: boolean) => void
}) => {
  const onCancel = () => {
    const inTenYears = new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    Cookies.set('cookie_acceptance', 'false', { expires: inTenYears })
    window.location.replace('https://www.betriebsarztservice.de/')
    setCookiesPresent(false)
  }

  const onConfirm = () => {
    const inTenYears = new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    Cookies.set('cookie_acceptance', 'true', { expires: inTenYears })
    setCookiesPresent(true)
  }

  return (
    <>
      <div className='fixed w-full h-full bg-brand-behind-modal-color z-[52] left-0 top-0'></div>
      <div className='bg-white rounded-lg shadow fixed z-[53] max-w-[400px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <div className='p-6 text-left flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            <h2 className='customHead2 medium-size'>{t('COOKIES_MODAL.HEADER')}</h2>
            <p className='customParagraph text-gray-500'>{t('COOKIES_MODAL.MAIN_TEXT')}</p>
          </div>
          <div className='flex flex-col sm:flex-row gap-2'>
            <Button onClick={() => onConfirm()} variant='contained' className='main-btn'>
              {t('COOKIES_MODAL.ACCEPT')}
            </Button>
            <Button variant='contained' className='secondary-btn' onClick={() => onCancel()}>
              {t('COOKIES_MODAL.DECLINE')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
