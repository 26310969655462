import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorAlert } from 'src/components/Alerts'
import ButtonProcessing from 'src/components/ButtonProcessing'
import {
  InputLabelLeft,
  SelectLabelLeft,
  SelectLabelLeftCreatable
} from 'src/components/FormComponents'
import LoadingOverlay from 'src/components/LoadingOverlay'
import Modal from 'src/components/Modal'
import { CompanyLocationEntity, UserEntity } from 'src/entities/interfaces'
import { CompanyLocationOption } from 'src/interfaces/companyLocations'
import {
  PreventiveLogCostumersErrors,
  PreventiveLogCustomersInputs
} from 'src/interfaces/preventiveLogCostumers'
import { findByRelation } from 'src/redux/actions/practiceActions'
import { enable } from 'src/redux/actions/preventiveLogActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { checkRequiredFields, validation } from 'src/services/validation/customersForm'
import {
  getAllContactsForCompanyByCompanyLocation,
  getCompanyByLocation,
  getLocationByPractice
} from './helpers/externalFunctions'

export const CreatePreventiveLogCustomer = () => {
  const [isSaveModal, setIsSaveModal] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [displaySuccessButton, setDisplaySuccessButton] = useState(false)
  const [fetchedLocations, setFetchedLocations] = useState<CompanyLocationEntity[]>()

  const { t } = useTranslation()
  const [inputs, setInputs] = useState<PreventiveLogCustomersInputs>({
    email: '',
    companyLocation: { value: '', label: '' },
    additionalEmails: [],
    locations: []
  })
  const [contactUsers, setContactUsers] = useState<UserEntity[]>([])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  let errors: PreventiveLogCostumersErrors = {}
  const { enablePreventiveLog, basPracticeUserRelation, userSignin } = useAppSelector(
    (state) => state
  )
  const { loading, success, error } = enablePreventiveLog
  const { practiceRelation } = basPracticeUserRelation
  const { user } = userSignin

  useEffect(() => {
    dispatch(findByRelation(user.id))
  }, [])

  useEffect(() => {
    ;(async () => {
      if (practiceRelation) {
        const locationHelper: CompanyLocationEntity[] = []
        for (let i = 0; i < practiceRelation.length; i++) {
          const location = await getLocationByPractice(practiceRelation[i].id, 'contact')
          if (location.length > 0) locationHelper.push(location)
        }
        setFetchedLocations(locationHelper)
      }
    })()
  }, [practiceRelation])

  useEffect(() => {
    ;(async () => {
      if (fetchedLocations && fetchedLocations.length > 0) {
        const locationsArray = fetchedLocations.flat()
        const locationHelper: CompanyLocationOption[] = []
        for (let i = 0; i < locationsArray.length; i++) {
          const location = locationsArray[i]
          const company = await getCompanyByLocation(location.id)
          if (company[0].contractInPlace === true) {
            locationHelper.push({
              value: location.id,
              label: `${company[0]?.name} - ${location.name}`
            })
          }
        }
        setInputs({ ...inputs, locations: locationHelper })
      }
    })()
  }, [fetchedLocations])

  useEffect(() => {
    ;(async () => {
      if (inputs.companyLocation.value !== '') {
        const company = await getCompanyByLocation(inputs.companyLocation.value)
        if (company) {
          const contactUsers = await getAllContactsForCompanyByCompanyLocation(company[0].id)
          if (contactUsers) {
            setContactUsers(contactUsers)
          }
        }
      }
    })()
  }, [inputs.companyLocation])

  useEffect(() => {
    const isDisabled = checkRequiredFields(inputs, ['email', 'companyLocation', 'additionalEmails'])
    if (isDisabled > 0) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
  }, [inputs])

  const setVal = (value: any, name: string) => {
    if (name == 'email' && contactUsers.length > 0) {
      setInputs({ ...inputs, [name]: value.value })
    } else {
      setInputs({ ...inputs, [name]: value })
    }
  }

  const onSubmit = () => {
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      const data = {
        userEmail: inputs.email,
        companyLocationId: inputs.companyLocation.value,
        additionalUserEmails: inputs.additionalEmails.map((email) => email.email),
        senderEmail: user.email
      }
      dispatch(enable(data))
    }
  }

  useEffect(() => {
    if (displaySuccessButton) {
      setTimeout(() => {
        setDisplaySuccessButton(false)
      }, 3000)
    }
  }, [displaySuccessButton])

  useEffect(() => {
    if (success) {
      setDisplaySuccessButton(true)
      navigate('/clients')
    }
  }, [success])

  if (submitted) {
    errors = validation(inputs)
  }
  const editAdditionalEmails = (value: string, id: string) => {
    const editedAdditionalEmails = inputs.additionalEmails.map((email) => {
      if (email.id == id) {
        email.email = value
      }
      return email
    })
    setInputs({
      ...inputs,
      additionalEmails: [...editedAdditionalEmails]
    })
  }

  const setAdditionalEmails = (id: string, action: string) => {
    if (action === 'add') {
      setInputs({
        ...inputs,
        additionalEmails: [...inputs.additionalEmails, { id: String(Date.now()), email: '' }]
      })
    } else {
      const filteredAdditionalEmails = inputs.additionalEmails.filter((email) => email.id !== id)
      setInputs({
        ...inputs,
        additionalEmails: [...filteredAdditionalEmails]
      })
    }
  }

  return (
    <>
      {loading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <form
          className='space-y-8 relative'
          onSubmit={(e) => {
            e.preventDefault()
            setIsSaveModal(true)
          }}
        >
          {isCancelModal && (
            <Modal
              onCancel={() => setIsCancelModal(false)}
              onConfirm={() => navigate('/clients')}
              message={t('MODAL.CANCEL')}
            />
          )}
          {isSaveModal && (
            <Modal
              onCancel={() => setIsSaveModal(false)}
              onConfirm={() => {
                onSubmit()
                setIsSaveModal(false)
              }}
              message={t('CUSTOMER_OVERVIEW.MODAL_MESSAGE')}
            />
          )}
          {error && <ErrorAlert message={error} hide />}
          <div className='space-y-6'>
            <SelectLabelLeft
              label={t('CREATE_PREVENTIVE_LOG_CUSTOMER.SELECT_LOCATION')}
              name='companyLocation'
              value={inputs['companyLocation']}
              onChange={setVal}
              options={inputs['locations']}
              error={errors['companyLocation']}
              required
            />
            {contactUsers.length > 0 ? (
              <SelectLabelLeftCreatable
                label={`#1 ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
                name='email'
                onChange={setVal}
                error={errors['email']}
                value={inputs['email'] ? { value: inputs['email'], label: inputs['email'] } : ''}
                options={contactUsers.map((user) => ({ value: user.email, label: user.email }))}
                required
              />
            ) : (
              <InputLabelLeft
                type='text'
                name='email'
                label={`#1 ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
                value={inputs['email']}
                onChange={setVal}
                error={errors['email']}
                required
              />
            )}
            <div className='space-y-6'>
              {inputs.additionalEmails &&
                inputs.additionalEmails.map((email, index: number) => {
                  const error = errors.additionalEmails?.find((error) => error.id === email.id)
                  return (
                    <div key={index} className='relative flex sm:block'>
                      {contactUsers.length > 0 ? (
                        <SelectLabelLeftCreatable
                          label={`#${index + 2} ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
                          name={email.id}
                          value={email.email ? { value: email.email, label: email.email } : ''}
                          error={error?.message}
                          onChange={(value: any) => editAdditionalEmails(value.value, email.id)}
                          required
                          options={contactUsers.map((user) => ({
                            value: user.email,
                            label: user.email
                          }))}
                        />
                      ) : (
                        <InputLabelLeft
                          type='text'
                          name={email.id}
                          label={`#${index + 2} ${t('CREATE_PREVENTIVE_LOG_CUSTOMER.CONTACT_EMAIL')}`}
                          value={email.email}
                          onChange={editAdditionalEmails}
                          error={error?.message}
                          required
                        />
                      )}
                      <div className='absolute right-0 sm:bottom-[14px]'>
                        <IconButton
                          className='ml-auto flex gap-2 items-center px-0 hover:bg-white'
                          onClick={() => setAdditionalEmails(email.id, 'remove')}
                          title={t('CREATE_PREVENTIVE_LOG_CUSTOMER.REMOVE_CONTACT')}
                        >
                          <XCircleIcon className='w-7 text-brand-orange-700' />
                        </IconButton>
                      </div>
                    </div>
                  )
                })}
            </div>
            <IconButton
              className='flex items-center gap-2 px-0 hover:bg-white'
              onClick={() => setAdditionalEmails('', 'add')}
            >
              <PlusCircleIcon className='w-7 text-brand-orange-700' />
              <p className='text-sm text-brand-orange-700'>
                {t('CREATE_PREVENTIVE_LOG_CUSTOMER.ADD_ADDITIONAL_CONTACT')}
              </p>
            </IconButton>
          </div>
          <div>
            <p className='customParagraph mt-10'>
              <span className='text-red-700'>* </span>
              {t('GENERIC.REQUIRED_FIELD')}
            </p>
          </div>
          <div className='flex justify-start gap-3'>
            <Button
              type='submit'
              className={`main-btn ${isSaveDisabled && 'disabled'} ${
                (loading || displaySuccessButton) && 'processing'
              }`}
              disabled={isSaveDisabled || loading || displaySuccessButton}
              variant='contained'
            >
              {loading || displaySuccessButton ? (
                <ButtonProcessing displaySuccessButton={displaySuccessButton} />
              ) : (
                t('BUTTONS.SAVE')
              )}
            </Button>
            <Button
              className='secondary-btn'
              variant='contained'
              onClick={() => setIsCancelModal(true)}
            >
              {t('BUTTONS.CANCEL')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
