import { EyeIcon } from '@heroicons/react/20/solid'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGrid,
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import i18next from 'i18next'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/EmptyState'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { GridStyles } from 'src/components/gridStyles'
import { config } from 'src/config/config'
import { DoctorAvailabillityEntity } from 'src/entities/interfaces'
import {
  QueryObject,
  SortObjectPreventiveLog,
  UserListItemPreventiveLog,
  UserSearchPreventiveLog
} from 'src/interfaces/users'
import { getAllUsersPreventiveLog } from 'src/redux/actions/userActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setTheme } from 'src/services/Translations/dataGridTheme'
import { employeeColumns } from '../helper/TableColumnsHelper'
import { getSlots } from '../helper/internalFunctions'

export const List = () => {
  const [limit, setLimit] = useState(config.limit)
  const { userGetAllPreventiveLog } = useAppSelector((state) => state)
  const { users, loading: getAllLoading, count } = userGetAllPreventiveLog
  const [page, setPage] = useState<number | string>(0)
  const [queryObject, setQueryObject] = useState<QueryObject>({})
  const [sortObject, setSortObject] = useState<SortObjectPreventiveLog>({})
  const [tableRows, setTableRows] = useState<UserListItemPreventiveLog[]>([])
  const { state } = useLocation()
  const [filter, setFilter] = useState<string>('')
  const [firstLoad, setIsFirstLoad] = useState(true)
  const [language, setLanguage] = useState<string>('DE')
  const deleteButtonRef = useRef<HTMLInputElement>(null)

  const { id: companyLocationId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    if (state) {
      setFilter(state.filter)
      setIsFirstLoad(false)
    }
  }, [state])
  const savedLng = i18next.language
  useEffect(() => {
    if (savedLng) {
      setLanguage(savedLng)
    }
  }, [savedLng])
  useEffect(() => {
    if (companyLocationId) {
      let sort = sortObject?.field ? sortObject.field : ''
      if (sort === 'fullName') {
        sort = 'firstName'
      }
      const order = sortObject?.sort ? sortObject.sort : ''
      const userNameFilter =
        queryObject.field === 'fullName' && queryObject.value ? queryObject.value : ''
      const userNameFilterType =
        queryObject.field === 'fullName' && queryObject.operator ? queryObject.operator : 'contains'
      const departmentFilter =
        queryObject.field === 'department' && queryObject.value ? queryObject.value : ''
      const departmentFilterType =
        queryObject.field === 'department' && queryObject.operator
          ? queryObject.operator
          : 'contains'
      const positionFilter =
        queryObject.field === 'position' && queryObject.value ? queryObject.value : ''
      const positionFilterType =
        queryObject.field === 'position' && queryObject.operator ? queryObject.operator : 'contains'
      const nextExaminationFilter =
        queryObject.field === 'nextExamination' && queryObject.operator ? queryObject.operator : ''
      const nextExaminationFilterType =
        queryObject.field === 'nextExamination' && queryObject.operator ? queryObject.operator : ''
      const quickFilter =
        queryObject.field === 'quickFilter' && queryObject.value ? queryObject.value : ''
      let gridFilter
      if (nextExaminationFilter) {
        gridFilter = nextExaminationFilter
      } else if (filter) {
        gridFilter = filter
      } else if (state && !filter) {
        gridFilter = state.filter
      } else gridFilter = ''
      dispatch(
        getAllUsersPreventiveLog({
          limit,
          page,
          queryObject,
          sortObject,
          companyLocationId: companyLocationId,
          roleType: 'employee',
          sort,
          order,
          userNameFilter,
          userNameFilterType,
          departmentFilter,
          departmentFilterType,
          positionFilter,
          positionFilterType,
          quickFilter,
          nextExaminationFilter:
            gridFilter && gridFilter !== '' ? gridFilter : nextExaminationFilter,
          scheduledFilter: gridFilter && gridFilter === 'scheduled' ? gridFilter : '',
          missingFilter: gridFilter && gridFilter === 'missing' ? gridFilter : '',
          nextExaminationFilterType:
            gridFilter &&
            gridFilter !== '' &&
            gridFilter !== 'missing' &&
            gridFilter !== 'scheduled'
              ? gridFilter
              : nextExaminationFilterType
        } as UserSearchPreventiveLog)
      )
    }
  }, [page, queryObject, sortObject, limit])

  useEffect(() => {
    const usersHelper = []
    ;(async () => {
      if (users) {
        const departmentList: string | any[] = []
        const positionsList: string | any[] = []
        for (let i = 0; i < users.length; i++) {
          if (users[i].currentDepartment) {
            departmentList.push({
              label: users[i].currentDepartment,
              value: users[i].currentDepartment
            })
          }
          if (users[i].currentPosition) {
            positionsList.push({ label: users[i].currentPosition, value: users[i].currentPosition })
          }
          const { timeslotsScheduled } = getSlots(users[i])
          const preventiveLogHelper: any[] = []
          const preventiveLog = users[i]?.preventiveLog
          if (preventiveLog && preventiveLog.length > 0) {
            preventiveLog.map(
              (preventive: {
                preventiveLogExaminationTypes: {
                  examinationRequired: number
                  examinationType: { name: any }
                }[]
              }) => {
                preventive.preventiveLogExaminationTypes.map(
                  (type: { examinationRequired: number; examinationType: { name: any } }) => {
                    if (type.examinationRequired !== 0) {
                      preventiveLogHelper.push(type.examinationType.name)
                    } else {
                      return
                    }
                  }
                )
              }
            )
          }
          const user: UserListItemPreventiveLog = {
            id: users[i].id,
            fullName: `${users[i].firstName} ${users[i].lastName}`,
            dateOfBirth:
              users[i].dateOfBirth &&
              Math.abs(moment().diff(moment(users[i].dateOfBirth, 'YYYYMMDD'), 'years')),
            gender: users[i].gender,
            email: users[i].email,
            department: users[i].currentDepartment,
            position: users[i].currentPosition,
            examinations: preventiveLogHelper,
            status:
              preventiveLog && preventiveLog.length > 0
                ? translateStatus(preventiveLog[0].status)
                : '',
            nextExamination: users[i].nextExaminationDate
              ? formatNextExaminationDate(users[i].nextExaminationDate, timeslotsScheduled)
              : t('GENERIC.UNKNOWN'),
            preventiveLogId: preventiveLog && preventiveLog.length > 0 ? preventiveLog[0].id : ''
          }
          usersHelper.push(user)
        }
        setTableRows(usersHelper)
      }
    })()
  }, [users])

  const onFilterChange = (filterModel: GridFilterModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    const quickFilterValue = filterModel.quickFilterValues?.join(' ').toString()
    const filterItem = filterModel.items[0]
    setQueryObject(
      quickFilterValue
        ? { field: 'quickFilter', value: quickFilterValue, operator: 'contains' }
        : filterItem || {}
    )
  }

  const onSortChange = (sortModel: GridSortModel) => {
    setIsFirstLoad(false)
    typeof page === 'string' ? setPage(0) : setPage('0')
    setSortObject(sortModel[0])
  }

  const translateStatus = (status: string) => {
    switch (status) {
      case 'requested':
        return t('PREVENTIVE_LOG.REQUESTED')
      case 'invited':
        return t('PREVENTIVE_LOG.INVITED')
      case 'pending':
        return t('PREVENTIVE_LOG.PENDING')
      default:
        return ''
    }
  }

  const formatNextExaminationDate = (date: Date, timeslots: DoctorAvailabillityEntity[]) => {
    if (date && timeslots.length > 0) {
      return { scheduled: true, format: 'DD.MM.YYYY', date: date }
    } else if (date) {
      return { scheduled: false, format: 'MMMM YYYY', date: date }
    } else return `${t('PREVENTIVE_LOG.UNKNOWN')}`
  }

  const removeFilters = () => {
    if (queryObject) {
      setQueryObject({})
    }
    const deleteButton = deleteButtonRef.current
    const svg = deleteButton?.nextSibling?.firstChild
    svg?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
  }

  const { Toolbar } = useMemo(
    () => ({
      Toolbar: () => (
        <GridToolbarContainer className='flex items-center justify-between'>
          <div className='flex items-center'>
            <GridToolbarFilterButton />
            <GridToolbarExport
              printOptions={{ disableToolbarButton: true }}
              csvOptions={{
                delimiter: ';',
                utf8WithBom: true
              }}
            />
            <button className='rmFilterBtn' onClick={() => removeFilters()}>
              <EyeIcon className='h-5 w-5 mr-2' />
              {t('GENERIC.SHOW_ALL').toUpperCase()}
            </button>
          </div>
          <GridToolbarQuickFilter
            onClick={(e: any) => {
              setTimeout(() => {
                e.target.focus()
              }, 200)
            }}
            inputRef={deleteButtonRef}
          />
        </GridToolbarContainer>
      )
    }),
    []
  )

  return (
    <>
      {getAllLoading ? (
        <div className='flex justify-center items-center'>
          <LoadingOverlay />
        </div>
      ) : (
        <div className='mt-8 flex flex-col relative'>
          <div style={{ width: '100%' }}>
            {(tableRows && tableRows.length > 0) || (tableRows.length === 0 && !firstLoad) ? (
              <ThemeProvider theme={setTheme(language)}>
                <DataGrid
                  {...tableRows}
                  className='MuiDataGrid-cellContent-alignTop'
                  columns={employeeColumns}
                  rows={tableRows}
                  rowCount={count > 0 ? count : 0}
                  pagination
                  paginationMode='server'
                  sortingMode='server'
                  filterMode='server'
                  onSortModelChange={onSortChange}
                  onFilterModelChange={onFilterChange}
                  loading={getAllLoading}
                  paginationModel={{
                    page: Number(page),
                    pageSize: limit
                  }}
                  pageSizeOptions={config.dataGrid.rowsPerPageOptions}
                  onPaginationModelChange={(params) => {
                    setPage(params.page)
                    setLimit(params.pageSize)
                  }}
                  slots={{ toolbar: Toolbar }}
                  localeText={{
                    toolbarFilters: t('GENERIC.FILTER')
                  }}
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: t('GENERIC.ROWS_PER_PAGE')
                    },
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        placeholder: t('GENERIC.SEARCH'),
                        inputProps: {
                          'aria-label': t('GENERIC.SEARCH')
                        },
                        debounceMs: 500
                      }
                    }
                  }}
                  sx={GridStyles}
                  columnVisibilityModel={{ leftCompanyDate: false }}
                  getRowHeight={() => 'auto'}
                  autoHeight={true}
                  rowSelection={false}
                  checkboxSelection={false}
                  disableColumnSelector={true}
                  disableRowSelectionOnClick={true}
                />
              </ThemeProvider>
            ) : (
              <EmptyState
                titleText={t('EMPTY_STATE.EMPTY_STATE_HEADER', {
                  type: t('EMPTY_STATE.PREVENTIVE_LOG_EMPLOYEES')
                })}
                additionalText={t('EMPTY_STATE.EMPTY_STATE_ADDITIONAL')}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
