import { CompanyLocationEntity, ExaminationTypeEntity, UserEntity } from 'src/entities/interfaces'
import { ExaminationTypeSearch } from 'src/interfaces/examinationTypes'
import { api } from 'src/services/api.service'

export async function getExaminationTypesByRiskAreaId({
  page = 0,
  limit = 10,
  riskAreaId = ''
}: ExaminationTypeSearch): Promise<ExaminationTypeEntity[]> {
  return api
    .get(`/api/admin/examination-type?page=${page}&limit=${limit}&riskAreaId=${riskAreaId}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getExaminationTypeById(id: string): Promise<ExaminationTypeEntity> {
  return api
    .get(`/api/admin/examination-type/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getCompanyLocationById(id: string): Promise<CompanyLocationEntity> {
  return api
    .get(`/api/admin/company-location/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching users by location:', error)
    })
}

export async function getAssignedByUser(id: string): Promise<UserEntity> {
  return api
    .get(`/api/admin/company-location-selected-examination-type/assigned-by/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('Error fetching latest assigned by:', error)
    })
}
