import { AnyAction } from 'redux'
import {
  WIDGET_LOCATION_USER_NUMBER_GET_FAIL,
  WIDGET_LOCATION_USER_NUMBER_GET_REQUEST,
  WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS,
  WIDGET_SEND_FEEDBACK_FAIL,
  WIDGET_SEND_FEEDBACK_REQUEST,
  WIDGET_SEND_FEEDBACK_RESET,
  WIDGET_SEND_FEEDBACK_SUCCESS,
  WIDGET_UPCOMING_AR_GET_FAIL,
  WIDGET_UPCOMING_AR_GET_REQUEST,
  WIDGET_UPCOMING_AR_GET_SUCCESS
} from 'src/redux/constants/widgetConstants'
import i18n from 'src/services/language.service'

const errorMessage = i18n.t('ERROR_MESSAGES.DEFAULT')
const widgetUpcomingARGetReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case WIDGET_UPCOMING_AR_GET_REQUEST:
      return { loading: true }
    case WIDGET_UPCOMING_AR_GET_SUCCESS:
      return {
        loading: false,
        appointmentRequests: action.payload.appointmentRequests,
        count: action.payload.count
      }
    case WIDGET_UPCOMING_AR_GET_FAIL:
      return { loading: false, errorMessage }
    default:
      return state
  }
}

const widgetLocationsEmployeesNumberGetReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case WIDGET_LOCATION_USER_NUMBER_GET_REQUEST:
      return { loading: true }
    case WIDGET_LOCATION_USER_NUMBER_GET_SUCCESS:
      return {
        loading: false,
        numbers: action.payload
      }
    case WIDGET_LOCATION_USER_NUMBER_GET_FAIL:
      return { loading: false, errorMessage }
    default:
      return state
  }
}

const widgetSendFeedbackReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case WIDGET_SEND_FEEDBACK_REQUEST:
      return { loading: true }
    case WIDGET_SEND_FEEDBACK_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case WIDGET_SEND_FEEDBACK_FAIL:
      return { loading: false, errorMessage }
    case WIDGET_SEND_FEEDBACK_RESET:
      return {}
    default:
      return state
  }
}

export const widgetReducers = {
  widgetUpcomingARGet: widgetUpcomingARGetReducer,
  widgetLocationsEmployeesNumberGet: widgetLocationsEmployeesNumberGetReducer,
  widgetSendFeedback: widgetSendFeedbackReducer
}
