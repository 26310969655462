export const PRACTICE_CREATE_REQUEST = 'PRACTICE_CREATE_REQUEST'
export const PRACTICE_CREATE_SUCCESS = 'PRACTICE_CREATE_SUCCESS'
export const PRACTICE_CREATE_FAIL = 'PRACTICE_CREATE_FAIL'
export const PRACTICE_CREATE_RESET = 'PRACTICE_CREATE_RESET'

export const PRACTICE_EDIT_REQUEST = 'PRACTICE_EDIT_REQUEST'
export const PRACTICE_EDIT_SUCCESS = 'PRACTICE_EDIT_SUCCESS'
export const PRACTICE_EDIT_FAIL = 'PRACTICE_EDIT_FAIL'
export const PRACTICE_EDIT_RESET = 'PRACTICE_EDIT_RESET'

export const PRACTICE_GET_ONE_REQUEST = 'PRACTICE_GET_ONE_REQUEST'
export const PRACTICE_GET_ONE_SUCCESS = 'PRACTICE_GET_ONE_SUCCESS'
export const PRACTICE_GET_ONE_FAIL = 'PRACTICE_GET_ONE_FAIL'
export const PRACTICE_GET_ONE_RESET = 'PRACTICE_GET_ONE_RESET'

export const PRACTICE_GET_ALL_REQUEST = 'PRACTICE_GET_ALL_REQUEST'
export const PRACTICE_GET_ALL_SUCCESS = 'PRACTICE_GET_ALL_SUCCESS'
export const PRACTICE_GET_ALL_FAIL = 'PRACTICE_GET_ALL_FAIL'

export const PRACTICE_DELETE_REQUEST = 'PRACTICE_DELETE_REQUEST'
export const PRACTICE_DELETE_SUCCESS = 'PRACTICE_DELETE_SUCCESS'
export const PRACTICE_DELETE_FAIL = 'PRACTICE_DELETE_FAIL'
export const PRACTICE_DELETE_RESET = 'PRACTICE_DELETE_RESET'

export const BAS_PRACTICE_FIND_RELATION_REQUEST = 'BAS_PRACTICE_FIND_RELATION_REQUEST'
export const BAS_PRACTICE_FIND_RELATION_SUCCESS = 'BAS_PRACTICE_FIND_RELATION_SUCCESS'
export const BAS_PRACTICE_FIND_RELATION_FAIL = 'BAS_PRACTICE_FIND_RELATION_FAIL'

export const PRACTICE_WORKING_HOURS_GET_REQUEST = 'PRACTICE_WORKING_HOURS_GET_REQUEST'
export const PRACTICE_WORKING_HOURS_GET_SUCCESS = 'PRACTICE_WORKING_HOURS_GET_SUCCESS'
export const PRACTICE_WORKING_HOURS_GET_FAIL = 'PRACTICE_WORKING_HOURS_GET_FAIL'
